import gql from 'graphql-tag';

const updatePasswordQuery = gql`
  mutation($password: String!, $confirmPassword: String!) {
    updatePassword(password: $password, confirmPassword: $confirmPassword) {
      id
    }
  }
`;

export { updatePasswordQuery };
