import React, { HTMLProps } from 'react';

type EmailSendedProps = Omit<HTMLProps<HTMLImageElement>, 'crossOrigin'>;

const EmailSended = ({
  alt = 'E-mail enviado com sucesso',
  width = '24px',
  height = '24px',
  ...props
}: EmailSendedProps) => {
  return (
    <img
      {...props}
      alt={alt}
      src="/icons/email-sended.svg"
      width={width}
      height={height}
    />
  );
};

export type { EmailSendedProps };
export { EmailSended };
