import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Locales = {
  default: string,
  langs: Array<{
    name: string,
    suitable: Array<string>,
  }>,
}

type LocalizationContextProps = {
  currentLocaleKey: string | null;
  setCurrentLocaleKey: (currentLocaleKey: string) => void;
  locale: (key: string) => string;
};

type LocalizationProviderProps = {
  children: ReactNode;
  fallback: ReactNode;
  localesJsonFilesRelativePath?: string;
  locales: Locales;
  localeFile: { [key: string]: string };
};

const LocalizationContext = createContext({} as LocalizationContextProps);

const getCurrentLang = (locales: Locales): string | null => {
  if (typeof window === 'undefined') return locales.default;
  const lang =
    localStorage.getItem('currentLocale') ??
    navigator.language ??
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).userLanguage;
  if (locales.langs.map((l: { name: string }) => l.name).includes(lang))
    return lang;
  for (const l of locales.langs) {
    if (l.suitable.includes(lang)) return lang;
  }
  return locales.default;
};

const LocalizationProvider = ({
  children,
  locales,
  fallback,
  localesJsonFilesRelativePath = './locales/',
  localeFile,
}: LocalizationProviderProps) => {
  const [currentLocaleKey, setCurrentLocaleKey] = useState<string | null>(
    getCurrentLang(locales),
  );
  const [locale, setLocale] = useState<{ [key: string]: string }>(localeFile);

  useEffect(() => {
    if (currentLocaleKey) {
      import(`${localesJsonFilesRelativePath}${currentLocaleKey}.json`)
        .then(lang => {
          setLocale(lang.default);
        })
        .catch(err => {
          console.error(err);
        });
    };
  }, [currentLocaleKey]);

  const contextValue = useMemo(() => {
    return {
      locale: (key: string) => {
        return locale && Object.keys(locale).includes(key)
          ? locale[key] : key;
      },
      currentLocaleKey,
      setCurrentLocaleKey: (localeKey: string) => {
        if (typeof window !== 'undefined') {
          localStorage.setItem('currentLocale', localeKey);
        }
        setCurrentLocaleKey(localeKey);
      },
    };
  }, [locale, currentLocaleKey]);

  return Object.keys(locale).length > 0 ? (
    <LocalizationContext.Provider value={contextValue}>
      {children}
    </LocalizationContext.Provider>) : fallback;
};

const useLocalization = () => {
  const context = useContext(LocalizationContext);
  return context;
};

export type { Locales };
export { LocalizationContext, LocalizationProvider, useLocalization };
