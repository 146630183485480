import React, { useContext, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useRedirect } from '../../../utils/redirect';
import { AuthenticatorContext } from '../../contexts/AuthenticatorContext';
import * as session from '../../../utils/sessionStorage';

const ProtectedRoute = () => {
  const location = useLocation();
  const redirect = useRedirect();
  const { getToken } = useContext(AuthenticatorContext);

  const accessToken = useMemo(() => {
    if (!getToken) {
      return session.getItem('access_token');
    }
    return getToken();
  }, [getToken]);

  useEffect(() => {
    if (!accessToken) {
      const pathName = location.pathname;
      redirect.to('/login', { params: { redirect_to: pathName } });
    }
  }, [accessToken, location, redirect]);

  return <>{accessToken ? <Outlet /> : null}</>;
};

export {
  ProtectedRoute,
};
