import { css, styled } from 'styled-components';
import { colors, spaces } from '../../tokens';

const CardsListItemCard = styled.div<{ $hoverable: boolean }>`
  background-color: ${colors.white};
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: ${spaces.medium};
  overflow: hidden;
  width: 100%;

  ${({ $hoverable }) => $hoverable ? css`
    &:hover, &:active {
      box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.12);
    }
  ` : null}
`;

const ImagePlaceholderContainer = styled.div`
  width: 125px;
  min-width: 125px;
  height: 140px;
  background-color: ${colors.backgroundPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardsListItemCardButton = styled.button`
  background-color: ${colors.transparent};
  border: none;
  width: 100%;
  height: 100%;
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};

  & * {
    cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
  }
`;

const CardsListInteractiveButtonsRow = styled.div`
  padding: ${`0 ${spaces.xLarge} 0 0`};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;

  & > * {
    margin-right: ${spaces.medium};
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export {
  CardsListItemCard,
  ImagePlaceholderContainer,
  CardsListItemCardButton,
  CardsListInteractiveButtonsRow,
};
