import React, { ReactNode } from 'react';
import { Typography } from '..';
import { colors } from '../../tokens';
import { MainMenuItemStyle, SelectedArrow } from './MainMenuItem.styles';

type MainMenuItemProps = {
  title: string;
  icon: ReactNode;
  selected: boolean;
  onClick: () => void;
};

const MainMenuItem = ({
  title,
  selected,
  onClick,
  icon,
}: MainMenuItemProps) => {
  return (
    <MainMenuItemStyle onClick={onClick} title={title}>
      {selected && <SelectedArrow />}
      {icon}
      <Typography variant="button" color={colors.white}>
        {title}
      </Typography>
    </MainMenuItemStyle>
  );
};

export { MainMenuItem };
