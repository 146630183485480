import gql from 'graphql-tag';

const institutionsTypesQuery = gql`
  query {
    institutionsTypes {
      id
      guid
      createdAt
      updatedAt
      type
    }
  }
`;

export { institutionsTypesQuery };
