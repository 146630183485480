import { AddressModel } from '../models/AddressModel';

const formatAddress = (address: AddressModel | null): string => {
  if (address === null) {
    return '';
  }

  const addressTopics = [];
  const streetTopic = [];
  const cityStateCountryTopic = [];

  if (address.street) {
    streetTopic.push(address.street);
  }

  if (address.number) {
    streetTopic.push(address.number);
  }

  if (streetTopic.length > 0) {
    addressTopics.push(streetTopic.join(', '));
  }

  if (address.zipcode) {
    addressTopics.push(`CEP: ${address.zipcode}`);
  }

  if (address.city) {
    cityStateCountryTopic.push(
      address.city.cityName,
    );
  }

  if (address.state) {
    cityStateCountryTopic.push(
      address.state.abbreviation,
    );
  }

  if (address.country) {
    cityStateCountryTopic.push(
      address.country.countryLocalizations?.find(loc => loc.language.langCode === 'pt-BR'),
    );
  }

  if (cityStateCountryTopic.length > 0) {
    addressTopics.push(cityStateCountryTopic.join(', '));
  }

  return addressTopics.join(' - ');
};

export {
  formatAddress,
};
