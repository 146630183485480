import gql from 'graphql-tag';

const findMeasureUnitQuery = gql`
  query ($name: String!) {
  findMeasureUnit(name: $name) {
      id
      guid
      createdAt
      updatedAt
      name
    }
  }
`;

export { findMeasureUnitQuery };
