import gql from 'graphql-tag';

const disableApiTokenQuery = gql`
  mutation($storeId: Int!, $apiTokenId: Int!) {
    disableApiToken(storeId: $storeId, apiTokenId: $apiTokenId) {
      id
      guid
      createdAt
      updatedAt
      token
      domain
      enabled
    }
  }
`;

export { disableApiTokenQuery };
