import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import { ContentHeader, ContentWrapper } from '../../../ui';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { ResidueFormWrapper } from './ResidueForm';

const ResiduesEditCreatePage = () => {
  const { storeId, itemId } = useParams();
  const { store } = useContext(StoreContext);

  return (
    <Page variant="primary">
      <StoreMenu current={mainMenuOptions.residues} />
      <ContentWrapper>
        <ContentHeader
          title={itemId ? 'Editar resíduo' : 'Adicionar resíduo'}
          subtitle={itemId ? 'Edite o resíduo' : 'Crie um novo resíduo'}
          breadcrumbs={[
            {
              name: 'Empresas',
              link: '/store',
            },
            {
              name: 'Início',
              link: `/store/${store?.id}`,
            },
            {
              name: mainMenuOptions.residues,
              link: `/store/${storeId}/residue`,
            },
            {
              name: !itemId ? 'Novo item' : 'Edição de resíduo',
              link: !itemId ? `/store/${storeId}/residue/item` : `/store/${storeId}/residue/item/${itemId}`,
            },
          ]}
        />
        <ResidueFormWrapper />
      </ContentWrapper>
    </Page>
  );
};

export { ResiduesEditCreatePage };
