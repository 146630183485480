import React, { ReactNode } from 'react';
import { colors } from '../../tokens';
import { Container, WhiteBodyReset, PrimaryBodyReset } from './Page.styles';

interface PageProps {
  children: ReactNode;
  variant?: 'white' | 'primary';
  height?: string;
}

const Page = ({
  children,
  variant = 'white',
  height = 'auto',
}: PageProps) => {
  return (
    <Container
      $backgroundColor={variant === 'white' ? colors.white : colors.backgroundPrimary}
      $height={height}
    >
      {variant === 'white' ? <WhiteBodyReset /> : <PrimaryBodyReset />}
      {children}
    </Container>
  );
};

export { Page };
