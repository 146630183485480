import React, { useContext, useMemo, useState } from 'react';
import {
  Dropdown,
  Avatar,
  Typography,
  Row,
  spaces,
  Col,
  colors,
  DropdownOption,
} from '../../../ui';
import { AuthenticatorContext } from '../../contexts/AuthenticatorContext';
import * as session from '../../../utils/sessionStorage';
import { useRedirect } from '../../../utils/redirect';
import { StoreContext } from '../../contexts/StoreContext';
import { AccountContainer, AvatarWrapper } from './Account.styles';

type AccountProps = {
  right?: number;
};

const Account = ({ right = 50 }: AccountProps) => {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const { currentUser } = useContext(AuthenticatorContext);
  const { store } = useContext(StoreContext);
  const redirect = useRedirect();

  const logOut = () => {
    session.removeItem('access_token');
    session.removeItem('refresh_token');
  };

  const accountMenu = useMemo(() => {
    const menuOptions: Array<DropdownOption> = [];

    if (store?.id && currentUser?.id) {
      menuOptions.push({
        title: 'Configuração',
        action: () => redirect.to(`/store/${store?.id}/team/member/${currentUser.id}`),
      });
    }

    menuOptions.push({
      title: 'Sair',
      action: logOut,
      href: '/login',
    });

    return menuOptions;
  }, [redirect, currentUser]);

  return (
    <AccountContainer
      $right={right}
      onClick={() => setDropDownIsOpen(!dropDownIsOpen)}
      id="account-container"
    >
      <Row justifyContent="flex-end">
        <Col gutter={spaces.xxSmall}>
          <Typography
            variant="paragraph"
            color={colors.black}
            align="right"
            wide
          >{currentUser?.name}</Typography>
          {store?.role?.name && <Typography
            variant="caption"
            color={colors.primary}
            align="right"
            style={{ fontStyle: 'italic' }}
            wide
          >{store.role.name}</Typography>}
        </Col>
        <AvatarWrapper $roleName={store?.role?.name}>
          <Dropdown
            isOpen={dropDownIsOpen}
            title="Minha Conta"
            align="right"
            listItems={accountMenu}
            onClose={() => null}
          >
            <Avatar
              username={currentUser?.name}
              imageUrl={currentUser?.avatar?.url}
            />
          </Dropdown>
        </AvatarWrapper>
      </Row>
    </AccountContainer>
  );
};

export { Account };
