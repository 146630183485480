import React, {
  ReactNode,
  useState,
  useMemo,
  useRef,
  useCallback,
  ChangeEvent,
  useEffect,
} from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { FileInfo } from '../../../../common/models';
import { deleteMedia, uploadMedia, validateFile } from '../../../utils/file';
import { ApiEndpointProps } from '../../../utils/apiEndpoints';
import {
  UploadIcon,
  ImagePlaceholder,
} from '../../icons';
import {
  colors,
  spaces,
} from '../../tokens';
import { SnackbarProps } from '../Snackbar';
import { ProgressIndicator } from '../ProgressIndicator';
import { Center } from '../Center';
import { Row } from '../Row';
import { Padding } from '../Padding';
import { Typography } from '../Typography';
import {
  ImageCardContainer,
  ImageCardImage,
  ImageCardIconWrapper,
  EditIconWrapper,
} from './ImageCard.styles';

interface ImageCardProps<TFields extends FieldValues, TImageModel> {
  id: string;
  src?: string;
  alt?: string;
  uploadable?: boolean;
  width?: number;
  icon?: ReactNode;
  wide?: boolean;
  register?: UseFormRegister<TFields>;
  state?: 'valid' | 'neutral' | 'invalid';
  errorMessage?: string;
  onClick?: () => void;
  value?: string | number | string[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  fitParent?: boolean;
  showActionIconOnHoverOnly?: boolean;
  onSnackbar?: (props: SnackbarProps) => void;
  postUpload?: (file: FileInfo) => Promise<TImageModel>;
  apiEndpointProps?: ApiEndpointProps;
};

const ImageCard = <TFields extends FieldValues, TImageModel>({
  id,
  onClick,
  register,
  postUpload,
  onSnackbar = () => null,
  icon = <ImagePlaceholder />,
  src = undefined,
  alt = undefined,
  uploadable = false,
  width = 125,
  wide = false,
  state = 'neutral',
  errorMessage = '',
  fitParent = false,
  showActionIconOnHoverOnly = false,
  apiEndpointProps,
  ...rest
}: ImageCardProps<TFields, TImageModel>) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [files, setFiles] = useState<FileList>(null);
  const [media, setMedia] = useState<TImageModel>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const fileInputRef = useRef(null);
  const isInvalid = useMemo(() => state === 'invalid', [state]);

  const handleOnChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFetching(true);

    const files = (e.target as HTMLInputElement)?.files;
    const valid = await validateFile(files);

    if (valid.error) {
      onSnackbar({
        title: `Não foi possível enviar o arquivo. ${valid.error.message}`,
        actionText: 'OK',
        fail: true,
      });
      setFetching(false);
      return;
    }

    try {
      const response = await uploadMedia(files, { apiEndpointProps });

      if (response.errors && response.errors.length > 0) {
        onSnackbar({
          title: `Não foi possível enviar o arquivo. ${response.errors[0].message}`,
          actionText: 'OK',
          fail: true,
        });
        setFetching(false);
        return;
      }

      if (postUpload) {
        const file = response.files[0];
        postUpload(file).then((media) => {
          onSnackbar({
            title: `Arquivo "${file.fileName}" enviado com sucesso.`,
            actionText: 'OK',
            fail: false,
          });
          setFetching(false);
          setFiles(files);
          setMedia(media);
          setImgSrc(
            URL.createObjectURL(
              ((e.target as HTMLInputElement)?.files as FileList)[0],
            ),
          );
        }).catch(async (err) => {
          await deleteMedia(file.fileName, { apiEndpointProps });
          onSnackbar({
            title: `Não foi possível enviar o arquivo "${file.fileName}". ${err?.message ?? err?.toString()}`,
            actionText: 'OK',
            fail: true,
          });
          setFetching(false);
        });
      } else {
        setFetching(false);
      }
    } catch (err) {
      onSnackbar({
        title: `Não foi possível enviar o arquivo. ${err?.message ?? err?.toString()}`,
        actionText: 'OK',
        fail: true,
      });
      setFetching(false);
    }
  }, []);

  const restWithRegister = useMemo(() => register ? {
    ...rest,
    onChange: handleOnChange,
    ...(register as UseFormRegister<FieldValues>)(id, {
      onChange: handleOnChange,
    }),
  } : { ...rest, onChange: handleOnChange }, [register, rest]);

  useEffect(() => {
    if (imgSrc && files && media) {
      rest.onChange({ target: { files, value: JSON.stringify(media) } } as ChangeEvent<HTMLInputElement>);
    }
  }, [imgSrc, files, media]);

  return (
    <>
      <ImageCardContainer
        width={width}
        $wide={wide}
        $fitParent={fitParent}
        $clickable={uploadable || onClick !== undefined}
        $isInvalid={isInvalid}
        $showActionIconOnHoverOnly={showActionIconOnHoverOnly}
        onClick={() => {
          if (uploadable) {
            fileInputRef?.current?.click();
          }
          if (onClick) onClick();
        }}
      >
        {imgSrc && !fetching ? (
          <ImageCardImage src={imgSrc} alt={alt} />
        ) : fetching ? (
          <ImageCardIconWrapper>
            <Center>
              <ProgressIndicator color={colors.backgroundPrimary} />
            </Center>
          </ImageCardIconWrapper>
        ) : (
          <ImageCardIconWrapper>{icon}</ImageCardIconWrapper>
        )}
        {uploadable && (
          <EditIconWrapper>
            <UploadIcon />
            <input
              id={id}
              name={id}
              type="file"
              style={{ display: 'none' }}
              {...restWithRegister}
              ref={fileInputRef}
            />
          </EditIconWrapper>
        )}
      </ImageCardContainer>
      {isInvalid && (
        <Row>
          <Padding padding={`${spaces.large} 0 0`}>
            <Typography variant="caption" color={colors.error}>
              {isInvalid && errorMessage}
            </Typography>
          </Padding>
        </Row>
      )}
    </>
  );
};

export { ImageCard };
