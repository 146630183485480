import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import { Card, ContentHeader, ContentWrapper, Padding, spaces } from '../../../ui';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { useRedirect } from '../../../utils/redirect';
import { TeamMemberEditForm } from './TeamMemberEditForm';
import { TeamMemberInviteForm } from './TeamMemberInviteForm';
import { AuthenticatorContext } from '../../contexts/AuthenticatorContext';
import { UpdatePasswordForm } from './UpdatePasswordForm/UpdatePasswordForm';
import { SnackbarContext } from '../../contexts/SnackbarContext';

const TeamMemberEditInvitePage = () => {
  const { storeId, memberId } = useParams();
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();
  const { addSnackbar } = useContext(SnackbarContext);
  const { currentUser } = useContext(AuthenticatorContext);

  const form = useMemo(() => {
    return memberId ?
      <TeamMemberEditForm storeId={parseInt(storeId)} memberId={parseInt(memberId)} onExitForm={() => redirect.to(`/store/${storeId}/team`)} /> :
      <TeamMemberInviteForm onExitForm={() => redirect.to(`/store/${storeId}/team`)} />;
  }, [memberId, queryParams, redirect]);

  const isUser = useMemo(() => currentUser && memberId && (currentUser.id === parseInt(memberId)), [memberId, currentUser]);

  return (
    <Page variant="primary">
      <StoreMenu current={mainMenuOptions.team} />
      <ContentWrapper>
        <ContentHeader
          title={memberId ? 'Editar membro da equipe' : 'Convidar membro da equipe'}
          subtitle={memberId ? 'Edite informações do membro da equipe' : 'Convide um membro para sua equipe dessa empresa'}
          breadcrumbs={[
            {
              name: 'Empresas',
              link: '/store',
            },
            {
              name: 'Início',
              link: `/store/${store?.id}`,
            },
            {
              name: mainMenuOptions.team,
              link: `/store/${storeId}/team`,
            },
            {
              name: !memberId ? 'Convidar' : 'Editar membro',
              link: !memberId ? `/store/${storeId}/team/member` : `/store/${storeId}/team/member/${memberId}`,
            },
          ]}
        />
        <Card padding={spaces.xLarge}>
          {form}
        </Card>
        {isUser && (
          <Padding padding={`${spaces.xLarge} ${spaces.none}`}>
            <Card padding={spaces.xLarge}>
              <UpdatePasswordForm
                title="Alterar senha"
                onSubmitSucceeded={() => {
                  addSnackbar({
                    title: 'Senha alterada com sucesso.',
                    actionText: 'OK',
                    fail: false,
                  });
                }}
              />
            </Card>
          </Padding>
        )}
      </ContentWrapper>
    </Page>
  );
};

export { TeamMemberEditInvitePage };
