import React, { useState, ChangeEvent, useEffect, useMemo } from 'react';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import { CheckboxIcon, CheckBoxOutlineBlank } from '../../icons';
import { colors } from '../../tokens';
import { Input, Label, LabelText } from './Checkbox.styles';

interface CheckboxProps<T extends FieldValues> {
  label?: string;
  id: string;
  checked?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  register?: UseFormRegister<T>;
  errorMessage?: string;
}

const Checkbox = <T extends FieldValues,>({
  label,
  id,
  checked = false,
  disabled = false,
  value = '',
  onChange = () => null,
  register,
  errorMessage = '',
}: CheckboxProps<T>) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);

    onChange(event);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const restWithRegister = useMemo(() => register ? {
    ...(register as UseFormRegister<FieldValues>)(id, {
      onChange: handleOnChange,
    }),
  } : {
    onChange: handleOnChange,
  }, [register]);

  const hasError = useMemo(() => typeof errorMessage !== 'undefined' && errorMessage !== '', [errorMessage]);

  return (
    <Label
      htmlFor={id}
      $hasError={hasError}
    >
      <Input
        id={id}
        value={value}
        disabled={disabled}
        checked={isChecked}
        aria-checked={isChecked}
        {...restWithRegister}
      />
      {isChecked ? (
        <CheckboxIcon fill={colors.primary} />
      ) : (
        <CheckBoxOutlineBlank fill={colors.primary} />
      )}
      <LabelText>{label}</LabelText>
    </Label>
  );
};

export { Checkbox };
