import React from 'react';
import { AvatarWrapper, FallbackAvatar } from './Avatar.styles';
import { Size } from './Avatar.types';
import { UserIcon } from '../../icons';

interface AvatarProps {
  imageUrl?: string;
  username?: string;
  size?: Size;
}

const Avatar = ({ imageUrl, username = '', size = 'large' }: AvatarProps) => {
  return imageUrl ? (
    <AvatarWrapper
      src={imageUrl}
      aria-label={username}
      size={size}
    />
  ) : (
    <FallbackAvatar size={size}>
      <UserIcon />
    </FallbackAvatar>
  );
};

export { Avatar };
