import { styled } from 'styled-components';
import { zIndex, spaces } from '../../../ui';

const AccountContainer = styled.div<{ $right: number }>`
  cursor: pointer;
  position: absolute;
  right: ${({ $right }) => `${$right}px`};
  top: ${spaces.small};
  z-index: ${zIndex.mountains};

  & * {
    cursor: pointer;
  }
`;

const AvatarWrapper = styled.div<{ $roleName?: string }>`
  margin-left: ${spaces.small};
  margin-top: ${({ $roleName }) => $roleName ? '-32px' : '-8px'};
`;

export { AccountContainer, AvatarWrapper };
