/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useState } from 'react';
import axios, { AxiosResponse, RawAxiosResponseHeaders, AxiosResponseHeaders } from 'axios';
import { DocumentNode } from 'graphql';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ApiEndpointProps, apiRoutes, getApiEndpoint } from './apiEndpoints';
import { useRedirect } from './redirect';
import * as session from './sessionStorage';
import { RequestReturnedNoDataError } from './errors';
import { SnackbarContext } from '../dashboard/contexts/SnackbarContext';
import { AuthenticatorContext } from '../dashboard/contexts/AuthenticatorContext';

type GraphQlOptions = {
  startFetching?: boolean,
  failOnNull?: boolean,
  failOnAnyError?: boolean,
  apiEndpointProps?: ApiEndpointProps,
};

function useGraphQl<TModel = any, TVariables = any>(
  setup: {
    query: DocumentNode,
    url?: string,
    headers?: Record<string, string>,
  },
  {
    startFetching,
    failOnAnyError,
    failOnNull,
    apiEndpointProps,
  }: GraphQlOptions = {
      startFetching: true,
      failOnNull: false,
      failOnAnyError: true,
    }) {
  const { getToken, getRefreshToken } = useContext(AuthenticatorContext);

  const setAuthorizationFn = useCallback(() => {
    if (getToken) {
      return `Bearer ${getToken()}`;
    }
    return `Bearer ${session.getItem<string | null>('access_token')}`;
  }, [getToken]);

  const setRefreshTokenFn = useCallback(() => {
    if (getRefreshToken) {
      return getRefreshToken();
    }
    return session.getItem<string | null>('refresh_token');
  }, [getRefreshToken]);

  const setCorrelationIdFn = (headers?: RawAxiosResponseHeaders | AxiosResponseHeaders) => {
    let correlationId = session.getItem<string | null>('correlation_id');
    if (correlationId) return correlationId;
    correlationId = headers ? headers['x-mecena-correlation-id'] ?? headers['X-Mecena-Correlation-Id'] : null;
    if (correlationId) session.setItem('correlation_id', correlationId);
    return correlationId;
  };

  const { mutateAsync, isLoading } = useMutation(
    (variables?: TVariables) => {
      return axios.post<{ data: TModel, errors?: Array<Error> }>(setup.url ?? getApiEndpoint({
        ...apiEndpointProps,
        route: apiRoutes.admin,
      }), {
        query: setup.query.loc?.source.body,
        variables,
      }, {
        headers: {
          ...setup.headers,
          authorization: setAuthorizationFn(),
          'X-Mecena-Ref-Token': setRefreshTokenFn(),
          'X-Mecena-Correlation-Id': setCorrelationIdFn(),
        },
      });
    });

  const location = useLocation();
  const redirect = useRedirect();
  const [fetching, setFetching] = useState(startFetching ?? true);
  const { addSnackbar } = useContext(SnackbarContext);

  return {
    isFetching: useCallback(() => fetching, [fetching]),
    invoke: useCallback(({
      variables,
      onSuccess,
      onFail,
    }: {
      variables?: TVariables,
      onSuccess?: (data: AxiosResponse<TModel, any>) => void,
      onFail?: (err: Error, errors?: Array<Error>) => void,
    }) => {
      if (isLoading) return;

      mutateAsync(variables).then((response) => {
        if (response.data.errors && failOnAnyError) {
          if (onFail) onFail(response.data.errors[0], response.data.errors);
          setFetching(false);
          return;
        }
        // FIXME: Em alguns casos o back-end retorna um erro nessa estrutura.
        if (Object.keys(response.data).includes('errorMessage')) {
          if (onFail) onFail(new Error((response.data as unknown as { errorMessage: string }).errorMessage));
          setFetching(false);
          return;
        }
        if (!response.data.data && failOnNull) {
          if (onFail) onFail(new RequestReturnedNoDataError());
          setFetching(false);
          return;
        }
        setCorrelationIdFn(response.headers);
        if (onSuccess) onSuccess({ ...response, data: response.data.data });
        setFetching(false);
      }).catch((err) => {
        if (onFail) onFail(err);

        if (err?.message === 'Network Error') {
          addSnackbar({
            title: 'Erro de conexão com o servidor.',
            actionText: 'OK',
            fail: true,
          });
        }

        if (err?.response?.data?.error && err.response.data.error.name === 'UnauthorizedRequestError') {
          session.removeItem('access_token');
          session.removeItem('refresh_token');
          const pathName = location.pathname;
          redirect.to('/login', { params: { redirect_to: pathName } });
        }

        setFetching(false);
      });
    }, [mutateAsync, redirect, location]),
  };
};

export { useGraphQl };
