import gql from 'graphql-tag';

const inviteTeamMemberMutation = gql`
  mutation(
    $email: String!
    $roleName: String!
  ) {
    inviteTeamMember(
      email: $email
      roleName: $roleName
    ) {
      id
    }
  }
`;

export {
  inviteTeamMemberMutation,
};
