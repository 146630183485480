import React, { SVGProps } from 'react';

const ArrowHeadEnd = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 10 26" width={10} height={26} {...props}>
      <polygon points="0,26 10,13 0,0 " />
    </svg>
  );
};

export { ArrowHeadEnd };
