import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type Redirect = (
  route?: string | null,
  options?: {
    params?: { [key: string]: string },
    doNotPreserveParams?: boolean,
    removeParams?: Array<string>,
    scroll?: boolean,
    repeatKeys?: boolean,
  },
) => void;

const useRedirect = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  return {
    to: useCallback<Redirect>((route, options) => {
      let qs: string | null = null;

      if (!options?.doNotPreserveParams) {
        const newParams = options?.params ?? {};

        Object.keys(newParams).forEach((paramKey) => {
          newParams[paramKey] = encodeURI(newParams[paramKey]).replaceAll('/', '%2F');
        });

        let currentParams = queryParams.toString().split('&');

        options?.removeParams?.forEach((rmParamKey) => {
          currentParams = currentParams.filter((currentParam) => !currentParam.includes(`${rmParamKey}=`));
        });

        if (!options?.repeatKeys) {
          const params = new Set();
          currentParams = currentParams.map((currParam) => {
            const [key, value] = currParam.split('=');
            return { key, value };
          }).filter(({ key }) => {
            if (params.has(key) || key === '' || Object.keys(newParams).includes(key)) {
              return false;
            } else {
              params.add(key);
              return true;
            }
          }).map(({ key, value }) => `${key}=${value}`);
        }

        qs = [...currentParams,
        ...Object
          .keys(newParams)
          .map((paramKey) => `${paramKey}=${newParams[paramKey]}`)]
          .join('&');
      }
      navigate(qs ? `${route ?? '/'}?${qs}` : route ?? '/');
    }, [queryParams]),
  };
};

const initialRedirectTo = (queryParams: URLSearchParams, redirect: { to: Redirect }) => {
  const redirectToParam = queryParams.get('redirect_to');
  const redirectTo = redirectToParam === '/login' ? '/store' : redirectToParam;
  redirect.to(redirectTo, { removeParams: ['redirect_to'] });
};

export { useRedirect, initialRedirectTo };
