import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useStateMachine } from '../../contexts/StateMachine';
import { EyeIcon, EyeOffIcon } from '../../../ui';
import {
  Button,
  Form,
  FormControl,
  Padding,
  TextField,
  Typography,
  GridContainer,
  GridItem,
  colors,
} from '../../../ui';
import { useRedirect } from '../../../utils/redirect';
import { newPasswordQuery } from '../../queries';
import { authPageStateValues } from '../../../../common/models';
import { useGraphQl } from '../../../utils/gql';

type FormDataType = {
  password?: string;
  confirmPassword?: string;
} & FieldValues;

const newPasswordValidationSchema = yup.object({
  password: yup.string().required('A senha é obrigatória'),
  confirmPassword: yup
    .string()
    .required('A confirmação da senha é obrigatória')
    .oneOf([yup.ref('password')], 'As senhas devem ser iguais!'),
});

const NewPasswordForm = () => {
  const [hidePassword, setHidePassword] = useState(true);
  const { setState } = useStateMachine();
  const [params] = useSearchParams();
  const redirect = useRedirect();
  const newPasswordRequest = useGraphQl({
    query: newPasswordQuery,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataType>({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    resolver: yupResolver(newPasswordValidationSchema),
  });

  const onSubmit = (data: FormDataType) => {
    setState(authPageStateValues.loading);
    if (!params.get('username') || !params.get('token')) {
      setTimeout(() => {
        redirect.to('/login');
      }, 1000);
      return;
    }

    newPasswordRequest.invoke({
      onFail: (newPasswordError) => {
        setState(authPageStateValues.error, {
          errorMessage: newPasswordError?.message,
        });
      },
      onSuccess: () => {
        // show({ title: 'Senha alterada com sucesso!' });
        // setState(authPageStateValues.login, { userName: params.username });
        setState(authPageStateValues.success, {
          successTitle: 'Senha alterada com sucesso!',
          successBody:
            'Agora é possível efetuar seu login com essa nova senha.',
        });
      },
      variables: {
        userName: params.get('username'),
        newPasswordToken: params.get('token'),
        password: data.password,
      },
    });
  };

  return (
    <Padding padding="20px 0" wide>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Typography variant="subtitle" color={colors.primary} align="center">
            Alterar senha
          </Typography>
        </FormControl>
        <FormControl>
          <TextField
            id="password"
            type={hidePassword ? 'password' : 'text'}
            label="senha"
            register={register}
            errorMessage={errors.password?.message}
            state={errors.password ? 'invalid' : 'neutral'}
            iconButton={{
              icon: hidePassword ? <EyeOffIcon /> : <EyeIcon />,
              label: hidePassword ? 'exibir senha' : 'esconder senha',
              onClick: () => setHidePassword(!hidePassword),
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="confirmPassword"
            type={hidePassword ? 'password' : 'text'}
            label="confirmar senha"
            register={register}
            errorMessage={errors.confirmPassword?.message}
            state={errors.confirmPassword ? 'invalid' : 'neutral'}
            iconButton={{
              icon: hidePassword ? <EyeOffIcon /> : <EyeIcon />,
              label: hidePassword ? 'exibir senha' : 'esconder senha',
              onClick: () => setHidePassword(!hidePassword),
            }}
          />
        </FormControl>
        <GridContainer noPadding>
          <GridItem lg={6}>
            <FormControl>
              <Button
                variant="back"
                wide
                type="button"
                onClick={() => {
                  setState(authPageStateValues.login);
                }}
              >
                Voltar
              </Button>
            </FormControl>
          </GridItem>
          <GridItem lg={6}>
            <FormControl>
              <Button variant="default" wide type="submit">
                Confirmar
              </Button>
            </FormControl>
          </GridItem>
        </GridContainer>
      </Form>
    </Padding>
  );
};

export { NewPasswordForm };
