import gql from 'graphql-tag';

const createApiTokenQuery = gql`
  mutation($storeId: Int!, $domain: String!) {
    createApiToken(storeId: $storeId, domain: $domain) {
      id
      guid
      createdAt
      updatedAt
      token
      domain
      enabled
    }
  }
`;

export { createApiTokenQuery };
