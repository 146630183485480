import { styled } from 'styled-components';
import { mix } from 'polished';
import { colors } from '../../tokens';

const EditIconWrapper = styled.div`
  width: 45px;
  height: 45px;
  fill: ${colors.primary};
  background-color: ${colors.white};
  box-shadow: 0px 1px 3px ${colors.dropShadow};
  border-radius: 4px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    background-color: ${mix(0.9, colors.white, colors.primary)};
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const ImageCardContainer = styled.div<{
  width: number;
  $wide: boolean;
  $clickable: boolean;
  $isInvalid: boolean;
  $fitParent: boolean;
  $showActionIconOnHoverOnly: boolean;
}>`
  width: ${({ width, $wide, $fitParent }) => ($fitParent ? '100%' : $wide ? '100%' : `${width}px`)};
  height: ${({ width, $wide, $fitParent }) => ($fitParent ? '100%' : $wide ? '0' : `${width}px`)};
  padding-top: ${({ $wide }) => ($wide ? '100%' : 'auto')};
  border: ${({ $isInvalid }) =>
    $isInvalid ? `1px solid ${colors.error}` : 'none'};
  border-radius: ${({ $fitParent }) => $fitParent ? '0' : '8px'};
  background-color: ${colors.backButton};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'auto')};

  & ${EditIconWrapper} {
    display: ${({ $showActionIconOnHoverOnly }) => $showActionIconOnHoverOnly ? 'none' : 'inline'};
  }

  &:hover {
    background-color: ${mix(0.8, colors.backButton, colors.transparent)};

    & ${EditIconWrapper} {
      display: inline;
    }
  }
`;

const ImageCardImage = styled.img`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &:hover {
    opacity: 0.8;
  }
`;

const ImageCardIconWrapper = styled.div`
  position: absolute;
  width: 50%;
  height: 50%;
  fill: ${colors.backgroundPrimary};
  top: 25%;
  left: 25%;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export {
  ImageCardContainer,
  ImageCardImage,
  ImageCardIconWrapper,
  EditIconWrapper,
};
