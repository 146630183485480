import { styled, css } from 'styled-components';
import { colors, spaces, fontSize, fontWeight, fontFamily } from '../../tokens';
import { HTMLAttributes, ReactNode } from 'react';

interface InputLabelProps {
  $isInvalid: boolean;
  $isValid: boolean;
  disabled: boolean;
  $isActive: boolean;
  $placeholder?: string;
}

interface InputWrapperProps {
  $isInvalid: boolean;
  $isValid: boolean;
  disabled: boolean;
  $noPadding?: boolean;
}

interface HintsProps {
  $isInvalid: boolean;
}

const InputWrapperValidationStyles = ({
  $isValid,
  $isInvalid,
  disabled,
}: InputWrapperProps) => {
  if ($isValid && !disabled) {
    return css`
      caret-color: ${colors.primary};
      border-bottom: 3px solid ${colors.primary};
    `;
  }

  if ($isInvalid && !disabled) {
    return css`
      caret-color: ${colors.error};
      border-bottom: 3px solid ${colors.error};
    `;
  }

  if (disabled) {
    return css`
      cursor: not-allowed;
      color: ${colors.disabled};
      border-bottom: 3px solid ${colors.disabled};
    `;
  }

  return css`
    border-bottom: 3px solid ${colors.primary};
  `;
};

const inputLabelValidationStyles = ({
  $isValid,
  $isInvalid,
  disabled,
}: InputLabelProps) => {
  if ($isValid && !disabled) {
    return `color: ${colors.primary};`;
  }
  if ($isInvalid && !disabled) {
    return `color: ${colors.error}`;
  }

  if (disabled) {
    return `color: ${colors.secondary}`;
  }

  return `color: ${colors.secondary}`;
};

const InputRoot = styled.div`
  position: relative;
`;

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  ${({ $noPadding }) =>
    $noPadding
      ? css`
          padding: ${spaces.none};
        `
      : css`
          padding: ${spaces.xLarge} ${spaces.large} ${spaces.xSmall};
        `}
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  height: 70px;
  background-color: ${colors.background};
  transition: all 0.3s ease-in-out;
  border-radius: 8px 8px 0px 0px;
  color: ${colors.primary};
  font-size: ${fontSize.inputLabel};
  font-weight: ${fontWeight.semiBold};
  ${InputWrapperValidationStyles};

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ disabled }) =>
    disabled ? colors.background : colors.backgroundHover};
  }
`;

const InputLabel = styled.label<InputLabelProps>`
  ${inputLabelValidationStyles};
  position: absolute;
  left: ${spaces.small};
  font-size: ${fontSize.inputPlaceholder};
  transition: 0.3s ease all;
  transform: translateY(-50%);
  pointer-events: none;
  color: ${({ $isInvalid }) => $isInvalid ? colors.error : colors.primary};
  ${({ $isActive, $placeholder }) =>
    $isActive
      ? css`
          top: ${spaces.medium};
        `
      : css`
          top: ${$placeholder ? spaces.large : '50%'};
        `}
`;

const InputField = styled.input<HTMLAttributes<HTMLInputElement>>`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: ${fontFamily.montserrat};
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.inputPlaceholder};
  color: ${colors.black};

  &:disabled {
    cursor: not-allowed;
    color: ${colors.disabled};
  }
`;

const HintsWrapper = styled.div<HintsProps>`
  margin-top: ${spaces.xxSmall};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ $isInvalid }) => ($isInvalid ? colors.error : colors.primary)};
  font-size: ${fontSize.inputLabel};
  font-weight: ${fontWeight.semiBold};
`;

const CharactersCounter = styled.span`
  margin-right: ${spaces.small};
`;

const InputMessage = styled.span`
  margin-left: ${spaces.small};
`;

const Prefix = styled.span`
  margin-left: ${spaces.small};
  color: ${colors.secondary};
`;

const TextFieldIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin-left: ${spaces.medium};

  svg {
    fill: ${colors.primary};
  }
`;

export type {
  InputWrapperProps,
};
export {
  InputRoot,
  InputWrapper,
  InputLabel,
  InputField,
  HintsWrapper,
  InputMessage,
  CharactersCounter,
  Prefix,
  TextFieldIconButton,
};
