import { styled } from 'styled-components';

const LogoWrapper = styled.div<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  width: 100%;
  height: 294px;


  & img {
    max-width: 100%;
    height: 147px;
  }

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  width: 100%;
  height: ${`${400 - 294}px`};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export { LogoWrapper, NameWrapper };
