import gql from 'graphql-tag';

const removeProductMutation = gql`
  mutation(
    $id: Int!
    $storeId: Int!
    $collectionName: String!
  ) {
    removeProduct(id: $id, storeId: $storeId, collectionName: $collectionName)
  }
`;

export {
  removeProductMutation,
};
