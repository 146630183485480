import gql from 'graphql-tag';

const addMaterialToGarmentSpecSheetMutation = gql`
  mutation(
    $storeId: Int!
    $productId: Int!
    $materialId: Int!
  ) {
    addMaterialToGarmentSpecSheet(
      storeId: $storeId
      productId: $productId
      materialId: $materialId
    ) {
      id
      itemType
      itemRef
      itemColorName
      itemName
      itemValue
      supplierName
      measures {
          unit
          customUnit
          quantity
      }
      productQuantities {
        unit
        customUnit
        quantity
      }
    }
  }
`;

export { addMaterialToGarmentSpecSheetMutation };
