import gql from 'graphql-tag';

const residuesOptionsQuery = gql`
  query (
    $storeId: Int!
  ) {
    residuesOptions(
      storeId: $storeId
    ) {
      id
      name
    }
  }
`;

export { residuesOptionsQuery };
