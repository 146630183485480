import gql from 'graphql-tag';

const confirmUserNameQuery = gql`
  mutation($userName: String!, $confirmationToken: String!) {
    confirmUserName(
      userName: $userName
      confirmationToken: $confirmationToken
    ) {
      id
    }
  }
`;

export { confirmUserNameQuery };
