import { styled, css, createGlobalStyle } from 'styled-components';
import { colors, screens, zIndex, spaces } from '../../tokens';

const MainMenuContainer = styled.div<{ $visible: boolean }>`
  background-color: ${colors.primary};
  width: 252px;
  height: 100vh;
  left: 0;
  top: 0;
  transition: left 0.3s ease-in-out, width 0.2s ease-in-out;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: ${zIndex.heaven};
  max-width: 90%;

  @media screen and (max-width: ${screens.sm}) {
    ${({ $visible }) =>
    !$visible &&
    css`
        left: -252px;
      `}
  }
`;

const MainMenuOverlay = styled.div<{ $visible: boolean }>`
  background-color: ${colors.overlay};
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  opacity: 0;
  z-index: ${zIndex.purgatory};

  @media screen and (max-width: ${screens.sm}) {
    pointer-events: ${({ $visible }) => ($visible ? 'auto' : 'none')};
    opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  }
`;

const MainMenuButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  position: absolute;
  left: ${spaces.large};
  top: ${spaces.small};

  @media screen and (max-width: ${screens.sm}) {
    display: block;
    cursor: pointer;
  }
`;

const BodyReset = createGlobalStyle`
  body {
    @media screen and (max-width: ${screens.sm}) {
      overflow: hidden !important;
    }
  }
`;

export { MainMenuContainer, MainMenuOverlay, MainMenuButton, BodyReset };
