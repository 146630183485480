import React, { useContext } from 'react';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Form,
  FormControl,
  TextField,
  Typography,
  GridContainer,
  GridItem,
  Button,
  colors,
  spaces,
} from '../../../ui';
import { AuthenticatorContext } from '../../contexts/AuthenticatorContext';
import { useGraphQl } from '../../../utils/gql';
import { createStoreQuery } from '../../queries';
import { apiRoutes, getApiEndpoint } from '../../../utils/apiEndpoints';
import { MediaModel, StoreModel } from '../../../../common/models';
import { cnpjInputProps, yupCnpj } from '../../../utils/cnpj';
import { cleanupCnpj } from '../../../../common/utils';
import { MediaCard } from '../medias';

const createStoreValidationSchema = yup.object({
  storeName: yup.string().required('O nome da empresa é obrigatório.'),
  logoId: yup.number().typeError('Valor do logo inválido.').optional(),
  cnpj: yupCnpj().optional(),
  communicationEmail: yup.string().required('O e-mail de comunicação não pode ficar em branco.'),
});

type StoreCreateFormProps = {
  onSubmitStarted?: () => void,
  onSubmitSucceeded?: (store: StoreModel) => void,
  onSubmitFailed?: (err: Error) => void,
  onExitForm?: () => void,
};

const StoreCreateForm = ({
  onSubmitFailed,
  onSubmitStarted,
  onSubmitSucceeded,
  onExitForm,
}: StoreCreateFormProps) => {
  const { currentUser, addUserStore } = useContext(AuthenticatorContext);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      storeName: undefined,
      logoId: undefined,
      cnpj: undefined,
      communicationEmail: undefined,
    },
    resolver: yupResolver(createStoreValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;
  const createStoreRequest = useGraphQl<{ createStore: StoreModel }>({
    query: createStoreQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const onSubmit = (data: {
    storeName: string,
    logoId?: number,
    cnpj?: string,
    communicationEmail: string,
  }) => {
    if (onSubmitStarted) onSubmitStarted();
    createStoreRequest.invoke({
      variables: {
        name: data.storeName,
        ownerId: currentUser?.id,
        development: false,
        logoId: data.logoId,
        cnpj: cleanupCnpj(data.cnpj),
        communicationEmail: data.communicationEmail,
      },
      onFail: (err) => {
        if (onSubmitFailed) onSubmitFailed(err);
      },
      onSuccess: ({ data: createStoreData }) => {
        if (!createStoreData.createStore) return;
        addUserStore(createStoreData.createStore);
        if (onSubmitSucceeded) onSubmitSucceeded(createStoreData.createStore);
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Typography variant="title" color={colors.primary}>
          Cadastre sua empresa
        </Typography>
        <Typography variant="sublead" color={colors.black} margin={`${spaces.xSmall} 0 0`}>
          Preencha todos os campos para ter acesso a todos os recursos do Mecena.
        </Typography>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={3} md={4} sm={6}>
            <MediaCard
              id="logoMedia"
              uploadable
              wide
              onChange={async ({ target: { value } }) => {
                const media: MediaModel = JSON.parse(value) as MediaModel;
                setValue('logoId', media.id);
              }}
            />
          </GridItem>
          <GridItem lg={9} md={8} sm={12}>
            <TextField
              id="storeName"
              type="text"
              label="nome"
              register={castedRegister}
              errorMessage={errors.storeName?.message}
              state={errors.storeName ? 'invalid' : 'neutral'}
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={6} sm={12}>
            <TextField
              id="cnpj"
              type="text"
              label="CNPJ da empresa"
              register={castedRegister}
              errorMessage={errors.cnpj?.message}
              state={errors.cnpj ? 'invalid' : 'neutral'}
              {...cnpjInputProps}
            />
          </GridItem>
          <GridItem lg={6} sm={12}>
            <TextField
              id="communicationEmail"
              type="text"
              label="E-mail principal da empresa"
              register={castedRegister}
              errorMessage={errors.communicationEmail?.message}
              state={errors.communicationEmail ? 'invalid' : 'neutral'}
              defaultValue={currentUser.userNames[0].userName}
              required
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <GridContainer noPadding>
        <GridItem lg={6}>
          {onExitForm && (
            <Button
              variant="back"
              wide
              type="button"
              onClick={onExitForm}
            >
              Voltar
            </Button>
          )}
        </GridItem>
        <GridItem lg={6}>
          <Button variant="default" wide type="submit">
            Cadastrar
          </Button>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

export { StoreCreateForm };
