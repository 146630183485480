import React, { SVGProps } from 'react';

const UploadIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 30 30" x="0px" y="0px" {...props}>
      <path
        d="M28.5,18c-0.8,0-1.5,0.7-1.5,1.5v6c0,0.8-0.7,1.5-1.5,1.5h-21C3.7,27,3,26.3,3,25.5v-6C3,18.7,2.3,18,1.5,18S0,18.7,0,19.5
        v6C0,28,2,30,4.5,30h21c2.5,0,4.5-2,4.5-4.5v-6C30,18.7,29.3,18,28.5,18z"
      />
      <path
        d="M8.6,10.1l4.9-4.9v14.4c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V5.1l4.9,4.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4
        c0.6-0.6,0.6-1.5,0-2.1l-7.5-7.5c-0.1-0.1-0.3-0.3-0.5-0.3c0,0,0,0,0,0C15.4,0,15.2,0,15,0s-0.4,0-0.5,0.1c0,0,0,0,0,0
        c-0.2,0.1-0.4,0.2-0.5,0.3L6.4,7.9c-0.6,0.6-0.6,1.5,0,2.1C7,10.6,8,10.6,8.6,10.1z"
      />
    </svg>
  );
};

export { UploadIcon };
