import gql from 'graphql-tag';

const createStoreMediaQuery = gql`
  mutation(
    $storeId: Int!
    $fileBuffer: String!
    $mimeType: String!
    $title: String
    $alt: String
    $encoding: EncodingType
  ) {
    createStoreMedia(
      storeId: $storeId
      fileBuffer: $fileBuffer
      mimeType: $mimeType
      title: $title
      alt: $alt
      encoding: $encoding
    ) {
      id
      url
      name
      alt
    }
  }
`;

export { createStoreMediaQuery };
