import gql from 'graphql-tag';

const deactivateMaterialInventoryItemMutation = gql`
  mutation (
    $id: Int!
    $storeId: Int!
  ) {
    deactivateMaterialInventoryItem(
      id: $id
      storeId: $storeId
    )
  }
`;

export {
  deactivateMaterialInventoryItemMutation,
};
