import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import { Card, ContentHeader, ContentWrapper, spaces } from '../../../ui';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { InventoryCreateEditFabricItemForm, InventoryCreateEditTrimItemForm } from './InventoryItemForm';
import { useRedirect } from '../../../utils/redirect';

const InventoryListEditCreatePage = () => {
  const { storeId, itemId } = useParams();
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();

  const form = useMemo(() => {
    return queryParams.get('type')?.toLowerCase() === 'aviamento' ?
      <InventoryCreateEditTrimItemForm onExitForm={() => redirect.to(`/store/${storeId}/storage`)} /> :
      <InventoryCreateEditFabricItemForm onExitForm={() => redirect.to(`/store/${storeId}/storage`)} />;
  }, [queryParams, redirect]);

  const name = useMemo(() => {
    switch (queryParams.get('type')?.toLowerCase()) {
      case 'aviamento':
        return mainMenuOptions.trims;
        break;
      case 'tecido':
        return mainMenuOptions.fabrics;
        break;
      default:
        return mainMenuOptions.storage;
    }
  }, [queryParams]);

  return (
    <Page variant="primary">
      <StoreMenu current={mainMenuOptions.storage} />
      <ContentWrapper>
        <ContentHeader
          title={itemId ? 'Editar item do estoque' : 'Adicionar item no estoque'}
          subtitle={itemId ? 'Edite o insumo no armazenamento' : 'Crie um insumo novo no armazenamento'}
          breadcrumbs={[
            {
              name: 'Empresas',
              link: '/store',
            },
            {
              name: 'Início',
              link: `/store/${store?.id}`,
            },
            {
              name,
              link: `/store/${storeId}/storage`,
            },
            {
              name: !itemId ? 'Novo item' : 'Edição de insumo',
              link: !itemId ? `/store/${storeId}/storage/item` : `/store/${storeId}/storage/item/${itemId}`,
            },
          ]}
        />
        <Card padding={spaces.xLarge}>
          {queryParams.has('type') && form}
        </Card>
      </ContentWrapper>
    </Page>
  );
};

export { InventoryListEditCreatePage };
