import React, { SVGProps } from 'react';

const Cart = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 40 35.556" width={40} height={35.556} {...props}>
      <path d="M36.675,20.925,39.958,6.48a1.667,1.667,0,0,0-1.625-2.036H11.056L10.42,1.333A1.667,1.667,0,0,0,8.787,0H1.667A1.667,1.667,0,0,0,0,1.667V2.778A1.667,1.667,0,0,0,1.667,4.444H6.52L11.4,28.294a3.889,3.889,0,1,0,4.656.595H30.613a3.888,3.888,0,1,0,4.417-.723l.383-1.686a1.667,1.667,0,0,0-1.625-2.036H15.147l-.455-2.222H35.05A1.667,1.667,0,0,0,36.675,20.925Z" />
    </svg>
  );
};

export { Cart };
