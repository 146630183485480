import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CardsList, ListFilterOption, Typography, colors, spaces } from '../../../../ui';
import { useGraphQl } from '../../../../utils/gql';
import { materialInventoryItemsQuery } from '../../../queries/materialInventoryItemsQuery';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { StoreContext } from '../../../contexts/StoreContext';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { MaterialInventoryItemModel, MaterialType, MediaModel } from '../../../../../common/models';
import {
  updateTrimMaterialInventoryItemMutation,
  updateFabricMaterialInventoryItemMutation,
  deactivateMaterialInventoryItemMutation,
} from '../../../queries';
import { createStoreMediaMutation } from '../../../queries/createStoreMediaMutation';
import { createUserMediaMutation } from '../../../queries/createUserMediaMutation';
import { materialInventoryItemsReportQuery } from '../../../queries/materialInventoryItemsReportQuery';
import { ReportLinksModel } from '../../../../../common/models/ReportLinksModel';

const InventoryList = () => {
  // TODO: Reduzir o volume de dados.
  const { storeId } = useParams();
  const [queryParams] = useSearchParams();
  const [items, setItems] = useState<Array<MaterialInventoryItemModel> | null>(null);
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const redirect = useRedirect();
  const isTrim = useMemo(() => queryParams.get('type') === 'aviamento', [queryParams]);

  const materialInventoryItemsRequest = useGraphQl<{ materialInventoryItems: Array<MaterialInventoryItemModel> }>({
    query: materialInventoryItemsQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const materialInventoryItemsReportRequest = useGraphQl<{ materialInventoryItemsReport: ReportLinksModel }>({
    query: materialInventoryItemsReportQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateMaterialInventoryItemRequest = useGraphQl({
    query: isTrim ? updateTrimMaterialInventoryItemMutation : updateFabricMaterialInventoryItemMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const removeMaterialInventoryItemRequest = useGraphQl<{ deactivateMaterialInventoryItem: boolean }>({
    query: deactivateMaterialInventoryItemMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createStoreMediaRequest = useGraphQl<{
    createStoreMedia?: MediaModel,
    createUserMedia?: MediaModel,
  }>({
    query: createStoreMediaMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createUserMediaRequest = useGraphQl<{
    createUserMedia?: MediaModel,
    createStoreMedia?: MediaModel,
  }>({
    query: createUserMediaMutation,
    url: getApiEndpoint({
      route: apiRoutes.admin,
    }),
  });

  useEffect(() => {
    materialInventoryItemsRequest.invoke({
      variables: {
        storeId: store?.id,
        materialType: isTrim ? MaterialType.Aviamento : MaterialType.Tecido,
      },
      onSuccess: ({ data: { materialInventoryItems } }) => {
        setItems(materialInventoryItems);
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar a lista de items do estoque.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, [queryParams]);

  const filters = useMemo(() => {
    const filtersList: Array<ListFilterOption<MaterialInventoryItemModel>> = [
      {
        label: 'Referência',
        resolver: (filterItems, value) => filterItems.filter(item => item?.itemRef?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Nome',
        resolver: (filterItems, value) => filterItems.filter(item => item?.itemName?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Fornecedor',
        resolver: (filterItems, value) => filterItems.filter(item => item?.supplierName?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Cor',
        resolver: (filterItems, value) => filterItems.filter(item => item?.itemColorName?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Observações',
        resolver: (filterItems, value) => filterItems.filter(item => item?.observation?.toLowerCase().includes(value.toLowerCase())),
      },
    ];
    if (isTrim) {
      filtersList.push({
        label: 'Material do aviamento',
        resolver: (filterItems, value) => filterItems.filter(item => item?.trimMaterialType?.toLowerCase().includes(value.toLowerCase())),
      });
    }
    return filtersList;
  }, [isTrim]);

  return (
    <CardsList<MaterialInventoryItemModel, MediaModel>
      emptyText='Nenhum insumo foi cadastrado até o momento.'
      items={items}
      onSnackbar={addSnackbar}
      filters={filters}
      renderContent={(item) => (
        <>
          <Typography variant="sublead" align="left" color={colors.black} margin={`0 0 ${spaces.small}`}>
            {item.itemRef} - {item.itemName} - {item.itemColorName ?? 'Sem cor'}
          </Typography>
          {item.measures.map(({ unit, customUnit, quantity }) => {
            const measureUnit = unit === 'custom' ? customUnit : unit;
            return <Typography key={measureUnit} variant="paragraph" align="left" color={colors.black}>{measureUnit}: {quantity}</Typography>;
          })}
          {item.itemColorName &&
            <Typography variant="paragraph" align="left" color={colors.black}>Cor: {item.itemColorName}</Typography>}
        </>
      )}
      onItemClick={(item) => redirect.to(`/store/${storeId}/storage/item/${item.id}`)}
      onItemRemove={(removedItem) => {
        removeMaterialInventoryItemRequest.invoke({
          variables: {
            id: removedItem?.id,
            storeId: store?.id,
          },
          onSuccess: ({ data: { deactivateMaterialInventoryItem } }) => {
            if (deactivateMaterialInventoryItem) {
              setItems(items?.filter(filteringItem => filteringItem.id !== removedItem.id));
            }
          },
          onFail: (err) => {
            if (err.name === 'UnauthorizedRequestError') {
              redirect.to('/login');
              return;
            }

            addSnackbar({
              title: `Não foi possível remover o item. ${err.message}`,
              fail: true,
              actionText: 'OK',
            });
          },
        });
      }}
      getItemImageUrl={(item) => item.itemImage?.presignedUrl ?? item.itemImage?.url}
      onItemImageChange={(item, media) => {
        updateMaterialInventoryItemRequest.invoke({
          variables: {
            id: item.id,
            storeId: parseInt(storeId),
            itemPictureMediaId: media.id,
          },
          onFail: (err) => {
            addSnackbar({
              title: `Erro ao atualizar a imagem do item "${item.itemRef}". ${err.message}`,
              actionText: 'OK',
              fail: true,
            });
          },
          onSuccess: () => {
            addSnackbar({
              title: `Imagem do item "${item.itemRef}" atualizada com sucesso.`,
              actionText: 'OK',
              fail: false,
            });
          },
        });
      }}
      onItemImagePostUpload={(file) => new Promise((resolve, reject) => {
        (store ? createStoreMediaRequest : createUserMediaRequest).invoke({
          variables: {
            storeId: store?.id,
            url: file.downloadUrl,
            name: file.fileName,
            mimeType: file.mimeType,
          },
          onFail: async (err) => {
            reject(err);
          },
          onSuccess: async ({ data: { createStoreMedia, createUserMedia } }) => {
            resolve(createStoreMedia ?? createUserMedia);
          },
        });
      })}
      onRequestXlsxReport={() => {
        materialInventoryItemsReportRequest.invoke({
          variables: {
            storeId: store?.id,
            materialType: isTrim ? MaterialType.Aviamento : MaterialType.Tecido,
          },
          onSuccess: ({ data: { materialInventoryItemsReport } }) => {
            console.log(materialInventoryItemsReport.xlsx);
            window.open(materialInventoryItemsReport.xlsx, '_blank');
          },
        });
      }}
      onRequestPdfReport={() => {
        materialInventoryItemsReportRequest.invoke({
          variables: {
            storeId: store?.id,
            materialType: isTrim ? MaterialType.Aviamento : MaterialType.Tecido,
          },
          onSuccess: ({ data: { materialInventoryItemsReport } }) => {
            console.log(materialInventoryItemsReport.pdf);
            window.open(materialInventoryItemsReport.pdf, '_blank');
          },
        });
      }}
    />
  );
};

export { InventoryList };
