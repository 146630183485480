import React, { ReactNode, useState } from 'react';
import { Placement } from './Tooltip.types';
import { Wrapper, Ballon } from './Tooltip.styles';

interface TooltipProps {
  children: ReactNode;
  text: string;
  placement?: Placement;
  width?: string;
}

const Tooltip = ({
  children,
  text,
  placement = 'bottom-left',
  width = 'auto',
}: TooltipProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Wrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      $width={width}
    >
      {children}
      {isHover && <Ballon $placement={placement}>{text}</Ballon>}
    </Wrapper>
  );
};

export { Tooltip };
