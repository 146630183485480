import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  CardsList,
  Typography,
  colors,
  spaces,
} from '../../../../ui';
import { useGraphQl } from '../../../../utils/gql';
import { AdminUserModel, UserNameType, MediaModel } from '../../../../../common/models';
import {
  teamMembersQuery,
  updateTeamMemberMutation,
  removeTeamMemberMutation,
} from '../../../queries';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { StoreContext } from '../../../contexts/StoreContext';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { AuthenticatorContext } from '../../../contexts/AuthenticatorContext';
import { createStoreMediaMutation } from '../../../queries/createStoreMediaMutation';
import { createUserMediaMutation } from '../../../queries/createUserMediaMutation';

const TeamMembersList = () => {
  // TODO: Reduzir o volume de dados.
  const { storeId } = useParams();
  const { currentUser, setUser } = useContext(AuthenticatorContext);
  const [queryParams] = useSearchParams();
  const [items, setItems] = useState<Array<AdminUserModel> | null>(null);
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const redirect = useRedirect();

  const teamMembersRequest = useGraphQl<{ teamMembers: Array<AdminUserModel> }>({
    query: teamMembersQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateTeamMemberRequest = useGraphQl({
    query: updateTeamMemberMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const removeTeamMemberRequest = useGraphQl<{ removeTeamMember: boolean }>({
    query: removeTeamMemberMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createStoreMediaRequest = useGraphQl<{
    createStoreMedia?: MediaModel,
    createUserMedia?: MediaModel,
  }>({
    query: createStoreMediaMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createUserMediaRequest = useGraphQl<{
    createUserMedia?: MediaModel,
    createStoreMedia?: MediaModel,
  }>({
    query: createUserMediaMutation,
    url: getApiEndpoint({
      route: apiRoutes.admin,
    }),
  });

  useEffect(() => {
    teamMembersRequest.invoke({
      variables: {
        storeId: store?.id,
      },
      onSuccess: ({ data: { teamMembers } }) => {
        setItems(teamMembers);
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar a lista de membros da equipe.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, [queryParams]);

  return (
    <CardsList<AdminUserModel, MediaModel>
      emptyText='Nenhum membro da equipe foi cadastrado até o momento.'
      items={items}
      onSnackbar={addSnackbar}
      filters={[
        {
          label: 'Nome',
          resolver: (filterItems, value) => filterItems.filter(item => item?.name?.toLowerCase().startsWith(value.toLowerCase())),
        },
        {
          label: 'CPF',
          resolver: (filterItems, value) => filterItems.filter(item => item?.cpf?.toLowerCase().startsWith(value.toLowerCase())),
        },
        {
          label: 'E-mail',
          resolver: (filterItems, value) => filterItems.filter(item => item?.userNames?.filter(
            userName => userName.userNameType === UserNameType.EMAIL && userName.userName.toLowerCase().startsWith(value.toLowerCase())).length > 0),
        },
        {
          label: 'Telefone',
          resolver: (filterItems, value) => filterItems.filter(item => item?.userNames?.filter(
            userName => userName.userNameType === UserNameType.TEL && userName.userName.toLowerCase().startsWith(value.toLowerCase())).length > 0),
        },
      ]}
      renderContent={(item) => {
        const isUser = item?.id === currentUser?.id;
        return (
          <>
            <Typography variant="sublead" align="left" color={colors.black} margin={`0 0 ${spaces.small}`}>{item.name}</Typography>
            {isUser && <Typography variant="paragraph" align="left" color={colors.secondary}>(Sua conta)</Typography>}
          </>
        );
      }}
      onItemClick={(item) => redirect.to(`/store/${storeId}/team/member/${item?.id}`)}
      onItemRemove={(item) => {
        const isUser = item?.id === currentUser?.id;

        if (isUser) {
          return;
        }

        removeTeamMemberRequest.invoke({
          variables: {
            id: item?.id,
            storeId: store?.id,
          },
          onSuccess: ({ data: { removeTeamMember } }) => {
            if (removeTeamMember) {
              setItems(items.filter(filteringItem => filteringItem?.id !== item?.id));
            }
          },
          onFail: (err) => {
            if (err.name === 'UnauthorizedRequestError') {
              redirect.to('/login');
              return;
            }

            addSnackbar({
              title: `Não foi possível remover o item. ${err.message}`,
              fail: true,
              actionText: 'OK',
            });
          },
        });
      }}
      getItemImageUrl={(item) => item.avatar?.presignedUrl ?? item.avatar?.url}
      onItemImageChange={(item, media) => {
        const isUser = item?.id === currentUser?.id;
        updateTeamMemberRequest.invoke({
          variables: {
            id: item?.id,
            storeId: parseInt(storeId),
            avatarId: media?.id,
          },
          onFail: (err) => {
            addSnackbar({
              title: `Erro ao atualizar a imagem do membro da equipe "${item.name}". ${err.message}`,
              actionText: 'OK',
              fail: true,
            });
          },
          onSuccess: () => {
            if (isUser) {
              setUser({ ...currentUser, avatar: media });
            }
            addSnackbar({
              title: `Imagem do membro da equipe "${item.name}" atualizada com sucesso.`,
              actionText: 'OK',
              fail: false,
            });
          },
        });
      }}
      onItemHideRemoveButton={(item) => item?.id === currentUser?.id}
      onItemImagePostUpload={(file) => new Promise((resolve, reject) => {
        (store ? createStoreMediaRequest : createUserMediaRequest).invoke({
          variables: {
            storeId: store?.id,
            url: file.downloadUrl,
            name: file.fileName,
            mimeType: file.mimeType,
          },
          onFail: async (err) => {
            reject(err);
          },
          onSuccess: async ({ data: { createStoreMedia, createUserMedia } }) => {
            resolve(createStoreMedia ?? createUserMedia);
          },
        });
      })}
    />
  );
};

export { TeamMembersList };
