import * as yup from 'yup';

const yupEmail = ({ required }: { required: boolean } = { required: false }) =>
  yup
    .string()
    .typeError('E-mail inválido.')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      message: 'Formatação incorreta.',
      excludeEmptyString: !required,
    });

export {
  yupEmail,
};
