import { styled } from 'styled-components';
import { darken } from 'polished';

const LoadingRectStyle = styled.div<{
  $width: string;
  $height: string;
  $borderRadius: string;
  $backgroundColor?: string;
}>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, ${({ $backgroundColor }) => darken(0.3, $backgroundColor)}, transparent);
    animation: wave 1s infinite;
  }

  @keyframes wave {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(100%);
    }
  }
`;

export {
  LoadingRectStyle,
};
