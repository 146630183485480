import gql from 'graphql-tag';

export const unloadingLogsReportQuery = gql`
  query(
    $storeId: Int!
    $invalidated: Boolean!
    $orderBy: OrderByInput
    $filters: [ResiduesUnloadingLogFilter]
  ) {
    unloadingLogsReport(
      storeId: $storeId
      invalidated: $invalidated
      orderBy: $orderBy
      filters: $filters
    ) {
      csv
      xlsx
      pdf
    }
  }
`;
