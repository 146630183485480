import React, { useEffect, useState } from 'react';
import {
  spaces,
  GridContainer,
  GridItem,
  Image,
  Center,
  SizedContainer,
  Padding,
  MecenaLogoVariants,
} from '../../../../ui';
import { StateMachine } from '../../../contexts/StateMachine';
import {
  authPageStateValues,
  AuthPageState,
} from '../../../../../common/models';
import { Page } from '../../Page';
import { LoginForm } from '../LoginForm';
import { AuthPageLoading } from '../AuthPageLoading';
import { AuthPageErrorFeedback } from '../AuthPageErrorFeedback';
import { SignUpForm } from '../SignUpForm';
import { PasswordForm } from '../PasswordForm';
import { AuthPageSuccessFeedback } from '../AuthPageSuccessFeedback';
import { NewPasswordForm } from '../NewPasswordForm';
import { ConfirmEmail } from '../ConfirmEmail';
import { ImageCover } from './AuthPage.styles';

type AuthPageProps = {
  initialState?: AuthPageState;
};

type AuthStateMachineData = {
  userName?: string,
  errorMessage?: string,
  successTitle?: string,
  successBody?: string,
};

const AuthPage = ({
  initialState = authPageStateValues.login,
}: AuthPageProps) => {
  const [screenSize, setScreenSize] = useState({
    width: 1920,
    height: 1080,
  });

  useEffect(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    window.addEventListener('resize', () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }, []);

  return (
    <Page height="100%" showAccountButton={false}>
      <GridContainer columns={12} gutter={0} noPadding>
        <GridItem lg={7} md={0}>
          <ImageCover height={`${screenSize.height}px`}>
            <Image
              alt="Imagem de destaque, pessoa no notebook"
              src="/images/istock-889461006-3271x2104_5.png"
              mask
            />
          </ImageCover>
        </GridItem>
        <GridItem lg={5} md={12}>
          <GridContainer columns={8} gutter={0}>
            <GridItem lg={6} lgOffset={1} xsOffset={1} xs={6}>
              <Center>
                <SizedContainer height="100%" width="100%" maxWidth="600px">
                  <Center>
                    <Padding padding={`${spaces.xHuge} 0`}>
                      <Center>
                        <MecenaLogoVariants size="sm" variant="whiteBg" />
                      </Center>
                    </Padding>
                    <StateMachine
                      data={{
                        userName: undefined,
                        errorMessage: undefined,
                        successTitle: undefined,
                        successBody: undefined,
                      } as AuthStateMachineData}
                      initialState={initialState}
                      states={{
                        login: () => <LoginForm />,
                        loading: () => <AuthPageLoading />,
                        error: () => <AuthPageErrorFeedback />,
                        signup: () => <SignUpForm />,
                        password: () => <PasswordForm />,
                        success: () => <AuthPageSuccessFeedback />,
                        newpass: () => <NewPasswordForm />,
                        confirm: () => <ConfirmEmail />,
                      }}
                    />
                  </Center>
                </SizedContainer>
              </Center>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Page>
  );
};

export type { AuthStateMachineData };
export { AuthPage };
