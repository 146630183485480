import React from 'react';
import { colors, spaces } from '../../tokens';
import { Button } from '../Button';
import { Center } from '../Center';
import { Modal } from '../Modal';
import { Padding } from '../Padding';
import { Row } from '../Row';
import { Typography } from '../Typography';

type ConfirmModalData<TData> = {
  isOpen: boolean;
  itemKey: string | null;
  itemName: string | null;
  data: TData | null;
}

function ConfirmModal<TData>({
  onClose,
  onConfirm,
  ...modalData
}: {
  onClose: (closedData: ConfirmModalData<TData>) => Promise<void> | void,
  onConfirm: (confirmedData: ConfirmModalData<TData>) => Promise<void> | void,
} & ConfirmModalData<TData>) {
  return modalData.isOpen && modalData.itemKey && modalData.itemName && modalData.data && (
    <Modal
      isOpen={modalData.isOpen}
      onClose={async () => { await onClose(modalData); }}
      title={`Confirma a remoção do item ${modalData.data[modalData.itemName]}?`}
    >
      <Center>
        <Padding padding={spaces.large}>
          <Typography variant="sublead" color={colors.black} align="center">ATENÇÃO! Essa ação não poderá ser desfeita!</Typography>
        </Padding>
        <Row justifyContent="center" gutter={spaces.large}>
          <Button onClick={async () => {
            await onClose(modalData);
          }}>Não</Button>
          <Button variant="danger" onClick={async () => {
            await onConfirm(modalData);
            await onClose(modalData);
          }}>Sim</Button>
        </Row>
      </Center>
    </Modal>
  );
}

export type {
  ConfirmModalData,
};
export {
  ConfirmModal,
};
