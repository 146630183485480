import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  useProductReducer,
} from '../reducers/productReducer';
import { ProductModel } from '../../../common/models';
import { useGraphQl } from '../../utils/gql';
import { productQuery } from '../queries';
import { apiRoutes, getApiEndpoint } from '../../utils/apiEndpoints';
import { SnackbarContext } from './SnackbarContext';
import { useRedirect } from '../../utils/redirect';

const ProductContext = createContext<ReturnType<typeof useProductReducer> & { isFetching: boolean }>(null);

const ProductProvider = ({
  children,
}: {
  children: ReactNode,
}) => {
  const { addSnackbar } = useContext(SnackbarContext);
  const { storeId, itemId } = useParams();
  const redirect = useRedirect();
  const productReducer = useProductReducer(null);

  const productRequest = useGraphQl<{ product: ProductModel }>({
    query: productQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  useEffect(() => {
    if (itemId) {
      productRequest.invoke({
        variables: {
          id: parseInt(itemId),
          storeId: parseInt(String(storeId)),
        },
        onSuccess: ({ data: { product } }) => {
          productReducer.setProduct(product);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Erro ao carregar o produto. ${err.message}`,
            actionText: 'OK',
            fail: true,
          });
          redirect.to(`/store/${storeId}/specsheet`);
        },
      });
    }
  }, [itemId]);

  const productContextValue = useMemo(() => ({
    ...productReducer,
    isFetching: itemId && productRequest.isFetching(),
  }), [productReducer]);

  return (
    <ProductContext.Provider value={productContextValue}>
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider };
