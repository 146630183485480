import React, { HTMLAttributes, Suspense } from 'react';
import {
  ImageWrapper,
  Image as ImageStyle,
  ImageMask,
  ImagePlaceholder,
} from './Image.styles';

type ImageProps = {
  src: string;
  alt: string;
  isVertical?: boolean;
  mask?: boolean;
  width?: string;
  height?: string;
} & HTMLAttributes<HTMLImageElement>;

const Image = ({
  src,
  alt,
  isVertical = true,
  mask = false,
  width = '100%',
  height = '100%',
  ...props
}: ImageProps) => {
  const Content = (
    <ImageWrapper
      $isVertical={isVertical || false}
      width={width}
      height={height}
    >
      <Suspense fallback={<ImagePlaceholder
        $isVertical={isVertical || false}
        width={width}
        height={height}
      />}>
        <ImageStyle
          {...props}
          src={src}
          $isVertical={isVertical || false}
          alt={alt}
          width={width}
          height={height}
        />
      </Suspense>
    </ImageWrapper>
  );

  return mask ? <ImageMask>{Content}</ImageMask> : Content;
};

export { Image };
