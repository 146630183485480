import React, { useEffect, ReactNode } from 'react';
import { useKeyListener } from '../../../utils/useKeyListener';
import { Typography } from '../Typography';
import { Wrapper, ListOptions, Option, Title } from './Dropdown.styles';

interface DropdownOption {
  title: string;
  action?(): void;
  href?: string;
  as?: string;
};
interface DropdownProps {
  listItems: Array<DropdownOption>;
  children: ReactNode;
  isOpen?: boolean;
  onClose(): void;
  title?: string;
  align?: 'left' | 'right';
  fixedOverflow?: boolean;
}

const Dropdown = ({
  listItems,
  children,
  isOpen = true,
  onClose,
  title = '',
  align = 'left',
  fixedOverflow = false,
}: DropdownProps) => {
  useKeyListener({ condition: isOpen, callback: onClose });

  useEffect(() => {
    if (!isOpen) {
      document.removeEventListener('click', onClose);
      return () => null;
    }

    document.addEventListener('click', onClose);

    return () => {
      document.removeEventListener('click', onClose);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (fixedOverflow) {
      document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, fixedOverflow]);

  return (
    <Wrapper>
      <div style={{ height: '20px', width: '20px' }}>{children}</div>
      {isOpen && (
        <ListOptions $align={align}>
          <>
            {title && <Title>{title}</Title>}
            {listItems.map((item) => {
              return item.href ? (
                <a key={item.title} href={item.href}>
                  <Option
                    key={item.title}
                    onClick={(event) => {
                      event.nativeEvent.stopImmediatePropagation();
                      if (item.action) {
                        item.action();
                      }
                      onClose();
                    }}
                  >
                    <Typography variant="caption">{item.title}</Typography>
                  </Option>
                </a>
              ) : (
                <Option
                  key={item.title}
                  onClick={(event) => {
                    event.nativeEvent.stopImmediatePropagation();
                    if (item.action) {
                      item.action();
                    }
                    onClose();
                  }}
                >
                  <Typography variant="caption">{item.title}</Typography>
                </Option>
              );
            })}
          </>
        </ListOptions>
      )}
    </Wrapper>
  );
};

export type {
  DropdownOption,
};
export { Dropdown };
