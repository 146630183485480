import React, { cloneElement, ReactElement, SVGProps, useMemo } from 'react';
import { Button, Col, colors, Padding, spaces, Typography } from '../../../../ui';
import { useRedirect } from '../../../../utils/redirect';
import { QuickAccessButtonProp } from './QuickAccessMenu.types';

const QuickAccessButton = ({
  link,
  params,
  icon,
  text,
}: QuickAccessButtonProp) => {
  const redirect = useRedirect();

  const Icon = useMemo(() =>
    React.isValidElement(icon)
      ? cloneElement(icon as ReactElement<SVGProps<SVGSVGElement>>, {
        width: 50,
        height: 50,
      }) : null
    , [icon]);

  return (
    <Padding padding={`${spaces.none} ${spaces.small} ${spaces.none} ${spaces.none}`}>
      <Button
        type="button"
        variant="transparent"
        width="200px"
        height="200px"
        onClick={() => redirect.to(link, { params })}
      >
        <Col alignItems="center">
          {Icon}
          <Typography color={colors.primary} variant="sublead" align="center">{text}</Typography>
        </Col>
      </Button>
    </Padding>
  );
};

export {
  QuickAccessButton,
};
