import gql from 'graphql-tag';

const updateMaterialQuantityOnProductMutation = gql`
  mutation(
    $storeId: Int!
    $productId: Int!
    $materialId: Int!
    $unit: String!
    $quantity: Float!
  ) {
    updateMaterialQuantityOnProduct(
      storeId: $storeId
      productId: $productId
      materialId: $materialId
      unit: $unit
      quantity: $quantity
    ) {
      id
      itemType
      itemRef
      itemColorName
      itemName
      itemValue
      supplierName
      measures {
          unit
          customUnit
          quantity
      }
      productQuantities {
        unit
        customUnit
        quantity
      }
    }
  }
`;

export { updateMaterialQuantityOnProductMutation };
