import React from 'react';
import { SizedContainerProps } from './SizedContainer.models';
import { SizedContainerStyle } from './SizedContainer.styles';

const SizedContainer = ({
  children,
  width = 'auto',
  height = 'auto',
  minWidth = 'none',
  minHeight = 'none',
  maxWidth = 'none',
  maxHeight = 'none',
}: SizedContainerProps) => {
  return (
    <SizedContainerStyle
      $width={width}
      $height={height}
      $minWidth={minWidth}
      $minHeight={minHeight}
      $maxWidth={maxWidth}
      $maxHeight={maxHeight}
    >
      {children}
    </SizedContainerStyle>
  );
};

export { SizedContainer };
