import { styled } from 'styled-components';

const Center = styled.div<{ $alignItems?: 'center' | 'flex-start' | 'flex-end' }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems ? $alignItems : 'center'};
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export { Center };
