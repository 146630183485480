import gql from 'graphql-tag';

const currenciesQuery = gql`
  query {
    currencies {
      name
      symbol
    }
  }
`;

export { currenciesQuery };
