import { OnboardingStepProps } from '../../OnboardingPage';
import { Button, colors, Typography } from '../../../../../ui';

const OnboardingApproveTermsOfUse = ({ step, onComplete }: OnboardingStepProps) => {
  const handleCompleted = () => {
    if (!step) return;
    onComplete({
      ...step,
      completed: true,
    });
  };

  return (
    <>
      <Typography variant='title' color={colors.primary}>{step?.title}</Typography>
      <Button type='submit' variant='default' onClick={handleCompleted}>Continuar</Button>
    </>
  );
};

export { OnboardingApproveTermsOfUse };
