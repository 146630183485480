class RequestReturnedNoDataError extends Error {
  constructor() {
    super('A requisição retornou vazia.');
    this.name = 'RequestReturnedNoDataError';
  }
}

export {
  RequestReturnedNoDataError,
};
