import gql from 'graphql-tag';

const institutionsOptionsQuery = gql`
  query (
    $storeId: Int!
  ) {
    institutionsOptions(
      storeId: $storeId
    ) {
      id
      name
    }
  }
`;

export { institutionsOptionsQuery };
