import { styled, css } from 'styled-components';
import { fontSize, colors, spaces, zIndex } from '../../tokens';
import { Placement } from './Tooltip.types';
import { ReactNode } from 'react';

interface BallonProps {
  $placement: Placement;
}

const BallonPositionStyle = ({ $placement }: BallonProps) => {
  if ($placement === 'bottom-left') {
    return css`
      right: 0;
      top: 100%;
    `;
  }

  return null;
};

const Ballon = styled.div<BallonProps>`
  background-color: ${colors.secondary};
  color: ${colors.white};
  font-size: ${fontSize.caption};
  padding: ${spaces.medium};
  border-radius: 4px;
  ${BallonPositionStyle}
  min-width: max-content;
  transition: 0.3s;
  position: absolute;
  z-index: ${zIndex.beyond};
`;

const Wrapper = styled.div<{ $width: string, children: ReactNode }>`
  position: relative;
  display: inline-block;
  width: ${({ $width }) => $width};
  z-index: ${zIndex.earth};
`;

export { Wrapper, Ballon };
