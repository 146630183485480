import React, { SVGProps } from 'react';

const ArrowHeadStart = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 26" width={16} height={26} {...props}>
      <polygon points="16,26 0,26 10,13 0,0 16,0 " />
    </svg>
  );
};

export { ArrowHeadStart };
