import gql from 'graphql-tag';

const loginMutation = gql`
  mutation ($userName: String!, $password: String!) {
    login(userName: $userName, password: $password) {
      id
      guid
      createdAt
      updatedAt
      name
      cpf
      birthDate
      termsAccepted
      userCreatedPassword
      completedOnboarding
      wasInvited
      userNames {
        userName
        verified
        userNameType
      }
      stores {
        id
        name
        owner {
          id
        }
        role {
          id
          guid
          name
        }
        cnpj {
          id
          value
        }
        storeSetting {
          communicationEmail
        }
      }
      contract {
        id
        guid
        signed
        plan {
          id
          guid
          name
          maxUsers
          maxStores
        }
        features
      }
    }
  }
`;

export { loginMutation };
