import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Center,
  Card,
  Typography,
  SizedContainer,
  AddCircle,
  colors,
  Padding,
} from '../../../../ui';
import { useStateMachine } from '../../../contexts/StateMachine';
import { storesQuery } from '../../../queries';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { StoreModel, storePageStateValues } from '../../../../../common/models';
import { StoreCard } from '../StoreCard';
import { AddCardWrapper, StoresContainer } from './StoresList.styles';
import { useGraphQl } from '../../../../utils/gql';
import { LoadingPage } from '../../LoadingPage';
import { AuthenticatorContext } from '../../../contexts/AuthenticatorContext';

const StoresList = () => {
  const { setState } = useStateMachine();
  const [stores, setStores] = useState<Array<StoreModel> | null>(null);
  const { currentUser } = useContext(AuthenticatorContext);
  const storesRequest = useGraphQl<{ stores: Array<StoreModel> }>({
    url: getApiEndpoint({ route: apiRoutes.admin }),
    query: storesQuery,
  });

  useEffect(() => {
    if (stores === null) {
      storesRequest.invoke({
        onFail: (storesError) => {
          setState(storePageStateValues.error, {
            errorMessage: storesError?.message,
          });
        },
        onSuccess: (response) => {
          if (!response.data.stores) return;
          setStores(response.data.stores);
        },
      });
    }
  }, []);

  const maxStores = useMemo(() => currentUser?.contract?.plan?.maxStores ?? 1, [currentUser]);
  const canAddStore = useMemo(() => stores && stores.length < maxStores, [maxStores, stores]);
  const columns = useMemo(() => stores && stores.length <= (canAddStore ? 0 : 1) ? 1 : 2, [canAddStore, stores]);
  const rows = useMemo(() => stores && stores.length < 3 ? 1 : 2, [stores]);

  return (
    <SizedContainer>
      <Center>
        {stores ?
          (
            <>
              <Padding padding="50px 0 0" wide>
                <Typography variant='title' align="center" color={colors.primary}>Suas empresas</Typography>
              </Padding>
              <StoresContainer $columns={columns} $rows={rows}>
                {stores.map((store) => (
                  <StoreCard
                    key={store?.id}
                    name={store?.name}
                    storeId={store?.id}
                    logoSrc={store?.logo?.presignedUrl ?? store?.logo?.url}
                  />
                ))}
                {canAddStore && (
                  <Card
                    width="400px"
                    height="400px"
                    onClick={() => setState(storePageStateValues.create)}
                  >
                    <AddCardWrapper>
                      <AddCircle fill={colors.primary} width={54} height={54} />
                      <Typography variant="sublead" color={colors.primary}>
                        Adicionar nova empresa
                      </Typography>
                    </AddCardWrapper>
                  </Card>
                )}
              </StoresContainer>
            </>
          ) : (
            <LoadingPage />
          )}
      </Center>
    </SizedContainer >
  );
};

export { StoresList };
