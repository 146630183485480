import gql from 'graphql-tag';

const createFabricMaterialInventoryItemMutation = gql`
  mutation(
    $storeId: Int!
    $itemRef: String!
    $itemName: String!
    $itemValue: String!
    $itemWeight: Float!
    $itemSize: Float!
    $supplierName: String!
    $itemColorName: String
    $itemColorValue: String
    $itemPictureMediaId: Int
    $observation: String
  ) {
    createFabricMaterialInventoryItem(
        storeId: $storeId
        itemRef: $itemRef
        itemName: $itemName
        itemValue: $itemValue
        itemWeight: $itemWeight
        itemSize: $itemSize
        supplierName: $supplierName
        itemColorName: $itemColorName
        itemColorValue: $itemColorValue
        itemPictureMediaId: $itemPictureMediaId
        observation: $observation
      ) {
        id
        storeId
        itemRef
        itemType
        itemName
        itemValue
        measures {
          unit
          customUnit
          quantity
        }
        supplierName
        trimMaterialType
        itemColorName
        itemColorValue
        itemPictureMediaId
        observation
    }
  }
`;

export { createFabricMaterialInventoryItemMutation };
