import gql from 'graphql-tag';

const newPasswordQuery = gql`
  mutation($userName: String!, $password: String!, $newPasswordToken: String!) {
    newPassword(
      userName: $userName
      password: $password
      newPasswordToken: $newPasswordToken
    ) {
      id
    }
  }
`;

export { newPasswordQuery };
