import { useCallback, useContext } from 'react';
import { AuthenticatorContext } from '../contexts/AuthenticatorContext';
import { FeatureName } from '../../../common/models/FeatureName';

const useFeature = () => {
  const { currentUser } = useContext(AuthenticatorContext);

  const hasFeature = useCallback((name: FeatureName) => currentUser?.contract?.features?.includes(name), [currentUser]);

  return {
    hasFeature,
  };
};

export {
  useFeature,
};
