import { styled } from 'styled-components';
import { CardWrapper, screens } from '../../../../ui';

const StoresContainer = styled.div<{ $columns?: number, $rows?: number }>`
  display: grid;
  grid-template-rows: ${({ $rows }) => `repeat(${$rows ?? 1}, 400px)`};
  grid-template-columns: ${({ $columns }) => `repeat(${$columns ?? 1}, 400px)`};
  grid-gap: 40px;
  margin: 144px auto;

  @media only screen and (max-width: ${screens.lg}) {
    grid-template-columns: repeat(1, 400px);
  }

  @media only screen and (max-width: ${screens.sm}) {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;

    & > a ${CardWrapper}, & > ${CardWrapper} {
      max-width: 400px;
    }
  }

  /* @media only screen and (max-width: ${screens.xs}) {
    width: 100%;
  } */
`;

const AddCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover > * {
    opacity: 0.8;
  }

  & > * {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export { StoresContainer, AddCardWrapper };
