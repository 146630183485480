import { styled, css } from 'styled-components';
import { Typography } from '../Typography';
import { colors, spaces, opacity } from '../../tokens';

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled(Typography).attrs({
  variant: 'paragraph',
})`
  flex: 1 1 0;
  padding-right: ${spaces.medium};
`;

function SwitchInputEnableState({ disabled }: { disabled: boolean }) {
  if (disabled) {
    return css`
      cursor: no-drop;
      opacity: ${opacity[4]};
    `;
  }

  return css`
    cursor: pointer;
    opacity: ${opacity[0]};
  `;
}

const SwitchInput = styled.input<{ checked: boolean; disabled: boolean }>`
  appearance: none;
  width: 60px;
  min-width: 60px;
  height: 32px;
  flex-grow: 1 60px 0;
  position: relative;
  border: none;
  border-radius: 16px;
  background-color: ${({ checked }) =>
    checked ? colors.primary : colors.secondary};
  transition: background-color 0.2s ease;
  ${SwitchInputEnableState}
`;

const SwitchSlider = styled.span<{ checked: boolean }>`
  width: 28px;
  height: 28px;
  position: absolute;
  border-radius: 50%;
  background-color: ${colors.white};
  top: 50%;
  left: ${({ checked }) => (checked ? '30px' : '2px')};
  transform: translate(0, -50%);
  transition: left 0.2s ease;
  pointer-events: none;
`;

const SwitchInputContainer = styled.div`
  width: 60px;
  height: 32px;
  position: relative;
`;

export {
  SwitchWrapper,
  SwitchLabel,
  SwitchInput,
  SwitchSlider,
  SwitchInputContainer,
};
