import { patternFormatter } from 'react-number-format';
import validator from 'validator';
import * as yup from 'yup';
import { validateCpf } from '../../common/utils';

const cpfInputProps = { format: '###.###.###-##', mask: '_', helperText: 'Formatação: 000.000.000-00' };

const yupCpf = ({ required }: { required: boolean } = { required: false }) =>
  yup
    .string()
    .typeError('CPF inválido.')
    .test('cpfValidation', 'CPF inválido.', (value) => {
      return required ? validateCpf(value) : (
        typeof value === 'undefined' ||
        value === null ||
        validator.isEmpty(String(value)) ||
        validateCpf(value)
      );
    });

const formatCpf = (value: string | null | undefined) => value && patternFormatter(value, cpfInputProps);

export {
  yupCpf,
  cpfInputProps,
  formatCpf,
};
