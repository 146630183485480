import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ContentWrapper,
  ContentHeader,
  Button,
} from '../../../ui';
import { StoreContext } from '../../contexts/StoreContext';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { SpecSheetsList } from './SpecSheetsList';
import { useRedirect } from '../../../utils/redirect';
import { LoadingPage } from '../LoadingPage';

const SpecSheetsListPage = () => {
  const { storeId } = useParams();
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();

  const page = useMemo(() => {
    return (
      <Page variant="primary">
        <StoreMenu current={mainMenuOptions.specSheets} />
        <ContentWrapper>
          <ContentHeader
            title="Gerenciamentos de fichas-técnicas"
            subtitle="Controle todas as fichas-técnicas"
            breadcrumbs={[
              {
                name: 'Empresas',
                link: '/store',
                redirectOptions: {
                  removeParams: ['type'],
                },
              },
              {
                name: 'Início',
                link: `/store/${store?.id}`,
                redirectOptions: {
                  removeParams: ['type'],
                },
              },
              {
                name: mainMenuOptions.specSheets,
                link: `/store/${storeId}/specsheet`,
              },
            ]}
          />
          <SpecSheetsList />
          <Button type="button" onClick={() => redirect.to(`/store/${store?.id}/specsheet/item`)}>Adicionar ficha-técnica</Button>
        </ContentWrapper>
      </Page>
    );
  }, [queryParams, store, storeId, redirect]);

  return page ? page : <LoadingPage />;
};

export { SpecSheetsListPage };
