import gql from 'graphql-tag';

const residuesPaginationQuery = gql`
  query (
    $storeId: Int!
    $first: Int!
    $after: Int
    $offset: Int
    $orderBy: OrderByInput
    $active: Boolean
    $filters: [ResidueFilter]
  ) {
    residuesPagination(
      storeId: $storeId
      first: $first
      after: $after
      offset: $offset
      orderBy: $orderBy
      active: $active
      filters: $filters
    ) {
      totalCount,
        edges {
          node {
            id
            guid
            createdAt
            updatedAt
            name
            type {
              id
              name
            }
            description
            active
            picture {
              id
              url
              presignedUrl
            }
            categories {
              id
              name
            }
            inventoryUnitObservation
            size {
              id
              name
            }
            measures {
              unit
              customUnit
              quantity
            }
          }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export { residuesPaginationQuery };
