import {
  Card,
  Center,
  Padding,
  Typography,
  SizedContainer,
  GridContainer,
  GridItem,
  Button,
  CheckCircle,
  colors,
} from '../../../ui';
import { useStateMachine } from '../../contexts/StateMachine';
import { storePageStateValues } from '../../../../common/models';
import { useRedirect } from '../../../utils/redirect';
import { StoreStateMachineData } from './StorePage/StorePage';

const StorePageSuccessFeedback = () => {
  const { setState, data } = useStateMachine<StoreStateMachineData>();
  const redirect = useRedirect();

  return (
    <SizedContainer height="100vh">
      <Center>
        <Card>
          <Padding padding="40px">
            <Typography variant="lead" align="center" color={colors.primary}>
              {data.successTitle}
            </Typography>
            <Padding padding="50px 0">
              <Center>
                <CheckCircle width={112} height={112} fill={colors.success} />
              </Center>
            </Padding>
            <GridContainer noPadding>
              <GridItem lg={6}>
                <Button
                  variant="back"
                  wide
                  type="button"
                  onClick={() => {
                    setState(storePageStateValues.list);
                  }}
                >
                  Voltar
                </Button>
              </GridItem>
              <GridItem lg={6}>
                <Button
                  variant="default"
                  wide
                  type="button"
                  onClick={() =>
                    redirect.to(`/store/${data.storeId}`)
                  }
                >
                  Editar
                </Button>
              </GridItem>
            </GridContainer>
          </Padding>
        </Card>
      </Center>
    </SizedContainer>
  );
};

export { StorePageSuccessFeedback };
