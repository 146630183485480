import gql from 'graphql-tag';

const updateMyInfoMutation = gql`
  mutation(
    $storeId: Int!
    $name: String
    $cpf: String
    $birthDate: String
    $email: String
    $tel: String
    $roleId: Int
    $avatarId: Int
  ) {
    updateMyInfo(
      storeId: $storeId
      name: $name
      cpf: $cpf
      birthDate: $birthDate
      email: $email
      tel: $tel
      roleId: $roleId
      avatarId: $avatarId
    ) {
      id
      guid
      createdAt
      updatedAt
      name
      cpf
      birthDate
      termsAccepted
      userCreatedPassword
      completedOnboarding
      wasInvited
      avatar {
        id
        url
      }
      userNames {
        userName
        verified
        userNameType
      }
      stores {
        id
        name
        owner {
          id
        }
        role {
          id
          guid
          name
        }
        cnpj {
          id
          value
        }
        storeSetting {
          communicationEmail
        }
      }
      contract {
        id
        guid
        signed
        plan {
          id
          guid
          name
          maxUsers
          maxStores
        }
        features
      }
    }
  }
`;

export {
  updateMyInfoMutation,
};
