import React, {
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  useCallback,
  HTMLAttributes,
  useRef,
  forwardRef,
  Ref,
} from 'react';
import composeRefs from '@seznam/compose-react-refs';
import FocusLock from 'react-focus-lock';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { useKeyListener } from '../../../utils/useKeyListener';
import {
  InputRoot,
  InputWrapper,
  InputLabel,
  ValidState,
  HintsWrapper,
  InputMessage,
  Prefix,
  InputField,
} from '../TextField';
import { ArrowDropDown } from '../../icons';
import {
  FieldButton,
  ListBox,
  Option,
  SelectedItemText,
  Input,
  IconsWrapper,
} from './Select.styles';
import { LoadingRect } from '../LoadingRect';
import { Typography } from '../Typography';
import { colors } from '../../tokens';

interface ListItem {
  value: unknown;
  text: string;
}

interface SearchOptions {
  ordered?: boolean;
  caseSensitive?: boolean;
  acceptNewValue?: boolean;
}

interface SelectProps extends HTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl>;
  helperText?: string;
  prefix?: string;
  disabled?: boolean;
  state?: keyof typeof ValidState;
  placeholder?: string;
  required?: boolean;
  fixedOverflow?: boolean;
  listItems: Array<ListItem>;
  searchable?: boolean;
  searchOptions?: SearchOptions;
  onChange?: (event?: ChangeEvent<HTMLInputElement>, listItem?: ListItem) => void;
  loading?: boolean;
}

const Select = forwardRef(({
  id,
  label,
  errorMessage = '',
  helperText = '',
  prefix = '',
  disabled = false,
  state = 'neutral',
  required = false,
  listItems,
  defaultValue,
  fixedOverflow = false,
  searchable = false,
  searchOptions = { ordered: false, caseSensitive: false, acceptNewValue: false },
  onChange,
  loading = false,
  ...rest
}: SelectProps, ref: Ref<HTMLInputElement>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [optionsFilter, setOptionsFilter] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);

  const searchInputRef = useRef<null | HTMLInputElement>(null);

  const isValid = useMemo(() => state === 'valid', [state]);
  const isInvalid = useMemo(() => state === 'invalid', [state]);
  const isActive = useMemo(() => value !== '' || isOpen, [value, isOpen]);
  const showInputMessage = useMemo(
    () => isInvalid || helperText !== '',
    [isInvalid, helperText],
  );
  const inputMessage = useMemo(
    () => (isInvalid ? errorMessage.toString() : helperText),
    [isInvalid, errorMessage, helperText],
  );
  const hasHint = useMemo(
    () => showInputMessage,
    [showInputMessage],
  );
  const hasPrefix = useMemo(() => prefix !== '', [prefix]);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const closeList = useCallback(() => {
    setIsOpen(false);
  }, []);

  useKeyListener({ condition: isOpen, callback: closeList, keyCode: 'Escape' });
  useKeyListener({
    condition: isOpen && searchOptions?.acceptNewValue, callback: () => {
      closeList();
      if (searchInputRef && searchInputRef?.current) {
        searchInputRef.current.blur();
      }
    }, keyCode: 'Enter',
  });

  useEffect(() => {
    if (fixedOverflow) {
      document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, fixedOverflow]);

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      // No caso de busca na lista de opções disponíveis.
      if (searchable && !searchOptions?.acceptNewValue) {
        const filteredSelect = listItems.filter(item => item.text === optionsFilter);
        const newValue = filteredSelect?.length > 0 ? filteredSelect[0].value : '';
        const newSelectedItem = filteredSelect?.length > 0 ? filteredSelect[0] : null;
        onChange({
          ...e,
          target: {
            ...e.target,
            value: newValue,
          },
        } as ChangeEvent<HTMLInputElement>,
          newSelectedItem);
        return;
      }

      // No caso de onChange com o dropDown sem input de busca.
      const filteredSelect = listItems.filter(item => item.value === e?.target?.value);
      const item = filteredSelect?.length > 0 ? filteredSelect[0] : null;
      onChange(e, {
        text: item?.text,
        value: item?.value,
      });
    }
  }, [searchable, onChange, searchOptions]);

  const cleanFilter = () => {
    setOptionsFilter('');
  };

  const handleDropDownOptionSelect = useCallback((listItem: ListItem) => {
    setOptionsFilter(listItem?.text ?? '');
    setValue(listItem.value?.toString());
    closeList();
    if (onChange) onChange({ target: { value: listItem.value } } as ChangeEvent<HTMLInputElement>, listItem);
  }, []);

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (searchable) {
      const newText = e.target?.value ?? '';
      setIsOpen(true);
      setOptionsFilter(newText);

      if (searchOptions?.acceptNewValue) {
        // setValue(newText);
        if (onChange) {
          onChange({
            ...e,
            target: {
              ...e.target,
              value: newText,
            },
          } as ChangeEvent<HTMLInputElement>,
            {
              text: newText,
              value: newText,
            });
        };
      }
    }
  }, [searchable, onChange, searchOptions]);

  const filteredList = useMemo(() => {
    if (!searchable) {
      return listItems;
    }
    return listItems?.filter((item) => {
      const params = searchOptions?.caseSensitive ? {
        text: item?.text,
        optionsFilter,
      } : {
        text: item?.text?.toLowerCase(),
        optionsFilter: optionsFilter?.toLowerCase(),
      };
      return searchOptions?.ordered ?
        params?.text.startsWith(params?.optionsFilter) :
        params?.text.includes(params?.optionsFilter);
    });
  }, [optionsFilter, listItems, searchable]);

  const selectedText = useMemo(() => {
    const items = listItems
      .filter(item => item.value == value)
      .map(item => item.text);

    if (items && items.length > 0) {
      return items[0];
    }

    return '';
  }, [value, listItems]);

  useEffect(() => {
    setOptionsFilter(selectedText);
  }, [selectedText]);

  return (
    <InputRoot>
      <FocusLock disabled={!isFocused}>
        {loading ? <LoadingRect
          width="100%"
          height="70px"
          borderRadius="8px 8px 0px 0px"
        /> :
          <InputWrapper
            $isInvalid={isInvalid}
            $isValid={isValid}
            disabled={disabled}
            $noPadding
          >
            {hasPrefix && isActive && <Prefix>{prefix}</Prefix>}
            <FieldButton type="button" onClick={toggleIsOpen} $searchable={searchable}>
              {searchable && (
                <InputField
                  id={id}
                  name={id}
                  disabled={disabled}
                  value={optionsFilter}
                  onChange={onSearchChange}
                  type="text"
                  ref={composeRefs(searchInputRef, ref)}
                  onBlur={handleOnBlur}
                  onFocus={handleOnFocus}
                  autoComplete="off"
                  placeholder={rest.placeholder}
                />
              )}
              <Input
                type="hidden"
                tabIndex={-1}
                defaultValue={defaultValue}
                readOnly
                onChange={handleOnChange}
                required={required}
                autoComplete="off"
                {...rest}
              />
              <InputLabel
                htmlFor={id}
                $isInvalid={isInvalid}
                $isValid={isValid}
                disabled={disabled}
                $isActive={isActive}
                $placeholder={rest.placeholder}
              >
                {label}
                {required && ' *'}
              </InputLabel>
              {!searchable && (
                <SelectedItemText $color={selectedText === '' ? colors.ghost : 'inherit'}>{selectedText === '' ? rest?.placeholder : selectedText}</SelectedItemText>
              )}
              <IconsWrapper aria-hidden="true">
                <ArrowDropDown />
              </IconsWrapper>
            </FieldButton>
            {isOpen && (
              <ListBox role="tablist">
                {filteredList.map((listItem) => (
                  <Option
                    role="tab"
                    key={listItem?.text}
                    onClick={() => handleDropDownOptionSelect(listItem)}
                    selected={listItem?.value === value}
                    aria-selected={listItem?.value === value}
                  >
                    {listItem?.text}
                  </Option>
                ))}
                {searchable && optionsFilter && (
                  <Option
                    role="tab"
                    onClick={cleanFilter}
                    selected={false}
                  >
                    <Typography color={colors.ghost} fontStyle="italic">Limpar filtro...</Typography>
                  </Option>)}
              </ListBox>
            )}
          </InputWrapper>}
      </FocusLock>
      {hasHint && (
        <HintsWrapper $isInvalid={isInvalid}>
          <InputMessage>{inputMessage}</InputMessage>
        </HintsWrapper>
      )}
    </InputRoot>
  );
});

Select.displayName = 'Select';

export type { SelectProps, ListItem };
export { ValidState, Select };
