import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StoreContext } from '../../../contexts/StoreContext';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { useGraphQl } from '../../../../utils/gql';
import { CardsList, ListFilterOption, Typography } from '../../../../ui/components';
import { MediaModel } from '../../../../../common/models';
import { colors, spaces } from '../../../../ui/tokens';
import { InstitutionConnectionModel } from '../../../../../common/models/InstitutionConnectionModel';
import { InstitutionModel } from '../../../../../common/models/InstitutionModel';
import { institutionsPaginationQuery, institutionsReportQuery } from '../../../queries';
import { ReportLinksModel } from '../../../../../common/models/ReportLinksModel';
import { deactivateInstitutionMutation } from '../../../queries/deactivateInstitutionMutation';
import { formatCnpj } from '../../../../utils/cnpj';
import { formatCpf } from '../../../../utils/cpf';
import { formatAddress } from '../../../../../common/utils/address';

const InstitutionsList = () => {
  const { storeId } = useParams();
  const [queryParams] = useSearchParams();
  const [items, setItems] = useState<Array<InstitutionModel> | null>(null);
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const redirect = useRedirect();

  const institutionsPaginationRequest = useGraphQl<{ institutionsPagination: InstitutionConnectionModel }>({
    query: institutionsPaginationQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const institutionsReportRequest = useGraphQl<{ institutionsReport: ReportLinksModel }>({
    query: institutionsReportQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const removeInstitutionItemRequest = useGraphQl<{ deactivateInstitution: boolean }>({
    query: deactivateInstitutionMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  useEffect(() => {
    institutionsPaginationRequest.invoke({
      variables: {
        storeId: store?.id,
        first: 99999,
      },
      onSuccess: ({ data: { institutionsPagination } }) => {
        setItems(institutionsPagination.edges.map(edge => edge.node));
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar a lista de instituições.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, [queryParams]);

  const filters = useMemo(() => {
    const filtersList: Array<ListFilterOption<InstitutionModel>> = [
      {
        label: 'Nome',
        resolver: (filterItems, value) => filterItems.filter(item => item?.name?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Documento (CNPJ ou CPF)',
        resolver: (filterItems, value) => filterItems.filter(item => {
          if (item.cpf) {
            return item?.cpf?.toLowerCase().includes(value.toLowerCase());
          }
          return item?.cnpj?.value?.toLowerCase().includes(value.toLowerCase());
        }),
      },
      {
        label: 'País',
        resolver: (filterItems, value) => filterItems.filter(item => item?.address?.country?.countryLocalizations?.find(loc => loc.language?.langCode === 'pt-BR')?.countryName?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Estado',
        resolver: (filterItems, value) => filterItems.filter(item => item?.address?.state?.stateName?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Cidade',
        resolver: (filterItems, value) => filterItems.filter(item => item?.address?.city?.cityName?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'CEP',
        resolver: (filterItems, value) => filterItems.filter(item => item?.address?.zipcode?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'E-mail',
        resolver: (filterItems, value) => filterItems.filter(item => item?.email?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Telefone',
        resolver: (filterItems, value) => filterItems.filter(item => item?.phoneNumber?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Ponto de contato (Nome)',
        resolver: (filterItems, value) => filterItems.filter(item => item?.personOfContact?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Tamanhos de preferência',
        resolver: (filterItems, value) => filterItems.filter(item => item?.sizes?.find(size => size?.name?.toLowerCase().includes(value.toLowerCase()))),
      },
      {
        label: 'Tipos de resíduos de preferência',
        resolver: (filterItems, value) => filterItems.filter(item => item?.categories?.find(category => category?.name?.toLowerCase().includes(value.toLowerCase()))),
      },
    ];
    return filtersList;
  }, []);

  return <CardsList<InstitutionModel, MediaModel>
    emptyText='Nenhuma instituição foi cadastrado até o momento.'
    items={items}
    onSnackbar={addSnackbar}
    filters={filters}
    renderContent={(item: InstitutionModel) => (
      <>
        <Typography variant="sublead" align="left" color={colors.black} margin={`0 0 ${spaces.small}`}>{item.name}</Typography>
        {item.type && <Typography variant="paragraph" align="left" color={colors.black}>Tipo: {item.type.type}</Typography>}
        {item.cpf && <Typography variant="paragraph" align="left" color={colors.black}>CPF: {formatCpf(item.cpf)}</Typography>}
        {item.cnpj && <Typography variant="paragraph" align="left" color={colors.black}>CNPJ: {formatCnpj(item.cnpj.value)}</Typography>}
        {item.address && <Typography variant="paragraph" align="left" color={colors.black}>Endereço: {formatAddress(item.address)}</Typography>}
      </>
    )}
    onItemClick={(item) => redirect.to(`/store/${storeId}/institution/item/${item.id}`)}
    onItemRemove={(removedItem) => {
      removeInstitutionItemRequest.invoke({
        variables: {
          id: removedItem?.id,
          storeId: store?.id,
        },
        onSuccess: ({ data: { deactivateInstitution } }) => {
          if (deactivateInstitution) {
            setItems(items?.filter(filteringItem => filteringItem.id !== removedItem.id));
          }
        },
        onFail: (err) => {
          if (err.name === 'UnauthorizedRequestError') {
            redirect.to('/login');
            return;
          }

          addSnackbar({
            title: `Não foi possível remover o item. ${err.message}`,
            fail: true,
            actionText: 'OK',
          });
        },
      });
    }}
    getItemImageUrl={(_item) => null}
    onItemImageChange={(_item, _media) => {
      // TODO:
    }}
    onItemImagePostUpload={(_file) => null} // TODO:
    onRequestXlsxReport={() => {
      institutionsReportRequest.invoke({
        variables: {
          storeId: store?.id,
        },
        onSuccess: ({ data: { institutionsReport } }) => {
          console.log(institutionsReport.xlsx);
          window.open(institutionsReport.xlsx, '_blank');
        },
      });
    }}
    onRequestPdfReport={() => {
      institutionsReportRequest.invoke({
        variables: {
          storeId: store?.id,
        },
        onSuccess: ({ data: { institutionsReport } }) => {
          console.log(institutionsReport.pdf);
          window.open(institutionsReport.pdf, '_blank');
        },
      });
    }}
  />;
};

export {
  InstitutionsList,
};
