import { styled } from 'styled-components';

const Separator = styled.p`
  @media only screen and (max-width: 1340px) {
    display: none;
  }
`;

const AuthLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: 1340px) {
    flex-direction: column;
    text-align: center;

    & > * {
      margin-bottom: 10px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  & > * {
    margin-right: 10px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export { AuthLinks, Separator };
