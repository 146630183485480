import { styled } from 'styled-components';
import { rgba } from 'polished';
import { spaces, colors } from '../../tokens';

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RadioButtonStyle = styled.input.attrs({ type: 'radio' })`
  margin-right: ${spaces.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${colors.secondary};
  border-radius: 50%;
  background: transparent;
  transition: background 0.2s;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in;
  cursor: pointer;

  &:before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    border-radius: 50%;
    background: ${colors.secondary};
    opacity: 0;
    transition: 0.2s ease-in;
  }

  &:focus,
  &:hover {
    box-shadow: ${rgba(colors.primary, 0.4)} 0px 0px 0px 3px;
  }

  &:checked {
    &:before {
      opacity: 1;
    }
  }
`;

export { RadioButtonStyle, Label };
