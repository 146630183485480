import { styled, css, createGlobalStyle } from 'styled-components';
import { colors, screens, zIndex, spaces } from '../../tokens';

const InspectorContainer = styled.div<{ $visible: boolean }>`
  background-color: ${colors.white};
  width: 328px;
  height: 100vh;
  right: 0;
  top: 0;
  transition: right 0.3s ease-in-out, width 0.2s ease-in-out;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: ${zIndex.heaven};
  padding: ${spaces.huge};
  justify-content: space-between;
  max-width: 90%;

  @media screen and (max-width: ${screens.xxxl}) {
    ${({ $visible }) =>
    !$visible &&
    css`
        right: -328px;
      `}
  }
`;

const InspectorOverlay = styled.div<{ $visible: boolean }>`
  background-color: ${colors.overlay};
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  opacity: 0;
  z-index: ${zIndex.purgatory};

  @media screen and (max-width: ${screens.xxxl}) {
    pointer-events: ${({ $visible }) => ($visible ? 'auto' : 'none')};
    opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  }
`;

const InspectorButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  position: absolute;
  right: ${spaces.xxHuge};
  top: ${spaces.small};

  @media screen and (max-width: ${screens.xxxl}) {
    display: block;
    cursor: pointer;
  }
`;

const IconButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  position: fixed;
  right: ${spaces.large};
  bottom: ${spaces.small};
  z-index: ${zIndex.mountains};
  border-radius: 50px;
  width: 50px;
  height: 50px;

  @media screen and (max-width: ${screens.xxxl}) {
    display: block;
    cursor: pointer;
    background-color: ${colors.primary};
  }
`;

const BodyReset = createGlobalStyle`
  body {
    @media screen and (max-width: ${screens.xxxl}) {
      overflow: hidden !important;
    }
  }
`;

export {
  InspectorContainer,
  InspectorOverlay,
  InspectorButton,
  IconButton,
  BodyReset,
};
