import { ReactNode } from 'react';
import { styled } from 'styled-components';

const SizedContainerStyle = styled.div<{
  children: ReactNode;
  $width?: string;
  $height?: string;
  $maxWidth?: string;
  $maxHeight?: string;
  $minWidth?: string;
  $minHeight?: string;
}>`
  display: block;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  max-width: ${({ $maxWidth }) => $maxWidth};
  max-height: ${({ $maxHeight }) => $maxHeight};
  min-width: ${({ $minWidth }) => $minWidth};
  min-height: ${({ $minHeight }) => $minHeight};
`;

export { SizedContainerStyle };
