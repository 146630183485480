import React from 'react';
import { HorizontalRuleStyle } from './HorizontalRule.styles';
import { colors } from '../../tokens';

type HorizontalRuleProps = {
  color?: string;
  thickness?: number;
  margin?: string;
};

const HorizontalRule = ({
  color = colors.backButton,
  thickness = 1,
  margin = '0',
}: HorizontalRuleProps) => {
  return (
    <HorizontalRuleStyle color={color} $thickness={thickness} $margin={margin} />
  );
};

export { HorizontalRule };
