import React, {
  useState,
  HTMLAttributes,
  ReactNode,
  ChangeEvent,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import {
  UseFormRegister,
  FieldValues,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form';
import { NumberFormatValues, PatternFormat, SourceInfo } from 'react-number-format';
import validator from 'validator';
import { Tooltip } from '../Tooltip';
import {
  InputRoot,
  InputWrapper,
  InputLabel,
  InputField,
  HintsWrapper,
  InputMessage,
  CharactersCounter,
  Prefix,
  TextFieldIconButton,
} from './TextField.styles';

enum ValidState {
  valid = 'valid',
  neutral = 'neutral',
  invalid = 'invalid',
}

interface TextFieldProps<T extends FieldValues> extends HTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl>;
  helperText?: string;
  prefix?: string;
  disabled?: boolean;
  state?: keyof typeof ValidState;
  register?: UseFormRegister<T>;
  placeholder?: string;
  required?: boolean;
  charLimit?: number;
  defaultValue?: string | number;
  type?: 'text' | 'number' | 'tel' | 'email' | 'password' | 'hidden';
  step?: string;
  min?: number;
  max?: number;
  format?: string;
  mask?: string;
  iconButton?: {
    icon: ReactNode;
    label: string;
    onClick: () => void;
  };
  onValueChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void;
  forceActive?: boolean;
}

const TextField = <T extends FieldValues,>({
  id,
  label,
  errorMessage = '',
  helperText = '',
  prefix = '',
  disabled = false,
  state = 'neutral',
  register,
  required = false,
  charLimit,
  defaultValue = '',
  type = 'text',
  min,
  max,
  format,
  mask,
  iconButton = undefined,
  onValueChange,
  forceActive = false,
  ...rest
}: TextFieldProps<T>) => {
  const [value, setValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);
  const [valueLength, setValueLength] = useState(0);
  const isValid = useMemo(() => state === 'valid', [state]);
  const isInvalid = useMemo(() => state === 'invalid', [state]);
  // TODO: Não forçar o ativo sempre que tem mask e format se o valor está vazio.
  const isActive = useMemo(() => forceActive || (mask && !validator.isEmpty(mask) && format && !validator.isEmpty(format)) || value !== '' || isFocused, [value, isFocused]);
  const showInputMessage = useMemo(
    () => isInvalid || helperText !== '',
    [isInvalid, helperText],
  );
  const inputMessage = useMemo(
    () => (isInvalid ? errorMessage.toString() : helperText),
    [isInvalid, errorMessage, helperText],
  );
  const hasHint = useMemo(
    () => showInputMessage || Boolean(charLimit),
    [showInputMessage, charLimit],
  );
  const hasPrefix = useMemo(() => prefix !== '', [prefix]);

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValueLength(e.target.value.length);
    setValue(e.target.value);
    if (rest?.onChange) {
      rest.onChange(e);
    }
  }, [rest]);

  const restWithRegister = useMemo(() => typeof register === 'function' ? {
    ...rest, ...(register as UseFormRegister<FieldValues>)(id, {
      onBlur: handleOnBlur,
      onChange: handleOnChange,
      required,
    }),
  } : {
    ...rest,
    onBlur: handleOnBlur,
    onChange: handleOnChange,
    required,
  }, [rest, register, required, handleOnBlur, handleOnChange]);

  return (
    <InputRoot>
      <InputWrapper $isInvalid={isInvalid} $isValid={isValid} disabled={disabled}>
        {hasPrefix && isActive && <Prefix>{prefix}</Prefix>}
        {mask && format ?
          <PatternFormat
            format={format}
            mask={mask}
            onFocus={handleOnFocus}
            defaultValue={defaultValue}
            id={id}
            disabled={disabled}
            maxLength={charLimit}
            min={min}
            max={max}
            onBlur={restWithRegister.onBlur}
            onKeyDown={rest.onKeyDown}
            onValueChange={(values, sourceInfo) => {
              restWithRegister.onChange({ target: { value: values.value } } as unknown as ChangeEvent<HTMLInputElement>);
              if (onValueChange) onValueChange(values, sourceInfo);
            }}
            customInput={(inputProps) => {
              return (
                <InputField
                  id={id}
                  disabled={disabled}
                  maxLength={charLimit}
                  type={type}
                  min={min}
                  max={max}
                  {...restWithRegister}
                  {...inputProps}
                />
              );
            }}
          />
          : <InputField
            defaultValue={defaultValue}
            onFocus={handleOnFocus}
            id={id}
            disabled={disabled}
            maxLength={charLimit}
            type={type}
            min={min}
            max={max}
            onKeyDown={rest.onKeyDown}
            {...restWithRegister}
          />}
        {iconButton && (
          <Tooltip text={iconButton.label}>
            <TextFieldIconButton
              onClick={iconButton.onClick}
              aria-label={iconButton.label}
              type="button"
            >
              {iconButton.icon}
            </TextFieldIconButton>
          </Tooltip>
        )}
        <InputLabel
          htmlFor={id}
          $isInvalid={isInvalid}
          $isValid={isValid}
          disabled={disabled}
          $isActive={isActive}
          $placeholder={rest.placeholder}
        >
          {label}
          {required && ' *'}
        </InputLabel>
      </InputWrapper>
      {hasHint && (
        <HintsWrapper $isInvalid={isInvalid}>
          <InputMessage>{inputMessage}</InputMessage>
          {charLimit && (
            <CharactersCounter>{`${valueLength}/${charLimit}`}</CharactersCounter>
          )}
        </HintsWrapper>
      )}
    </InputRoot>
  );
};

const ErrorField = ({ message }: { message: string }) => {
  return (
    <HintsWrapper $isInvalid={true}>
      <InputMessage>{message}</InputMessage>
    </HintsWrapper>
  );
};

export { ValidState, TextField, ErrorField };
