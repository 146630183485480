import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import { ContentHeader, ContentWrapper } from '../../../ui';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { UnloadingLogFormWrapper } from './UnloadingLogForm';

const UnloadingLogsViewCreatePage = () => {
  const { storeId, itemId } = useParams();
  const { store } = useContext(StoreContext);

  return (
    <Page variant="primary">
      <StoreMenu current={mainMenuOptions.unloadingLogs} />
      <ContentWrapper>
        <ContentHeader
          title={itemId ? 'Dados do registro de saída' : 'Adicionar registro de saída'}
          subtitle={itemId ? 'Verifique os dados do registro de saída' : 'Crie um novo registro de saída'}
          breadcrumbs={[
            {
              name: 'Empresas',
              link: '/store',
            },
            {
              name: 'Início',
              link: `/store/${store?.id}`,
            },
            {
              name: mainMenuOptions.unloadingLogs,
              link: `/store/${storeId}/unloadinglog`,
            },
            {
              name: !itemId ? 'Novo item' : 'Dados do registro de saída',
              link: !itemId ? `/store/${storeId}/unloadinglog/item` : `/store/${storeId}/unloadinglog/item/${itemId}`,
            },
          ]}
        />
        <UnloadingLogFormWrapper />
      </ContentWrapper>
    </Page>
  );
};

export { UnloadingLogsViewCreatePage };
