// import 'regenerator-runtime/runtime';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalReset } from '../ui/components';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { router } from './router';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { DebugMenu } from '../ui/components/DebugMenu';
import { MecenaIcon } from '../ui';
import { environment } from '../utils/environment';
import { LocalizationProvider } from '../contexts/LocalizationContext';
import { locales } from './locales';
import { LoadingPage } from './components/LoadingPage';
import ptBr from './locales/ptBr';

const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        locales={locales}
        localesJsonFilesRelativePath='../dashboard/locales/'
        fallback={<LoadingPage />}
        localeFile={ptBr}
      >
        <GlobalReset />
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </SnackbarProvider>
        <DebugMenu
          icon={<MecenaIcon />}
          side='right'
          stage={environment()}
          items={[
            {
              link: 'http://local.app.mecena.com.br:13012/',
              label: 'Prisma Studio',
            },
            {
              link: 'http://localhost:13032/',
              label: 'E-mails (AWS SES Local)',
            },
            {
              link: 'http://local.app.mecena.com.br:13052/',
              label: 'Storybook',
            },
            {
              link: 'http://local.api.dashboard.mecena.com.br:13002/api/dashboard/v1/admin',
              label: 'Apollo Playground /admin',
            },
            {
              link: 'http://local.api.dashboard.mecena.com.br:13002/api/dashboard/v1/auth',
              label: 'Apollo Playground /auth',
            },
            {
              link: 'https://komutilo.postman.co/workspace/Komutilo~11c79329-00fb-4b87-9bbb-e66937bceeac/folder/7715106-87c3565a-f857-4fc1-92f1-49e2b4c4f930?action=share&creator=7715106&ctx=documentation&active-environment=7715106-bbc67b5c-e6ee-42a9-86fd-fe343d022a8f',
              label: 'Postman',
            },
          ]}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export { App };
