import React, { useContext, useMemo } from 'react';
import { FaRecycle } from 'react-icons/fa6';
import { BsHouseHeart } from 'react-icons/bs';
import { GoPackageDependents } from 'react-icons/go';
import {
  ContentWrapper,
  ContentHeader,
  Fabric,
  Zipper,
  Cloth,
  Team,
  Gear,
  Typography,
  colors,
  Padding,
  spaces,
} from '../../../ui';
import { StoreContext } from '../../contexts/StoreContext';
import { mainMenuOptions, StoreMenu } from './index';
import { Page } from '../Page';
import { AuthenticatorContext } from '../../contexts/AuthenticatorContext';
import { QuickAccessMenu } from './QuickAccessMenu';
import { useFeature } from '../../hooks/useFeature';
import { FeatureName } from '../../../../common/models/FeatureName';

const StoreHomePage = () => {
  const { currentUser } = useContext(AuthenticatorContext);
  const { store } = useContext(StoreContext);
  const { hasFeature } = useFeature();

  const quickMenu = useMemo(() => {
    const opts = [];

    if (!store) {
      return opts;
    }

    if (hasFeature(FeatureName.Materials)) {
      opts.push({
        link: `/store/${store?.id}/storage`,
        params: { type: 'tecido' },
        icon: <Fabric />,
        text: 'Gerenciar tecidos',
      });
      opts.push({
        link: `/store/${store?.id}/storage`,
        params: { type: 'aviamento' },
        icon: <Zipper />,
        text: 'Gerenciar aviamentos',
      });
    }

    if (hasFeature(FeatureName.SpecSheets)) {
      opts.push({
        link: `/store/${store?.id}/specsheet`,
        icon: <Cloth />,
        text: 'Gerenciar fichas-técnicas',
      });
    }

    if (hasFeature(FeatureName.Residues)) {
      opts.push({
        link: `/store/${store?.id}/residue`,
        icon: <FaRecycle size={50} />,
        text: 'Gerenciar resíduos',
      });
    }

    if (hasFeature(FeatureName.Institutions)) {
      opts.push({
        link: `/store/${store?.id}/institution`,
        icon: <BsHouseHeart size={50} />,
        text: 'Gerenciar instituições',
      });
    }

    if (hasFeature(FeatureName.Residues) && hasFeature(FeatureName.Institutions)) {
      opts.push({
        link: `/store/${store?.id}/unloadinglog`,
        icon: <GoPackageDependents size={50} />,
        text: 'Gerenciar registros de saída',
      });
    }

    return [
      ...opts,
      {
        link: `/store/${store?.id}/team`,
        icon: <Team />,
        text: 'Gerenciar equipe',
      },
      {
        link: `/store/${store?.id}/settings`,
        icon: <Gear />,
        text: 'Configurações da empresa',
      },
    ];
  }, [hasFeature, store]);

  return (
    <Page variant="primary">
      <StoreMenu current={mainMenuOptions.products} />
      <ContentWrapper>
        <ContentHeader
          title={`Boas-vindas ${currentUser?.name}!`}
          subtitle={`Esse é o ponto inicial da empresa ${store?.name}`}
          breadcrumbs={[
            {
              name: 'Empresas',
              link: '/store',
            },
            {
              name: 'Início',
              link: `/store/${store?.id}`,
            },
          ]}
        />
        <Padding padding={`${spaces.none} ${spaces.none} ${spaces.small}`}>
          <Typography variant="lead" color={colors.primary}>Menu de fácil acesso</Typography>
        </Padding>
        <QuickAccessMenu menuItems={quickMenu} />
      </ContentWrapper>
    </Page>
  );
};

export { StoreHomePage };
