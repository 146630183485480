/* eslint-disable no-useless-escape */
import validator from 'validator';

let cnpjValidator = null;

import('cpf-cnpj-validator').then(m => {
  cnpjValidator = m.cnpj;
});

const digit = (numbers: string): number => {
  let index = 2;

  const sum = [...numbers].reverse().reduce((buffer, number) => {
    buffer += Number(number) * index;
    index = index === 9 ? 2 : index + 1;
    return buffer;
  }, 0);

  const mod = sum % 11;
  return mod < 2 ? 0 : 11 - mod;
};

const validateCnpj = async (cnpj?: string | number): Promise<boolean> => {
  if (!cnpjValidator?.isValid(String(cnpj).toString())) {
    return false;
  }
  const cleaned = String(cnpj).toString().replace(/[\.\/\-]/g, '');

  if (!cleaned || cleaned.length !== 14 || /^(\d)\1+$/.test(cleaned)) {
    return false;
  }

  let registration = cleaned.substring(0, 12);
  registration += digit(registration);
  registration += digit(registration);

  return registration.slice(-2) === cleaned.slice(-2);
};

const cleanupCnpj = (value: string) => {
  if (!value || validator.isEmpty(value)) return undefined;
  return value?.replace(/[^\d]/g, '');
};

const formatCnpj = (value: string): string => {
  return cnpjValidator?.format(value);
};

export {
  cleanupCnpj,
  validateCnpj,
  formatCnpj,
};
