import gql from 'graphql-tag';

const enableApiTokenQuery = gql`
  mutation($storeId: Int!, $apiTokenId: Int!) {
    enableApiToken(storeId: $storeId, apiTokenId: $apiTokenId) {
      id
      guid
      createdAt
      updatedAt
      token
      domain
      enabled
    }
  }
`;

export { enableApiTokenQuery };
