import React, { useRef, useState, useEffect } from 'react';
import { Container, CloseButton } from './Snackbar.styles';
import { setBool } from '../../../../common/utils';

type SnackbarProps = {
  title: string;
  show?: boolean;
  actionText?: string;
  durationMs?: number;
  onClose?(): void;
  fail?: boolean;
};

const Snackbar = ({
  title,
  actionText,
  show: initialShow = true,
  onClose = () => null,
  fail = false,
  ...props
}: SnackbarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hasAction = !!actionText;
  const [show, setShow] = useState(initialShow);

  useEffect(() => {
    setShow(initialShow);
  }, [initialShow]);

  return (
    <Container
      $show={setBool(show)}
      $fail={fail}
      {...props}
      ref={containerRef}
    >
      {title}
      {hasAction && (
        <CloseButton
          onClick={() => {
            setShow(false);
            if (onClose) onClose();
          }}
          variant="back"
        >
          {actionText}
        </CloseButton>
      )}
    </Container>
  );
};

export type { SnackbarProps };
export { Snackbar };
