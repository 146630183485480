import React, { SVGProps } from 'react';

const Team = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
      <path d="M6,21c-1.1,0-2-0.4-2.8-1.2C2.4,19,2,18.1,2,17s0.4-2,1.2-2.8C4,13.4,4.9,13,6,13s2,0.4,2.8,1.2C9.6,15,10,15.9,10,17
	s-0.4,2-1.2,2.8C8,20.6,7.1,21,6,21z M18,21c-1.1,0-2-0.4-2.8-1.2C14.4,19,14,18.1,14,17s0.4-2,1.2-2.8C16,13.4,16.9,13,18,13
	s2,0.4,2.8,1.2C21.6,15,22,15.9,22,17s-0.4,2-1.2,2.8C20,20.6,19.1,21,18,21z M6,19c0.6,0,1-0.2,1.4-0.6S8,17.5,8,17s-0.2-1-0.6-1.4
	S6.6,15,6,15s-1,0.2-1.4,0.6S4,16.4,4,17s0.2,1,0.6,1.4S5.5,19,6,19z M18,19c0.6,0,1-0.2,1.4-0.6S20,17.5,20,17s-0.2-1-0.6-1.4
	S18.6,15,18,15c-0.5,0-1,0.2-1.4,0.6C16.2,16,16,16.4,16,17s0.2,1,0.6,1.4C17,18.8,17.5,19,18,19z M12,11c-1.1,0-2-0.4-2.8-1.2
	S8,8.1,8,7s0.4-2,1.2-2.8S10.9,3,12,3s2,0.4,2.8,1.2S16,5.9,16,7s-0.4,2-1.2,2.8S13.1,11,12,11z M12,9c0.6,0,1-0.2,1.4-0.6
	C13.8,8,14,7.5,14,7s-0.2-1-0.6-1.4C13,5.2,12.6,5,12,5s-1,0.2-1.4,0.6C10.2,6,10,6.4,10,7s0.2,1,0.6,1.4C11,8.8,11.4,9,12,9z"/>
    </svg>
  );
};

export { Team };
