import React from 'react';
import { QuickAccessButton } from './QuickAccessButton';
import { QuickAccessMenuProps } from './QuickAccessMenu.types';
import { QuickAccessMenuContainer } from './QuickAccessMenuContainer.styles';

const QuickAccessMenu = ({ menuItems }: QuickAccessMenuProps) => {
  return (
    <QuickAccessMenuContainer>
      {menuItems.map((item) => (
        <QuickAccessButton key={item.text} {...item} />
      ))}
    </QuickAccessMenuContainer>
  );
};

export {
  QuickAccessMenu,
};
