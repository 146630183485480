import React, { ReactNode } from 'react';
import { Container } from './Grid.styles';
import { grid } from '../../tokens';

interface GridContainerProps {
  children: ReactNode;
  columns?: number;
  gutter?: number;
  noPadding?: boolean;
  wide?: boolean;
}

const GridContainer = ({
  children,
  columns = grid.columns,
  gutter = grid.gutter,
  noPadding = false,
  wide = false,
}: GridContainerProps) => (
  <Container
    $wide={wide}
    $columns={Number(columns)}
    $gutter={Number(gutter)}
    $noPadding={noPadding}
  >
    {children}
  </Container>
);

export { GridContainer };
