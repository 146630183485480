import gql from 'graphql-tag';

const teamMemberQuery = gql`
  query(
    $id: Int!
    $storeId: Int!
  ) {
    teamMember(
      id: $id
      storeId: $storeId
    ) {
      id
      guid
      createdAt
      updatedAt
      name
      cpf
      birthDate
      termsAccepted
      userCreatedPassword
      completedOnboarding
      wasInvited
      avatar {
        id
        url
        presignedUrl
      }
      currentStoreRole {
        id
        name
      }
      userNames {
        userName
        verified
        userNameType
      }
    }
  }
`;

export {
  teamMemberQuery,
};
