import React, { SVGProps } from 'react';

const AddCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 40 40" width={40} height={40} {...props}>
      <path
        d="M23.375,3.375a20,20,0,1,0,20,20A20,20,0,0,0,23.375,3.375Zm8.7,21.538H24.913v7.163a1.538,1.538,0,0,1-3.077,0V24.913H14.673a1.538,1.538,0,1,1,0-3.077h7.163V14.673a1.538,1.538,0,1,1,3.077,0v7.163h7.163a1.538,1.538,0,0,1,0,3.077Z"
        transform="translate(-3.375 -3.375)"
      />
    </svg>
  );
};

export { AddCircle };
