import gql from 'graphql-tag';

const deactivateInstitutionMutation = gql`
  mutation (
    $id: Int!
    $storeId: Int!
  ) {
    deactivateInstitution(
      id: $id
      storeId: $storeId
    ) {
      id
    }
  }
`;

export {
  deactivateInstitutionMutation,
};
