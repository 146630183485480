import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import { ContentHeader, ContentWrapper } from '../../../ui';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { InstitutionFormWrapper } from './InstitutionForm';

const InstitutionsEditCreatePage = () => {
  const { storeId, itemId } = useParams();
  const { store } = useContext(StoreContext);

  return (
    <Page variant="primary">
      <StoreMenu current={mainMenuOptions.institutions} />
      <ContentWrapper>
        <ContentHeader
          title={itemId ? 'Editar instituição' : 'Adicionar instituição'}
          subtitle={itemId ? 'Edite a instituição' : 'Crie uma nova instituição'}
          breadcrumbs={[
            {
              name: 'Empresas',
              link: '/store',
            },
            {
              name: 'Início',
              link: `/store/${store?.id}`,
            },
            {
              name: mainMenuOptions.institutions,
              link: `/store/${storeId}/institution`,
            },
            {
              name: !itemId ? 'Novo item' : 'Edição de instituição',
              link: !itemId ? `/store/${storeId}/institution/item` : `/store/${storeId}/institution/item/${itemId}`,
            },
          ]}
        />
        <InstitutionFormWrapper />
      </ContentWrapper>
    </Page>
  );
};

export { InstitutionsEditCreatePage };
