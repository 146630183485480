import React, { SVGProps } from 'react';

const CheckCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 40 40" width={40} height={40} {...props}>
      <path
        d="M40.563,20.562a20,20,0,1,1-20-20A20,20,0,0,1,40.563,20.562ZM18.249,31.152,33.088,16.314a1.29,1.29,0,0,0,0-1.825l-1.825-1.825a1.29,1.29,0,0,0-1.825,0l-12.1,12.1-5.65-5.65a1.29,1.29,0,0,0-1.825,0L8.037,20.94a1.29,1.29,0,0,0,0,1.825l8.387,8.387a1.29,1.29,0,0,0,1.825,0Z"
        transform="translate(-0.563 -0.563)"
      />
    </svg>
  );
};

export { CheckCircle };
