import { styled } from 'styled-components';

type FlexAlignItems = 'center' | 'start' | 'end' | 'self-start' | 'self-end' | 'flex-start' | 'flex-end';
type FlexJustifyContent = 'space-around' | 'space-between' | 'flex-start' | 'flex-end';

interface IColBase {
  $justifyContent?: FlexJustifyContent;
  $alignItems?: FlexAlignItems;
  $gutter: string;
  $minHeight: string;
}

const ColBase = styled.div<IColBase>`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems ?? 'flex-start'};
  justify-content: ${({ $justifyContent }) => $justifyContent ?? 'space-between'};
  padding: 0.3rem;
  min-height: ${({ $minHeight }) => $minHeight};

  & > * {
    margin-bottom: ${({ $gutter }) => $gutter};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export type { FlexAlignItems, FlexJustifyContent };
export { ColBase };
