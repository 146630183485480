import { styled } from 'styled-components';
import { colors } from '../../tokens';
import { SelectedArrow as SelectedArrowIcon } from '../../icons';

const MainMenuItemStyle = styled.div`
  height: 88px;
  border-top: solid 1px ${colors.primaryDarker};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
  transition: background-color 0.2s ease-in-out;
  fill: white;

  & > svg {
    max-width: 24px;
  }

  & > * {
    margin-right: 35px;
  }

  & > *:last-child {
    margin-right: 0;
  }

  &:last-child {
    border-bottom: solid 1px ${colors.primaryDarker};
  }

  & > * {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    background-color: #fff1;
  }

  &:active,
  &:focus {
    background-color: #0001;
  }
`;

const SelectedArrow = styled(SelectedArrowIcon)`
  width: 15px;
  height: 88px;
  position: absolute;
  left: 0;
  fill: white;
`;

export { MainMenuItemStyle, SelectedArrow };
