import gql from 'graphql-tag';

const institutionsReportQuery = gql`
  query (
    $storeId: Int!
    $type: String
    $orderBy: OrderByInput
    $active: Boolean
    $filters: [InstitutionFilter]
  ) {
    institutionsReport(
      storeId: $storeId
      orderBy: $orderBy
      type: $type
      active: $active
      filters: $filters
    ) {
      csv
      xlsx
      pdf
    }
  }
`;

export { institutionsReportQuery };
