/* eslint-disable no-useless-escape */
import { patternFormatter } from 'react-number-format';
import validator from 'validator';
import * as yup from 'yup';
import { validateCnpj } from '../../common/utils';

const cnpjInputProps = { format: '##.###.###/####-##', mask: '_', helperText: 'Formatação: 00.000.000/0001-00' };

const yupCnpj = ({ required }: { required: boolean } = { required: false }) =>
  yup
    .string()
    .typeError('CNPJ inválido.')
    .matches(/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/, {
      message: 'Formatação incorreta.',
      excludeEmptyString: !required,
    })
    .test('cnpjValidation', 'CNPJ inválido.', (value) => {
      return required ? validateCnpj(value) : (
        typeof value === 'undefined' ||
        value === null ||
        validator.isEmpty(String(value)) ||
        validateCnpj(value)
      );
    });

const formatCnpj = (value: string | null | undefined) => value && patternFormatter(value, cnpjInputProps);

export {
  yupCnpj,
  cnpjInputProps,
  formatCnpj,
};
