import { getProcess } from './getProcess';

type StageType = 'local' | 'dev' | 'staging' | 'prod';
const validEnvironmentsNames = ['local', 'dev', 'staging', 'prod'];

const environment = (): StageType | undefined => {
  const env = String(getProcess()?.env?.STAGE ?? getProcess()?.env?.PUBLIC_DASHBOARD_STAGE);

  if (env !== 'prod') {
    return validEnvironmentsNames.includes(env) ? env as StageType : undefined;
  }

  return 'prod';
};

const isDevelopment = (): boolean => environment() === 'local';

export type { StageType };
export { environment, isDevelopment, validEnvironmentsNames };
