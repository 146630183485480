import React, { SVGProps } from 'react';

const UserIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
      <g>
        <path d="M12.7,12.1c-4.3-0.4-8.4,2.7-9.4,7c-0.5,2.1-0.3,3.3,1,4.2C4.9,23.7,5.6,24,6.4,24h11.2c0.9,0,1.8-0.3,2.4-0.9
		c0.6-0.6,0.9-1.3,0.9-2.4C20.8,16.1,17.3,12.5,12.7,12.1z"/>
        <path d="M12,8.9c2.4,0,4.4-2,4.5-4.4c0-2.4-2-4.5-4.5-4.5C9.6,0,7.6,2,7.5,4.4C7.5,6.9,9.6,8.9,12,8.9z" />
      </g>
    </svg>
  );
};

export { UserIcon };
