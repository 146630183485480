import gql from 'graphql-tag';

const refreshQuery = gql`
  mutation {
    refresh {
      user {
        id
        guid
        createdAt
        updatedAt
        name
        termsAccepted
        userNames {
          userName
          verified
          userNameType
        }
      }
      accessToken
    }
  }
`;

export { refreshQuery };
