import gql from 'graphql-tag';

const adminUserContractQuery = gql`
  query {
    adminUserContract {
      id
      guid
      signed
      plan {
        id
        guid
        name
        maxUsers
        maxStores
      }
      features
    }
  }
`;

export { adminUserContractQuery };
