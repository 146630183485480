import {
  Button,
  colors,
  fontWeight,
  Form,
  FormControl,
  HorizontalRule,
  ProgressIndicator,
  Typography,
} from '../../../../../ui';
import { useForm } from 'react-hook-form';
import { OnboardingStepProps } from '../../OnboardingPage';
import { useGraphQl } from '../../../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../../../utils/apiEndpoints';
import { formatCnpj } from '../../../../../utils/cnpj';
import { AdminUserModel, StoreModel } from '../../../../../../common/models';
import React, { useCallback, useContext, useState } from 'react';
import { SnackbarContext } from '../../../../contexts/SnackbarContext';
import { SuccessFeedback } from '../SuccessFeedback';
import { completeOnboardingQuery } from '../../../../queries/completeOnboardingQuery';
import { AuthenticatorContext } from '../../../../contexts/AuthenticatorContext';

const DataReportForm = ({
  title,
  onSubmitSucceeded,
  store,
}: {
  title: string,
  onSubmitSucceeded: (response: { userId: number }) => void,
  store: StoreModel | null,
}) => {
  const { addSnackbar } = useContext(SnackbarContext);
  const { currentUser } = useContext(AuthenticatorContext);

  const { handleSubmit } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
  });

  const completeOnboardingRequest = useGraphQl<{ completeOnboarding: AdminUserModel }>({
    query: completeOnboardingQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const onSubmit = () => {
    completeOnboardingRequest.invoke({
      onFail: (err) => {
        addSnackbar({
          title: `Falha ao confirmar os dados do Onboarding. ${err?.message
            }`,
          actionText: 'OK',
          fail: true,
        });
      },
      onSuccess: ({ data }) => {
        onSubmitSucceeded({ userId: data.completeOnboarding.id });
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Typography variant="lead" color={colors.primary}>{title}</Typography>
      </FormControl>
      <FormControl>
        <Typography variant="subtitle" color={colors.primaryDarker}>Usuário</Typography>
        <HorizontalRule color={colors.primaryDarker} />
      </FormControl>
      <FormControl>
        <Typography color={colors.black}><span style={{ fontWeight: fontWeight.semiBold }}>Nome:</span> {currentUser?.name}</Typography>
        {currentUser?.cpf && <Typography color={colors.black}><span style={{ fontWeight: fontWeight.semiBold }}>CPF:</span> {currentUser?.cpf}</Typography>}
      </FormControl>
      {store && (
        <>
          <FormControl>
            <Typography variant="subtitle" color={colors.primaryDarker}>Empresa</Typography>
            <HorizontalRule color={colors.primaryDarker} />
          </FormControl>
          <FormControl>
            {store?.name &&
              <Typography color={colors.black}><span style={{ fontWeight: fontWeight.semiBold }}>Nome:</span> {store?.name}</Typography>}
            {store?.cnpj &&
              <Typography color={colors.black}><span style={{ fontWeight: fontWeight.semiBold }}>CNPJ:</span> {formatCnpj(store?.cnpj?.value)}</Typography>}
            {store?.storeSetting &&
              <Typography color={colors.black}><span style={{ fontWeight: fontWeight.semiBold }}>E-mail da empresa:</span> {store?.storeSetting?.communicationEmail}</Typography>}
            {store?.role?.name &&
              <Typography color={colors.black}><span style={{ fontWeight: fontWeight.semiBold }}>Cargo na Empresa:</span> {store?.role?.name}</Typography>}
          </FormControl>
        </>
      )}
      <Button type='submit' variant='default'>Finalizar</Button>
    </Form >
  );
};

const OnboardingConfirmUserAndStoreData = ({ step, onComplete, store }: OnboardingStepProps & { store: StoreModel | null }) => {
  const [
    updateSuccessResponse,
    setUpdateSuccessResponse,
  ] = useState<{ userId: number } | null>(null);
  const { currentUser, setUser } = useContext(AuthenticatorContext);

  const handleCompleted = useCallback(() => {
    if (!step) return;
    onComplete({
      ...step,
      completed: true,
    });
    setUser({
      ...(currentUser as AdminUserModel),
      completedOnboarding: true,
    });
  }, [step, onComplete]);

  return (
    step ?
      updateSuccessResponse ?
        (<SuccessFeedback step={step} handleCompleted={handleCompleted} />) :
        (<DataReportForm
          title={step.title}
          onSubmitSucceeded={setUpdateSuccessResponse}
          store={store}
        />) :
      (<ProgressIndicator />)
  );
};

export { OnboardingConfirmUserAndStoreData };
