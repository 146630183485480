import React, { ReactNode, HTMLAttributes } from 'react';
import { colors } from '../../tokens';
import {
  TypographyTitle,
  TypographyLead,
  TypographySubtitle,
  TypographySublead,
  TypographyParagraph,
  TypographyCaption,
  TypographyFootnote,
  TypographyInputLabel,
  TypographyInputPlaceholder,
  TypographyButton,
  TypographyLink,
} from './Typography.styles';

const variants = {
  title: TypographyTitle,
  lead: TypographyLead,
  subtitle: TypographySubtitle,
  sublead: TypographySublead,
  paragraph: TypographyParagraph,
  caption: TypographyCaption,
  footnote: TypographyFootnote,
  inputLabel: TypographyInputLabel,
  inputPlaceholder: TypographyInputPlaceholder,
  button: TypographyButton,
  link: TypographyLink,
} as const;

type TypographyVariants = keyof typeof variants;

interface TypographyProps extends HTMLAttributes<HTMLParagraphElement> {
  children: ReactNode;
  variant?: TypographyVariants;
  color?: string;
  align?: 'left' | 'center' | 'right';
  fontWeight?: number;
  renderInline?: boolean;
  cursor?: string;
  margin?: string;
  upperCase?: boolean;
  htmlFor?: string;
  wide?: boolean;
  fontStyle?: 'normal' | 'italic';
}

const Typography = ({
  children,
  variant = 'paragraph',
  align = 'left',
  renderInline = false,
  color = colors.secondary,
  cursor = 'auto',
  margin = '0',
  upperCase = undefined,
  wide = false,
  fontStyle,
  ...rest
}: TypographyProps) => {
  const Variant = variants[variant];
  return (
    <Variant
      color={color}
      $align={align}
      $renderInline={renderInline}
      $cursor={cursor}
      $margin={margin}
      $upperCase={upperCase}
      $wide={wide}
      $fontStyle={fontStyle}
      {...rest}
    >
      {children}
    </Variant>
  );
};

export type { TypographyVariants, TypographyProps };
export { Typography };
