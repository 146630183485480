import gql from 'graphql-tag';

const updateTeamMemberMutation = gql`
  mutation(
    $id: Int!
    $storeId: Int!
    $name: String
    $cpf: String
    $birthDate: String
    $email: String
    $tel: String
    $roleId: Int
    $avatarId: Int
  ) {
    updateTeamMember(
    id: $id
    storeId: $storeId
    name: $name
    cpf: $cpf
    birthDate: $birthDate
    email: $email
    tel: $tel
    roleId: $roleId
    avatarId: $avatarId
    ) {
      id
      guid
      createdAt
      updatedAt
      name
      cpf
      birthDate
      termsAccepted
      userCreatedPassword
      completedOnboarding
      wasInvited
      avatar {
        id
        url
      }
      userNames {
        userName
        verified
        userNameType
      }
    }
  }
`;

export {
  updateTeamMemberMutation,
};
