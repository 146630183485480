import { styled } from 'styled-components';
import { colors, fontFamily, fontSize, fontWeight } from '../../tokens';

const DatePickerWrapper = styled.div`
  width: 100%;
  height: 100%;

  & .react-datepicker-wrapper {
    width: 100%;
    height: 100%;

    & .react-datepicker__input-container {
      width: 100%;
      height: 100%;

      & input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        font-family: ${fontFamily.montserrat};
        font-weight: ${fontWeight.regular};
        font-size: ${fontSize.inputPlaceholder};
        color: ${colors.black};

        &:disabled {
          cursor: not-allowed;
          color: ${colors.disabled};
        }
      }
    }
  }
`;

export {
  DatePickerWrapper,
};
