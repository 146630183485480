import gql from 'graphql-tag';

const updateInstitutionMutation = gql`
  mutation (
    $id: Int!
    $storeId: Int!
    $name: String
    $typeId: Int
    $motivation: String
    $observation: String
    $cnpjOrCpf: String
    $address: AddressInput
    $email: String
    $phoneNumber: String
    $personOfContact: String
    $categoriesIds: [Int]
    $residueSizesIds: [Int]
  ) {
    updateInstitution(
      id: $id
      storeId: $storeId
      name: $name
      typeId: $typeId
      motivation: $motivation
      observation: $observation
      cnpjOrCpf: $cnpjOrCpf
      address: $address
      email: $email
      phoneNumber: $phoneNumber
      personOfContact: $personOfContact
      categoriesIds: $categoriesIds
      residueSizesIds: $residueSizesIds
    ) {
      id
      guid
      createdAt
      updatedAt
      name
      active
      type {
        id
        type
      }
      motivation
      observation
      email
      phoneNumber
      personOfContact
      cpf
      cnpj {
        id
        value
      }
      address {
        id
        country {
          id
          abbreviation
          countryLocalizations {
            id
            countryName
            language {
              id
              langCode
            }
          }
        }
        state {
          id
          stateName
          abbreviation
        }
      }
      categories {
        id
        name
      }
      sizes {
        id
        name
      }
    }
  }
`;

export { updateInstitutionMutation };
