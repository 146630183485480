import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ContentWrapper,
  ContentHeader,
  Card,
  spaces,
} from '../../../ui';
import { StoreContext } from '../../contexts/StoreContext';
import { mainMenuOptions, StoreMenu } from './index';
import { Page } from '../Page';
import { useRedirect } from '../../../utils/redirect';
import { LoadingPage } from '../LoadingPage';
import { StoreSettingsForm } from './StoreSettingsForm';

const StoreSettingsPage = () => {
  const { storeId } = useParams();
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();

  const page = useMemo(() => {
    return (
      <Page variant="primary">
        <StoreMenu current={mainMenuOptions.settings} />
        <ContentWrapper>
          <ContentHeader
            title="Configurações da empresa"
            subtitle={store ? `Alterar propriedades da empresa ${store?.name}` : 'Alterar propriedades da empresa'}
            breadcrumbs={[
              {
                name: 'Empresas',
                link: '/store',
                redirectOptions: {
                  removeParams: ['type'],
                },
              },
              {
                name: 'Início',
                link: `/store/${store?.id}`,
                redirectOptions: {
                  removeParams: ['type'],
                },
              },
              {
                name: mainMenuOptions.settings,
                link: `/store/${storeId}/settings`,
              },
            ]}
          />
          <Card padding={spaces.xLarge}>
            <StoreSettingsForm store={store} />
          </Card>
        </ContentWrapper>
      </Page>
    );
  }, [queryParams, store, storeId, redirect]);

  return page ? page : <LoadingPage />;
};

export { StoreSettingsPage };
