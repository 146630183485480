import React from 'react';
import { colors } from '../../tokens';
import { LoadingRectStyle } from './LoadingRect.styles';

type LoadingRectProps = {
  width: string;
  height: string;
  borderRadius?: string;
  backgroundColor?: string;
}

const LoadingRect = ({
  width,
  height,
  borderRadius = '8px',
  backgroundColor = colors.background,
}: LoadingRectProps) => {
  return <LoadingRectStyle
    $width={width}
    $height={height}
    $borderRadius={borderRadius}
    $backgroundColor={backgroundColor}
  />;
};

export type {
  LoadingRectProps,
};
export {
  LoadingRect,
};
