function formatFloatToFiveDigits(n: number): string {
  const integerPart = Number.isInteger(n) ? n >= 100 ? n.toString().slice(0, 2) : n.toString() : n.toString().split('.')[0];
  const decimalPart = Number.isInteger(n) ? n >= 100 ? n.toString().slice(2) : '0' : n.toString().split('.')[1];
  return `${integerPart.padStart(2, '0')}.${decimalPart.padEnd(3, '0')}`;
}

export {
  formatFloatToFiveDigits,
};

