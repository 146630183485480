import React, { useCallback, useContext } from 'react';
import { StoreCreateForm } from '../../../stores';
import { OnboardingStepProps } from '../../OnboardingPage';
import { StoreModel } from '../../../../../../common/models';
import { ProgressIndicator } from '../../../../../ui';
import { SuccessFeedback } from '../SuccessFeedback';
import { SnackbarContext } from '../../../../contexts/SnackbarContext';

const OnboardingCreateStore = ({
  step,
  onComplete,
  store,
  setStore,
}: OnboardingStepProps & {
  store: StoreModel | null,
  setStore: (store: StoreModel) => void,
}) => {
  const { addSnackbar } = useContext(SnackbarContext);
  const handleCompleted = useCallback(() => {
    if (!step) return;
    onComplete({
      ...step,
      completed: true,
    });
  }, [step, onComplete]);

  return (
    step ?
      store ?
        (<SuccessFeedback step={step} handleCompleted={handleCompleted} />) :
        (<StoreCreateForm onSubmitSucceeded={setStore} onSubmitFailed={(err) => {
          addSnackbar({
            title: `Falha ao criar a empresa. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        }} />) :
      (<ProgressIndicator />)
  );
};

export { OnboardingCreateStore };
