import { styled } from 'styled-components';

const HorizontalRuleStyle = styled.hr<{
  color: string;
  $thickness: number;
  $margin: string;
}>`
  border: ${({ color, $thickness }) => `${$thickness}px solid ${color}`};
  margin: ${({ $margin }) => $margin};
`;

export { HorizontalRuleStyle };
