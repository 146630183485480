import gql from 'graphql-tag';

const materialInventoryItemsReportQuery = gql`
  query (
    $storeId: Int!
    $materialType: MaterialType!
    $orderBy: OrderByInput
    $active: Boolean
    $filters: [MaterialFilter]
  ) {
    materialInventoryItemsReport(
      storeId: $storeId
      materialType: $materialType
      orderBy: $orderBy
      active: $active
      filters: $filters
    ) {
      xlsx
      csv
      pdf
    }
  }
`;

export { materialInventoryItemsReportQuery };
