import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Padding } from '../Padding';
import { colors, spaces } from '../../tokens';
import { Typography } from '../Typography';
import { Col } from '../Col';
import { ProgressIndicator } from '../ProgressIndicator';
import { Row } from '../Row';
import { ImageCard } from '../ImageCard';
import { Button } from '../Button';
import { EditIcon, TrashBin } from '../../icons';
import { ConfirmModal, ConfirmModalData } from '../ConfirmModal';
import { ApiEndpointProps } from '../../../utils/apiEndpoints';
import { SnackbarProps } from '../Snackbar';
import { SearchBar } from '../SearchBar';
import { Select } from '../Select';
import { GridContainer, GridItem } from '../Grid';
import {
  CardsListItemCard,
  CardsListItemCardButton,
  ImagePlaceholderContainer,
  CardsListInteractiveButtonsRow,
} from './CardsList.styles';
import { Center } from '../Center';
import { FileInfo } from '../../../../common/models';

type ListFilterOption<TDataItem> = {
  label: string,
  resolver: (items: Array<TDataItem>, value: string) => Array<TDataItem>,
};

type ListFilterData<TDataItem> = {
  value: string,
  option?: ListFilterOption<TDataItem> | null,
};

type CardsListProps<TDataItem, TImageModel> = {
  items: Array<TDataItem>,
  renderContent: (item: TDataItem) => ReactNode,
  emptyText?: string,
  apiEndpointProps?: ApiEndpointProps,
  onSnackbar?: (props: SnackbarProps) => void,
  filters?: Array<ListFilterOption<TDataItem>>,
  itemNameProp?: string,
  onItemClick?: (item: TDataItem) => void,
  onItemRemove?: (item: TDataItem) => void,
  getItemImageUrl?: (item: TDataItem) => string,
  onItemImageChange?: (item: TDataItem, media: TImageModel) => void,
  onItemHideRemoveButton?: (item: TDataItem) => boolean,
  onItemImagePostUpload?: (file: FileInfo) => Promise<TImageModel>;
  onRequestXlsxReport?: () => void,
  onRequestPdfReport?: () => void,
}

const CardsList = <TDataItem, TImageModel,>({
  items: statelessItems,
  emptyText = 'Não há items cadastrados.',
  apiEndpointProps,
  filters,
  itemNameProp = 'name',
  onSnackbar = () => null,
  renderContent,
  onItemClick,
  onItemRemove,
  getItemImageUrl,
  onItemImageChange,
  onItemHideRemoveButton,
  onItemImagePostUpload,
  onRequestXlsxReport,
  onRequestPdfReport,
}: CardsListProps<TDataItem, TImageModel>) => {
  const [modalData, setModalData] = useState<ConfirmModalData<TDataItem>>({
    isOpen: false,
    itemKey: null,
    itemName: null,
    data: null,
  });
  const [items, setItems] = useState(statelessItems);
  const [filter, setFilter] = useState<ListFilterData<TDataItem>>({
    value: '',
    option: filters && filters.length > 0 ? filters[0] : null,
  });
  const filteredItems: Array<TDataItem> = useMemo(() =>
    filter?.option?.resolver && items && filter.value && filter.value != '' ? filter.option.resolver(items, filter.value) : items, [items, filter]);

  const showDeleteButton = useCallback((item: TDataItem) => {
    if (!onItemHideRemoveButton) return true;
    return !(onItemHideRemoveButton(item));
  }, [onItemHideRemoveButton]);

  const selectedFilter = useMemo(() => filter?.option?.label, [filter]);

  useEffect(() => setItems(statelessItems), [statelessItems]);

  return (
    <>
      <Padding padding={`${spaces.large} 0`}>
        {filters && filters.length > 0 &&
          (
            <Padding padding={` 0 0 ${spaces.large} `}>
              <Row width="100%">
                <GridContainer noPadding wide>
                  <GridItem sm={12} lg={6}>
                    <SearchBar
                      id="cardsListSearch"
                      placeholder="Buscar..."
                      autoComplete="off"
                      onSubmit={(value) => {
                        setFilter({
                          ...filter,
                          value,
                        });
                      }}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          value: e?.target?.value,
                        });
                      }}
                    />
                  </GridItem>
                  <GridItem sm={12} lg={6}>
                    <Select
                      id="cardsListFilterProp"
                      label="Filtrar por"
                      defaultValue={selectedFilter}
                      listItems={(filters?.map((f) => ({
                        value: f.label,
                        text: f.label,
                      }))) ?? []}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          option: filters?.find(f => f.label === e.target.value),
                        });
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </Row>
            </Padding>
          )}
        {onRequestXlsxReport || onRequestPdfReport ? (
          <Padding padding={` 0 0 ${spaces.large} `}>
            <GridContainer noPadding wide>
              <GridItem sm={12} md={12} lg={4}>
                <Padding padding="25px 10px 0">
                  <Typography variant="button" align="left" color={colors.primary}>Gerar relatórios: </Typography>
                </Padding>
              </GridItem>
              {onRequestXlsxReport && (
                <GridItem sm={12} md={6} lg={4}>
                  <Button
                    wide
                    variant="outline"
                    onClick={onRequestXlsxReport}
                  >
                    <Typography variant="button" align="center" color={colors.primary}>Planilha (.xlsx)</Typography>
                  </Button>
                </GridItem>
              )}
              {onRequestPdfReport && (
                <GridItem sm={12} md={6} lg={4}>
                  <Button
                    wide
                    variant="outline"
                    onClick={onRequestPdfReport}
                  >
                    <Typography variant="button" align="center" color={colors.primary}>PDF</Typography>
                  </Button>
                </GridItem>
              )}
            </GridContainer>
          </Padding>
        ) : null}
        {filteredItems && Array.isArray(filteredItems) ? (
          <>
            {filteredItems?.length === 0 ?
              (
                <Typography
                  color={colors.primary}
                  align="left"
                  variant="sublead"
                  margin={`${spaces.huge} 0`}
                >
                  {emptyText}
                </Typography>
              ) : (
                <Col>
                  {filteredItems?.map((item, itemIndex) => {
                    const itemKey = item[Object.getOwnPropertyNames(item)[0]] ?? `Item${itemIndex}`;
                    return (
                      <CardsListItemCard key={itemKey} $hoverable={onItemClick !== undefined}>
                        <Row>
                          {getItemImageUrl && (
                            <ImagePlaceholderContainer>
                              <ImageCard
                                id={`${itemKey}Image`}
                                uploadable
                                fitParent
                                showActionIconOnHoverOnly
                                apiEndpointProps={apiEndpointProps}
                                src={getItemImageUrl(item)}
                                onChange={async ({ target: { value } }) => {
                                  if (onItemImageChange) {
                                    const media: TImageModel = JSON.parse(value) as TImageModel;
                                    onItemImageChange(item, media);
                                  }
                                }}
                                onSnackbar={onSnackbar}
                                postUpload={onItemImagePostUpload}
                              />
                            </ImagePlaceholderContainer>)}
                          <CardsListItemCardButton onClick={onItemClick ? () => onItemClick(item) : undefined}>
                            <Padding padding={spaces.medium}>
                              {renderContent(item)}
                            </Padding>
                          </CardsListItemCardButton>
                          <CardsListInteractiveButtonsRow>
                            <>
                              {onItemClick && (
                                <Button
                                  icon={<EditIcon width={24} height={24} />}
                                  onClick={() => onItemClick(item)}
                                />
                              )}
                              {onItemRemove && showDeleteButton(item) && (
                                <Button
                                  variant="danger"
                                  icon={<TrashBin />}
                                  onClick={() => {
                                    setModalData({
                                      isOpen: true,
                                      itemKey,
                                      itemName: itemNameProp,
                                      data: item,
                                    });
                                  }}
                                />
                              )}
                            </>
                          </CardsListInteractiveButtonsRow>
                        </Row>
                      </CardsListItemCard>
                    );
                  })}
                </Col>
              )}
          </>
        ) : (
          <Center>
            <ProgressIndicator />
          </Center>
        )}
      </Padding >
      <ConfirmModal
        onClose={() => setModalData({
          isOpen: false,
          itemKey: null,
          itemName: null,
          data: null,
        })}
        onConfirm={({ itemName, data }) => {
          if (onItemRemove && data && itemName) {
            onItemRemove(data);
            setItems(items?.filter(item => data[itemName] !== item[itemName]));
          }
        }}
        {...modalData}
      />
    </>
  );
};

export type {
  CardsListProps,
  ListFilterOption,
};
export { CardsList };
