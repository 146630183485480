import React, { FormEvent, useCallback, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Form,
  GridContainer,
  GridItem,
  Modal,
  TextField,
  FormControl,
  Button,
  Padding,
  spaces,
  ImagePlaceholder,
} from '../../../../ui';
import { MediaCard } from './MediaCard';
import { yupFile } from '../../../../utils/file';

const mediaValidationSchemaFields = {
  file: yupFile(),
  title: yup.string().typeError('O título não pode ficar vazio.').required('O título é obrigatório.'),
  alt: yup.string().optional(),
};
const mediaValidationSchema = yup.object().shape(mediaValidationSchemaFields);

type MediaData = {
  file?: FileList,
  title?: string,
  alt?: string,
};

type MediaCardModalProps = {
  id: string;
  onClose: () => void;
  onSubmit?: (data: MediaData) => void;
};

const MediaCardModal = ({ onSubmit, onClose }: MediaCardModalProps) => {
  const formRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [data, setData] = useState<MediaData>({
    file: undefined,
    title: undefined,
  });
  const [errors, setErrors] = useState<{
    file?: Error,
    title?: Error,
  }>({});

  const validateFields = useCallback(async () => {
    try {
      await mediaValidationSchema.validate(data);
      return true;
    } catch (_err) {
      const newErrors = { ...errors };
      await Promise.all(Object.keys(data).map(async (dataKey) => {
        try {
          await mediaValidationSchemaFields[dataKey].validate(data[dataKey]);
          newErrors[dataKey] = undefined;
        } catch (err) {
          newErrors[dataKey] = err;
        }
      }));
      setErrors(newErrors);
      return false;
    }
  }, [data]);

  const handleUpdate = useCallback((newData: MediaData) => {
    setData({
      ...data,
      ...newData,
    });
  }, [data]);

  const handleSubmit = useCallback(async () => {
    const valid = await validateFields();
    if (valid) {
      if (onSubmit) onSubmit(data);
      setIsOpen(false);
    }
  }, [data]);

  const handleOnBlur = useCallback(async () => {
    await validateFields();
  }, [data]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Adicionar mídia">
      <Form
        ref={formRef}
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
        }}
      >
        <Padding padding={`${spaces.huge} 0`}>
          <GridContainer noPadding>
            <GridItem sm={12} lg={6}>
              <FormControl>
                <MediaCard
                  uploadable
                  wide
                  id="file"
                  onChange={(e) => handleUpdate({ file: e.target.files })}
                  errorMessage={errors.file?.message}
                  state={errors.file ? 'invalid' : 'neutral'}
                  icon={<ImagePlaceholder />}
                  onBlur={handleOnBlur}
                />
              </FormControl>
            </GridItem>
            <GridItem sm={12} lg={6}>
              <FormControl>
                <TextField
                  id="title"
                  label="título"
                  type="text"
                  errorMessage={errors.title?.message}
                  helperText="Título que irá salvar a imagem."
                  onChange={(e) => handleUpdate({ title: e.currentTarget.value })}
                  state={errors.title ? 'invalid' : 'neutral'}
                  onBlur={handleOnBlur}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <FormControl>
            <Button variant="default" float="right" type="button" onClick={handleSubmit}>
              Confirmar
            </Button>
          </FormControl>
        </Padding>
      </Form>
    </Modal >
  );
};

export { MediaCardModal };
