import React, { ReactNode } from 'react';
import { Wrapper } from './ContentWrapper.styles';
import { Center } from '../Center';

type ContentWrapperProps = {
  children: ReactNode;
};

const ContentWrapper = ({ children }: ContentWrapperProps) => {
  return (
    <Center>
      <Wrapper>{children}</Wrapper>
    </Center>
  );
};

export { ContentWrapper };
