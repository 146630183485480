import { styled } from 'styled-components';
import { colors, fontWeight, fontSize, outline } from '../../tokens';

const LinkStyle = styled.a<{ $align: string }>`
  display: block;
  color: ${colors.primary};
  text-decoration: underline;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.link};
  cursor: pointer;
  text-align: ${({ $align }) => $align};

  &:focus {
    outline: ${outline.default};
  }
`;

export { LinkStyle };
