import gql from 'graphql-tag';

const residuesTypesQuery = gql`
  query {
    residuesTypes {
      id
      name
    }
  }
`;

export { residuesTypesQuery };
