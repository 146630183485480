import { ChangeEvent, useCallback, useState } from 'react';
import validator from 'validator';
import * as yup from 'yup';
import { CnpjModel } from '../../common/models';
import { formatCnpj, formatCpf, validateCnpj, validateCpf } from '../../common/utils';

const yupCnpjOrCpf = ({ required }: { required: boolean } = { required: false }) =>
  yup
    .string()
    .typeError('CNPJ ou CPF inválido.')
    .test('cnpjOrCpfValidation', 'CNPJ ou CPF inválido.', (value) => {
      const isNullOrEmpty = !value || validator.isEmpty(value);
      if (required && isNullOrEmpty) {
        return false;
      }
      else if (isNullOrEmpty && !required) {
        return true;
      }
      return validateCpf(value) || validateCnpj(value) || false;
    });

const useCnpjOrCpf = () => {
  const [cnpj, setCnpj] = useState<CnpjModel | null>(null);
  const [cpf, setCpf] = useState<string | null>(null);

  const startCnpjOrCpf = useCallback((cnpjInput: CnpjModel, cpfInput: string) => {
    setCnpj(cnpjInput);
    setCpf(cpfInput);
  }, []);

  const getCnpjOrCpf = useCallback((): string => {
    if (cnpj) {
      return formatCnpj(cnpj?.value);
    } else if (cpf) {
      return formatCpf(cpf);
    }
    return undefined;
  }, [cnpj, cpf]);

  const recognizeCnpjOrCpfOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let result = e.target.value.replace(/\D+/gmi, '');
    if (result.length > 14) {
      result = result.slice(0, 14);
    }

    if (validateCpf(result)) {
      result = formatCpf(result);
    }

    else if (validateCnpj(result)) {
      result = formatCnpj(result);
    }

    return result;
  }, []);

  return {
    startCnpjOrCpf,
    getCnpjOrCpf,
    recognizeCnpjOrCpfOnChange,
  };
};

export {
  yupCnpjOrCpf,
  useCnpjOrCpf,
};
