import { styled } from 'styled-components';
import { Button } from '../Button';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  spaces,
  zIndex,
} from '../../tokens';

type ContainerProps = {
  $show: boolean | number;
  $fail: boolean;
};

const Container = styled.div<ContainerProps>`
  font-family: ${fontFamily.montserrat};
  font-size: ${fontSize.paragraph};
  font-weight: ${fontWeight.regular};
  z-index: ${zIndex.paradise};
  position: fixed;
  bottom: ${({ $show }) => ($show === true || $show === 1 ? '1rem' : '-100px')};
  transition: bottom 0.25s ease-in-out;
  left: 1rem;
  display: flex;
  align-items: center;
  padding: ${spaces.medium};
  max-width: 500px;
  background-color: ${({ $fail }) => ($fail ? '#5b082bc4' : '#17082bc4')};
  color: ${colors.white};
  width: fit-content;
  border-radius: 8px;
`;

const CloseButton = styled(Button)`
  margin-left: ${spaces.xLarge};
  min-width: 100px;
  height: 50px;
  padding: ${spaces.small};

  &:hover > *,
  &:active > *,
  &:focus > * {
    color: ${colors.white};
  }
`;

export { Container, CloseButton };
