const zIndex = {
  debug: 9,
  beyond: 5,
  paradise: 4,
  heaven: 3,
  purgatory: 2,
  mountains: 1,
  earth: 0,
} as const;

export { zIndex };
