import React, { SVGProps } from 'react';

const ArrowDropDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} {...props}>
      <path d="M7 10l5 5 5-5z" />
    </svg>
  );
};

export { ArrowDropDown };
