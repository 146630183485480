import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StoreContext } from '../../../contexts/StoreContext';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { useGraphQl } from '../../../../utils/gql';
import { CardsList, ListFilterOption, Typography } from '../../../../ui/components';
import { MediaModel } from '../../../../../common/models';
import { colors, spaces } from '../../../../ui/tokens';
import { invalidateLogMutation, unloadingLogsReportQuery, unloadingLogsPaginationQuery } from '../../../queries';
import { ReportLinksModel } from '../../../../../common/models/ReportLinksModel';
import { ResiduesUnloadingLogModel } from '../../../../../common/models/ResiduesUnloadingLogModel';
import { ResiduesUnloadingLogConnectionModel } from '../../../../../common/models/ResiduesUnloadingLogConnectionModel';
import { formatMeasure } from '../../../../utils/measure';

const UnloadingLogList = () => {
  const [queryParams] = useSearchParams();
  const [items, setItems] = useState<Array<ResiduesUnloadingLogModel> | null>(null);
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const redirect = useRedirect();

  const unloadingLogsPaginationRequest = useGraphQl<{ unloadingLogsPagination: ResiduesUnloadingLogConnectionModel }>({
    query: unloadingLogsPaginationQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const unloadingLogsReportRequest = useGraphQl<{ unloadingLogsReport: ReportLinksModel }>({
    query: unloadingLogsReportQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const invalidateLogRequest = useGraphQl<{ invalidateLog: boolean }>({
    query: invalidateLogMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  useEffect(() => {
    unloadingLogsPaginationRequest.invoke({
      variables: {
        storeId: store?.id,
        invalidated: false,
        first: 99999,
      },
      onSuccess: ({ data: { unloadingLogsPagination } }) => {
        setItems(unloadingLogsPagination?.edges?.map(edge => edge.node));
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar a lista de registros de saída.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, [queryParams]);


  const filters = useMemo(() => {
    const filtersList: Array<ListFilterOption<ResiduesUnloadingLogModel>> = [
      {
        label: 'Instituição',
        resolver: (filterItems, value) => filterItems.filter(item => item?.institution?.name?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Resíduo',
        resolver: (filterItems, value) => filterItems.filter(item => item?.residue?.name?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Data',
        resolver: (filterItems, value) => filterItems.filter(item => item?.createdAt && new Date(item.createdAt).toLocaleString('pt-BR').toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Peso maior que',
        resolver: (filterItems, value) => filterItems.filter(item => item?.measureUnitResidueInventoryUnitRelation?.find(m => m.quantity > parseFloat(value.replace(',', '.')))),
      },
      {
        label: 'Peso menor que',
        resolver: (filterItems, value) => filterItems.filter(item => item?.measureUnitResidueInventoryUnitRelation?.find(m => m.quantity < parseFloat(value.replace(',', '.')))),
      },
    ];
    return filtersList;
  }, []);

  return <CardsList<ResiduesUnloadingLogModel, MediaModel>
    emptyText='Nenhum registro de saída foi cadastrado até o momento.'
    items={items}
    filters={filters}
    itemNameProp='id'
    onSnackbar={addSnackbar}
    renderContent={(item: ResiduesUnloadingLogModel) => (
      <>
        <Typography variant="sublead" align="left" color={colors.black} margin={`0 0 ${spaces.small}`}>{item.residue?.name} por {item.institution?.name}</Typography>
        {item.createdAt && (<Typography variant="paragraph" align="left" color={colors.black}>Data: {item.createdAt && new Date(item.createdAt).toLocaleString('pt-BR')}</Typography>)}
        {item.actor && (<Typography variant="paragraph" align="left" color={colors.black}>Autor: {item.actor?.name}</Typography>)}
        {item.measureUnitResidueInventoryUnitRelation?.map((m) => (
          <Typography key={m.measureUnitName} variant="paragraph" align="left" color={colors.black}>Quantidade: {formatMeasure(m.quantity.toString(), m.measureUnitName)}</Typography>
        ))}
        {item.observation && (<Typography variant="paragraph" align="left" color={colors.black}>Observações: {item.observation}</Typography>)}
      </>
    )}
    onItemRemove={(removedItem) => {
      invalidateLogRequest.invoke({
        variables: {
          id: removedItem?.id,
          storeId: store?.id,
        },
        onSuccess: ({ data: { invalidateLog } }) => {
          if (invalidateLog) {
            setItems(items?.filter(filteringItem => filteringItem.id !== removedItem.id));
          }
        },
        onFail: (err) => {
          if (err.name === 'UnauthorizedRequestError') {
            redirect.to('/login');
            return;
          }

          addSnackbar({
            title: `Não foi possível remover o item. ${err.message}`,
            fail: true,
            actionText: 'OK',
          });
        },
      });
    }}
    onRequestXlsxReport={() => {
      unloadingLogsReportRequest.invoke({
        variables: {
          storeId: store?.id,
          invalidated: false,
        },
        onSuccess: ({ data: { unloadingLogsReport } }) => {
          console.log(unloadingLogsReport.xlsx);
          window.open(unloadingLogsReport.xlsx, '_blank');
        },
      });
    }}
    onRequestPdfReport={() => {
      unloadingLogsReportRequest.invoke({
        variables: {
          storeId: store?.id,
          invalidated: false,
        },
        onSuccess: ({ data: { unloadingLogsReport } }) => {
          console.log(unloadingLogsReport.pdf);
          window.open(unloadingLogsReport.pdf, '_blank');
        },
      });
    }}
  />;
};

export {
  UnloadingLogList,
};
