const storePageStateValues = {
  list: 'list',
  create: 'create',
  loading: 'loading',
  error: 'error',
  success: 'success',
} as const;
type StorePageState = keyof typeof storePageStateValues;

export type { StorePageState };
export { storePageStateValues };
