import { Button, Center, colors, Padding, Typography } from '../../../ui';
import { Page } from '../Page';
import { initialRedirectTo, useRedirect } from '../../../utils/redirect';
import { useSearchParams } from 'react-router-dom';

const PageNotFound = () => {
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();

  return (
    <Page height="100vh" showAccountButton={false} variant='primary'>
      <Center>
        <Typography variant="title" color={colors.primary}>Página não encontrada...</Typography>
        <Padding padding="20px 0" wide>
          <Center>
            <Button
              variant="back"
              onClick={() => initialRedirectTo(queryParams, redirect)}
            >Voltar</Button>
          </Center>
        </Padding>
      </Center>
    </Page>
  );
};

export { PageNotFound };
