import { useReducer } from 'react';
import { MaterialInventoryItemModel, ProductModel } from '../../../common/models';

enum ProductActions {
  setProduct = 'PRODUCT/SET_PRODUCT',
  setProductMaterials = 'PRODUCT/SET_PRODUCT_MATERIALS',
}

const productReducer = (
  state: ProductModel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { type: ProductActions } & Record<string, any>,
) => {
  switch (action.type) {
    case ProductActions.setProduct:
      return { ...state, ...action.product };
    case ProductActions.setProductMaterials:
      return {
        ...state,
        garmentSpecSheet: {
          ...state.garmentSpecSheet,
          materials: action.materials,
        },
      };
    default:
      return state;
  }
};

const useProductReducer = (initialData?: ProductModel) => {
  const [state, dispatch] = useReducer(productReducer, initialData);

  return {
    product: state,
    setProduct: (product: ProductModel) => dispatch({
      type: ProductActions.setProduct,
      product,
    }),
    setProductMaterials: (materials: Array<MaterialInventoryItemModel>) => dispatch({
      type: ProductActions.setProductMaterials,
      materials,
    }),
  };
};

export { useProductReducer, ProductActions };
