import { Card, Center, Typography, Cart, colors } from '../../../../ui';
import { useRedirect } from '../../../../utils/redirect';
import { LogoWrapper, NameWrapper } from './StoreCard.styles';

type StoreCardProps = {
  name?: string;
  storeId?: number;
  backgroundColor?: string;
  logoSrc?: string;
};

const StoreCard = ({
  name,
  backgroundColor,
  logoSrc,
  storeId,
}: StoreCardProps) => {
  const redirect = useRedirect();

  return (
    <Card
      // width="400px"
      // height="400px"
      overflow="hidden"
      onClick={() => redirect.to(`/store/${storeId}`)}
    >
      <LogoWrapper $backgroundColor={backgroundColor ?? colors.backButton}>
        <Center>
          {logoSrc ? (
            <img src={logoSrc} alt={`logo ${name}`} title={name} />
          ) : (
            <Cart height={147} width={147} fill={colors.backgroundPrimary} />
          )}
        </Center>
      </LogoWrapper>
      <NameWrapper>
        <Typography variant="paragraph" color={colors.primary}>
          {name}
        </Typography>
      </NameWrapper>
    </Card>
  );
};

export { StoreCard };
