const getProcess = () => {
  if (
    typeof process === 'undefined' ||
    process === null) {
    return {
      env: {
        // FIXME: Workaround para o Vite com o tsconfig.
        STAGE: process.env.STAGE,
        PUBLIC_DASHBOARD_API: process.env.PUBLIC_DASHBOARD_API,
        PUBLIC_DASHBOARD_STAGE: process.env.STAGE,
        PUBLIC_LANDING_PAGE_GTM_ID: process.env.PUBLIC_LANDING_PAGE_GTM_ID,
        PUBLIC_LANDING_PAGE_API_ADDRESS: process.env.PUBLIC_LANDING_PAGE_API_ADDRESS,
        PUBLIC_LANDING_PAGE_DASHBOARD_APP_ADDRESS: process.env.PUBLIC_LANDING_PAGE_DASHBOARD_APP_ADDRESS,
      },
    };
  } else {
    return process;
  }
};

export {
  getProcess,
};
