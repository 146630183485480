import { getProcess } from './getProcess';

const apiRoutes = {
  auth: '/auth',
  admin: '/admin',
  mediaUpload: '/media/upload',
  mediaDownload: '/media/files',
  mediaDelete: '/media/delete',
  graphql: '/graphql',
} as const;

type ApiAddresses =
  'http://local.api.dashboard.mecena.com.br:13002' |
  'https://dev.api.dashboard.mecena.com.br' |
  'https://staging.api.dashboard.mecena.com.br' |
  'https://api.dashboard.mecena.com.br' |
  'http://local.api.mecena.com.br:13006' |
  'https://api.mecena.com.br';

type ApiVersions =
  'v1';

type ApiApplications =
  'dashboard' |
  'landing-page';

type ApiRoutes = typeof apiRoutes[keyof typeof apiRoutes];

type ApiEndpointProps = {
  api?: ApiAddresses,
  version?: ApiVersions,
  app?: ApiApplications,
  route: ApiRoutes,
};

const getApiEndpoint = ({
  api = String(getProcess()?.env?.PUBLIC_DASHBOARD_API) as ApiAddresses,
  version = 'v1',
  app = 'dashboard',
  route,
}: ApiEndpointProps): string => `${api}/api/${app}/${version}${route}`;

export type {
  ApiAddresses,
  ApiRoutes,
  ApiEndpointProps,
};
export {
  apiRoutes,
  getApiEndpoint,
};
