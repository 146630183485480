import gql from 'graphql-tag';

const deactivateQuery = gql`
  mutation($storeId: Int!, $productId: Int!) {
    deactivateProduct(storeId: $storeId, productId: $productId) {
      active
    }
  }
`;

export { deactivateQuery };
