import { ReactNode } from 'react';
import { styled } from 'styled-components';

type ImageProps = {
  children?: ReactNode,
  $isVertical: boolean,
  height: string,
  width: string,
};

const Image = styled.img<ImageProps>`
  width: ${({ $isVertical, width }) => ($isVertical ? 'auto' : width ? width : '100%')};
  height: ${({ $isVertical, height }) => ($isVertical ? height ? height : '100%' : 'auto')};
`;

const ImagePlaceholder = styled.div<ImageProps>`
  display: block;
  width: ${({ $isVertical, width }) => ($isVertical ? 'auto' : width ? width : '100%')};
  height: ${({ $isVertical, height }) => ($isVertical ? height ? height : '100%' : 'auto')};
`;

const ImageWrapper = styled.div<ImageProps>`
  position: absolute;
  width: ${({ $isVertical, width }) => ($isVertical ? 'auto' : width ? width : '100%')};
  height: ${({ $isVertical, height }) => ($isVertical ? height ? height : '100%' : 'auto')};
`;

const ImageMask = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export { Image, ImagePlaceholder, ImageWrapper, ImageMask };
