import React, { HTMLAttributes, ReactNode, MouseEvent } from 'react';
import { setBool } from '../../../../common/utils';
import { SpaceBetween } from '../SpaceBetween';
import { TypographyVariants } from '../Typography';
import { Center } from '../Center';
import { ButtonStyle, ButtonText, LoadingIcon } from './Button.styles';
import { buttonVariants, ButtonVariants } from './ButtonVariants';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  variant?: ButtonVariants;
  noBorder?: boolean;
  type?: 'submit' | 'button';
  textVariant?: TypographyVariants;
  icon?: ReactNode;
  width?: string;
  height?: string;
  float?: 'left' | 'right' | 'none';
  form?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  maxWidth?: string;
}

const Button = ({
  children,
  loading = false,
  disabled = false,
  wide = false,
  variant = 'default',
  type = 'button',
  noBorder = false,
  textVariant = 'button',
  icon = undefined,
  width = 'auto',
  height = 'auto',
  float = 'none',
  onClick = undefined,
  maxWidth,
  ...rest
}: ButtonProps) => {
  const loadingValue = setBool(loading);
  const ariaLabel: string | undefined =
    typeof children === 'string' ? children : rest['aria-label'];

  return (
    <ButtonStyle
      $loading={loadingValue}
      aria-label={ariaLabel}
      disabled={disabled}
      $noBorder={noBorder}
      type={type}
      $variant={variant}
      $wide={wide}
      $width={width}
      $float={float}
      $height={height}
      $maxWidth={maxWidth}
      onClick={onClick}
      {...rest}
    >
      <LoadingIcon
        data-testid="progress-indicator-svg"
        color={buttonVariants[variant].progressIndicatorColor}
      />
      {icon && children ? (
        <SpaceBetween direction="horizontal">
          <ButtonText variant={textVariant} align="left">
            {children}
          </ButtonText>
          {icon}
        </SpaceBetween>
      ) : icon && !children ? (
        <Center>{icon}</Center>
      ) : (
        <ButtonText variant={textVariant} align="center">
          {children}
        </ButtonText>
      )}
    </ButtonStyle>
  );
};

export { Button };
