import gql from 'graphql-tag';

const productsQuery = gql`
  query(
    $storeId: Int!
  ) {
    products(
      storeId: $storeId
    ) {
      id
      name
      description
      cover {
        id
        url
        name
      }
      active
      garmentSpecSheet {
        id
        collection {
          id
          guid
          createdAt
          updatedAt
          name
        }
        technicalDraw {
          id
          url
          name
        }
        materials {
          id
          itemRef
          itemType
          itemName
          itemValue
          measures {
            unit
            customUnit
            quantity
          }
          supplierName
          trimMaterialType
          itemColorName
          itemColorValue
        }
        garmentSizes {
          id
          label
        }
        colors {
          id
          label
          r
          g
          b
        }
        medias {
          id
          url
          name
        }
      }
      productMedias {
          id
          url
          name
      }
    }
  }
`;

export {
  productsQuery,
};
