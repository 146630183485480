import gql from 'graphql-tag';

const garmentSpecSheetMaterialsQuery = gql`
  query(
    $storeId: Int!
    $productId: Int!
  ) {
    garmentSpecSheetMaterials(
      storeId: $storeId
      productId: $productId
    ) {
      id
      itemType
      itemRef
      itemColorName
      itemName
      itemValue
      supplierName
      measures {
          unit
          customUnit
          quantity
      }
      productQuantities {
        unit
        customUnit
        quantity
      }
    }
  }
`;

export { garmentSpecSheetMaterialsQuery };
