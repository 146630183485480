import React, { ChangeEvent, FormEvent, useRef } from 'react';
import { Search as SearchIcon, Close } from '../../icons';
import {
  Wrapper,
  InputSearch,
  Button,
  ResetButton,
  InputWrapper,
  Label,
} from './SearchBar.styles';

interface SearchBarProps {
  id: string;
  placeholder: string;
  onSubmit: (value: string) => void;
  onChange?: (event?: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: 'on' | 'off';
}

const SearchBar = ({
  id,
  placeholder,
  onSubmit,
  onChange,
  autoComplete = 'on',
}: SearchBarProps) => {
  const inputRef = useRef<null | HTMLInputElement>(null);

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(inputRef?.current?.value as string);
  };

  return (
    <Wrapper onSubmit={handleOnSubmit}>
      <InputWrapper>
        <Label htmlFor={id}>{placeholder}</Label>
        <InputSearch
          placeholder={placeholder}
          ref={inputRef}
          id={id}
          name={id}
          onChange={onChange}
          autoComplete={autoComplete}
        />
        <ResetButton aria-label="Limpar">
          <Close width={24} height={24} />
        </ResetButton>
      </InputWrapper>
      <Button aria-label="Buscar">
        <SearchIcon style={{
          position: 'absolute',
        }} />
      </Button>
    </Wrapper>
  );
};

export type { SearchBarProps };
export { SearchBar };
