import React, { useContext, useMemo } from 'react';
import { FaRecycle } from 'react-icons/fa6';
import { BsHouseHeart } from 'react-icons/bs';
import {
  ExitLine,
  MainMenu,
  Zipper,
  Fabric,
  Cloth,
  Gear,
  Team,
  colors,
} from '../../../ui';
import { useParams } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import { useFeature } from '../../hooks/useFeature';
import { FeatureName } from '../../../../common/models/FeatureName';
import { GoPackageDependents } from 'react-icons/go';

const mainMenuOptions = {
  products: 'Produtos',
  materials: 'Insumos',
  employees: 'Equipe',
  storage: 'Armazenamento',
  fabrics: 'Tecidos',
  trims: 'Aviamentos',
  brands: 'Marcas',
  purchaseOrders: 'Ordens de compra',
  api: 'API e integrações',
  audit: 'Histórico',
  medias: 'Mídias',
  stores: 'Outras empresas',
  settings: 'Configurações',
  specSheets: 'Fichas-técnicas',
  team: 'Time',
  residues: 'Resíduos',
  institutions: 'Instituições',
  unloadingLogs: 'Registros de saída',
} as const;

type OptionsType = typeof mainMenuOptions[keyof typeof mainMenuOptions];

const StoreMenu = ({
  current = mainMenuOptions.products,
}: {
  current?: OptionsType;
}) => {
  const { store } = useContext(StoreContext);
  const { storeId } = useParams();
  const { hasFeature } = useFeature();

  const options = useMemo(() => {
    const opts = [];

    if (hasFeature(FeatureName.Materials)) {
      opts.push({
        name: mainMenuOptions.fabrics,
        icon: <Fabric />,
        route: `/store/${storeId}/storage`,
        params: {
          type: 'tecido',
        },
      });
      opts.push({
        name: mainMenuOptions.trims,
        icon: <Zipper />,
        route: `/store/${storeId}/storage`,
        params: {
          type: 'aviamento',
        },
      });
    }

    if (hasFeature(FeatureName.SpecSheets)) {
      opts.push({
        name: mainMenuOptions.specSheets,
        icon: <Cloth />,
        route: `/store/${storeId}/specsheet`,
        removeParams: ['type'],
      });
    }

    if (hasFeature(FeatureName.Residues)) {
      opts.push({
        name: mainMenuOptions.residues,
        icon: <FaRecycle size={24} color={colors.white} />,
        route: `/store/${storeId}/residue`,
        removeParams: ['type'],
      });
    }

    if (hasFeature(FeatureName.Institutions)) {
      opts.push({
        name: mainMenuOptions.institutions,
        icon: <BsHouseHeart size={24} color={colors.white} />,
        route: `/store/${storeId}/institution`,
        removeParams: ['type'],
      });
    }

    if (hasFeature(FeatureName.Residues) && hasFeature(FeatureName.Institutions)) {
      opts.push({
        name: mainMenuOptions.unloadingLogs,
        icon: <GoPackageDependents size={24} color={colors.white} />,
        route: `/store/${storeId}/unloadinglog`,
        removeParams: ['type'],
      });
    }

    return [
      ...opts,
      {
        name: mainMenuOptions.team,
        icon: <Team />,
        route: `/store/${storeId}/team`,
        removeParams: ['type'],
      },
      {
        name: mainMenuOptions.settings,
        icon: <Gear />,
        route: `/store/${storeId}/settings`,
        removeParams: ['type'],
      },
      {
        name: mainMenuOptions.stores,
        icon: <ExitLine />,
        route: '/store',
        removeParams: ['type'],
      },
    ];
  }, [hasFeature]);

  return (
    <MainMenu
      current={current}
      title={store?.name}
      logoSrc={store?.logo?.presignedUrl ?? store?.logo?.url}
      options={options}
      homeLink={`/store/${storeId}`}
    />
  );
};

export type { OptionsType };
export { mainMenuOptions, StoreMenu };
