import {
  Card,
  Center,
  colors,
  Padding,
  ProgressIndicator,
  Typography,
} from '../../../ui';
import { Page } from '../Page';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthenticatorContext } from '../../contexts/AuthenticatorContext';
import { LoadingPage } from '../LoadingPage';
import { OnboardingApproveTermsOfUse } from './components/steps/OnboardingApproveTermsOfUse';
import { OnboardingCreateStore } from './components/steps/OnboardingCreateStore';
import { OnboardingCreatePassword } from './components/steps/OnboardingCreatePassword';
import { OnboardingConfirmUserAndStoreData } from './components/steps/OnboardingConfirmUserAndStoreData';
import { initialRedirectTo, useRedirect } from '../../../utils/redirect';
import { useSearchParams } from 'react-router-dom';
import { StoreModel } from '../../../../common/models';

type Step = {
  title: string;
  successTitle: string;
  state: string;
  required: boolean;
  completed: boolean;
};

type OnboardingData = {
  currentStep: string | null;
  totalSteps: number;
  currentStepIndex: number;
  steps: Array<Step>;
};

type OnboardingStepProps = {
  step: Step | null;
  onComplete: (step: Step) => void;
};

const OnboardingPage = () => {
  const redirect = useRedirect();
  const [queryParams] = useSearchParams();
  const { currentUser } = useContext(AuthenticatorContext);
  const [onboardingData, setOnboardingData] = useState<OnboardingData | null>(null);
  const [store, setStore] = useState<StoreModel | null>(null);

  const initialSteps = useMemo(() => {
    return currentUser ? [
      {
        title: 'Confirmação dos termos de uso',
        state: 'approveTermsOfUse',
        successTitle: 'Termos de uso aceito com sucesso!',
        required: false /* TODO: Criar página com termos de uso. */,
        completed: currentUser.termsAccepted,
      },
      {
        title: 'Criação da empresa',
        state: 'createStore',
        successTitle: 'Empresa criada com sucesso!',
        required: !currentUser.wasInvited,
        completed: currentUser.stores.length > 0,
      },
      {
        title: 'Atualize sua senha',
        state: 'createPassword',
        successTitle: 'Senha alterada com sucesso!',
        required: true,
        completed: currentUser.userCreatedPassword,
      },
      {
        title: 'Confirmar dados da empresa e usuário',
        state: 'confirmUserAndStoreData',
        successTitle: 'Onboarding concluído!',
        required: true,
        completed: currentUser.completedOnboarding,
      },
    ] : null;
  }, [currentUser]);

  const redirectToStore = useCallback(() => {
    initialRedirectTo(queryParams, redirect);
  }, [queryParams, redirect]);

  useEffect(() => {
    if (currentUser?.completedOnboarding) {
      redirectToStore();
    }
  }, [currentUser]);

  const setOnboardingDataFromSteps = useCallback((steps: Array<Step>) => {
    const requiredSteps = steps.filter(step => step.required);
    const completedSteps: Array<Step> = [];
    const notCompletedSteps: Array<Step> = [];

    requiredSteps.forEach(step => step.completed ? completedSteps.push(step) : notCompletedSteps.push(step));

    if (requiredSteps.length === completedSteps.length) {
      redirectToStore();
      return;
    }

    setOnboardingData({
      steps,
      totalSteps: requiredSteps.length,
      currentStepIndex: completedSteps.length + 1,
      currentStep: notCompletedSteps.length === 0 ? null : notCompletedSteps[0].state,
    });
  }, []);

  useEffect(() => {
    if (!onboardingData && initialSteps) {
      setOnboardingDataFromSteps(initialSteps);
    };
  }, [onboardingData, initialSteps]);

  const updateOnboardingData = useCallback((newStep: Step) => {
    if (!onboardingData) return;

    const steps = onboardingData.steps.map((step) => {
      if (newStep.state === step.state) return newStep;
      return step;
    });

    setOnboardingDataFromSteps(steps);
  }, [onboardingData]);

  const getStep = useCallback((state: string) => {
    if (!onboardingData) return null;
    const selectedStep = onboardingData.steps.filter((step) => step.state === state);
    if (selectedStep.length > 0) return selectedStep[0];
    return null;
  }, [onboardingData]);

  const getStatePage = useCallback((stateName: string | null): ReactNode => {
    if (!stateName) return;

    switch (stateName) {
      case 'approveTermsOfUse':
        return <OnboardingApproveTermsOfUse step={getStep(stateName)} onComplete={updateOnboardingData} />;
      case 'createStore':
        return <OnboardingCreateStore
          step={getStep(stateName)}
          onComplete={updateOnboardingData}
          store={store}
          setStore={setStore}
        />;
      case 'createPassword':
        return <OnboardingCreatePassword step={getStep(stateName)} onComplete={updateOnboardingData} />;
      case 'confirmUserAndStoreData':
        return <OnboardingConfirmUserAndStoreData
          step={getStep(stateName)}
          onComplete={updateOnboardingData}
          store={store}
        />;
      default:
        return <ProgressIndicator />;
    }
  }, [onboardingData, store]);

  useEffect(() => {
    if (onboardingData && !store) {
      if (onboardingData.currentStep === 'confirmUserAndStoreData') {
        const ownedStore = currentUser?.stores?.find((userStore) => userStore.owner?.id === currentUser?.id);
        setStore(ownedStore);
      }
    }
  }, [store, onboardingData]);

  return onboardingData ? (
    <Page variant="primary" showAccountButton={true}>
      <Padding padding="50px 0 0" wide>
        <Typography variant='title' align="center" color={colors.primary}>Onboarding</Typography>
      </Padding>
      <Padding padding="30px 0" wide>
        <Center>
          <Card width='800px' nWidth={800} padding='30px' margin={30}>
            <Padding padding="0 0 8px">
              <Typography variant='sublead' margin='0 0 8px'>Passo {onboardingData.currentStepIndex} de {onboardingData.totalSteps}</Typography>
            </Padding>
            {getStatePage(onboardingData.currentStep)}
          </Card>
        </Center>
      </Padding>
    </Page>
  ) : <LoadingPage />;
};

export type { Step, OnboardingStepProps };
export { OnboardingPage };
