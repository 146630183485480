import gql from 'graphql-tag';

const institutionsPaginationQuery = gql`
  query (
    $storeId: Int!
    $type: String
    $first: Int!
    $after: Int
    $offset: Int
    $orderBy: OrderByInput
    $active: Boolean
    $filters: [InstitutionFilter]
  ) {
    institutionsPagination(
      storeId: $storeId
      type: $type
      first: $first
      after: $after
      offset: $offset
      orderBy: $orderBy
      active: $active
      filters: $filters
    ) {
      totalCount,
        edges {
          node {
            id
            guid
            createdAt
            updatedAt
            name
            active
            type {
              id
              type
            }
            motivation
            observation
            email
            phoneNumber
            personOfContact
            cpf
            cnpj {
              id
              value
            }
            address {
              id
              country {
                id
                abbreviation
                countryLocalizations {
                  id
                  countryName
                  language {
                    id
                    langCode
                  }
                }
              }
              state {
                id
                stateName
                abbreviation
              }
            }
            categories {
              id
              name
            }
            sizes {
              id
              name
            }
          }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export { institutionsPaginationQuery };
