import { animations } from './tokens/animations';
import { boxShadow } from './tokens/boxShadow';
import { colors } from './tokens/colors';
import { fontFamily } from './tokens/fontFamily';
import { fontSize } from './tokens/fontSize';
import { fontWeight } from './tokens/fontWeight';
import { grid } from './tokens/grid';
import { opacity } from './tokens/opacity';
import { outline } from './tokens/outline';
import { screens } from './tokens/screens';
import { spaces } from './tokens/spaces';
import { zIndex } from './tokens/z-index';

const mecenaTheme = {
  animations,
  boxShadow,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  grid,
  opacity,
  outline,
  screens,
  spaces,
  zIndex,
} as const;

type MecenaTheme = typeof mecenaTheme;

export type { MecenaTheme };
export { mecenaTheme };
