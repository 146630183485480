import {
  createContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { apiRoutes, getApiEndpoint } from '../../utils/apiEndpoints';
import { viewerQuery } from '../queries';
import * as session from '../../utils/sessionStorage';
import { LoadingPage } from '../components/LoadingPage';
import { useRedirect } from '../../utils/redirect';
import { AdminUserModel, StoreModel } from '../../../common/models';
import { useGraphQl } from '../../utils/gql';

type AuthenticatorContextProps = {
  currentUser: AdminUserModel | null;
  invalidateFetching: () => void;
  setUser: (user: AdminUserModel) => void;
  addUserStore: (store: StoreModel) => void;
  getToken: () => string | null;
  getRefreshToken: () => string | null;
  setToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
};

const AuthenticatorContext = createContext({} as AuthenticatorContextProps);

const AuthenticatorProvider = () => {
  const [user, setUser] = useState<AdminUserModel | null>(null);
  const redirect = useRedirect();
  const location = useLocation();
  const [fetching, setFetching] = useState(true);
  const viewerRequest = useGraphQl<{ viewer: AdminUserModel }>({
    query: viewerQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const [accessToken, setAccessToken] = useState<string | null>(session.getItem('access_token'));
  const [refreshToken, setRefreshToken] = useState<string | null>(session.getItem('refresh_token'));

  useEffect(() => {
    if (fetching && !user) {
      viewerRequest.invoke({
        onFail: () => {
          const pathName = location.pathname;
          redirect.to('/login', { params: { redirect_to: pathName } });
          setFetching(false);
        },
        onSuccess: (response) => {
          setUser(response.data.viewer);
          setFetching(false);
        },
      });
    };
  }, [fetching, user]);

  const contextValue = useMemo(() => {
    return {
      currentUser: user,
      setUser,
      addUserStore: (store: StoreModel) => {
        setUser({
          ...(user as AdminUserModel),
          stores: [
            ...(user?.stores ?? []),
            store,
          ],
        });
      },
      invalidateFetching: () => {
        setFetching(false);
      },
      getToken: () => {
        return fetching ? null : accessToken;
      },
      getRefreshToken: () => {
        return fetching ? null : refreshToken;
      },
      setToken: (token: string) => {
        session.setItem('access_token', token);
        setAccessToken(token);
      },
      setRefreshToken: (token: string) => {
        session.setItem('refresh_token', token);
        setRefreshToken(token);
      },
    };
  }, [user, accessToken, refreshToken]);

  return (
    <AuthenticatorContext.Provider
      value={contextValue}
    >
      {fetching && (
        <LoadingPage />
      )}
      {!fetching && <Outlet />}
    </AuthenticatorContext.Provider>
  );
};

export { AuthenticatorProvider, AuthenticatorContext };
