import gql from 'graphql-tag';

const residueQuery = gql`
  query (
    $id: Int!
    $storeId: Int!
  ) {
  residue(
    id: $id
    storeId: $storeId
  ) {
      id
      guid
      createdAt
      updatedAt
      name
      type {
        id
        name
      }
      description
      active
      picture {
        id
        url
        presignedUrl
      }
      categories {
        id
        name
      }
      inventoryUnitObservation
      size {
        id
        name
      }
      measures {
        unit
        quantity
      }
    }
  }
`;

export { residueQuery };
