import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CardsList, Typography, colors, spaces } from '../../../../ui';
import { useGraphQl } from '../../../../utils/gql';
import { productsQuery, updateProductMutation, removeProductMutation } from '../../../queries';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { StoreContext } from '../../../contexts/StoreContext';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { ProductModel, MediaModel } from '../../../../../common/models';
import { createStoreMediaMutation } from '../../../queries/createStoreMediaMutation';
import { createUserMediaMutation } from '../../../queries/createUserMediaMutation';

const SpecSheetsList = () => {
  // TODO: Reduzir o volume de dados.
  const { storeId } = useParams();
  const [queryParams] = useSearchParams();
  const [items, setItems] = useState<Array<ProductModel> | null>(null);
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const redirect = useRedirect();

  const productsRequest = useGraphQl<{ products: Array<ProductModel> }>({
    query: productsQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateProductRequest = useGraphQl({
    query: updateProductMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const removeProductRequest = useGraphQl<{ removeProduct: boolean }>({
    query: removeProductMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createStoreMediaRequest = useGraphQl<{
    createStoreMedia?: MediaModel,
    createUserMedia?: MediaModel,
  }>({
    query: createStoreMediaMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createUserMediaRequest = useGraphQl<{
    createUserMedia?: MediaModel,
    createStoreMedia?: MediaModel,
  }>({
    query: createUserMediaMutation,
    url: getApiEndpoint({
      route: apiRoutes.admin,
    }),
  });

  useEffect(() => {
    productsRequest.invoke({
      variables: {
        storeId: store?.id,
      },
      onSuccess: ({ data: { products } }) => {
        setItems(products);
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar a lista de fichas-técnicas.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, [queryParams]);

  return (
    <CardsList<ProductModel, MediaModel>
      emptyText='Nenhum produto foi cadastrado até o momento.'
      items={items}
      onSnackbar={addSnackbar}
      filters={[
        {
          label: 'Nome',
          resolver: (filterItems, value) => filterItems.filter(item => item?.name?.toLowerCase().startsWith(value.toLowerCase())),
        },
        {
          label: 'Descrição',
          resolver: (filterItems, value) => filterItems.filter(item => item?.description?.toLowerCase().startsWith(value.toLowerCase())),
        },
        {
          label: 'Coleção',
          resolver: (filterItems, value) => filterItems.filter(item => item?.garmentSpecSheet?.collection?.name?.toLowerCase().startsWith(value.toLowerCase())),
        },
      ]}
      renderContent={(item) => (
        <>
          <Typography variant="sublead" align="left" color={colors.black} margin={`0 0 ${spaces.small}`}>{item.name}</Typography>
          <Typography variant="paragraph" align="left" color={colors.black} margin={`0 0 ${spaces.small}`}>
            Coleção: {item.garmentSpecSheet?.collection?.name}
          </Typography>
        </>
      )}
      onItemClick={(item) => redirect.to(`/store/${storeId}/specsheet/item/${item.id}`)}
      onItemRemove={(removedItem) => {
        const collectionName = removedItem?.garmentSpecSheet?.collection?.name;
        removeProductRequest.invoke({
          variables: {
            id: removedItem.id,
            storeId: store?.id,
            collectionName,
          },
          onSuccess: ({ data: { removeProduct } }) => {
            if (removeProduct) {
              setItems(items.filter(filteringItem => filteringItem.id !== removedItem.id));
            }
          },
          onFail: (err) => {
            if (err.name === 'UnauthorizedRequestError') {
              redirect.to('/login');
              return;
            }

            addSnackbar({
              title: `Não foi possível remover o item. ${err.message}`,
              fail: true,
              actionText: 'OK',
            });
          },
        });
      }}
      getItemImageUrl={(item) => item.garmentSpecSheet?.technicalDraw?.url}
      onItemImageChange={(item, media) => {
        const collectionName = item?.garmentSpecSheet?.collection?.name;
        updateProductRequest.invoke({
          variables: {
            id: item.id,
            storeId: parseInt(storeId),
            technicalDrawId: media.id,
            collectionName: collectionName,
          },
          onFail: (err) => {
            addSnackbar({
              title: `Erro ao atualizar a imagem do produto "${item.name}". ${err.message}`,
              actionText: 'OK',
              fail: true,
            });
          },
          onSuccess: () => {
            addSnackbar({
              title: `Imagem do produto "${item.name}" atualizada com sucesso.`,
              actionText: 'OK',
              fail: false,
            });
          },
        });
      }}
      onItemImagePostUpload={(file) => new Promise((resolve, reject) => {
        (store ? createStoreMediaRequest : createUserMediaRequest).invoke({
          variables: {
            storeId: store?.id,
            url: file.downloadUrl,
            name: file.fileName,
            mimeType: file.mimeType,
          },
          onFail: async (err) => {
            reject(err);
          },
          onSuccess: async ({ data: { createStoreMedia, createUserMedia } }) => {
            resolve(createStoreMedia ?? createUserMedia);
          },
        });
      })}
    />
  );
};

export { SpecSheetsList };
