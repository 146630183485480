import gql from 'graphql-tag';

const completeOnboardingQuery = gql`
  mutation {
    completeOnboarding {
      id
    }
  }
`;

export { completeOnboardingQuery };
