import { darken, rgba } from 'polished';
import { colors, opacity } from '../../tokens';

const buttonVariants = {
  default: {
    backgroundColor: colors.primary,
    backgroundColorBorderShadow: colors.primaryDarker,
    backgroundColorDisabled: colors.disabled,
    backgroundColorDisabledBorderShadow: colors.disabledDarker,
    backgroundColorHover: colors.buttonHover,
    backgroundColorHoverBorderShadow: colors.primary,
    backgroundColorFocus: colors.buttonActive,
    backgroundColorFocusBorderShadow: colors.buttonActiveDarker,
    backgroundColorPressed: colors.buttonActive,
    backgroundColorPressedBorderShadow: colors.buttonActiveDarker,
    progressIndicatorColor: colors.white,
    textColor: colors.white,
    borderStrokeColor: undefined,
  },
  back: {
    backgroundColor: colors.backButton,
    backgroundColorBorderShadow: colors.backButtonDarker,
    backgroundColorDisabled: colors.disabled,
    backgroundColorDisabledBorderShadow: colors.disabledDarker,
    backgroundColorHover: rgba(colors.backButton, opacity[5]),
    backgroundColorHoverBorderShadow: rgba(colors.backButton, opacity[6]),
    backgroundColorFocus: rgba(colors.backButton, opacity[4]),
    backgroundColorFocusBorderShadow: rgba(colors.backButton, opacity[5]),
    backgroundColorPressed: rgba(colors.backButton, opacity[4]),
    backgroundColorPressedBorderShadow: rgba(colors.backButton, opacity[5]),
    progressIndicatorColor: colors.primary,
    textColor: colors.primary,
    borderStrokeColor: undefined,
  },
  danger: {
    backgroundColor: colors.error,
    backgroundColorBorderShadow: darken(0.2, colors.error),
    backgroundColorDisabled: colors.disabled,
    backgroundColorDisabledBorderShadow: colors.disabledDarker,
    backgroundColorHover: rgba(colors.error, opacity[3]),
    backgroundColorHoverBorderShadow: rgba(colors.error, opacity[4]),
    backgroundColorFocus: rgba(colors.error, opacity[2]),
    backgroundColorFocusBorderShadow: rgba(colors.error, opacity[3]),
    backgroundColorPressed: rgba(colors.error, opacity[2]),
    backgroundColorPressedBorderShadow: rgba(colors.error, opacity[3]),
    progressIndicatorColor: colors.primary,
    textColor: colors.white,
    borderStrokeColor: undefined,
  },
  transparent: {
    backgroundColor: colors.transparent,
    backgroundColorBorderShadow: colors.transparent,
    backgroundColorDisabled: colors.transparent,
    backgroundColorDisabledBorderShadow: colors.transparent,
    backgroundColorHover: rgba(colors.black, opacity[5]),
    backgroundColorHoverBorderShadow: rgba(colors.black, opacity[5]),
    backgroundColorFocus: rgba(colors.black, opacity[4]),
    backgroundColorFocusBorderShadow: rgba(colors.black, opacity[4]),
    backgroundColorPressed: rgba(colors.black, opacity[4]),
    backgroundColorPressedBorderShadow: rgba(colors.black, opacity[4]),
    progressIndicatorColor: colors.primary,
    textColor: colors.primary,
    borderStrokeColor: undefined,
  },
  outline: {
    backgroundColor: colors.transparent,
    backgroundColorBorderShadow: undefined,
    backgroundColorDisabled: colors.transparent,
    backgroundColorDisabledBorderShadow: undefined,
    backgroundColorHover: rgba(colors.primary, opacity[5]),
    backgroundColorHoverBorderShadow: undefined,
    backgroundColorFocus: rgba(colors.primary, opacity[4]),
    backgroundColorFocusBorderShadow: undefined,
    backgroundColorPressed: rgba(colors.primary, opacity[4]),
    backgroundColorPressedBorderShadow: undefined,
    progressIndicatorColor: colors.primary,
    textColor: colors.primary,
    borderStrokeColor: colors.primary,
  },
} as const;

export type ButtonVariants = keyof typeof buttonVariants;
export { buttonVariants };
