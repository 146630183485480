import { Currencies, Locale } from '../../common/models';

const formatFloatValuePrice = (
  value: number,
  options: {
    locale: Locale,
  } = {
      locale: Locale.ptBR,
    },
): string => {
  let formatted = value.toFixed(2).toLocaleString();

  switch (options.locale) {
    case Locale.ptBR:
    default:
      formatted = `${Currencies.BRL} ${formatted.replace('.', ',')}`;
      break;
  }

  return formatted;
};

export {
  formatFloatValuePrice,
};
