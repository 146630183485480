import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Form,
  spaces,
  ProgressIndicator,
  Center,
  Padding,
} from '../../../../ui';
import { useGraphQl } from '../../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { StoreModel, MaterialInventoryItemModel, MaterialType } from '../../../../../common/models';
import {
  createFabricMaterialInventoryItemMutation,
  updateFabricMaterialInventoryItemMutation,
} from '../../../queries';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { useParams } from 'react-router-dom';
import { materialInventoryItemQuery } from '../../../queries/materialInventoryItemQuery';
import { MeasureUnit } from '../../../../../common/models/MeasureUnit';

const createMaterialValidationSchema = yup.object({
  itemRef: yup.string().required('A referência do tecido é obrigatória.'),
  itemName: yup.string().required('O nome do tecido é obrigatório.'),
  itemValue: yup.string().required('O valor do tecido é obrigatório.'),
  itemWeight: yup.number().typeError('O peso deve ser um número.').required('O peso do tecido é obrigatório.'),
  itemSize: yup.number().typeError('O peso deve ser um número.').required('O peso do tecido é obrigatório.'),
  itemColorName: yup.string().required('A cor do tecido é obrigatório.'),
  itemColorValue: yup.string().optional(),
  supplierName: yup.string().required('O nome do fornecedor é obrigatório.'),
  observation: yup.string().optional(),
  itemPictureMediaId: yup.number().typeError('Valor da imagem inválido.').optional(),
});

type InventoryCreateEditFabricItemFormProps = {
  onSubmitStarted?: () => void,
  onSubmitSucceeded?: (store: StoreModel) => void,
  onSubmitFailed?: (err: Error) => void,
  onExitForm?: () => void,
};

function getItemWeightAndItemSize(measures: Array<{
  unit: MeasureUnit,
  customUnit?: string,
  quantity: number,
}> | undefined): {
  itemWeight?: number,
  itemSize?: number,
} {
  const itemWeights = measures && measures.filter((measure) => measure.unit === MeasureUnit.kg).map((measure) => measure.quantity);
  const itemSizes = measures && measures.filter((measure) => measure.unit === MeasureUnit.m).map((measure) => measure.quantity);

  return {
    itemWeight: itemWeights && itemWeights?.length >= 0 ? itemWeights[0] : undefined,
    itemSize: itemSizes && itemSizes?.length >= 0 ? itemSizes[0] : undefined,
  };
};
const TeamMemberInviteForm = ({
  onSubmitFailed,
  onSubmitStarted,
  onSubmitSucceeded,
}: InventoryCreateEditFabricItemFormProps) => {
  const { addSnackbar } = useContext(SnackbarContext);
  const { storeId, itemId } = useParams();
  const redirect = useRedirect();
  const [item, setItem] = useState<MaterialInventoryItemModel | null>(null);

  const materialInventoryItemRequest = useGraphQl<{ materialInventoryItem: MaterialInventoryItemModel }>({
    query: materialInventoryItemQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const { itemWeight, itemSize } = getItemWeightAndItemSize(item?.measures);

  const { handleSubmit, setValue } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: { // TODO: Trazer o valor do item se existir.
      itemRef: item?.itemRef,
      itemName: item?.itemName,
      itemValue: item?.itemValue.toString(),
      itemWeight,
      itemSize,
      supplierName: item?.supplierName,
      itemColorName: item?.itemColorName,
      itemColorValue: item?.itemColorValue,
      observation: item?.observation,
      itemPictureMediaId: item?.itemImage?.id,
    },
    resolver: yupResolver(createMaterialValidationSchema),
  });
  const createMaterialInventoryItemRequest = useGraphQl<{ createFabricMaterialInventoryItem: MaterialInventoryItemModel }>({
    query: createFabricMaterialInventoryItemMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateMaterialInventoryItemRequest = useGraphQl<{ updateFabricMaterialInventoryItem: MaterialInventoryItemModel }>({
    query: updateFabricMaterialInventoryItemMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  useEffect(() => {
    if (itemId) {
      materialInventoryItemRequest.invoke({
        variables: {
          id: parseInt(itemId),
          storeId: parseInt(String(storeId)),
          materialType: MaterialType.Tecido,
        },
        onSuccess: ({ data: { materialInventoryItem } }) => {
          setItem(materialInventoryItem);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Erro ao carregar o insumo. ${err.message}`,
            actionText: 'OK',
            fail: true,
          });
          redirect.to(`/store/${storeId}/specsheet`);
        },
      });
    }
  }, [itemId, setValue]);

  const onSubmit = useCallback((data: yup.InferType<typeof createMaterialValidationSchema>) => {
    if (onSubmitStarted) onSubmitStarted();
    const variables = {
      id: itemId ? parseInt(itemId) : undefined,
      storeId: parseInt(String(storeId)),
      itemRef: data.itemRef,
      itemName: data.itemName,
      itemValue: data.itemValue,
      itemWeight: data.itemWeight,
      itemSize: data.itemSize,
      supplierName: data.supplierName,
      itemColorName: data.itemColorName,
      itemColorValue: data.itemColorValue,
      itemPictureMediaId: data.itemPictureMediaId,
      observation: data.observation,
    };

    if (itemId) {
      updateMaterialInventoryItemRequest.invoke({
        variables,
        onFail: (err) => {
          if (onSubmitFailed) onSubmitFailed(err);
          addSnackbar({
            title: `Falha ao criar insumo. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        },
        onSuccess: ({ data: { updateFabricMaterialInventoryItem } }) => {
          if (!updateFabricMaterialInventoryItem) return;
          if (onSubmitSucceeded) onSubmitSucceeded(updateFabricMaterialInventoryItem);
          addSnackbar({
            title: `Item ${data.itemRef} atualizado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/specsheet`);
        },
      });
    } else {
      createMaterialInventoryItemRequest.invoke({
        variables,
        onFail: (err) => {
          if (onSubmitFailed) onSubmitFailed(err);
          addSnackbar({
            title: `Falha ao criar insumo. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        },
        onSuccess: ({ data: { createFabricMaterialInventoryItem } }) => {
          if (!createFabricMaterialInventoryItem) return;
          if (onSubmitSucceeded) onSubmitSucceeded(createFabricMaterialInventoryItem);
          addSnackbar({
            title: `Item ${data.itemRef} criado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/specsheet`);
        },
      });
    }
  }, [item]);

  return itemId && !item ? (
    <Padding padding={`${spaces.huge} 0`}>
      <Center>
        <ProgressIndicator />
      </Center>
    </Padding>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Padding padding={`${spaces.huge} 0`}>
        <Center>
          <ProgressIndicator />
        </Center>
      </Padding>
    </Form>
  );
};

export { TeamMemberInviteForm };
