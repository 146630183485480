import { styled, css } from 'styled-components';
import { spaces, colors, zIndex, fontWeight } from '../../tokens';
import { InputLabel } from '../TextField';

const FieldButton = styled.button<{ $searchable: boolean }>`
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  padding: ${spaces.none} ${spaces.small};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: ${({ $searchable }) => $searchable ? `${spaces.small} ${spaces.small} ${spaces.none}` : `${spaces.none} ${spaces.small}`};
  display: flex;
  width: 100%;
  height: 70px;

  & ${InputLabel} {
    font-weight: ${fontWeight.semiBold};
  }
`;

const ListBox = styled.div`
  z-index: ${zIndex.purgatory};
  position: absolute;
  top: calc(100% + ${spaces.xSmall});
  left: 0;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 3px 8px ${colors.secondary};
  display: flex;
  flex-direction: column;
`;

const Option = styled.button.attrs({ type: 'button' }) <{ selected: boolean }>`
  width: 100%;
  background: none;
  border: none;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: ${spaces.medium};
  cursor: pointer;
  outline: none;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${colors.backgroundHover};
    `};

  &:hover,
  &:focus {
    background-color: ${colors.backgroundHover};
  }
`;

const SelectedItemText = styled.span<{ $color?: string }>`
  padding: ${spaces.xLarge} ${spaces.none} ${spaces.xSmall};
  color: ${({ $color }) => $color ? $color : 'inherit'};
`;

const Input = styled.input`
  left: 0;
  width: 100%;
  bottom: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

const IconsWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export { FieldButton, ListBox, Option, SelectedItemText, Input, IconsWrapper };
