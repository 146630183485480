import React from 'react';
import { TableRowProps } from './Table.types';
import { Row } from './Table.styles';
import { colors } from '../../tokens';

const TableRow = ({
  children,
  withBorder = true,
  highlightColor,
  hover = true,
  hoverColor = colors.backgroundHover,
  ...props
}: TableRowProps & {
  withBorder?: boolean,
  hoverColor?: string,
  hover?: boolean,
  highlightColor?: string,
}) => {
  return (
    <Row
      $withBorder={withBorder}
      $highlightColor={highlightColor}
      $hover={hover}
      $hoverColor={hoverColor}
      {...props}
    >
      {children}
    </Row>
  );
};

export { TableRow };
