import * as yup from 'yup';
import { DeleteResponse, UploadResponse } from '../../common/models';
import * as session from './sessionStorage';
import { ApiEndpointProps, apiRoutes, getApiEndpoint } from './apiEndpoints';

type FileOptions = {
  apiEndpointProps: ApiEndpointProps,
}

const yupFile = ({ _required }: { _required: boolean } = { _required: false }) =>
  yup
    .mixed()
    .test('empty', 'Necessário selecionar um arquivo.', (fileList) => {
      return fileList && (fileList as FileList).length >= 1;
    })
    .test('fileSize', 'O arquivo não pode ser maior que 100MB.', (fileList) => {
      const castedFileList = fileList as FileList;
      if (castedFileList && castedFileList.length <= 0) return false;
      return castedFileList[0].size <= 104857600;
    })
    .test('type', 'O arquivo precisa ser uma imagem.', (fileList) => {
      const castedFileList = fileList as FileList;
      if (castedFileList && castedFileList.length <= 0) return false;
      return castedFileList[0].type.includes('image');
    });

const validateFile = async (files: FileList): Promise<{
  valid: boolean,
  error?: yup.ValidationError,
}> => {
  try {
    await yupFile().validate(files);
    return {
      valid: true,
    };
  } catch (err) {
    return {
      valid: false,
      error: err,
    };
  }
};

const uploadMedia = async (files: FileList, options?: FileOptions): Promise<UploadResponse> => {
  const formData = new FormData();
  formData.append('files', files[0]);

  const correlationId = session.getItem<string | null>('correlation_id');
  const accessToken = session.getItem<string | null>('access_token');
  const refreshToken = session.getItem<string | null>('refresh_token');

  const valid = await validateFile(files);

  if (valid.error) {
    throw valid.error;
  }

  const response = await fetch(getApiEndpoint({
    ...options?.apiEndpointProps,
    route: apiRoutes.mediaUpload,
  }), {
    method: 'PUT',
    body: formData,
    headers: {
      'X-Mecena-Correlation-Id': correlationId,
      authorization: `Bearer ${accessToken}`,
      'X-Mecena-Ref-Token': refreshToken,
      // 'Content-Type': 'multipart/form-data',
    },
  });

  return await response.json();
};

const deleteMedia = async (fileName: string, options?: FileOptions): Promise<DeleteResponse> => {
  const correlationId = session.getItem<string | null>('correlation_id');
  const accessToken = session.getItem<string | null>('access_token');
  const refreshToken = session.getItem<string | null>('refresh_token');

  const response = await fetch(`${getApiEndpoint({
    ...options?.apiEndpointProps,
    route: apiRoutes.mediaDelete,
  })}/${fileName}`, {
    method: 'DELETE',
    headers: {
      'X-Mecena-Correlation-Id': correlationId,
      authorization: `Bearer ${accessToken}`,
      'X-Mecena-Ref-Token': refreshToken,
    },
  });

  return await response.json();
};

export {
  yupFile,
  validateFile,
  uploadMedia,
  deleteMedia,
};
