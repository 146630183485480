import { createBrowserRouter } from 'react-router-dom';
import {
  AuthPage,
  InventoryListPage,
  InventoryListEditCreatePage,
  StoreHomePage,
  StorePage,
  OnboardingPage,
  StoreSettingsPage,
  SpecSheetsListPage,
  SpecSheetsListEditCreatePage,
  TeamMembersListPage,
  TeamMemberEditInvitePage,
} from './components';
import { authPageStateValues } from '../../common/models';
import { StoreProvider } from './contexts/StoreContext';
import { AuthenticatorProvider } from './contexts/AuthenticatorContext';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { HomePage } from './components/HomePage/HomePage';
import { PageNotFound } from './components/404';
import { FeaturedRoute } from './components/FeaturedRoute/FeaturedRoute';
import { FeatureName } from '../../common/models/FeatureName';
import { InstitutionsEditCreatePage, InstitutionsListPage } from './components/institution';
import { ResiduesEditCreatePage, ResiduesListPage } from './components/residues';
import { UnloadingLogsListPage, UnloadingLogsViewCreatePage } from './components/unloadingLogs';

const router: ReturnType<typeof createBrowserRouter> = createBrowserRouter(
  [
    {
      element: <AuthenticatorProvider />,
      children: [
        {
          path: '/',
          element: <HomePage />,
        },
        {
          path: '/index.html',
          element: <HomePage />,
        },
        {
          path: '/login',
          element: <AuthPage />,
        },
        {
          path: '/login/confirm-email',
          element: <AuthPage initialState={authPageStateValues.confirm} />,
        },
        {
          path: '/login/new-password',
          element: <AuthPage initialState={authPageStateValues.newpass} />,
        },
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: '/onboarding',
              element: <OnboardingPage />,
            },
            {
              path: '/store',
              element: <StorePage />,
            },
            {
              element: <StoreProvider />,
              children: [
                {
                  path: '/store/:storeId',
                  element: <StoreHomePage />,
                },
                // ARMAZENAMENTO
                {
                  element: <FeaturedRoute featureName={FeatureName.Materials} />,
                  children: [
                    {
                      path: '/store/:storeId/storage',
                      element: <InventoryListPage />,
                    },
                    {
                      path: '/store/:storeId/storage/item',
                      element: <InventoryListEditCreatePage />,
                    },
                    {
                      path: '/store/:storeId/storage/item/:itemId',
                      element: <InventoryListEditCreatePage />,
                    },
                  ],
                },
                // EMPRESA
                {
                  path: '/store/:storeId/settings',
                  element: <StoreSettingsPage />,
                },
                // FICHA TÉCNICA
                {
                  element: <FeaturedRoute featureName={FeatureName.SpecSheets} />,
                  children: [
                    {
                      path: '/store/:storeId/specsheet',
                      element: <SpecSheetsListPage />,
                    },
                    {
                      path: '/store/:storeId/specsheet/item',
                      element: <SpecSheetsListEditCreatePage />,
                    },
                    {
                      path: '/store/:storeId/specsheet/item/:itemId',
                      element: <SpecSheetsListEditCreatePage />,
                    },
                  ],
                },
                // RESÍDUOS
                {
                  element: <FeaturedRoute featureName={FeatureName.Residues} />,
                  children: [
                    {
                      path: '/store/:storeId/residue',
                      element: <ResiduesListPage />,
                    },
                    {
                      path: '/store/:storeId/residue/item',
                      element: <ResiduesEditCreatePage />,
                    },
                    {
                      path: '/store/:storeId/residue/item/:itemId',
                      element: <ResiduesEditCreatePage />,
                    },
                  ],
                },
                // INSTITUIÇÕES
                {
                  element: <FeaturedRoute featureName={FeatureName.Institutions} />,
                  children: [
                    {
                      path: '/store/:storeId/institution',
                      element: <InstitutionsListPage />,
                    },
                    {
                      path: '/store/:storeId/institution/item',
                      element: <InstitutionsEditCreatePage />,
                    },
                    {
                      path: '/store/:storeId/institution/item/:itemId',
                      element: <InstitutionsEditCreatePage />,
                    },
                  ],
                },
                // REGISTROS DE SAÍDA
                {
                  element: <FeaturedRoute featureName={FeatureName.Institutions} />,
                  children: [
                    {
                      path: '/store/:storeId/unloadinglog',
                      element: <UnloadingLogsListPage />,
                    },
                    {
                      path: '/store/:storeId/unloadinglog/item',
                      element: <UnloadingLogsViewCreatePage />,
                    },
                  ],
                },
                // TIME
                {
                  path: '/store/:storeId/team',
                  element: <TeamMembersListPage />,
                },
                {
                  path: '/store/:storeId/team/member',
                  element: <TeamMemberEditInvitePage />,
                },
                {
                  path: '/store/:storeId/team/member/:memberId',
                  element: <TeamMemberEditInvitePage />,
                },
              ],
            },
          ],
        },
        {
          path: '*',
          element: <PageNotFound />,
        },
      ],
    },
  ],
);

export { router };
