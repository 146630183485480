import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FieldValues, useForm, UseFormRegister } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  Col,
  colors,
  ErrorField,
  fontWeight,
  Form,
  FormControl,
  GridContainer,
  GridItem,
  HorizontalRule,
  Padding,
  spaces,
  TextField,
  Typography,
} from '../../../ui';
import { cleanupCnpj } from '../../../../common/utils';
import { yupCnpj, formatCnpj, cnpjInputProps } from '../../../utils/cnpj';
import { StoreModel, RoleModel, MediaModel } from '../../../../common/models';
import { useGraphQl } from '../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../utils/apiEndpoints';
import { updateStoreQuery } from '../../queries/updateStoreQuery';
import { StoreContext } from '../../contexts/StoreContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { MediaCard } from '../medias';
import { updateStoreLogoQuery } from '../../queries/updateStoreLogoQuery';

const storeSettingsValidationSchema = yup.object({
  name: yup.string().required('O nome da empresa não pode ficar em branco.'),
  logoId: yup.number().typeError('Valor do logo inválido.').optional(),
  cnpj: yupCnpj().optional(),
  communicationEmail: yup.string().required('O e-mail de comunicação não pode ficar em branco.'),
  roleObject: yup.string().required('Os valores dos cargos são obrigatórios.'),
});

const rolesToJson = (roles: Array<RoleModel>): string => JSON.stringify(roles);
const rolesFromJson = (rolesJson: string): Array<RoleModel> => JSON.parse(rolesJson) as Array<RoleModel>;

const StoreSettingsForm = ({ store }: { store: StoreModel }) => {
  const [roles, setRoles] = useState<Array<RoleModel> | null>(null);
  const { updateStore } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);

  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: store?.name,
      logoId: store?.logo?.id ?? undefined,
      cnpj: formatCnpj(store?.cnpj?.value),
      communicationEmail: store?.storeSetting?.communicationEmail,
      roleObject: rolesToJson(store?.roles),
    },
    resolver: yupResolver(storeSettingsValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  const updateStoreRequest = useGraphQl<{ updateStore: StoreModel }>({
    query: updateStoreQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateStoreLogoRequest = useGraphQl<{ updateStoreLogo: StoreModel }>({
    query: updateStoreLogoQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const onSubmit = (data: {
    name: string,
    logoId?: number,
    cnpj?: string,
    communicationEmail: string,
    roleObject: string,
  }) => {
    updateStoreRequest.invoke({
      variables: {
        id: store?.id,
        name: data.name,
        logoId: data.logoId,
        cnpj: cleanupCnpj(data.cnpj),
        communicationEmail: data.communicationEmail,
      },
      onFail: (err) => {
        addSnackbar({
          title: `Erro ao atualizar a empresa. ${err.message}`,
          actionText: 'OK',
          fail: true,
        });
      },
      onSuccess: ({ data: updateStoreData }) => {
        addSnackbar({
          title: `Empresa "${data.name}" atualizada com sucesso.`,
          actionText: 'OK',
          fail: false,
        });
        updateStore(updateStoreData.updateStore);
      },
    });
  };

  useEffect(() => {
    setRoles(rolesFromJson(getValues('roleObject')));
  }, [getValues]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Typography variant="sublead" color={colors.black} margin={`${spaces.xSmall} 0 0`}>
          Altere os campos e confirme caso haja necessidade.
        </Typography>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={3} md={4} sm={6}>
            <MediaCard
              id="logoId"
              uploadable
              wide
              store={store}
              src={store?.logo?.presignedUrl ?? store?.logo?.url ?? undefined}
              onChange={async ({ target: { value } }) => {
                const media: MediaModel = JSON.parse(value) as MediaModel;
                setValue('logoId', media.id);
                updateStoreLogoRequest.invoke({
                  variables: {
                    id: store?.id,
                    logoId: media.id,
                  },
                  onFail: (err) => {
                    addSnackbar({
                      title: `Erro ao atualizar o logo da empresa. ${err.message}`,
                      actionText: 'OK',
                      fail: true,
                    });
                  },
                  onSuccess: ({ data: { updateStoreLogo } }) => {
                    addSnackbar({
                      title: `Logo da empresa "${store?.name}" atualizada com sucesso.`,
                      actionText: 'OK',
                      fail: false,
                    });
                    updateStore(updateStoreLogo);
                  },
                });
              }}
            />
          </GridItem>
          <GridItem lg={9} md={8} sm={12}>
            <TextField
              id="name"
              type="text"
              label="Nome da empresa"
              defaultValue={store?.name}
              register={castedRegister}
              errorMessage={errors.name?.message}
              state={errors.name ? 'invalid' : 'neutral'}
              required
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={6} sm={12}>
            <TextField
              id="cnpj"
              type="text"
              label="CNPJ da empresa"
              defaultValue={formatCnpj(store?.cnpj?.value)}
              register={castedRegister}
              errorMessage={errors.cnpj?.message}
              state={errors.cnpj ? 'invalid' : 'neutral'}
              {...cnpjInputProps}
            />
          </GridItem>
          <GridItem lg={6} sm={12}>
            <TextField
              id="communicationEmail"
              type="text"
              label="E-mail principal da empresa"
              defaultValue={store?.storeSetting?.communicationEmail}
              register={castedRegister}
              errorMessage={errors.communicationEmail?.message}
              state={errors.communicationEmail ? 'invalid' : 'neutral'}
              required
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <input
          id="roleObject"
          type="hidden"
          name="roleObject"
          defaultValue={rolesToJson(store?.roles)}
          {...castedRegister('roleObject')}
        />
        {roles && (
          <>
            <HorizontalRule />
            <FormControl>
              <Typography variant="sublead" color={colors.black} margin={`${spaces.huge} 0 0`}>
                Cargos
              </Typography>
            </FormControl>
            <FormControl>
              <GridContainer noPadding>
                {
                  roles.map((role) => (
                    <GridItem lg={6} sm={12} key={role.name}>
                      <Col minHeight='110px' gutter='0px' justifyContent='flex-start'>
                        <Typography variant="paragraph" color={colors.black} fontWeight={fontWeight.bold} margin={`${spaces.large} 0`}>
                          {role.name}
                        </Typography>
                        <Typography variant="paragraph" color={colors.black} margin={`${spaces.large} 0`}>
                          {role.description}
                        </Typography>
                      </Col>
                      {Object.keys(role.permissions).map((permission) => (
                        <Padding padding={`${spaces.xSmall} 0`} key={permission}>
                          <Checkbox
                            label={role.permissions[permission].locales.ptBR}
                            id={permission}
                            checked={role.permissions[permission].permitted}
                            value={role.permissions[permission].permitted}
                            disabled
                            onChange={(_event: ChangeEvent<HTMLInputElement>) => {
                              //TODO:
                              // const newRoles = roles.filter((filteringRole) => filteringRole.name !== role.name);
                              // const newRole = {
                              //   ...role,
                              //   permissions: {
                              //     ...role.permissions,
                              //   },
                              // };
                              // console.log(newRole);
                              // newRole.permissions[permission].permitted = (event.target.value === 'true');
                              // newRoles.push(newRole);

                              // setRoles(newRoles);
                              // setValue('roleObject', rolesToJson(newRoles));
                            }}
                          />
                        </Padding>
                      ))}
                    </GridItem>
                  ))
                }
              </GridContainer>
            </FormControl>
          </>
        )}
        {errors.roleObject && (
          <ErrorField message={errors.roleObject?.message} />
        )}
      </FormControl>
      <FormControl>
        <Button variant="default" type="submit">
          Confirmar
        </Button>
      </FormControl>
    </Form>
  );
};

export {
  StoreSettingsForm,
};
