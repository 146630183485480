import { styled, css } from 'styled-components';
import { colors, spaces, fontWeight } from '../../tokens';
import {
  TableHeadProps,
  TableBodyProps,
  TableRowProps,
} from './Table.types';
import { ReactNode } from 'react';

const hoverStyle = ({
  $hover,
  $hoverColor,
  $highlightColor,
}: TableRowProps | TableBodyProps) => {
  if ($hover && !$highlightColor) {
    return css`
      background-color: ${$hoverColor};
    `;
  }

  return null;
};

const Cell = styled.td`
  height: 64px;
  padding-left: ${spaces.xSmall};
`;

const Head = styled.thead<TableHeadProps>`
  background-color: ${({ color }) => color};
`;

const Row = styled.tr<TableRowProps>`
  width: 100%;
  min-height: 64px;
  padding: ${spaces.medium};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  position: relative;
  box-shadow: ${({ $withBorder }) =>
    $withBorder ? `${spaces.none} 1px ${colors.secondary}` : 'none'};
  background-color: ${({ $highlightColor }) => $highlightColor || 'transparent'};
  transition: 0.2s ease-out background-color;

  :hover,
  :focus-within {
    ${hoverStyle};
  }

  ${Head} & {
    box-shadow: ${({ $withBorder }) =>
    $withBorder ? `0px 1px ${colors.secondary} ` : 'none'};
    background-color: ${({ color }) => color || 'transparent'};
    font-weight: ${fontWeight.bold};
  }
`;

const Body = styled.tbody<TableBodyProps>`
  background-color: ${({ color }) => color};

  ${Row}:last-child {
    box-shadow: none;
  }

  :hover,
  :focus-within {
    ${hoverStyle};
  }
`;

const TableWrapper = styled.table<{ children: ReactNode, $tableLayout?: 'fixed' | 'auto' }>`
  width: 100%;
  border-spacing: 0;
  table-layout: ${({ $tableLayout }) => $tableLayout ? $tableLayout : 'auto'};
`;

export { TableWrapper, Head, Body, Row, Cell };
