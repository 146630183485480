import { patternFormatter } from 'react-number-format';
import * as yup from 'yup';
import validator from 'validator';
import { validateTel } from '../../common/utils';

const telInputProps = { format: '(##) #########', mask: ' ', helperText: 'Formatação: (00) 9?00000000', min: 10, max: 11 };

const yupTel = ({ required }: { required: boolean } = { required: false }) =>
  yup
    .string()
    .typeError('Telefone inválido.')
    .min(required ? 10 + 3 : 0 /* Contando os parênteses e espaço. */, 'O telefone está incompleto.')
    .max(11 + 3 /* Contando os parênteses e espaço. */, 'Telefone inválido.')
    .test('telValidation', 'Telefone inválido.', async (value) => {
      return required ? validateTel(value) : (
        typeof value === 'undefined' ||
        value === null ||
        validator.isEmpty(value) ||
        validateTel(value)
      );
    });

const formatTel = (value: string | null | undefined) => value && patternFormatter(value, telInputProps);

export {
  telInputProps,
  yupTel,
  formatTel,
};
