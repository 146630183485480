import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Form,
  FormControl,
  TextField,
  Typography,
  GridContainer,
  GridItem,
  Button,
  colors,
  spaces,
  ProgressIndicator,
  Center,
  Padding,
  TextArea,
  Select,
} from '../../../../ui';
import { useGraphQl } from '../../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import {
  createTrimMaterialInventoryItemMutation,
  updateTrimMaterialInventoryItemMutation,
  suppliersQuery,
} from '../../../queries';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { useParams } from 'react-router-dom';
import { materialInventoryItemQuery } from '../../../queries/materialInventoryItemQuery';
import { StoreContext } from '../../../contexts/StoreContext';
import { MediaCard } from '../../medias';
import { MediaModel, StoreModel, SupplierModel, MaterialInventoryItemModel } from '../../../../../common/models';

const createMaterialValidationSchema = yup.object({
  itemRef: yup.string().required('A referência do item é obrigatória.'),
  itemName: yup.string().required('O nome do item é obrigatório.'),
  itemValue: yup.string().required('O valor do item é obrigatório.'),
  itemMeasureUnit: yup.string().required('O valor da unidade de medida é obrigatório.'),
  quantity: yup.number().typeError('A quantidade deve ser um número.').required('A quantidade do item é obrigatório.'),
  trimMaterialType: yup.string().required('O tipo de material do aviamento é obrigatório.'),
  itemColorName: yup.string().optional(),
  itemColorValue: yup.string().optional(),
  supplierName: yup.string().required('O nome do fornecedor é obrigatório.'),
  observation: yup.string().optional(),
  itemPictureMediaId: yup.number().typeError('Valor da imagem inválido.').optional(),
});

type InventoryCreateEditTrimItemFormProps = {
  onSubmitStarted?: () => void,
  onSubmitSucceeded?: (store: StoreModel) => void,
  onSubmitFailed?: (err: Error) => void,
  onExitForm?: () => void,
};

function getMeasureUnitAndQuantity(measures: Array<{
  unit: string,
  customUnit?: string,
  quantity: number,
}> | undefined): {
  itemMeasureUnit?: string,
  quantity?: number,
} {
  const items = measures && measures
    .filter((measure) => measure.unit === 'un' || measure.unit === 'custom')
    .map((measure) => ({
      measureUnit: measure.unit === 'custom' ? measure.customUnit : measure.unit,
      quantity: measure.quantity,
    }));

  return {
    itemMeasureUnit: items && items?.length > 0 ? items[0].measureUnit : undefined,
    quantity: items && items?.length > 0 ? items[0].quantity : undefined,
  };
};

const InventoryCreateEditTrimItemForm = ({
  onSubmitFailed,
  onSubmitStarted,
  onSubmitSucceeded,
  onExitForm,
}: InventoryCreateEditTrimItemFormProps) => {
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const { storeId, itemId } = useParams();
  const redirect = useRedirect();
  const [item, setItem] = useState<MaterialInventoryItemModel | null>(null);
  const [suppliers, setSuppliers] = useState<Array<SupplierModel>>([]);

  const { itemMeasureUnit, quantity } = getMeasureUnitAndQuantity(item?.measures);

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: { // TODO: Trazer o valor do item se existir.
      itemRef: item?.itemRef,
      itemName: item?.itemName,
      itemValue: item?.itemValue.toString(),
      itemMeasureUnit: itemMeasureUnit,
      quantity: quantity,
      supplierName: item?.supplierName,
      trimMaterialType: item?.trimMaterialType,
      itemColorName: item?.itemColorName,
      itemColorValue: item?.itemColorValue,
      observation: item?.observation,
      itemPictureMediaId: item?.itemImage?.id,
    },
    resolver: yupResolver(createMaterialValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  // FIXME: Workaround para o Select que é required.
  useEffect(() => {
    const supplierName = getValues('supplierName');
    if (!supplierName) {
      setValue('supplierName', item?.supplierName);
    };
  }, [item, getValues]);

  const materialInventoryItemRequest = useGraphQl<{ materialInventoryItem: MaterialInventoryItemModel }>({
    query: materialInventoryItemQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const suppliersRequest = useGraphQl<{ suppliers: Array<SupplierModel> }>({
    query: suppliersQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createMaterialInventoryItemRequest = useGraphQl<{ createTrimMaterialInventoryItem: MaterialInventoryItemModel }>({
    query: createTrimMaterialInventoryItemMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateMaterialInventoryItemRequest = useGraphQl<{ updateTrimMaterialInventoryItem: MaterialInventoryItemModel }>({
    query: updateTrimMaterialInventoryItemMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  useEffect(() => {
    if (itemId) {
      materialInventoryItemRequest.invoke({
        variables: {
          id: parseInt(itemId),
          storeId: parseInt(String(storeId)),
          materialType: 'Aviamento',
        },
        onSuccess: ({ data: { materialInventoryItem } }) => {
          setItem(materialInventoryItem);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Erro ao carregar o insumo. ${err.message}`,
            actionText: 'OK',
            fail: true,
          });
          redirect.to(`/store/${storeId}/storage`);
        },
      });
    }
  }, [itemId, setValue]);

  useEffect(() => {
    suppliersRequest.invoke({
      onSuccess: ({ data }) => {
        setSuppliers(data.suppliers);
      },
    });
  }, []);

  const onSubmit = useCallback((data: yup.InferType<typeof createMaterialValidationSchema>) => {
    if (onSubmitStarted) onSubmitStarted();
    const variables = {
      id: itemId ? parseInt(itemId) : undefined,
      storeId: parseInt(String(storeId)),
      itemRef: data.itemRef,
      itemName: data.itemName,
      itemValue: data.itemValue,
      itemMeasureUnit: data.itemMeasureUnit,
      quantity: data.quantity,
      supplierName: data.supplierName,
      trimMaterialType: data.trimMaterialType,
      itemColorName: data.itemColorName,
      itemColorValue: data.itemColorValue,
      itemPictureMediaId: data.itemPictureMediaId,
      observation: data.observation,
    };

    if (itemId) {
      updateMaterialInventoryItemRequest.invoke({
        variables,
        onFail: (err) => {
          if (onSubmitFailed) onSubmitFailed(err);
          addSnackbar({
            title: `Falha ao criar insumo. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        },
        onSuccess: ({ data: { updateTrimMaterialInventoryItem } }) => {
          if (!updateTrimMaterialInventoryItem) return;
          if (onSubmitSucceeded) onSubmitSucceeded(updateTrimMaterialInventoryItem);
          addSnackbar({
            title: `Item ${data.itemRef} atualizado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/storage`);
        },
      });
    } else {
      createMaterialInventoryItemRequest.invoke({
        variables,
        onFail: (err) => {
          if (onSubmitFailed) onSubmitFailed(err);
          addSnackbar({
            title: `Falha ao criar insumo. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        },
        onSuccess: ({ data: { createTrimMaterialInventoryItem } }) => {
          if (!createTrimMaterialInventoryItem) return;
          if (onSubmitSucceeded) onSubmitSucceeded(createTrimMaterialInventoryItem);
          addSnackbar({
            title: `Item ${data.itemRef} criado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/storage`);
        },
      });
    }
  }, [item]);

  return itemId && !item ? (
    <Padding padding={`${spaces.huge} 0`}>
      <Center>
        <ProgressIndicator />
      </Center>
    </Padding>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Typography variant="title" color={colors.primary}>
          {itemId ? `Alterar ${item?.itemRef ?? 'aviamento'}` : 'Criar novo aviamento'}
        </Typography>
        <Typography variant="sublead" color={colors.black} margin={`${spaces.xSmall} 0 0`}>
          Crie um insumo novo ou registre um produto armazenado.
        </Typography>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={3} md={4} sm={6}>
            <MediaCard
              id={`${item?.id}InventoryItemMedia`}
              uploadable
              wide
              store={store}
              src={item?.itemImage?.presignedUrl ?? item?.itemImage?.url}
              onChange={async ({ target: { value } }) => {
                const media: MediaModel = JSON.parse(value) as MediaModel;
                setValue('itemPictureMediaId', media.id);
                if (!item?.id) return;
                updateMaterialInventoryItemRequest.invoke({
                  variables: {
                    id: item?.id,
                    storeId: parseInt(storeId),
                    itemPictureMediaId: media.id,
                  },
                  onFail: (err) => {
                    addSnackbar({
                      title: `Erro ao atualizar a imagem do item "${item?.itemRef}". ${err.message}`,
                      actionText: 'OK',
                      fail: true,
                    });
                  },
                  onSuccess: () => {
                    addSnackbar({
                      title: `Imagem do item "${item?.itemRef}" atualizada com sucesso.`,
                      actionText: 'OK',
                      fail: false,
                    });
                  },
                });
              }}
            />
          </GridItem>
          <GridItem lg={9} md={8} sm={12}>
            <TextField
              id="itemRef"
              type="text"
              label="Código de referência"
              defaultValue={item?.itemRef}
              register={castedRegister}
              errorMessage={errors.itemRef?.message}
              state={errors.itemRef ? 'invalid' : 'neutral'}
              required
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={6} sm={12}>
            <TextField
              id="itemColorName"
              type="text"
              label="Cor"
              register={castedRegister}
              defaultValue={item?.itemColorName}
              errorMessage={errors.itemColorName?.message}
              state={errors.itemColorName ? 'invalid' : 'neutral'}
            />
          </GridItem>
          <GridItem lg={6} sm={12}>
            <TextField
              id="itemName"
              type="text"
              label="Nome do insumo"
              register={castedRegister}
              defaultValue={item?.itemName}
              errorMessage={errors.itemName?.message}
              state={errors.itemName ? 'invalid' : 'neutral'}
              required
            />
          </GridItem>
          <GridItem lg={6} sm={12}>
            <TextField
              id="trimMaterialType"
              type="text"
              label="Material do aviamento"
              register={castedRegister}
              defaultValue={item?.trimMaterialType}
              errorMessage={errors.trimMaterialType?.message}
              state={errors.trimMaterialType ? 'invalid' : 'neutral'}
              required
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={6} sm={12}>
            <TextField
              id="itemMeasureUnit"
              type="text"
              label="Unidade de medida"
              register={castedRegister}
              defaultValue={itemMeasureUnit}
              errorMessage={errors.itemMeasureUnit?.message}
              state={errors.itemMeasureUnit ? 'invalid' : 'neutral'}
              helperText='Qual a unidade de medida, exemplo: un, cm, g'
              required
            />
          </GridItem>
          <GridItem lg={6} sm={12}>
            <TextField
              id="quantity"
              type="number"
              label="Quantidade"
              step="0.001"
              register={castedRegister}
              defaultValue={quantity}
              errorMessage={errors.quantity?.message}
              state={errors.quantity ? 'invalid' : 'neutral'}
              required
              helperText="Formato: 00.000"
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={6} sm={12}>
            <TextField
              id="itemValue"
              type="text"
              label="Valor do insumo"
              register={castedRegister}
              defaultValue={item?.itemValue}
              errorMessage={errors.itemValue?.message}
              state={errors.itemValue ? 'invalid' : 'neutral'}
              helperText='Valor em reais do insumo por unidade de medida.'
              required
            />
          </GridItem>
          <GridItem lg={6} sm={12}>
            <Select
              id="supplierName"
              label="Nome do fornecedor"
              errorMessage={errors.supplierName?.message}
              state={errors.supplierName ? 'invalid' : 'neutral'}
              required
              listItems={suppliers ? suppliers.map((supplier) => ({
                text: supplier.name,
                value: supplier.name,
              })) : []}
              defaultValue={item?.supplierName}
              onChange={(_, selectedItem) => setValue('supplierName', selectedItem.text)}
              searchable
              searchOptions={{
                acceptNewValue: true,
              }}
              loading={suppliersRequest.isFetching()}
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={12} sm={12}>
            <TextArea
              id="observation"
              label="Observações"
              register={castedRegister}
              defaultValue={item?.observation}
              errorMessage={errors.observation?.message}
              state={errors.observation ? 'invalid' : 'neutral'}
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <GridContainer noPadding>
        <GridItem lg={6}>
          {onExitForm && (
            <Button
              variant="back"
              wide
              type="button"
              onClick={onExitForm}
            >
              Voltar
            </Button>
          )}
        </GridItem>
        <GridItem lg={6}>
          <Button variant="default" wide type="submit">
            {itemId ? 'Atualizar' : 'Cadastrar'}
          </Button>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

export { InventoryCreateEditTrimItemForm };
