import { styled } from 'styled-components';
import { colors, spaces } from '../../tokens';
import { Button as DefaultButton } from '../Button';

const Wrapper = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

const InputSearch = styled.input.attrs(() => ({
  type: 'search',
}))`
  height: 100%;
  outline: none;
  border: none;
  background-color: ${colors.white};
  padding-left: ${spaces.small};
  caret-color: ${colors.secondary};
  width: 100%;

  &:placeholder-shown + button {
    visibility: hidden;
  }

  & + button {
    visibility: visible;
  }
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

const Button = styled(DefaultButton).attrs(() => ({
  type: 'submit',
  noBorder: true,
  variant: 'default',
}))`
  svg {
    fill: ${colors.white};
  }
`;

const ResetButton = styled.button.attrs(() => ({
  type: 'reset',
}))`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: no-repeat;
  border: none;
  outline: none;
  cursor: pointer;

  &:active {
    svg {
      fill: ${colors.primary};
    }
  }
`;

export { Wrapper, Label, InputSearch, Button, ResetButton, InputWrapper };
