import gql from 'graphql-tag';

const createStoreMediaMutation = gql`
  mutation (
    $storeId: Int
    $url: String!
    $name: String!
    $mimeType: String!
    $alt: String
  ) {
    createStoreMedia(
      storeId: $storeId
      url: $url
      name: $name
      mimeType: $mimeType
      alt: $alt
    ) {
      id
      guid
      createdAt
      updatedAt
      url
      name
      alt
      mimeType
      type
    }
  }
`;

export { createStoreMediaMutation };
