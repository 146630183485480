import React, { useContext } from 'react';
import { ProductContext } from '../../../contexts/ProductContext';
import { SpecSheetForm } from './SpecSheetForm';
import { Center, ProgressIndicator } from '../../../../ui';

const SpecSheetFormWrapper = () => {
  const { product, setProduct, isFetching } = useContext(ProductContext);

  return (
    isFetching ?
      <Center>
        <ProgressIndicator />
      </Center> :
      product ?
        <SpecSheetForm product={product} setProduct={setProduct} /> :
        <SpecSheetForm setProduct={setProduct} />
  );
};

export {
  SpecSheetFormWrapper,
};
