import React from 'react';
import { Typography } from '../Typography';
import { colors, spaces } from '../../tokens';
import { BreadcrumbItemProps, Breadcrumbs } from '../Breadcrumbs';
import { Header } from './ContentHeader.styles';

type ContentHeaderProps = {
  title?: string;
  subtitle?: string;
  isDevelopment?: boolean;
  breadcrumbs?: Array<BreadcrumbItemProps>;
};

const ContentHeader = ({
  title,
  subtitle,
  breadcrumbs = [],
}: ContentHeaderProps) => {
  return (
    <Header>
      <Typography variant="title" color={colors.primary} margin={`0 0 ${spaces.xSmall}`}>{title}</Typography>
      <Typography variant="sublead" color={colors.black} margin={`0 0 ${spaces.large}`}>{subtitle}</Typography>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Header>
  );
};

export { ContentHeader };
