import gql from 'graphql-tag';

const createStoreQuery = gql`
  mutation(
    $name: String!
    $ownerId: Int!
    $development: Boolean
    $logoId: Int
    $cnpj: String
    $communicationEmail: String
    ) {
    createStore(
      name: $name
      ownerId: $ownerId
      development: $development
      logoId: $logoId
      cnpj: $cnpj
      communicationEmail: $communicationEmail
    ) {
      id
      guid
      name
      cnpj {
        id
        value
      }
      storeSetting {
        communicationEmail
      }
      role {
        id
        guid
        name
        description
        permissions {
          can_create_store {
            locales {
              ptBR
            }
            permitted
          }
          can_update_store {
            locales {
              ptBR
            }
            permitted
          }
          can_invite_user {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_user_role {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_user {
            locales {
              ptBR
            }
            permitted
          }
          can_create_material {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_material {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_material {
            locales {
              ptBR
            }
            permitted
          }
          can_create_product {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_product {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_product {
            locales {
              ptBR
            }
            permitted
          }
          can_create_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_deactivate_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_create_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_deactivate_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_create_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
        }
      }
    }
  }
`;

export { createStoreQuery };
