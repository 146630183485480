import React, { createContext, ReactNode, useState, useEffect, useMemo } from 'react';
import { Snackbar, SnackbarProps } from '../../ui';

interface SnackbarProviderProps {
  children: ReactNode;
}

interface SnackbarContextProps {
  addSnackbar(snackbarProps: SnackbarProps): void;
}

const SnackbarContext = createContext<SnackbarContextProps>({
  addSnackbar: () => null,
});

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState<SnackbarProps>({
    title: '',
    fail: false,
  });

  useEffect(() => {
    return () => clearTimeout(timer);
  });

  const contextValue = useMemo(() => ({
    addSnackbar: (snackbarProps: SnackbarProps) => {
      setSnackbarData(snackbarProps);
      setShowSnackbar(true);

      setTimer(
        setTimeout(
          () => {
            setShowSnackbar(false);
          },
          snackbarProps?.durationMs ? snackbarProps.durationMs : 6000,
        ),
      );
    },
  }), []);

  return (
    <SnackbarContext.Provider value={contextValue}>
      <Snackbar
        actionText={snackbarData.actionText}
        title={snackbarData.title}
        fail={snackbarData.fail}
        show={showSnackbar}
        onClose={() => {
          if (snackbarData?.onClose) snackbarData.onClose();
          clearTimeout(timer);
          setShowSnackbar(false);
        }}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, SnackbarContext };
