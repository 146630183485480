import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ContentWrapper,
  ContentHeader,
  Button,
} from '../../../ui';
import { StoreContext } from '../../contexts/StoreContext';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { useRedirect } from '../../../utils/redirect';
import { LoadingPage } from '../LoadingPage';
import { InstitutionsList } from './InstitutionsList';

const InstitutionsListPage = () => {
  const { storeId } = useParams();
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();

  const page = useMemo(() => {
    return (
      <Page variant="primary">
        <StoreMenu current={mainMenuOptions.institutions} />
        <ContentWrapper>
          <ContentHeader
            title="Gerenciamentos de instituições"
            subtitle="Controle todas os instituições"
            breadcrumbs={[
              {
                name: 'Empresas',
                link: '/store',
              },
              {
                name: 'Início',
                link: `/store/${store?.id}`,
              },
              {
                name: mainMenuOptions.institutions,
                link: `/store/${storeId}/institution`,
              },
            ]}
          />
          <InstitutionsList />
          <Button type="button" onClick={() => redirect.to(`/store/${store?.id}/institution/item`)}>Adicionar instituição</Button>
        </ContentWrapper>
      </Page>
    );
  }, [queryParams, store, storeId, redirect]);

  return page ? page : <LoadingPage />;
};

export { InstitutionsListPage };
