import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { ResidueModel } from '../../../../../common/models/ResidueModel';
import { ResidueCategoryModel } from '../../../../../common/models/ResidueCategoryModel';
import { ResidueSizeModel } from '../../../../../common/models/ResidueSizeModel';
import { ResidueTypeModel } from '../../../../../common/models/ResidueTypeModel';
import { useGraphQl } from '../../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { residueQuery, residuesCategoriesQuery, residuesSizesQuery, residuesTypesQuery } from '../../../queries';
import { ResidueCategory } from '../../../../../common/models/ResidueCategory';
import {
  Button,
  Card,
  Center,
  colors,
  Form,
  FormControl,
  GridContainer,
  GridItem,
  Padding,
  ProgressIndicator,
  Select,
  SpacedColumn,
  spaces,
  TextArea,
  TextField,
  Typography,
} from '../../../../ui';
import { MediaCard } from '../../medias';
import { StoreContext } from '../../../contexts/StoreContext';
import { MediaModel } from '../../../../../common/models';
import { createResidueMutation } from '../../../queries/createResidueMutation';
import { updateResidueMutation } from '../../../queries/updateResidueMutation';
import { CreateResidueInput } from '../../../../../common/models/CreateResidueInput';
import { UpdateResidueInput } from '../../../../../common/models/UpdateResidueInput';
import { MeasureUnitModel } from '../../../../../common/models/MeasureUnitModel';
import { findMeasureUnitQuery } from '../../../queries/findMeasureUnitQuery';
import { MeasureUnit } from '../../../../../common/models/MeasureUnit';
import { measureInputProps, yupMeasure, formatMeasure } from '../../../../utils/measure';
import { measureToNumber } from '../../../../../common/utils/measure';

const fabricResidueFormValidationSchema = yup.object({
  name: yup.string().required('O nome do resíduo é obrigatório.'),
  description: yup.string().optional(),
  sizeId: yup.number().typeError('Houve um erro na seleção do tamanho.').required('O tamanho do tecido é obrigatório.'),
  fabricTypeId: yup.number().typeError('Houve um erro na seleção do tipo de tecido.').required('O tipo do tecido é obrigatório.'),
  fabricSurfaceId: yup.number().typeError('Houve um erro na seleção da superfície do tecido.').required('A superfície do tecido é obrigatório.'),
  pictureId: yup.number().typeError('Valor da imagem inválido.').optional(),
  weight: yupMeasure().required('O peso dos resíduos é obrigatório.'),
  observation: yup.string().optional(),
});

const residueFabricType = [
  ResidueCategory.Malha,
  ResidueCategory.TecidoPlano,
  ResidueCategory.TecidoNaoTecidoTnt,
  ResidueCategory.MistoMalhaPlanoTnt,
] as const;

const residueFabricSurface = [
  ResidueCategory.TecidoLiso,
  ResidueCategory.TecidoEstampado,
  ResidueCategory.MistoLisoEstampado,
] as const;

const ResidueForm = () => {
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const { storeId, itemId } = useParams();
  const redirect = useRedirect();
  const [item, setItem] = useState<ResidueModel | null>(null);
  const [kgMeasureUnitId, setKgMeasureUnitId] = useState<number | null>(null);
  const [categories, setCategories] = useState<Array<ResidueCategoryModel>>([]);
  const [sizes, setSizes] = useState<Array<ResidueSizeModel>>([]);
  const [_types, setTypes] = useState<Array<ResidueTypeModel>>([]);

  const measureUnitRequest = useGraphQl<{ findMeasureUnit: MeasureUnitModel }>({
    query: findMeasureUnitQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const residueRequest = useGraphQl<{ residue: ResidueModel }>({
    query: residueQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const residuesCategoriesRequest = useGraphQl<{ residuesCategories: Array<ResidueCategoryModel> }>({
    query: residuesCategoriesQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const residuesSizesRequest = useGraphQl<{ residuesSizes: Array<ResidueSizeModel> }>({
    query: residuesSizesQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const residuesTypesRequest = useGraphQl<{ residuesTypes: Array<ResidueTypeModel> }>({
    query: residuesTypesQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createResidueRequest = useGraphQl<{ createResidue: ResidueModel }>({
    query: createResidueMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateResidueRequest = useGraphQl<{ updateResidue: ResidueModel }>({
    query: updateResidueMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const fabricTypeId = useMemo(() => item?.categories?.find(c => (residueFabricType as unknown as Array<ResidueCategory>).includes(c.name))?.id, [item]);

  const fabricSurfaceId = useMemo(() => item?.categories?.find(c => (residueFabricSurface as unknown as Array<ResidueCategory>).includes(c.name))?.id, [item]);

  const weight = useMemo(() => {
    const measure = item?.measures?.find(m => m.unit === MeasureUnit.kg);
    if (!measure) return undefined;
    return formatMeasure(measure.quantity.toString(), 'kg');
  }, [item]);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: item?.name,
      description: item?.description,
      sizeId: item?.size?.id,
      fabricTypeId,
      fabricSurfaceId,
      pictureId: item?.picture?.id,
      weight,
      observation: item?.inventoryUnitObservation,
    },
    resolver: yupResolver(fabricResidueFormValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  useEffect(() => {
    measureUnitRequest.invoke({
      variables: {
        name: MeasureUnit.kg,
      },
      onSuccess: ({ data: { findMeasureUnit } }) => {
        setKgMeasureUnitId(findMeasureUnit.id);
      },
      onFail: (err) => {
        addSnackbar({
          fail: true,
          title: `Erro ao carregar o resíduo. ${err.message}`,
          actionText: 'OK',
        });
      },
    });
  }, []);

  useEffect(() => {
    setValue('sizeId', item?.size?.id);
  }, [item]);

  useEffect(() => {
    setValue('fabricTypeId', fabricTypeId);
  }, [fabricTypeId]);

  useEffect(() => {
    setValue('fabricSurfaceId', fabricSurfaceId);
  }, [fabricSurfaceId]);

  useEffect(() => {
    residuesCategoriesRequest.invoke({
      onSuccess: ({ data: { residuesCategories } }) => {
        setCategories(residuesCategories);
      },
    });
  }, []);

  useEffect(() => {
    residuesSizesRequest.invoke({
      onSuccess: ({ data: { residuesSizes } }) => {
        setSizes(residuesSizes);
      },
    });
  }, []);

  useEffect(() => {
    residuesTypesRequest.invoke({
      onSuccess: ({ data: { residuesTypes } }) => {
        setTypes(residuesTypes);
      },
    });
  }, []);

  useEffect(() => {
    if (itemId) {
      residueRequest.invoke({
        variables: {
          id: parseInt(itemId),
          storeId: parseInt(String(storeId)),
        },
        onSuccess: ({ data: { residue } }) => {
          setItem(residue);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Erro ao carregar o resíduo. ${err.message}`,
            actionText: 'OK',
            fail: true,
          });
          redirect.to(`/store/${storeId}/residue`);
        },
      });
    }
  }, [itemId]);

  const fabricTypes = useMemo(() =>
    categories?.filter(c => (residueFabricType as unknown as Array<ResidueCategory>).includes(c.name)),
    [categories]);

  const fabricSurfaces = useMemo(() =>
    categories?.filter(c => (residueFabricSurface as unknown as Array<ResidueCategory>).includes(c.name)),
    [categories]);

  const onSubmit = useCallback((data: yup.InferType<typeof fabricResidueFormValidationSchema>) => {
    if (itemId) {
      const categoriesIds = [];

      if (data?.fabricTypeId) {
        categoriesIds.push(data?.fabricTypeId);
      }

      if (data?.fabricSurfaceId) {
        categoriesIds.push(data?.fabricSurfaceId);
      }

      const variables: UpdateResidueInput = {
        id: parseInt(String(itemId)),
        storeId: parseInt(String(storeId)),
        name: data?.name,
        residueSizeId: data?.sizeId,
        inventoryUnitObservation: data?.observation,
      };

      if (categoriesIds && categoriesIds.length > 0) {
        variables.categoriesIds = categoriesIds;
      }

      if (data?.weight && kgMeasureUnitId) {
        variables.measures = [{
          measureUnitId: kgMeasureUnitId,
          quantity: measureToNumber(data?.weight)[0],
        }];
      }

      updateResidueRequest.invoke({
        variables,
        onSuccess: ({ data: { updateResidue } }) => {
          if (!updateResidue) return;
          addSnackbar({
            title: `Item ${data.name} atualizado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/residue`);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Falha ao atualizar resíduo. ${err?.message}`,
            actionText: 'OK',
            fail: true,
          });
        },
      });
    } else {
      const variables: CreateResidueInput = {
        storeId: parseInt(String(storeId)),
        name: data?.name,
        residueSizeId: data?.sizeId,
        categoriesIds: [data?.fabricTypeId, data?.fabricSurfaceId],
        measures: [{
          measureUnitId: kgMeasureUnitId,
          quantity: measureToNumber(data?.weight)[0],
        }],
        inventoryUnitObservation: data?.observation,
      };

      createResidueRequest.invoke({
        variables,
        onSuccess: ({ data: { createResidue } }) => {
          if (!createResidue) return;
          addSnackbar({
            title: `Item ${data.name} criado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/residue`);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Falha ao criar resíduo. ${err?.message}`,
            actionText: 'OK',
            fail: true,
          });
        },
      });
    }
  }, [item, kgMeasureUnitId]);

  return itemId && !item ? (
    <Padding padding={`${spaces.huge} 0`}>
      <Center>
        <ProgressIndicator />
      </Center>
    </Padding>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Padding padding={`0 0 ${spaces.xLarge}`}>
        <Card padding={spaces.xLarge}>
          <FormControl>
            <Typography variant="title" color={colors.primary}>
              {itemId ? `Alterar ${item?.name ? `"${item?.name}"` : 'resíduo'}` : 'Criar novo tecido'}
            </Typography>
            <Typography variant="sublead" color={colors.black} margin={`${spaces.xSmall} 0 0`}>
              Dados de identificação
            </Typography>
          </FormControl>
          <FormControl>
            <GridContainer noPadding>
              <GridItem lg={3} md={4} sm={6}>
                <MediaCard
                  id={`${item?.id}ResidueMedia`}
                  uploadable
                  wide
                  store={store}
                  src={item?.picture?.presignedUrl ?? item?.picture?.url}
                  onChange={async ({ target: { value } }) => {
                    const media: MediaModel = JSON.parse(value) as MediaModel;
                    setValue('pictureId', media.id);
                    if (!item?.id) return;
                    updateResidueRequest.invoke({
                      variables: {
                        id: item?.id,
                        storeId: parseInt(storeId),
                        pictureId: media.id,
                      },
                      onFail: (err) => {
                        addSnackbar({
                          title: `Erro ao atualizar a imagem do item "${item?.name}". ${err.message}`,
                          actionText: 'OK',
                          fail: true,
                        });
                      },
                      onSuccess: () => {
                        addSnackbar({
                          title: `Imagem do item "${item?.name}" atualizada com sucesso.`,
                          actionText: 'OK',
                          fail: false,
                        });
                      },
                    });
                  }}
                />
              </GridItem>
              <GridItem lg={9} md={8} sm={12}>
                <SpacedColumn $gap={spaces.xLarge}>
                  <TextField
                    id="name"
                    type="text"
                    label="Nome / Identificação"
                    defaultValue={item?.name}
                    register={castedRegister}
                    errorMessage={errors.name?.message}
                    state={errors.name ? 'invalid' : 'neutral'}
                    required
                  />
                  <TextArea
                    id="description"
                    label="Descrição"
                    defaultValue={item?.description}
                    register={castedRegister}
                    errorMessage={errors.description?.message}
                    state={errors.description ? 'invalid' : 'neutral'}
                    rows={4}
                  />
                </SpacedColumn>
              </GridItem>
            </GridContainer>
          </FormControl>
        </Card>
      </Padding>
      <Padding padding={`0 0 ${spaces.xLarge}`}>
        <Card padding={spaces.xLarge}>
          <FormControl>
            <Typography variant="sublead" color={colors.black} margin={`${spaces.xLarge} 0 0`}>
              Características
            </Typography>
          </FormControl>
          <FormControl>
            <GridContainer noPadding>
              <GridItem lg={6} sm={12}>
                <Select
                  id="sizeId"
                  label="Tamanho do tecido"
                  placeholder="Selecione o tamanho do tecido..."
                  errorMessage={errors.sizeId?.message}
                  state={errors.sizeId ? 'invalid' : 'neutral'}
                  required
                  listItems={sizes ? sizes?.map((size) => ({
                    text: size.name,
                    value: size.id,
                  })) : []}
                  defaultValue={item?.size?.id}
                  onChange={(_, selectedItem) => setValue('sizeId', parseInt(selectedItem.value as string))}
                  loading={residuesSizesRequest.isFetching()}
                />
              </GridItem>
              <GridItem lg={6} sm={12}>
                <Select
                  id="fabricTypeId"
                  label="Tipo do tecido"
                  placeholder="Selecione o tipo de tecido..."
                  errorMessage={errors.fabricTypeId?.message}
                  state={errors.fabricTypeId ? 'invalid' : 'neutral'}
                  required
                  listItems={categories ? fabricTypes?.map((size) => ({
                    text: size.name,
                    value: size.id,
                  })) : []}
                  defaultValue={fabricTypeId}
                  onChange={(_, selectedItem) => setValue('fabricTypeId', parseInt(selectedItem.value as string))}
                  loading={residuesCategoriesRequest.isFetching()}
                />
              </GridItem>
              <GridItem lg={6} sm={12}>
                <Select
                  id="fabricSurfaceId"
                  label="Superfície do tecido"
                  placeholder="Selecione a superfície..."
                  errorMessage={errors.fabricSurfaceId?.message}
                  state={errors.fabricSurfaceId ? 'invalid' : 'neutral'}
                  required
                  listItems={categories ? fabricSurfaces?.map((size) => ({
                    text: size.name,
                    value: size.id,
                  })) : []}
                  defaultValue={fabricSurfaceId}
                  onChange={(_, selectedItem) => setValue('fabricSurfaceId', parseInt(selectedItem.value as string))}
                  loading={residuesCategoriesRequest.isFetching()}
                />
              </GridItem>
            </GridContainer>
          </FormControl>
        </Card>
      </Padding>
      <Padding padding={`0 0 ${spaces.xLarge}`}>
        <Card padding={spaces.xLarge}>
          <FormControl>
            <Typography variant="sublead" color={colors.black} margin={`${spaces.xLarge} 0 0`}>
              Informações de armazenamento
            </Typography>
          </FormControl>
          <FormControl>
            <GridContainer noPadding>
              <GridItem lg={6} sm={12}>
                <TextField
                  id="weight"
                  type="text"
                  label="Peso (kg)"
                  register={castedRegister}
                  defaultValue={weight}
                  errorMessage={errors.weight?.message}
                  state={errors.weight ? 'invalid' : 'neutral'}
                  required
                  {...measureInputProps('kg')}
                />
              </GridItem>
              <GridItem lg={6} sm={12}>
                <TextField
                  id="observation"
                  label="Observações"
                  type="text"
                  defaultValue={item?.inventoryUnitObservation}
                  register={castedRegister}
                  errorMessage={errors.observation?.message}
                  state={errors.observation ? 'invalid' : 'neutral'}
                />
              </GridItem>
            </GridContainer>
          </FormControl>
        </Card>
      </Padding>
      <GridContainer noPadding>
        <GridItem lg={6}>
          <Button
            variant="back"
            wide
            type="button"
            onClick={() => redirect.to(`/store/${storeId}/residue`)}
          >
            Voltar
          </Button>
        </GridItem>
        <GridItem lg={6}>
          <Button variant="default" wide type="submit">
            {itemId ? 'Atualizar' : 'Cadastrar'}
          </Button>
        </GridItem>
      </GridContainer>
    </Form >
  );
};
const ResidueFormWrapper = () => {
  return (
    <ResidueForm />
  );
};

export {
  ResidueFormWrapper,
};
