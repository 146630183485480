import validator from 'validator';
import * as yup from 'yup';
import { measureToString } from '../../common/utils/measure';

const measureInputProps = (unit: string) => ({ format: `##,### ${unit}`, mask: '_', helperText: `Formatação: 00,000 ${unit}` });

const yupMeasure = () =>
  yup
    .string()
    .typeError('Peso inválido.')
    .test('kgValidation', 'Peso inválido.', (value) => {
      return (
        typeof value !== 'undefined' ||
        value !== null ||
        !validator.isEmpty(String(value))
      );
    });

const formatMeasure = (value: string | null | undefined, unit: string) => value && measureToString(parseFloat(value), unit);

export {
  measureInputProps,
  formatMeasure,
  yupMeasure,
};
