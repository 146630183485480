import { useStateMachine } from '../../contexts/StateMachine';
import { Button, Typography, Padding, Center, EmailSended, colors } from '../../../ui';
import { authPageStateValues } from '../../../../common/models';
import { AuthStateMachineData } from './AuthPage';

const AuthPageSuccessFeedback = () => {
  const { setState, data: stateMachineData } = useStateMachine<AuthStateMachineData>();

  return (
    <>
      <Typography variant="subtitle" color={colors.primary} align="center">
        {stateMachineData.successTitle}
      </Typography>
      <Padding padding="40px 0 0">
        <Center>
          <EmailSended width={110} height={110} />
        </Center>
      </Padding>
      <Padding padding="40px 0">
        <Typography variant="paragraph" color={colors.primary} align="center">
          {stateMachineData.successBody}
        </Typography>
      </Padding>
      <Button
        variant="default"
        wide
        onClick={() => {
          setState(authPageStateValues.login);
        }}
      >
        OK
      </Button>
    </>
  );
};

export { AuthPageSuccessFeedback };
