const authPageStateValues = {
  login: 'login',
  loading: 'loading',
  error: 'error',
  signup: 'signup',
  password: 'password',
  success: 'success',
  newpass: 'newpass',
  confirm: 'confirm',
} as const;
type AuthPageState = keyof typeof authPageStateValues;

export type { AuthPageState };
export { authPageStateValues };
