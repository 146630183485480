import { useStateMachine } from '../../contexts/StateMachine';
import { Button, Typography, Padding, Center, ErrorSign, colors } from '../../../ui';
import { AuthStateMachineData } from './AuthPage/AuthPage';

const AuthPageErrorFeedback = () => {
  const { data, lastState, setState } = useStateMachine<AuthStateMachineData>();

  return (
    <>
      <Typography variant="subtitle" color={colors.primary} align="center">
        Houve um erro
      </Typography>
      <Padding padding="40px 0 0">
        <Center>
          <ErrorSign width={110} height={110} fill={colors.error} />
        </Center>
      </Padding>
      <Padding padding="40px 0">
        <Typography variant="paragraph" color={colors.primary} align="center">
          {data.errorMessage}
        </Typography>
      </Padding>
      <Button
        variant="default"
        wide
        onClick={() => {
          setState(lastState());
        }}
      >
        Retornar
      </Button>
    </>
  );
};

export { AuthPageErrorFeedback };
