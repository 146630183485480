import { Locales } from '../../contexts/LocalizationContext';

const locales: Locales = {
  default: 'pt-BR',
  langs: [
    {
      name: 'pt-BR',
      suitable: ['pt-PT', 'pt'],
    },
  ],
} as const;

export { locales };
