import React from 'react';
import { Body } from './Table.styles';
import { TableBodyProps } from './Table.types';
import { colors } from '../../tokens';

const TableBody = ({
  children,
  color = colors.white,
  hoverColor,
  ...props
}: TableBodyProps & { hoverColor?: string }) => {
  return (
    <Body color={color} $hoverColor={hoverColor} {...props}>
      {children}
    </Body>
  );
};

export { TableBody };
