import { styled } from 'styled-components';
import { Typography } from '../Typography';
import { spaces } from '../../tokens';

const Wrapper = styled.fieldset`
  border: none;
`;

const Legend = styled(Typography).attrs({
  variant: 'caption',
})`
  margin-bottom: ${spaces.medium};
`;

export { Wrapper, Legend };
