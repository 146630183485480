import {
  Card,
  Center,
  Padding,
  Typography,
  SizedContainer,
  Button,
  ErrorSign,
  colors,
} from '../../../ui';
import { useStateMachine } from '../../contexts/StateMachine';
import { storePageStateValues } from '../../../../common/models';
import { StoreStateMachineData } from './StorePage/StorePage';

const StorePageErrorFeedback = () => {
  const { setState, data } = useStateMachine<StoreStateMachineData>();

  return (
    <SizedContainer height="100vh">
      <Center>
        <Card>
          <Padding padding="40px">
            <Typography variant="lead" align="center" color={colors.primary}>
              Erro ao criar empresa
            </Typography>
            <Typography
              variant="paragraph"
              align="center"
              color={colors.primary}
            >
              {data.errorMessage}
            </Typography>
            <Padding padding="50px 0">
              <Center>
                <ErrorSign width={112} height={112} fill={colors.error} />
              </Center>
            </Padding>
            <Button
              variant="default"
              wide
              type="button"
              onClick={() => {
                setState(storePageStateValues.create);
              }}
            >
              Retornar
            </Button>
          </Padding>
        </Card>
      </Center>
    </SizedContainer>
  );
};

export { StorePageErrorFeedback };
