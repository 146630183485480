import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { DebugMenuButton, DebugMenuContainer, DebugMenuItem } from './DebugMenu.styles';
import { StageType } from '../../../utils/environment';

type DebugMenuProps = {
  icon: ReactNode;
  stage?: StageType;
  side?: 'left' | 'right';
  items: Array<{
    link: string,
    label: string,
  }>,
};

const DebugMenu = ({ icon, items, stage = 'dev', side = 'left' }: DebugMenuProps) => {
  const [opened, setOpened] = useState(false);

  const itemsLength = useMemo(() => items.length, [items]);

  const onClickHandler = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  return stage === 'local' ? (
    <>
      <DebugMenuButton $opened={opened} onClick={onClickHandler} $itemsLength={itemsLength} title="DEBUG" $side={side}>
        {icon}
      </DebugMenuButton>
      <DebugMenuContainer $opened={opened} $side={side}>
        {items.map((item) => (
          <DebugMenuItem key={item.label} href={item.link} target="_blank" $side={side}>
            {item.label}
          </DebugMenuItem>
        ))}
      </DebugMenuContainer>
    </>
  ) : null;
};

export type {
  StageType,
  DebugMenuProps,
};
export {
  DebugMenu,
};
