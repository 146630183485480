import React, { ReactNode, useState } from 'react';
import { useRedirect } from '../../../utils/redirect';
import { Menu as MenuIcon } from '../../icons';
import {
  MainMenuContainer,
  MainMenuOverlay,
  MainMenuButton,
  BodyReset,
} from './MainMenu.styles';
import { MainMenuItem } from './MainMenuItem';
import { Padding } from '../Padding';
import { colors, spaces } from '../../tokens';
import { Typography } from '../Typography';
import { Image } from '../Image';
import { Col } from '../Col';
import { Center } from '../Center';

type MainMenuOption = {
  name: string;
  icon: ReactNode;
  route: string;
  params?: { [key: string]: string };
  removeParams?: Array<string>;
};

type MainMenuProps = {
  current: string;
  onItemChange?: (selected: string) => void;
  options: Array<MainMenuOption>;
  logoSrc?: string;
  title?: string;
  homeLink?: string;
};

const MainMenu = ({
  current: currentProp,
  onItemChange,
  options,
  logoSrc,
  title,
  homeLink = '.',
}: MainMenuProps) => {
  const [current, setCurrent] = useState<string>(currentProp);
  const [visible, setVisible] = useState(false);
  const redirect = useRedirect();

  return (
    <>
      {visible && <BodyReset />}
      <MainMenuButton type="button" onClick={() => setVisible(!visible)}>
        <MenuIcon />
      </MainMenuButton>
      <MainMenuOverlay id="main-menu-overlay" $visible={visible} onClick={() => setVisible(false)} />
      <MainMenuContainer id="main-menu-container" $visible={visible}>
        {(title || logoSrc) && (
          <div onClick={() => redirect.to(homeLink)}>
            <Padding padding={`${spaces.xHuge} 0`}>
              {logoSrc && (
                <Padding padding={`${spaces.xHuge} 0`}>
                  <Col>
                    <Center>
                      <Image src={logoSrc} alt={`Logo da empresa ${title}`} height="100px" style={{ maxWidth: `calc(252px - (${spaces.large} * 2)` }} />
                    </Center>
                  </Col>
                </Padding>
              )}
              {title && (<Typography variant='lead' color={colors.white} align='center'>{title}</Typography>)}
            </Padding>
          </div>
        )}
        {options.map((option: MainMenuOption) => {
          return (
            <MainMenuItem
              key={option.name}
              title={option.name}
              icon={option.icon}
              selected={current === option.name}
              onClick={() => {
                setCurrent(option.name);
                if (onItemChange) onItemChange(option.name);
                setVisible(false);
                redirect.to(option.route, {
                  params: option.params,
                  removeParams: option.removeParams,
                });
              }}
            />
          );
        })}
      </MainMenuContainer >
    </>
  );
};

export { MainMenu };
