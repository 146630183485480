import React, { SVGProps } from 'react';

const ErrorSign = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 40 40" width={40} height={40} {...props}>
      <path
        d="M23,3A20,20,0,1,0,43,23,20.007,20.007,0,0,0,23,3Zm2,30H21V29h4Zm0-8H21V13h4Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export { ErrorSign };
