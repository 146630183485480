import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ContentWrapper,
  ContentHeader,
  Button,
} from '../../../ui';
import { StoreContext } from '../../contexts/StoreContext';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { useRedirect } from '../../../utils/redirect';
import { LoadingPage } from '../LoadingPage';
import { UnloadingLogList } from './UnloadingLogList';

const UnloadingLogsListPage = () => {
  const { storeId } = useParams();
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();

  const page = useMemo(() => {
    return (
      <Page variant="primary">
        <StoreMenu current={mainMenuOptions.unloadingLogs} />
        <ContentWrapper>
          <ContentHeader
            title="Gerenciamentos de registros de saída"
            subtitle="Controle todos os registros de saída"
            breadcrumbs={[
              {
                name: 'Empresas',
                link: '/store',
              },
              {
                name: 'Início',
                link: `/store/${store?.id}`,
              },
              {
                name: mainMenuOptions.unloadingLogs,
                link: `/store/${storeId}/unloadinglog`,
              },
            ]}
          />
          <UnloadingLogList />
          <Button type="button" onClick={() => redirect.to(`/store/${store?.id}/unloadinglog/item`)}>Adicionar registro de saída</Button>
        </ContentWrapper>
      </Page>
    );
  }, [queryParams, store, storeId, redirect]);

  return page ? page : <LoadingPage />;
};

export { UnloadingLogsListPage };
