import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import { ContentHeader, ContentWrapper } from '../../../ui';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { ProductProvider } from '../../contexts/ProductContext';
import { SpecSheetFormWrapper } from './SpecSheetForm';

const SpecSheetsListEditCreatePage = () => {
  const { storeId, itemId } = useParams();
  const { store } = useContext(StoreContext);

  return (
    <Page variant="primary">
      <StoreMenu current={mainMenuOptions.specSheets} />
      <ContentWrapper>
        <ContentHeader
          title={itemId ? 'Editar ficha-técnica' : 'Adicionar ficha-técnica'}
          subtitle={itemId ? 'Edite o produto e sua ficha-técnica' : 'Crie um produto novo e sua ficha-técnica'}
          breadcrumbs={[
            {
              name: 'Empresas',
              link: '/store',
            },
            {
              name: 'Início',
              link: `/store/${store?.id}`,
            },
            {
              name: mainMenuOptions.specSheets,
              link: `/store/${storeId}/specsheet`,
            },
            {
              name: !itemId ? 'Novo item' : 'Edição de ficha-técnica',
              link: !itemId ? `/store/${storeId}/specsheet/item` : `/store/${storeId}/specsheet/item/${itemId}`,
            },
          ]}
        />
        <ProductProvider>
          <SpecSheetFormWrapper />
        </ProductProvider>
      </ContentWrapper>
    </Page>
  );
};

export { SpecSheetsListEditCreatePage };
