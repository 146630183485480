import React, { Suspense, useContext, useEffect } from 'react';
import { StateMachine } from '../../../contexts/StateMachine';
import { Page } from '../../Page';
import { StorePageLoading } from '../StorePageLoading';
import { StoresList } from '../StoresList';
import { StoreCreatePage } from '../StoreCreatePage';
import { StorePageSuccessFeedback } from '../StorePageSuccessFeedback';
import { StorePageErrorFeedback } from '../StorePageErrorFeedback';
import { LoadingPage } from '../../LoadingPage';
import { AuthenticatorContext } from '../../../contexts/AuthenticatorContext';
import { useRedirect } from '../../../../utils/redirect';

type StoreStateMachineData = {
  errorMessage?: string,
  successTitle?: string,
  storeId?: string,
};

const StorePage = () => {
  const { currentUser } = useContext(AuthenticatorContext);
  const redirect = useRedirect();

  useEffect(() => {
    if (!currentUser?.completedOnboarding) {
      redirect.to('/onboarding');
      return;
    }
  }, [currentUser, redirect]);

  return currentUser?.completedOnboarding ? (
    <Page variant="primary" showAccountButton={false}>
      <StateMachine
        initialState="list"
        data={{
          errorMessage: undefined,
          successTitle: undefined,
          storeId: undefined,
        } as StoreStateMachineData}
        states={{
          list: () => (
            <Suspense fallback={<LoadingPage />}>
              <StoresList />
            </Suspense>
          ),
          create: () => <StoreCreatePage />,
          loading: () => <StorePageLoading />,
          success: () => <StorePageSuccessFeedback />,
          error: () => <StorePageErrorFeedback />,
        }}
      />
    </Page >
  ) : <LoadingPage />;
};

export type { StoreStateMachineData };
export { StorePage };
