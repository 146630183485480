import gql from 'graphql-tag';

const materialInventoryItemsQuery = gql`
  query ($storeId: Int!, $materialType: MaterialType!) {
    materialInventoryItems(storeId: $storeId, materialType: $materialType) {
      id
      itemRef
      itemName
      itemValue
      supplierName
      measures {
        unit
        customUnit
        quantity
      }
      itemColorName
      itemImage {
        id
        url
        presignedUrl
      }
    }
  }
`;

export { materialInventoryItemsQuery };
