import { rgba } from 'polished';
import { styled } from 'styled-components';
import { colors, opacity } from '../../tokens';

const IconButtonStyle = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${rgba(colors.secondary, opacity[4])};
  }
  svg {
    fill: ${colors.secondary};
  }
`;

export { IconButtonStyle };
