import React, { useState } from 'react';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { EyeIcon, EyeOffIcon } from '../../../ui';
import {
  Button,
  Form,
  FormControl,
  Padding,
  TextField,
  Typography,
  GridContainer,
  GridItem,
  colors,
} from '../../../ui';
import { useStateMachine } from '../../contexts/StateMachine';
import { signUpQuery } from '../../queries';
import { AdminUserModel, authPageStateValues } from '../../../../common/models';
import { AuthStateMachineData } from './AuthPage';
import { useGraphQl } from '../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../utils/apiEndpoints';

const signUpValidationSchema = yup.object({
  name: yup.string().required('O nome completo é obrigatório'),
  userName: yup.string().required('O e-mail é obrigatório'),
  password: yup.string().required('A senha é obrigatória'),
  confirmPassword: yup
    .string()
    .required('A confirmação da senha é obrigatória')
    .oneOf([yup.ref('password')], 'As senhas devem ser iguais!'),
});

const SignUpForm = () => {
  const { data: stateMachineData, setState } = useStateMachine<AuthStateMachineData>();
  const [hidePassword, setHidePassword] = useState(true);
  const signUpRequest = useGraphQl<{ signUp: AdminUserModel }>({
    query: signUpQuery,
    url: getApiEndpoint({ route: apiRoutes.auth }),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      userName: stateMachineData.userName,
      name: undefined,
      password: undefined,
      confirmPassword: undefined,
    },
    resolver: yupResolver(signUpValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  const onSubmit = (data: {
    name: string;
    userName: string;
    password: string;
    confirmPassword: string;
  }) => {
    setState(authPageStateValues.loading, { userName: data.userName });

    signUpRequest.invoke({
      variables: data,
      onFail: (signUpError) => {
        setState(authPageStateValues.error, {
          errorMessage: signUpError?.message || 'Houve um erro desconhecido.',
        });
      },
      onSuccess: ({ data: signUpData }) => {
        if (!signUpData.signUp) return;
        setState(authPageStateValues.success, {
          successTitle: 'Cadastro efetuado!',
          successBody:
            'Foi enviado para você um e-mail de confirmação. Você já pode logar com seu cadastro, mas é necessário que você confirme seu e-mail para ter acesso a todos os recursos da plataforma.',
        });
      },
    });
  };

  return (
    <Padding padding="20px 0" wide>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Typography variant="subtitle" color={colors.primary} align="center">
            Cadastrar-se
          </Typography>
        </FormControl>
        <FormControl>
          <TextField
            id="name"
            label="nome"
            register={castedRegister}
            errorMessage={errors.name?.message}
            state={errors.name ? 'invalid' : 'neutral'}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="userName"
            label="e-mail"
            type="email"
            register={castedRegister}
            errorMessage={errors.userName?.message}
            state={errors.userName ? 'invalid' : 'neutral'}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="password"
            type={hidePassword ? 'password' : 'text'}
            label="senha"
            register={castedRegister}
            errorMessage={errors.password?.message}
            state={errors.password ? 'invalid' : 'neutral'}
            iconButton={{
              icon: hidePassword ? <EyeOffIcon /> : <EyeIcon />,
              label: hidePassword ? 'exibir senha' : 'esconder senha',
              onClick: () => setHidePassword(!hidePassword),
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="confirmPassword"
            type={hidePassword ? 'password' : 'text'}
            label="confirmar senha"
            register={castedRegister}
            errorMessage={errors.confirmPassword?.message}
            state={errors.confirmPassword ? 'invalid' : 'neutral'}
            iconButton={{
              icon: hidePassword ? <EyeOffIcon /> : <EyeIcon />,
              label: hidePassword ? 'exibir senha' : 'esconder senha',
              onClick: () => setHidePassword(!hidePassword),
            }}
          />
        </FormControl>
        <GridContainer noPadding>
          <GridItem lg={6}>
            <FormControl>
              <Button
                variant="back"
                wide
                type="button"
                onClick={() => {
                  setState(authPageStateValues.login);
                }}
              >
                Voltar
              </Button>
            </FormControl>
          </GridItem>
          <GridItem lg={6}>
            <FormControl>
              <Button variant="default" wide type="submit">
                Cadastrar-se
              </Button>
            </FormControl>
          </GridItem>
        </GridContainer>
      </Form>
    </Padding>
  );
};

export { SignUpForm };
