import { styled } from 'styled-components';
import { spaces } from '../../tokens';

const BreadcrumbsContainer = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    margin-top: ${spaces.xxSmall};
    margin-left: -6px;
  }

  & > *:first-child {
    margin-left: 0;
  }
`;

export { BreadcrumbsContainer };
