import validator from 'validator';

let cpfValidator = null;

import('cpf-cnpj-validator').then(m => {
  cpfValidator = m.cpf;
});

const validateCpf = (cpf?: string | number): boolean => {
  return cpfValidator?.isValid(String(cpf).toString());
};


const cleanupCpf = (value: string) => {
  if (!value || validator.isEmpty(value)) return undefined;
  return value?.replace(/[^\d]/g, '');
};

const formatCpf = (value: string): string => {
  return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export {
  cleanupCpf,
  validateCpf,
  formatCpf,
};
