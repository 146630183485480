import gql from 'graphql-tag';

const updateProductMutation = gql`
  mutation(
    $input: ProductUpdateInput!
  ) {
    updateProduct(
      input: $input
    ) {
      id
      name
      description
      cover {
        id
        url
        name
      }
      active
      garmentSpecSheet {
        id
        collection {
          id
          guid
          createdAt
          updatedAt
          name
        }
        technicalDraw {
          id
          url
          name
        }
        garmentSizes {
          id
          label
        }
        colors {
          id
          label
          r
          g
          b
        }
        medias {
          id
          url
          name
        }
      }
      productMedias {
          id
          url
          name
      }
    }
  }
`;

export {
  updateProductMutation,
};
