import { patternFormatter } from 'react-number-format';
import * as yup from 'yup';

const dateInputProps = { format: '##/##/####', mask: '_', min: 8, max: 8 };

const yupDate = () =>
  yup
    .string()
    .typeError('Data inválida.')
    .matches(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/([0-9]{4})$/gm, 'A data deve seguir o formato: dd/mm/aaaa')
    .max(8 + 2)
    .min(8 + 2);

const formatDate = (value: string | null | undefined) => value && patternFormatter(value, dateInputProps);

export {
  dateInputProps,
  yupDate,
  formatDate,
};
