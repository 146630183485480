import {
  Card,
  Center,
  Padding,
  SizedContainer,
} from '../../../ui';
import { useStateMachine } from '../../contexts/StateMachine';
import { StoreCreateForm } from './StoreCreateForm';
import { storePageStateValues } from '../../../../common/models';

const StoreCreatePage = () => {
  const { setState } = useStateMachine();

  return (
    <Padding padding="140px 0 40px">
      <SizedContainer>
        <Center>
          <Card width="800px" padding="40px" margin={40} nWidth={800}>
            <StoreCreateForm
              onSubmitStarted={() => setState(storePageStateValues.loading)}
              onSubmitSucceeded={(store) => {
                setState(storePageStateValues.success, {
                  storeId: store?.id,
                  successTitle: 'Empresa criada com sucesso!',
                });
              }}
              onSubmitFailed={(err) => {
                setState(storePageStateValues.error, {
                  errorMessage: err?.message,
                });
              }}
              onExitForm={() => setState(storePageStateValues.list)}
            />
          </Card>
        </Center>
      </SizedContainer>
    </Padding>
  );
};

export { StoreCreatePage };
