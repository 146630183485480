import gql from 'graphql-tag';

const updateResidueMutation = gql`
  mutation (
    $id: Int!
    $storeId: Int!
    $name: String
    $residueSizeId: Int
    $categoriesIds: [Int]
    $measures: [MeasureInput]
    $description: String
    $pictureId: Int
    $inventoryUnitObservation: String
  ) {
  updateResidue(
    id: $id
    storeId: $storeId
    name: $name
    residueSizeId: $residueSizeId
    categoriesIds: $categoriesIds
    measures: $measures
    description: $description
    pictureId: $pictureId
    inventoryUnitObservation: $inventoryUnitObservation
  ) {
      id
      guid
      createdAt
      updatedAt
      name
      type {
        id
        name
      }
      description
      active
      picture {
        id
        url
        presignedUrl
      }
      categories {
        id
        guid
        createdAt
        updatedAt
        name
      }
      inventoryUnitObservation
      size {
        id
        guid
        createdAt
        updatedAt
        name
      }
      measures {
        unit
        customUnit
        quantity
      }
    }
  }
`;

export { updateResidueMutation };
