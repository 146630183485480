import React, { SVGProps } from 'react';

const CheckboxIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 27 27" width={27} height={27} {...props}>
      <path
        d="M19,0H8C3.6,0,0,3.6,0,8v11c0,4.4,3.6,8,8,8h11c4.4,0,8-3.6,8-8V8C27,3.6,23.4,0,19,0z M11,20l-6.2-6.2L6.6,12l4.5,4.5
	L20.5,7l1.8,1.8L11,20z"
      />
    </svg>
  );
};

export { CheckboxIcon };
