import { styled } from 'styled-components';
import { colors, spaces } from '../../tokens';
import { InputWrapper } from '../TextField/TextField.styles';
import { Button } from '../Button';

const AddTagInputRoot = styled.div`
  position: relative;
  overflow: hidden;
`;

const TagInputWrapper = styled(InputWrapper)`
  width: 90%;
`;

const AddButton = styled(Button).attrs({
  variant: 'default',
  loading: false,
  disabled: false,
  wide: false,
  noBorder: false,
  textVariant: 'button',
  icon: undefined,
  width: 'auto',
  float: 'none',
})`
  height: 100%;
  border: none;
  position: absolute;
  color: ${colors.white};
  right: 0;
  top: 0;
  z-index: 700;
  padding: 0 ${spaces.large};
  border-radius: 0 8px 8px 0;
  cursor: pointer;
`;

const TagItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TagItem = styled.div`
  background-color: ${colors.backButton};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${spaces.xSmall} ${spaces.xSmall} ${spaces.xSmall} 0;
  padding: ${spaces.xSmall};
`;

export {
  AddButton,
  AddTagInputRoot,
  TagItem,
  TagItemsContainer,
  TagInputWrapper,
};
