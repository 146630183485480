type MimeTypes =
  | 'text/html'
  | 'text/css'
  | 'application/javascript'
  | 'application/json'
  | 'image/png'
  | 'image/jpeg'
  | 'image/gif'
  | 'image/svg+xml'
  | 'text/plain'
  | 'application/pdf'
  | 'application/xml'
  | 'application/zip'
  | 'video/mp4'
  | 'audio/mpeg'
  | 'application/octet-stream';

const mimeTypes: { [ext: string]: MimeTypes } = {
  '.html': 'text/html',
  '.css': 'text/css',
  '.js': 'application/javascript',
  '.json': 'application/json',
  '.png': 'image/png',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.gif': 'image/gif',
  '.svg': 'image/svg+xml',
  '.txt': 'text/plain',
  '.pdf': 'application/pdf',
  '.xml': 'application/xml',
  '.zip': 'application/zip',
  '.mp4': 'video/mp4',
  '.mp3': 'audio/mpeg',
} as const;

type FileExtensions = keyof typeof mimeTypes;

const mimeTypeFromExtension = (ext: FileExtensions): MimeTypes => mimeTypes[ext] || 'application/octet-stream';

const getMediaTypeFromMimeType = (mimeType: MimeTypes | string): string => (mimeType as MimeTypes).split('/')[0];

const mediaTypes = Object.values(mimeTypes).map(getMediaTypeFromMimeType);

export { mimeTypeFromExtension, getMediaTypeFromMimeType, mediaTypes };
