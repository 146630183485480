import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ContentWrapper,
  ContentHeader,
  Button,
} from '../../../ui';
import { StoreContext } from '../../contexts/StoreContext';
import { mainMenuOptions, OptionsType, StoreMenu } from '../stores';
import { Page } from '../Page';
import { InventoryList } from './InventoryList';
import { useRedirect } from '../../../utils/redirect';
import { LoadingPage } from '../LoadingPage';

const InventoryListPage = () => {
  const { storeId } = useParams();
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();

  const page = useMemo(() => {
    let name: OptionsType = mainMenuOptions.storage;

    switch (queryParams.get('type')?.toLowerCase()) {
      case 'aviamento':
        name = mainMenuOptions.trims;
        break;
      case 'tecido':
        name = mainMenuOptions.fabrics;
        break;
      default:
        name = mainMenuOptions.storage;
    }

    return (
      <Page variant="primary">
        <StoreMenu current={name} />
        <ContentWrapper>
          <ContentHeader
            title={`Gerenciamento de estoque (${name})`}
            subtitle="Controle a quantidade de seus insumos armazenados"
            breadcrumbs={[
              {
                name: 'Empresas',
                link: '/store',
                redirectOptions: {
                  removeParams: ['type'],
                },
              },
              {
                name: 'Início',
                link: `/store/${store?.id}`,
                redirectOptions: {
                  removeParams: ['type'],
                },
              },
              {
                name,
                link: `/store/${storeId}/storage`,
              },
            ]}
          />
          <InventoryList />
          <Button type="button" onClick={() => redirect.to(`/store/${store?.id}/storage/item`)}>Adicionar insumo ao estoque</Button>
        </ContentWrapper>
      </Page>
    );
  }, [queryParams, store, storeId, redirect]);

  return page ? page : <LoadingPage />;
};

export { InventoryListPage };
