import gql from 'graphql-tag';

const createUserMediaMutation = gql`
  mutation (
    $url: String!
    $name: String!
    $mimeType: String!
    $alt: String
  ) {
    createUserMedia(
      url: $url
      name: $name
      mimeType: $mimeType
      alt: $alt
    ) {
      id
      guid
      createdAt
      updatedAt
      url
      name
      alt
      mimeType
      type
    }
  }
`;

export { createUserMediaMutation };
