import React, { SVGProps } from 'react';

const TrashBin = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" height={24} width={24} {...props}>
      <g>
        <path d="M22.1,4.1c-0.9,0-1.8,0-2.8,0l-1.5,0l0-0.6c0-0.2,0-0.5,0-0.7c0-1.6-1.2-2.8-2.8-2.8C13,0,11,0,8.9,0
		C7.4,0,6.1,1.2,6.1,2.8c0,0.2,0,0.4,0,0.7l0,0.6l-1.5,0c-1,0-1.9,0-2.8,0C1.3,4.1,1,4.4,1,4.8c0,0.5,0.3,0.8,0.8,0.8
		c0.2,0,0.4,0,0.6,0l0.3,0h0.3V21c0,1.9,1.2,3,3,3h11.8c1.9,0,3-1.2,3-3l0-15.3l0.6,0c0.2,0,0.5,0,0.7,0c0.2,0,0.4-0.1,0.6-0.3
		C22.9,5.2,23,4.9,23,4.7v0C22.9,4.2,22.4,4.1,22.1,4.1z M7.7,2.7L7.7,2.7C7.7,2,8.2,1.5,9,1.5c2.1,0,4.1,0,6.1,0
		c0.7,0,1.2,0.5,1.3,1.3c0,0.2,0,0.4,0,0.7l0,0.6H7.7V3.8c0-0.1,0-0.2,0-0.4C7.7,3.2,7.6,2.9,7.7,2.7z M19.4,20.9
		c0,1.1-0.5,1.6-1.6,1.6H6.2c-1.1,0-1.6-0.5-1.6-1.6V6.4c0-0.1,0-0.2,0-0.3l0-0.4h14.8L19.4,20.9z"/>
        <path d="M8.2,14.9c0,0.7,0,1.4,0,2.1c0,0.5,0.3,0.8,0.8,0.8c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-1.7,0-3.4,0-5.1
		l0-0.9c0-0.3-0.1-0.6-0.4-0.7c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.4,0.4-0.4,0.8c0,0.7,0,1.4,0,2.1l0,0.9
		L8.2,14.9z"/>
        <path d="M14.3,14.9c0,0.7,0,1.4,0,2.1c0,0.5,0.3,0.8,0.8,0.8c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-1.7,0-3.3,0-5l0-1
		c0-0.3-0.1-0.6-0.4-0.7c-0.2-0.1-0.5-0.1-0.7,0c-0.3,0.1-0.4,0.4-0.4,0.8c0,0.7,0,1.4,0,2.1l0,0.9L14.3,14.9z"/>
      </g>
    </svg>
  );
};

export { TrashBin };
