import gql from 'graphql-tag';

export const createLogMutation = gql`
  mutation(
    $storeId: Int!
    $institutionId: Int!
    $data: CreateResiduesUnloadingLogMeasuresInput!
  ) {
    createLog(
      storeId: $storeId
      institutionId: $institutionId
      data: $data
    ) {
      id
      guid
      createdAt
      updatedAt
      invalidated
      observation
      actor {
        id
        name
      }
      institution {
        id
        name
        cnpj {
          value
        }
      }
      measureUnitResidueInventoryUnitRelation {
        quantity
        measureUnitName
      }
      residue {
        id
        name
      }
    }
  }
`;
