import { styled } from 'styled-components';
import { colors, spaces, zIndex } from '../../tokens';

const DebugMenuButton = styled.button<{ $opened: boolean, $itemsLength: number, $side: 'left' | 'right' }>`
  background-color: ${colors.primary};
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: fixed;
  bottom: ${({ $opened, $itemsLength }) => $opened ? `${($itemsLength * (24 + 8)) + 18 + 10}px` : '10px'};
  ${({ $side }) => $side}: 0;
  cursor: pointer;
  padding: ${spaces.medium};
  border: none;
  border-radius: ${({ $side }) => $side === 'left' ? `0 ${spaces.small} ${spaces.small} 0` : `${spaces.small} 0 0 ${spaces.small}`};
  box-sizing: content-box;
  z-index: ${zIndex.debug};
  transition: bottom 0.2s ease;

  & svg {
    width: 20px;
    height: 20px;
  }
`;

const DebugMenuContainer = styled.div<{ $opened: boolean, $side: 'left' | 'right' }>`
  position: fixed;
  ${({ $side, $opened }) => `${$side}: ${$opened ? '0' : '-500px'}`};
  bottom: 10px;
  min-width: 200px;
  transition: ${`${({ $side }) => $side} 0.2s ease`};
  z-index: ${zIndex.debug};
  display: flex;
  flex-direction: column;
`;

const DebugMenuItem = styled.a<{ $side: 'left' | 'right' }>`
  background-color: ${colors.primaryLight};
  height: ${spaces.xLarge};
  padding: ${spaces.xSmall} ${spaces.medium} 0;
  box-sizing: content-box;
  ${({ $side }) => `border-${$side === 'left' ? 'right' : 'left'}:  3px solid ${colors.primary}`};

  &:hover {
    text-decoration: underline;
  }

  &:first-child {
    padding-top: ${spaces.small};
    border-radius:  ${({ $side }) => $side === 'left' ? `0 ${spaces.small} 0 0` : `${spaces.small} 0 0 0`};
    border-top: 2px solid ${colors.primary};
  }

  &:last-child {
    border-radius:  ${({ $side }) => $side === 'left' ? `0 0 ${spaces.small} 0` : `0 0 0 ${spaces.small}`};
    padding-bottom: ${spaces.small};
    border-bottom: 2px solid ${colors.primary};
  }
`;

export {
  DebugMenuButton,
  DebugMenuContainer,
  DebugMenuItem,
};
