import gql from 'graphql-tag';

const productQuery = gql`
  query(
    $id: Int!
    $storeId: Int!
  ) {
    product(
      id: $id
      storeId: $storeId
    ) {
      id
      name
      description
      cover {
        id
        url
        name
      }
      active
      garmentSpecSheet {
        id
        createdBy {
          id
          name
        }
        collection {
          id
          guid
          createdAt
          updatedAt
          name
        }
        technicalDraw {
          id
          url
          name
        }
        materials {
          id
          itemRef
          itemType
          itemName
          itemValue
          supplierName
          measures {
            unit
            customUnit
            quantity
          }
          supplierName
          trimMaterialType
          itemColorName
          itemColorValue
          productQuantities {
            unit
            customUnit
            quantity
          }
        }
        garmentSizes {
          id
          label
        }
        colors {
          id
          label
          r
          g
          b
        }
        medias {
          id
          url
          name
        }
      }
      productMedias {
          id
          url
          name
      }
    }
  }
`;

export {
  productQuery,
};
