import { styled, createGlobalStyle } from 'styled-components';
import { colors, spaces } from '../../tokens';
import { ReactNode } from 'react';

const Container = styled.div<{
  children: ReactNode;
  $backgroundColor: string;
  $height: string;
}>`
  margin: 0 auto;
  height: ${({ $height }) => $height};
  min-height: 100vh;
  padding: ${spaces.none};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  overflow-x: hidden;
`;

const WhiteBodyReset = createGlobalStyle`
  body {
    background-color: ${colors.white};
  }
`;

const PrimaryBodyReset = createGlobalStyle`
  body {
    background-color: ${colors.backgroundPrimary};
  }
`;

export { Container, WhiteBodyReset, PrimaryBodyReset };
