import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  UseFormRegister,
} from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ptBR, pt, enUS, es } from 'date-fns/locale';
import {
  HintsWrapper,
  InputLabel,
  InputMessage,
  InputRoot,
  InputWrapper,
  ValidState,
} from '../TextField';
import { Locale as MecenaLocale } from '../../../../common/models';
import { dateInputProps } from '../../../utils/date';
import { ProgressIndicator } from '../ProgressIndicator';
import { DatePickerWrapper } from './DatePicker.styles';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps<T> {
  id: string;
  label: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl>;
  helperText?: string;
  prefix?: string;
  disabled?: boolean;
  state?: keyof typeof ValidState;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  onChange?: (date: string) => void;
  locale?: MecenaLocale;
  register?: UseFormRegister<T | FieldValues>;
}

const DatePicker = <T,>({
  id,
  label,
  errorMessage = '',
  helperText = 'Formatação: dd/mm/aaaa',
  disabled = false,
  state = 'neutral',
  placeholder,
  required = false,
  defaultValue,
  locale = MecenaLocale.ptBR,
  register,
  onChange,
}: DatePickerProps<T>) => {
  const [value, setValue] = useState<Date>(defaultValue ? new Date(defaultValue) : undefined);
  const [isFocused, setIsFocused] = useState(false);

  const isValid = useMemo(() => state === 'valid', [state]);
  const isInvalid = useMemo(() => state === 'invalid', [state]);
  const isActive = useMemo(() => value !== undefined || isFocused, [value, isFocused]);

  const showInputMessage = useMemo(
    () => isInvalid || helperText !== '',
    [isInvalid, helperText],
  );

  const inputMessage = useMemo(
    () => (isInvalid ? errorMessage.toString() : helperText),
    [isInvalid, errorMessage, helperText],
  );

  const hasHint = useMemo(
    () => showInputMessage,
    [showInputMessage],
  );

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnChange = useCallback((date: Date | Array<Date>) => {
    if (Array.isArray(date)) return;
    setValue(date);
    if (onChange) {
      onChange(date ? new Intl.DateTimeFormat(locale).format(date) : undefined);
    }
  }, [onChange, setValue]);

  const locales = useMemo(() => {
    registerLocale('pt-BR', ptBR);
    const locs = {};
    locs[MecenaLocale.ptBR] = ptBR;
    locs[MecenaLocale.enUS] = enUS;
    locs[MecenaLocale.es] = es;
    locs[MecenaLocale.pt] = pt;
    return locs;
  }, [ptBR, pt, enUS, es]);

  useEffect(() => {
    if (locale && locales) {
      for (const loc of Object.keys(locales)) {
        registerLocale(loc, locales[loc]);
      }
      setDefaultLocale(locale);
    } else {
      registerLocale('pt-BR', ptBR);
    }
  }, [locale, locales]);

  const restWithRegister = useMemo(() => typeof register === 'function' ? {
    ...(register as UseFormRegister<FieldValues>)(id, {
      onBlur: handleOnBlur,
      required,
    }),
  } : {
    onBlur: handleOnBlur,
    required,
  }, [register]);

  return (
    locales ? (
      <InputRoot>
        <InputWrapper $isInvalid={isInvalid} $isValid={isValid} disabled={disabled}>
          <DatePickerWrapper>
            <PatternFormat
              {...dateInputProps}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              defaultValue={value ? new Intl.DateTimeFormat(locale).format(value) : undefined}
              disabled={disabled}
              required={required}
              onValueChange={(values) => {
                if (onChange) {
                  onChange(values.formattedValue);
                }
              }}
              customInput={(inputProps) => (
                <>
                  <ReactDatePicker
                    onBlur={inputProps.onBlur}
                    onFocus={inputProps.onFocus}
                    selected={value}
                    selectsMultiple={undefined}
                    dateFormat="dd/MM/yyyy"
                    locale={locale ?? 'pt-BR'}
                    onChange={handleOnChange}
                    onChangeRaw={(e?: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
                      if (e && e.type === 'change') {
                        const event = e as unknown as ChangeEvent<HTMLInputElement>;
                        inputProps.onChange(event);
                      }
                    }}
                    disabled={inputProps.disabled}
                    required={inputProps.required}
                  />
                  <input
                    id={id}
                    name={id}
                    {...restWithRegister}
                    {...inputProps}
                    type="hidden"
                  />
                </>
              )}
            />
          </DatePickerWrapper>
          <InputLabel
            htmlFor={id}
            $isInvalid={isInvalid}
            $isValid={isValid}
            disabled={disabled}
            $isActive={isActive}
            $placeholder={placeholder}
          >
            {label}
            {required && ' *'}
          </InputLabel>
        </InputWrapper>
        {hasHint && (
          <HintsWrapper $isInvalid={isInvalid}>
            <InputMessage>{inputMessage}</InputMessage>
          </HintsWrapper>
        )}
      </InputRoot>
    ) : (
      <ProgressIndicator />
    )
  );
};

export type {
  DatePickerProps,
};
export {
  DatePicker,
};
