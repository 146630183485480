import React, { SVGProps } from 'react';

const ExitLine = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
      <path d="M5,21c-0.5,0-1-0.2-1.4-0.6S3,19.5,3,19V5c0-0.5,0.2-1,0.6-1.4S4.5,3,5,3h6.3C11.7,3,12,3.3,12,3.7v0.5
	C12,4.7,11.7,5,11.3,5H5.4C5.2,5,5,5.2,5,5.4v13.1C5,18.8,5.2,19,5.4,19h5.8c0.4,0,0.7,0.3,0.7,0.7v0.5c0,0.4-0.3,0.7-0.7,0.7H5z
	 M16,17l-1.4-1.4l2.6-2.6H9v-2h8.2l-2.6-2.6L16,7l5,5L16,17z"/>
    </svg>
  );
};

export { ExitLine };
