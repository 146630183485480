import { styled } from 'styled-components';
import { screens } from '../../tokens';

const MansoryGridStyle = styled.div<{ gutter: string; columnCount: number }>`
  width: 100%;
  height: auto;
  column-count: ${({ columnCount }) => columnCount};
  column-gap: ${({ gutter }) => gutter};

  & > * {
    margin-bottom: ${({ gutter }) => gutter};
    display: block;
    break-inside: avoid;
  }

  @media screen and (max-width: ${screens.md}) {
    column-count: 1;
  }
`;

export { MansoryGridStyle };
