import gql from 'graphql-tag';

const institutionQuery = gql`
  query (
    $id: Int!
    $storeId: Int!
  ) {
    institution(
      id: $id
      storeId: $storeId
    ) {
      id
      guid
      createdAt
      updatedAt
      name
      active
      type {
        id
        type
      }
      motivation
      observation
      email
      phoneNumber
      personOfContact
      cpf
      cnpj {
        id
        value
      }
      address {
        id
        country {
          id
          abbreviation
          countryLocalizations {
            id
            countryName
            language {
              id
              langCode
            }
          }
        }
        state {
          id
          stateName
          abbreviation
        }
      }
      categories {
        id
        name
      }
      sizes {
        id
        name
      }
    }
  }
`;

export { institutionQuery };
