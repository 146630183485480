import {
  Button,
  Center,
  CheckCircle,
  colors,
  Padding,
  SizedContainer,
  spaces,
  Typography,
} from '../../../../ui';
import { Step } from '../OnboardingPage';

const SuccessFeedback = ({ step, handleCompleted }: { step: Step, handleCompleted: () => void }) => {
  return (
    <SizedContainer>
      <Padding padding={spaces.huge}>
        <Typography variant="lead" align="center" color={colors.primary}>
          {step.successTitle}
        </Typography>
        <Padding padding={`${spaces.huge} 0`}>
          <Center>
            <CheckCircle width={112} height={112} fill={colors.success} />
          </Center>
        </Padding>
        <Padding padding="auto" wide>
          <Center>
            <Button
              width="300px"
              maxWidth="100%"
              variant="default"
              type="submit"
              onClick={handleCompleted}
            >
              Continuar
            </Button>
          </Center>
        </Padding>
      </Padding>
    </SizedContainer>
  );
};

export { SuccessFeedback };
