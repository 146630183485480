import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  UseFormRegister,
  FieldValues,
} from 'react-hook-form';
import {
  Button,
  Center,
  GridContainer,
  GridItem,
  ListItem,
  Padding,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '..';
import {
  colors,
  fontWeight,
  spaces,
} from '../../tokens';
import {
  DynamicTableContainer,
  DynamicTableEmptyContainer,
  TableWrapper,
} from './DynamicTable.styles';
import { TrashBin } from '../../icons';
import { LoadingRect } from '../LoadingRect';

interface DynamicTableProps<T> {
  id: string;
  label: string;
  register?: UseFormRegister<T | FieldValues>;
  placeholder?: string;
  required?: boolean;
  availableItems: Array<T>;
  initialItems: Array<T>;
  title: string;
  tableProcessor: Record<string, (item: T) => ReactNode>;
  optionProcessor: (item: T) => { key: string, text: string };
  onTableItemsChange?: (items: Array<T>) => void;
  onAddItem?: (item: T) => void;
  onRemoveItem?: (item: T) => void;
  addButtonText?: string;
  emptyStateText?: string;
  loading?: boolean;
  searchable?: boolean;
  tableLayout?: 'fixed' | 'auto';
}

const DynamicTable = <T,>({
  id,
  label,
  register,
  required = false,
  availableItems,
  initialItems,
  title,
  addButtonText,
  tableProcessor,
  optionProcessor,
  onTableItemsChange,
  onAddItem,
  onRemoveItem,
  emptyStateText = 'Nenhum item adicionado.',
  loading = false,
  searchable = false,
  tableLayout = 'fixed',
}: DynamicTableProps<T>) => {
  const [selectedItem, setSelectedItem] = useState<ListItem>({ value: '', text: '' });
  const [items, setItems] = useState(initialItems);
  const [valueKey, setValueKey] = useState<string>(null);

  const listItems = useMemo(() => availableItems.map((item) => {
    const { key, text } = optionProcessor(item);
    setValueKey(key);
    return {
      value: item[key],
      text,
    };
  }), [availableItems]);

  const addItem = useCallback(() => {
    const values = items?.map((item) => item[valueKey]);
    const newItem = availableItems.find((item) => item[valueKey] === selectedItem?.value);

    if (!newItem) {
      return;
    }

    if (values?.includes(newItem[valueKey])) {
      return;
    }

    setItems([
      ...(items ?? []),
      newItem,
    ]);
    onAddItem(newItem);
  }, [availableItems, valueKey, items, selectedItem]);

  const removeItem = useCallback((value: unknown) => {
    const toRemove = items.find((item) => item[valueKey] === value);
    onRemoveItem(toRemove);
    setItems(items.filter((item) => item[valueKey] !== value));
  }, [items, valueKey]);

  useEffect(() => {
    onTableItemsChange(items);
  }, [items]);

  const restWithRegister = useMemo(() => typeof register === 'function' ? {
    ...(register as UseFormRegister<FieldValues>)(id, {
      required,
    }),
  } : { required }, [register]);

  return (
    <DynamicTableContainer>
      <input
        type="hidden"
        id={id}
        name={id}
        defaultValue={items?.map((item) => item[valueKey])}
        {...restWithRegister}
      />
      <Padding padding={`${spaces.none} ${spaces.none} ${spaces.medium}`}>
        <Typography variant="title" color={colors.primary}>{title}</Typography>
      </Padding>
      <GridContainer noPadding>
        <GridItem sm={12} md={7} lg={9}>
          <Select
            id={id}
            label={label}
            listItems={listItems}
            loading={loading}
            onChange={(_, item) => {
              setSelectedItem(item);
            }}
            searchable={searchable}
            searchOptions={{
              ordered: false,
              acceptNewValue: false,
            }}
          />
        </GridItem>
        <GridItem md={5} lg={3}>
          <Button wide onClick={addItem} loading={loading}>{addButtonText ?? 'Adicionar'}</Button>
        </GridItem>
      </GridContainer>
      <Padding padding={`${spaces.medium} ${spaces.none} ${spaces.none}`}>
        {loading ? (
          <DynamicTableEmptyContainer>
            <LoadingRect
              width="100%"
              height="100%"
            />
          </DynamicTableEmptyContainer>
        ) :
          (items && items.length > 0 && valueKey) ? (
            <TableWrapper>
              <Table tableLayout={tableLayout}>
                <TableHead>
                  <TableRow>
                    {Object.keys(tableProcessor).map((tableKey) => (
                      <TableCell key={tableKey}>{tableKey}</TableCell>
                    ))}
                    <TableCell>Remover</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => (
                    <TableRow key={item[valueKey]}>
                      {Object.keys(tableProcessor).map((tableKey) => (
                        <TableCell key={tableKey}>{tableProcessor[tableKey](item)}</TableCell>
                      ))}
                      <TableCell>
                        <Button wide type="button" variant="transparent" onClick={() => removeItem(item[valueKey])} icon={<TrashBin />} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          ) : (
            <DynamicTableEmptyContainer>
              <Center>
                <Typography variant="paragraph" color={colors.primary} align="center" fontWeight={fontWeight.medium}>{emptyStateText}</Typography>
              </Center>
            </DynamicTableEmptyContainer>
          )}
      </Padding>
    </DynamicTableContainer>
  );
};

export { DynamicTableProps };
export { DynamicTable };
