const fontSize = {
  title: '24px',
  lead: '21px',
  subtitle: '19px',
  sublead: '16px',
  button: '16px',
  link: '16px',
  paragraph: '14px',
  caption: '13px',
  footnote: '11px',
  inputLabel: '13px',
  inputPlaceholder: '15px',
} as const;

const typographyVariants = Object.keys(fontSize);

export { fontSize, typographyVariants };
