import gql from 'graphql-tag';

const suppliersQuery = gql`
  query {
    suppliers {
      id
      name
    }
  }
`;

export { suppliersQuery };
