import { styled } from 'styled-components';
import { colors, fontWeight, fontSize, spaces } from '../../tokens';

const Input = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  appearance: none;
  + svg {
    margin-right: ${spaces.xxSmall};
  }
  &:focus,
  &:hover {
    + svg path {
      outline: 2px solid ${colors.primary};
    }
  }
  &:disabled + svg {
    fill: ${colors.secondary};
    cursor: not-allowed;
  }
`;

const Label = styled.label<{
  $hasError: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ $hasError }) => ($hasError ? colors.error : colors.primary)};
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.paragraph};
`;

const LabelText = styled.span`
  margin-left: 10px;
`;

export { Input, Label, LabelText };
