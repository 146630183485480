import { Center, ProgressIndicator, SizedContainer } from '../../../ui';

const StorePageLoading = () => {
  return (
    <SizedContainer height="100vh">
      <Center>
        <ProgressIndicator />
      </Center>
    </SizedContainer>
  );
};

export { StorePageLoading };
