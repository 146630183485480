import gql from 'graphql-tag';

const materialInventoryItemQuery = gql`
  query($id: Int!, $storeId: Int!, $materialType: MaterialType!) {
    materialInventoryItem(id: $id, storeId: $storeId, materialType: $materialType) {
      id
      storeId
      itemRef
      itemType
      itemName
      itemValue
      measures {
        unit
        customUnit
        quantity
      }
      supplierName
      itemColorName
      itemColorValue
      itemPictureMediaId
      trimMaterialType
      observation
      itemImage {
        id
        url
        presignedUrl
      }
    }
  }
`;

export { materialInventoryItemQuery };
