import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStateMachine } from '../../contexts/StateMachine';
import {
  ProgressIndicator,
  Typography,
  Padding,
  Center,
  colors,
} from '../../../ui';
import { useRedirect } from '../../../utils/redirect';
import { confirmUserNameQuery } from '../../queries';
import { authPageStateValues } from '../../../../common/models';
import { useGraphQl } from '../../../utils/gql';

type ConfirmEmailQueryParams = {
  username?: string;
  token?: string;
};

const ConfirmEmail = () => {
  const redirect = useRedirect();
  const { setState } = useStateMachine();
  const params: ConfirmEmailQueryParams = useParams();
  const confirmUserNameRequest = useGraphQl({
    query: confirmUserNameQuery,
  });

  useEffect(() => {
    if (!params.username || !params.token) {
      setTimeout(() => {
        redirect.to('/login');
      }, 1000);
      return;
    }

    confirmUserNameRequest.invoke({
      variables: {
        userName: params.username,
        confirmationToken: params.token,
      },
      onFail: (err) => {
        setState(authPageStateValues.error, {
          errorMessage: err.message,
        });
      },
      onSuccess: () => {
        setState(authPageStateValues.success, {
          successTitle: 'E-mail confirmado com sucesso!',
          successBody:
            'Acompanhe por esse e-mail todas notificações da plataforma.',
        });
      },
    });
  }, [params, setState]);

  return (
    <>
      <Typography variant="subtitle" color={colors.primary} align="center">
        Confirmando e-mail...
      </Typography>
      <Padding padding="40px 0">
        <Center>
          <ProgressIndicator />
        </Center>
      </Padding>
    </>
  );
};

export { ConfirmEmail };
