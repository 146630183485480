import { styled } from 'styled-components';
import { Close } from '../../icons';
import { colors, spaces, zIndex, animations } from '../../tokens';

const ContentWrapper = styled.div`
  ${animations.fadeIn};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ModalWrapper = styled.div`
  overflow: hidden;
  z-index: ${zIndex.beyond};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  padding-top: ${spaces.medium};
  border-radius: 16px;
  max-width: 700px;
  width: calc(100% - 20px);
  margin: auto;
`;

const ModalOverlay = styled.div`
  z-index: ${zIndex.paradise};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.secondary};
`;

const Header = styled.div`
  padding: ${spaces.none} ${spaces.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spaces.xLarge};
  word-break: break-word;
`;

const Body = styled.div`
  padding: ${spaces.none} ${spaces.medium} ${spaces.medium};
  max-height: calc(100vh - 160px);
  overflow-y: auto;
`;

const CloseButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: ${spaces.small};
  background: none;
  border: none;
  width: 24px;
  height: 24px;
`;

const CloseIcon = styled(Close)`
  width: 100%;
  height: 100%;
`;

export {
  ContentWrapper,
  ModalWrapper,
  ModalOverlay,
  Header,
  Body,
  CloseButton,
  CloseIcon,
};
