import { HTMLAttributes } from 'react';
import { styled, css } from 'styled-components';
import {
  fontWeight as fontWeightTokens,
  fontSize,
  fontFamily,
} from '../../tokens';

interface TypographyBaseProps extends HTMLAttributes<HTMLParagraphElement> {
  color: string;
  $align: 'left' | 'center' | 'right';
  fontWeight?: number;
  $renderInline: boolean;
  $cursor?: string;
  $margin: string;
  $upperCase?: boolean;
  $wide?: boolean;
  $fontStyle?: 'normal' | 'italic';
}

const TypographyBase = styled.div<TypographyBaseProps>`
  color: ${({ color }) => color};
  text-align: ${({ $align }) => $align};
  display: ${({ $renderInline }) => ($renderInline ? 'inline' : 'block')};
  cursor: ${({ $cursor }) => $cursor};
  margin: ${({ $margin }) => $margin};
  width: ${({ $wide }) => $wide ? '100%' : 'auto'};
  ${({ $upperCase }) =>
    $upperCase
      ? css`
          text-transform: uppercase;
        `
      : ''};
`;

const TypographyTitle = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.semiBold};
  font-size: ${fontSize.title};
`;

const TypographyLead = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.medium};
  font-size: ${fontSize.lead};
`;

const TypographySubtitle = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.semiBold};
  font-size: ${fontSize.subtitle};
`;

const TypographySublead = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.medium};
  font-size: ${fontSize.sublead};
`;

const TypographyParagraph = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.regular};
  font-size: ${fontSize.paragraph};
`;

const TypographyCaption = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.medium};
  font-size: ${fontSize.caption};
`;

const TypographyFootnote = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.medium};
  font-size: ${fontSize.footnote};
  font-style: ${({ $fontStyle }) => $fontStyle ?? 'italic'};
`;

const TypographyInputLabel = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.semiBold};
  font-size: ${fontSize.inputLabel};
`;

const TypographyInputPlaceholder = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.semiBold};
  font-size: ${fontSize.inputPlaceholder};
`;

const TypographyButton = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.semiBold};
  font-size: ${fontSize.button};
`;

const TypographyLink = styled(TypographyBase)`
  font-family: ${fontFamily.montserrat};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeightTokens.medium};
  font-size: ${fontSize.link};
  text-decoration: underline;
`;

export type { TypographyBaseProps };
export {
  TypographyBase,
  TypographyTitle,
  TypographyLead,
  TypographySubtitle,
  TypographySublead,
  TypographyParagraph,
  TypographyCaption,
  TypographyFootnote,
  TypographyInputLabel,
  TypographyInputPlaceholder,
  TypographyButton,
  TypographyLink,
};
