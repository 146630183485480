import gql from 'graphql-tag';

const updateTrimMaterialInventoryItemMutation = gql`
  mutation(
    $id: Int!
    $storeId: Int!
    $itemRef: String
    $itemName: String
    $itemValue: String
    $itemMeasureUnit: String
    $quantity: Float
    $supplierName: String
    $trimMaterialType: String
    $itemColorName: String
    $itemColorValue: String
    $itemPictureMediaId: Int
    $observation: String
  ) {
    updateTrimMaterialInventoryItem(
        id: $id
        storeId: $storeId
        itemRef: $itemRef
        itemName: $itemName
        itemValue: $itemValue
        itemMeasureUnit: $itemMeasureUnit
        quantity: $quantity
        supplierName: $supplierName
        trimMaterialType: $trimMaterialType
        itemColorName: $itemColorName
        itemColorValue: $itemColorValue
        itemPictureMediaId: $itemPictureMediaId
        observation: $observation
      ) {
        id
        storeId
        itemRef
        itemType
        itemName
        itemValue
        measures {
          unit
          customUnit
          quantity
        }
        supplierName
        trimMaterialType
        itemColorName
        itemColorValue
        itemPictureMediaId
        observation
    }
  }
`;

export { updateTrimMaterialInventoryItemMutation };
