import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { InstitutionModel } from '../../../../../common/models/InstitutionModel';
import { InstitutionTypeModel } from '../../../../../common/models/InstitutionTypeModel';
import { useGraphQl } from '../../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import {
  institutionQuery,
  institutionsTypesQuery,
  residuesCategoriesQuery,
  residuesSizesQuery,
  createInstitutionMutation,
  updateInstitutionMutation,
} from '../../../queries';
import {
  Button,
  Card,
  Center,
  colors,
  Form,
  FormControl,
  GridContainer,
  GridItem,
  Padding,
  ProgressIndicator,
  Select,
  spaces,
  TextArea,
  TextField,
  Typography,
} from '../../../../ui';
import { CreateInstitutionInput } from '../../../../../common/models/CreateInstitutionInput';
import { UpdateInstitutionInput } from '../../../../../common/models/UpdateInstitutionInput';
import { ResidueCategoryModel } from '../../../../../common/models/ResidueCategoryModel';
import { ResidueSizeModel } from '../../../../../common/models/ResidueSizeModel';
import { useCnpjOrCpf, yupCnpjOrCpf } from '../../../../utils/useCnpjOrCpf';
import { formatTel, telInputProps, yupTel } from '../../../../utils/tel';
import { yupEmail } from '../../../../utils/email';

const institutionFormValidationSchema = yup.object({
  name: yup.string().required('O nome da instituição é obrigatório.'),
  typeId: yup.number().typeError('Houve um erro na seleção do tipo de instituição.').required('O tipo de instituição é obrigatório.'),
  observation: yup.string().optional(),
  motivation: yup.string().optional(),
  cnpjOrCpf: yupCnpjOrCpf().optional(),
  email: yupEmail().optional(),
  phoneNumber: yupTel().optional(),
  personOfContact: yup.string().optional(),
  // address
  residueSizeIds: yup.array(yup.number().typeError('Houve um erro na seleção dos tamanhos de resíduos.')).optional(),
  residueCategoryIds: yup.array(yup.number().typeError('Houve um erro na seleção das categorias de resíduos.')).optional(),
});

const InstitutionForm = () => {
  const { addSnackbar } = useContext(SnackbarContext);
  const { storeId, itemId } = useParams();
  const redirect = useRedirect();
  const [item, setItem] = useState<InstitutionModel | null>(null);
  const [_categories, setCategories] = useState<Array<ResidueCategoryModel>>([]);
  const [_sizes, setSizes] = useState<Array<ResidueSizeModel>>([]);
  const [types, setTypes] = useState<Array<InstitutionTypeModel>>([]);
  const {
    startCnpjOrCpf,
    getCnpjOrCpf,
    recognizeCnpjOrCpfOnChange,
  } = useCnpjOrCpf();

  const institutionRequest = useGraphQl<{ institution: InstitutionModel }>({
    query: institutionQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const residuesCategoriesRequest = useGraphQl<{ residuesCategories: Array<ResidueCategoryModel> }>({
    query: residuesCategoriesQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const residuesSizesRequest = useGraphQl<{ residuesSizes: Array<ResidueSizeModel> }>({
    query: residuesSizesQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const institutionsTypesRequest = useGraphQl<{ institutionsTypes: Array<InstitutionTypeModel> }>({
    query: institutionsTypesQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createInstitutionRequest = useGraphQl<{ createInstitution: InstitutionModel }>({
    query: createInstitutionMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateInstitutionRequest = useGraphQl<{ updateInstitution: InstitutionModel }>({
    query: updateInstitutionMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const { register, handleSubmit, setValue, formState: { errors }, getValues } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: item?.name,
      typeId: item?.type?.id,
      observation: item?.observation,
      motivation: item?.motivation,
      cnpjOrCpf: getCnpjOrCpf(),
      email: item?.email,
      phoneNumber: item?.phoneNumber && formatTel(item?.phoneNumber),
      personOfContact: item?.personOfContact,
      residueSizeIds: item?.sizes?.map(s => s.id),
      residueCategoryIds: item?.categories?.map(c => c.id),
    },
    resolver: yupResolver(institutionFormValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  useEffect(() => {
    setValue('typeId', item?.type?.id);
  }, [item]);

  useEffect(() => {
    startCnpjOrCpf(item?.cnpj, item?.cpf);
  }, [item]);

  useEffect(() => {
    setValue('cnpjOrCpf', getCnpjOrCpf());
  }, [getCnpjOrCpf]);

  useEffect(() => {
    residuesCategoriesRequest.invoke({
      onSuccess: ({ data: { residuesCategories } }) => {
        setCategories(residuesCategories);
      },
    });
  }, []);

  useEffect(() => {
    residuesSizesRequest.invoke({
      onSuccess: ({ data: { residuesSizes } }) => {
        setSizes(residuesSizes);
      },
    });
  }, []);

  useEffect(() => {
    institutionsTypesRequest.invoke({
      onSuccess: ({ data: { institutionsTypes } }) => {
        setTypes(institutionsTypes);
      },
    });
  }, []);

  useEffect(() => {
    if (itemId) {
      institutionRequest.invoke({
        variables: {
          id: parseInt(itemId),
          storeId: parseInt(String(storeId)),
        },
        onSuccess: ({ data: { institution } }) => {
          setItem(institution);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Erro ao carregar a instituição. ${err.message}`,
            actionText: 'OK',
            fail: true,
          });
          redirect.to(`/store/${storeId}/institution`);
        },
      });
    }
  }, [itemId]);

  const onSubmit = useCallback((data: yup.InferType<typeof institutionFormValidationSchema>) => {
    if (itemId) {
      const variables: UpdateInstitutionInput = {
        id: parseInt(String(itemId)),
        storeId: parseInt(String(storeId)),
        typeId: data?.typeId,
        name: data?.name,
        observation: data?.observation,
        motivation: data?.motivation,
        cnpjOrCpf: data?.cnpjOrCpf,
        email: data?.email,
        phoneNumber: data?.phoneNumber,
        personOfContact: data?.personOfContact,
      };

      updateInstitutionRequest.invoke({
        variables,
        onSuccess: ({ data: { updateInstitution } }) => {
          if (!updateInstitution) return;
          addSnackbar({
            title: `Item ${data.name} atualizado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/institution`);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Falha ao atualizar instituição. ${err?.message}`,
            actionText: 'OK',
            fail: true,
          });
        },
      });
    } else {
      const variables: CreateInstitutionInput = {
        storeId: parseInt(String(storeId)),
        typeId: data?.typeId,
        name: data?.name,
        observation: data?.observation,
        motivation: data?.motivation,
        cnpjOrCpf: data?.cnpjOrCpf,
        email: data?.email,
        phoneNumber: data?.phoneNumber,
        personOfContact: data?.personOfContact,
      };

      createInstitutionRequest.invoke({
        variables,
        onSuccess: ({ data: { createInstitution } }) => {
          if (!createInstitution) return;
          addSnackbar({
            title: `Item ${data.name} criado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/institution`);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Falha ao criar instituição. ${err?.message}`,
            actionText: 'OK',
            fail: true,
          });
        },
      });
    }
  }, [item]);

  return itemId && !item ? (
    <Padding padding={`${spaces.huge} 0`}>
      <Center>
        <ProgressIndicator />
      </Center>
    </Padding>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Padding padding={`0 0 ${spaces.xLarge}`}>
        <Card padding={spaces.xLarge}>
          <FormControl>
            <Typography variant="title" color={colors.primary}>
              {itemId ? `Alterar ${item?.name ? `"${item?.name}"` : 'instituição'}` : 'Criar novo tecido'}
            </Typography>
            <Typography variant="sublead" color={colors.black} margin={`${spaces.xSmall} 0 0`}>
              Dados da instituição
            </Typography>
          </FormControl>
          <FormControl>
            <GridContainer noPadding>
              <GridItem lg={4} md={6} sm={12}>
                <TextField
                  id="name"
                  type="text"
                  label="Nome"
                  defaultValue={item?.name}
                  register={castedRegister}
                  errorMessage={errors.name?.message}
                  state={errors.name ? 'invalid' : 'neutral'}
                  required
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12}>
                <Select
                  id="typeId"
                  label="Tipo de instituição"
                  placeholder="Selecione o tipo de instituição..."
                  defaultValue={item?.type?.id}
                  errorMessage={errors.typeId?.message}
                  state={errors.typeId ? 'invalid' : 'neutral'}
                  listItems={types?.map((type) => ({
                    text: type.type,
                    value: type.id,
                  }))}
                  required
                  onChange={(_, selectedItem) => setValue('typeId', parseInt(selectedItem.value as string))}
                  loading={institutionsTypesRequest.isFetching()}
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12}>
                <TextField
                  id="cnpjOrCpf"
                  type="text"
                  label="CNPJ ou CPF"
                  defaultValue={getCnpjOrCpf()}
                  register={castedRegister}
                  errorMessage={errors.cnpjOrCpf?.message}
                  state={errors.cnpjOrCpf ? 'invalid' : 'neutral'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue('cnpjOrCpf', recognizeCnpjOrCpfOnChange(e));
                  }}
                  forceActive={true}
                />
              </GridItem>
              <GridItem lg={6} sm={12}>
                <TextArea
                  id="motivation"
                  label="Motivação"
                  helperText="Como pretendem usar os resíduos arrecadados."
                  defaultValue={item?.motivation}
                  register={castedRegister}
                  errorMessage={errors.motivation?.message}
                  state={errors.motivation ? 'invalid' : 'neutral'}
                  rows={4}
                />
              </GridItem>
              <GridItem lg={6} sm={12}>
                <TextArea
                  id="observation"
                  label="Observações"
                  defaultValue={item?.observation}
                  register={castedRegister}
                  errorMessage={errors.observation?.message}
                  state={errors.observation ? 'invalid' : 'neutral'}
                  rows={4}
                />
              </GridItem>
            </GridContainer>
          </FormControl>
        </Card>
      </Padding>
      <Padding padding={`0 0 ${spaces.xLarge}`}>
        <Card padding={spaces.xLarge}>
          <FormControl>
            <Typography variant="sublead" color={colors.black} margin={`${spaces.xLarge} 0 0`}>
              Dados de contato
            </Typography>
          </FormControl>
          <FormControl>
            <GridContainer noPadding>
              <GridItem lg={4} md={6} sm={12}>
                <TextField
                  id="email"
                  type="text"
                  label="E-mail"
                  defaultValue={item?.email}
                  register={castedRegister}
                  errorMessage={errors.email?.message}
                  state={errors.email ? 'invalid' : 'neutral'}
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12}>
                <TextField
                  id="phoneNumber"
                  type="text"
                  label="Telefone"
                  defaultValue={item?.phoneNumber && formatTel(item?.phoneNumber)}
                  register={castedRegister}
                  errorMessage={errors.phoneNumber?.message}
                  state={errors.phoneNumber ? 'invalid' : 'neutral'}
                  {...telInputProps}
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12}>
                <TextField
                  id="personOfContact"
                  type="text"
                  label="Ponto de contato (nome)"
                  defaultValue={item?.personOfContact}
                  register={castedRegister}
                  errorMessage={errors.personOfContact?.message}
                  state={errors.personOfContact ? 'invalid' : 'neutral'}
                />
              </GridItem>
            </GridContainer>
          </FormControl>
        </Card>
      </Padding>
      {/* <Padding padding={`0 0 ${spaces.xLarge}`}>
        <Card padding={spaces.xLarge}>
          <FormControl>
            <Typography variant="sublead" color={colors.black} margin={`${spaces.xLarge} 0 0`}>
              Resíduos de preferência
            </Typography>
          </FormControl>
        </Card>
      </Padding> */}
      <GridContainer noPadding>
        <GridItem lg={6}>
          <Button
            variant="back"
            wide
            type="button"
            onClick={() => redirect.to(`/store/${storeId}/institution`)}
          >
            Voltar
          </Button>
        </GridItem>
        <GridItem lg={6}>
          <Button variant="default" wide type="submit">
            {itemId ? 'Atualizar' : 'Cadastrar'}
          </Button>
        </GridItem>
      </GridContainer>
    </Form >
  );
};
const InstitutionFormWrapper = () => {
  return (
    <InstitutionForm />
  );
};

export {
  InstitutionFormWrapper,
};
