import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ResidueModel } from '../../../../../common/models/ResidueModel';
import { StoreContext } from '../../../contexts/StoreContext';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { useGraphQl } from '../../../../utils/gql';
import { residuesPaginationQuery } from '../../../queries/residuesPaginationQuery';
import { ResidueConnectionModel } from '../../../../../common/models/ResidueConnectionModel';
import { CardsList, ListFilterOption, Typography } from '../../../../ui/components';
import { MediaModel } from '../../../../../common/models';
import { colors, spaces } from '../../../../ui/tokens';
import { deactivateResidueMutation, residuesReportQuery } from '../../../queries';
import { ReportLinksModel } from '../../../../../common/models/ReportLinksModel';
import { formatMeasure } from '../../../../utils/measure';

const ResidueList = () => {
  const { storeId } = useParams();
  const [queryParams] = useSearchParams();
  const [items, setItems] = useState<Array<ResidueModel> | null>(null);
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const redirect = useRedirect();

  const residuesPaginationRequest = useGraphQl<{ residuesPagination: ResidueConnectionModel }>({
    query: residuesPaginationQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const residuesReportRequest = useGraphQl<{ residuesReport: ReportLinksModel }>({
    query: residuesReportQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const removeResidueItemRequest = useGraphQl<{ deactivateResidue: boolean }>({
    query: deactivateResidueMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  useEffect(() => {
    residuesPaginationRequest.invoke({
      variables: {
        storeId: store?.id,
        first: 99999,
      },
      onSuccess: ({ data: { residuesPagination } }) => {
        setItems(residuesPagination.edges.map(edge => edge.node));
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar a lista de resíduos.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, [queryParams]);

  const filters = useMemo(() => {
    const filtersList: Array<ListFilterOption<ResidueModel>> = [
      {
        label: 'Nome',
        resolver: (filterItems, value) => filterItems.filter(item => item?.name?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Descrição',
        resolver: (filterItems, value) => filterItems.filter(item => item?.description?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Tamanho',
        resolver: (filterItems, value) => filterItems.filter(item => item?.size?.name?.toLowerCase().includes(value.toLowerCase())),
      },
      {
        label: 'Categoria',
        resolver: (filterItems, value) => filterItems.filter(item => item?.categories?.find(category => category?.name?.toLowerCase().includes(value.toLowerCase()))),
      },
    ];
    return filtersList;
  }, []);

  return <CardsList<ResidueModel, MediaModel>
    emptyText='Nenhum resíduo foi cadastrado até o momento.'
    items={items}
    onSnackbar={addSnackbar}
    filters={filters}
    renderContent={(item: ResidueModel) => (
      <>
        <Typography variant="sublead" align="left" color={colors.black} margin={`0 0 ${spaces.small}`}>{item.name}</Typography>
        {item.size && <Typography variant="paragraph" align="left" color={colors.black}>Tamanho: {item.size.name}</Typography>}
        {item.categories && item.categories.length > 0 && <Typography variant="paragraph" align="left" color={colors.black}>Categorias: {item.categories.map(c => c.name).join(', ')}</Typography>}
        {item.measures?.map((m) => (
          <Typography key={m.unit} variant="paragraph" align="left" color={colors.black}>Quantidade: {formatMeasure(m.quantity.toString(), m.unit)}</Typography>
        ))}
      </>
    )}
    onItemClick={(item) => redirect.to(`/store/${storeId}/residue/item/${item.id}`)}
    onItemRemove={(removedItem) => {
      removeResidueItemRequest.invoke({
        variables: {
          id: removedItem?.id,
          storeId: store?.id,
        },
        onSuccess: ({ data: { deactivateResidue } }) => {
          if (deactivateResidue) {
            setItems(items?.filter(filteringItem => filteringItem.id !== removedItem.id));
          }
        },
        onFail: (err) => {
          if (err.name === 'UnauthorizedRequestError') {
            redirect.to('/login');
            return;
          }

          addSnackbar({
            title: `Não foi possível remover o item. ${err.message}`,
            fail: true,
            actionText: 'OK',
          });
        },
      });
    }}
    getItemImageUrl={(item) => item.picture?.presignedUrl ?? item.picture?.url}
    onItemImageChange={(_item, _media) => {
      // TODO:
    }}
    onItemImagePostUpload={(_file) => null} // TODO:
    onRequestXlsxReport={() => {
      residuesReportRequest.invoke({
        variables: {
          storeId: store?.id,
        },
        onSuccess: ({ data: { residuesReport } }) => {
          console.log(residuesReport.xlsx);
          window.open(residuesReport.xlsx, '_blank');
        },
      });
    }}
    onRequestPdfReport={() => {
      residuesReportRequest.invoke({
        variables: {
          storeId: store?.id,
        },
        onSuccess: ({ data: { residuesReport } }) => {
          console.log(residuesReport.pdf);
          window.open(residuesReport.pdf, '_blank');
        },
      });
    }}
  />;
};

export {
  ResidueList,
};
