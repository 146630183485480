import {
  ReactNode,
  ChangeEvent,
  useContext,
} from 'react';
import {
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import {
  ImageCard,
} from '../../../../ui';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useGraphQl } from '../../../../utils/gql';
import { getProcess } from '../../../../utils/getProcess';
import { MediaModel, StoreModel } from '../../../../../common/models';
import { ApiAddresses, ApiEndpointProps, apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { createStoreMediaMutation } from '../../../queries/createStoreMediaMutation';
import { createUserMediaMutation } from '../../../queries/createUserMediaMutation';

interface ProductImageCardProps<T extends FieldValues> {
  src?: string;
  alt?: string;
  uploadable?: boolean;
  width?: number;
  icon?: ReactNode;
  wide?: boolean;
  register?: UseFormRegister<T>;
  id: string;
  onSubmit?: (data: { title?: string; alt?: string; file?: FileList }) => void;
  state?: 'valid' | 'neutral' | 'invalid';
  errorMessage?: string;
  onClick?: () => void;
  value?: string | number | string[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  store?: StoreModel;
  fitParent?: boolean;
  showActionIconOnHoverOnly?: boolean;
};

const MediaCard = <T extends FieldValues,>({
  store,
  ...mediaProps
}: ProductImageCardProps<T>) => {
  const { addSnackbar } = useContext(SnackbarContext);
  const apiEndpointProps: ApiEndpointProps = {
    api: String(getProcess()?.env?.PUBLIC_DASHBOARD_API) as ApiAddresses,
    version: 'v1',
    app: 'dashboard',
    route: apiRoutes.admin,
  };

  const createStoreMediaRequest = useGraphQl<{
    createStoreMedia?: MediaModel,
    createUserMedia?: MediaModel,
  }>({
    query: createStoreMediaMutation,
    url: getApiEndpoint({
      ...apiEndpointProps,
      route: apiRoutes.admin,
    }),
  });
  const createUserMediaRequest = useGraphQl<{
    createUserMedia?: MediaModel,
    createStoreMedia?: MediaModel,
  }>({
    query: createUserMediaMutation,
    url: getApiEndpoint({
      ...apiEndpointProps,
      route: apiRoutes.admin,
    }),
  });

  return (
    <ImageCard
      {...mediaProps}
      apiEndpointProps={apiEndpointProps}
      onSnackbar={addSnackbar}
      postUpload={(file) => new Promise((resolve, reject) => {
        (store ? createStoreMediaRequest : createUserMediaRequest).invoke({
          variables: {
            storeId: store?.id,
            url: file.downloadUrl,
            name: file.fileName,
            mimeType: file.mimeType,
          },
          onFail: async (err) => {
            reject(err);
          },
          onSuccess: async ({ data: { createStoreMedia, createUserMedia } }) => {
            resolve(createStoreMedia ?? createUserMedia);
          },
        });
      })}
    />);
};

export { MediaCard };
