import React, { useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ContentWrapper,
  ContentHeader,
} from '../../../ui';
import { StoreContext } from '../../contexts/StoreContext';
import { mainMenuOptions, StoreMenu } from '../stores';
import { Page } from '../Page';
import { TeamMembersList } from './TeamMembersList';
import { useRedirect } from '../../../utils/redirect';
import { LoadingPage } from '../LoadingPage';

const TeamMembersListPage = () => {
  const { storeId } = useParams();
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const redirect = useRedirect();

  const page = useMemo(() => {
    return (
      <Page variant="primary">
        <StoreMenu current={mainMenuOptions.team} />
        <ContentWrapper>
          <ContentHeader
            title="Gerenciamentos de membros do time"
            subtitle="Controle dos membros da equipe"
            breadcrumbs={[
              {
                name: 'Empresas',
                link: '/store',
                redirectOptions: {
                  removeParams: ['type'],
                },
              },
              {
                name: 'Início',
                link: `/store/${store?.id}`,
                redirectOptions: {
                  removeParams: ['type'],
                },
              },
              {
                name: mainMenuOptions.team,
                link: `/store/${storeId}/team`,
              },
            ]}
          />
          <TeamMembersList />
          {/* <Button type="button" onClick={() => redirect.to(`/store/${store?.id}/team/member`)}>Convidar membro da equipe</Button> */}
        </ContentWrapper>
      </Page>
    );
  }, [queryParams, store, storeId, redirect]);

  return page ? page : <LoadingPage />;
};

export { TeamMembersListPage };
