import gql from 'graphql-tag';

const apiTokensQuery = gql`
  query($storeId: Int!) {
    apiTokens(storeId: $storeId) {
      id
      guid
      createdAt
      updatedAt
      token
      domain
      enabled
    }
  }
`;

export { apiTokensQuery };
