import { styled } from 'styled-components';
import { colors, screens } from '../../tokens';

const DynamicTableContainer = styled.div`
  width: 100%;
`;

const DynamicTableEmptyContainer = styled.div`
  width: 100%;
  height: 128px;
  background-color: ${colors.backgroundPrimary};
  border-radius: 8px;
`;

const TableWrapper = styled.div`
  @media screen and (max-width: ${screens.sm}) {
    width: 100%;
    overflow-x: scroll;
  }
`;

export {
  DynamicTableContainer,
  DynamicTableEmptyContainer,
  TableWrapper,
};
