import gql from 'graphql-tag';

const storeQuery = gql`
  query($storeId: Int!) {
    store(storeId: $storeId) {
      id
      guid
      createdAt
      updatedAt
      name
      development
      logo {
        id
        url
        presignedUrl
      }
      cnpj {
        id
        value
      }
      owner {
        id
        guid
        name
      }
      storeSetting {
        communicationEmail
      }
      collections {
        id
        name
      }
      role {
        id
        guid
        name
        description
        permissions {
          can_create_store {
            locales {
              ptBR
            }
            permitted
          }
          can_update_store {
            locales {
              ptBR
            }
            permitted
          }
          can_invite_user {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_user_role {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_user {
            locales {
              ptBR
            }
            permitted
          }
          can_create_material {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_material {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_material {
            locales {
              ptBR
            }
            permitted
          }
          can_create_product {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_product {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_product {
            locales {
              ptBR
            }
            permitted
          }
          can_create_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_deactivate_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_create_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_deactivate_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_create_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
        }
      }
      roles {
        id
        guid
        name
        description
        permissions {
          can_create_store {
            locales {
              ptBR
            }
            permitted
          }
          can_update_store {
            locales {
              ptBR
            }
            permitted
          }
          can_invite_user {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_user_role {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_user {
            locales {
              ptBR
            }
            permitted
          }
          can_create_material {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_material {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_material {
            locales {
              ptBR
            }
            permitted
          }
          can_create_product {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_product {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_product {
            locales {
              ptBR
            }
            permitted
          }
          can_create_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_deactivate_residue {
            locales {
              ptBR
            }
            permitted
          }
          can_create_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_deactivate_institution {
            locales {
              ptBR
            }
            permitted
          }
          can_create_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
          can_edit_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
          can_remove_unloading_log {
            locales {
              ptBR
            }
            permitted
          }
        }
      }
    }
  }
`;

export { storeQuery };
