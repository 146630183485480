import { ReactNode } from 'react';
import { styled } from 'styled-components';

type JustifyContentType =
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'left'
  | 'right'
  | 'space-between'
  | 'space-around'
  | 'normal';

const RowStyle = styled.div<{
  children: ReactNode;
  $justifyContent: JustifyContentType;
  $width: string;
  $flexWrap: string;
  $gutter?: string;
}>`
  display: flex;
  flex-direction: row;
  width: ${({ $width }) => $width};
  align-items: center;
  justify-content: ${({ $justifyContent }) => $justifyContent};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};

  & > * {
    margin-right: ${({ $gutter }) => $gutter ? $gutter : '0'};
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export type { JustifyContentType };
export { RowStyle };
