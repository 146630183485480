import React, { SVGProps } from 'react';

const EditIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 40 40" width={40} height={40} {...props}>
      <path
        d="M38.9,11.1l-3.6,3.6a.938.938,0,0,1-1.328,0L25.3,6.031a.938.938,0,0,1,0-1.328l3.6-3.6a3.758,3.758,0,0,1,5.3,0l4.7,4.7A3.744,3.744,0,0,1,38.9,11.1ZM22.2,7.8,1.687,28.313.031,37.806A1.877,1.877,0,0,0,2.2,39.978L11.7,38.314,32.212,17.8a.938.938,0,0,0,0-1.328L23.54,7.8a.948.948,0,0,0-1.336,0ZM9.7,26.555a1.089,1.089,0,0,1,0-1.547L21.727,12.977a1.094,1.094,0,0,1,1.547,1.547L11.243,26.555a1.089,1.089,0,0,1-1.547,0Zm-2.82,6.571h3.75v2.836l-5.039.883-2.43-2.43.883-5.039H6.875Z"
        transform="translate(-0.002 -0.005)"
      />
    </svg>
  );
};

export { EditIcon };
