import React from 'react';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Form,
  FormControl,
  Padding,
  TextField,
  Typography,
  GridContainer,
  GridItem,
  colors,
} from '../../../ui';
import { useStateMachine } from '../../contexts/StateMachine';
import { apiRoutes, getApiEndpoint } from '../../../utils/apiEndpoints';
import { forgetPasswordMutation } from '../../queries';
import { authPageStateValues } from '../../../../common/models';
import { AuthStateMachineData } from './AuthPage';
import { useGraphQl } from '../../../utils/gql';

const passwordValidationSchema = yup.object({
  userName: yup.string().required('O e-mail é obrigatório'),
});

const PasswordForm = () => {
  const { data: stateMachineData, setState } = useStateMachine<AuthStateMachineData>();
  const forgetPasswordRequest = useGraphQl({
    query: forgetPasswordMutation,
    url: getApiEndpoint({ route: apiRoutes.auth }),
  });

  const { register, handleSubmit, formState: { errors } } = useForm<{ userName?: string }>({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      userName: stateMachineData.userName,
    },
    resolver: yupResolver(passwordValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  const onSubmit = (data: { userName: string }) => {
    setState(authPageStateValues.loading, { userName: data.userName });
    forgetPasswordRequest.invoke({
      onFail: (forgetPasswordError) => {
        setState(authPageStateValues.error, {
          errorMessage: forgetPasswordError?.message,
        });
      },
      onSuccess: () => {
        setState(authPageStateValues.success, {
          successTitle: 'Link enviado!',
          successBody:
            'Foi enviado para você um e-mail com link para alterar a senha.',
        });
      },
      variables: data,
    });
  };

  return (
    <Padding padding="20px 0" wide>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Typography variant="subtitle" color={colors.primary} align="center">
            Recuperar senha
          </Typography>
        </FormControl>
        <FormControl>
          <TextField
            id="userName"
            label="e-mail"
            register={castedRegister}
            errorMessage={errors.userName?.message}
            state={errors.userName ? 'invalid' : 'neutral'}
          />
        </FormControl>
        <FormControl>
          <Typography variant="paragraph" color={colors.primary}>
            Preencha com seu e-mail cadastrado. Ao confirmar será enviado um
            e-mail com um link para cadastrar uma nova senha.
          </Typography>
        </FormControl>
        <GridContainer noPadding>
          <GridItem lg={6}>
            <FormControl>
              <Button
                variant="back"
                wide
                type="button"
                onClick={() => {
                  setState(authPageStateValues.login);
                }}
              >
                Voltar
              </Button>
            </FormControl>
          </GridItem>
          <GridItem lg={6}>
            <FormControl>
              <Button variant="default" wide type="submit">
                Enviar link
              </Button>
            </FormControl>
          </GridItem>
        </GridContainer>
      </Form>
    </Padding>
  );
};

export { PasswordForm };
