import gql from 'graphql-tag';

const residuesSizesQuery = gql`
  query {
    residuesSizes {
      id
      name
    }
  }
`;

export { residuesSizesQuery };
