import { Image } from '../ui';

const MecenaLoadingGif = () => (
  <Image
    alt="Gif animado contendo padrões da identidade visual da Mecena"
    src="/images/mecena-loading-gif.gif"
    width="120px"
    height="120px"
  />
);

export { MecenaLoadingGif };
