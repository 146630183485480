import React from 'react';
import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem';
import { BreadcrumbsContainer } from './Breadcrumbs.styles';

type BreadcrumbsProps = {
  breadcrumbs: Array<BreadcrumbItemProps>;
};

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map((breadcrumb: BreadcrumbItemProps) => {
        return <BreadcrumbItem key={breadcrumb.name} {...breadcrumb} />;
      })}
    </BreadcrumbsContainer>
  );
};

export { Breadcrumbs };
