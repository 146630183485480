import React, { SVGProps } from 'react';

const Zipper = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" height={24} width={24} {...props}>
      <path d="M14.95,0c.18,.05,.36,.09,.53,.16,.73,.28,1.27,.78,1.52,1.52,.58,1.71,1.13,3.42,1.67,5.14,.32,1.04-.06,2.12-.95,2.79-.49,.37-.99,.73-1.5,1.09-.14,.1-.17,.18-.13,.35,.52,2.07,1.04,4.13,1.53,6.2,.62,2.61-.35,4.73-2.72,6.03-2.65,1.45-6.08,.7-7.85-1.73-.9-1.23-1.13-2.6-.77-4.07,.53-2.14,1.05-4.29,1.59-6.43,.04-.17,.01-.25-.13-.35-.49-.34-.96-.69-1.44-1.04-.97-.71-1.34-1.8-.98-2.94,.52-1.64,1.05-3.28,1.6-4.92,.32-.96,1-1.54,1.99-1.75C8.97,.04,9.01,.01,9.04,0c1.97,0,3.94,0,5.91,0Zm-5.09,8.85c-.05,.19-.09,.39-.14,.58-.67,2.73-1.34,5.47-2.02,8.2-.27,1.09-.2,2.11,.48,3.03,1.82,2.47,5.54,2.62,7.53,.13,.76-.94,.87-2.01,.58-3.16-.72-2.88-1.42-5.77-2.13-8.65-.01-.04-.03-.09-.06-.16-.36,1.2-1.1,1.82-2.12,1.82-1.01,0-1.76-.64-2.13-1.79Zm5.81,.51c.43-.31,.83-.59,1.21-.88,.44-.32,.6-.81,.43-1.33-.53-1.64-1.06-3.28-1.6-4.92-.17-.53-.59-.83-1.15-.83-1.71,0-3.42,0-5.13,0-.56,0-.98,.29-1.15,.82-.54,1.64-1.08,3.29-1.61,4.94-.14,.44-.06,.86,.28,1.16,.41,.36,.87,.67,1.34,1.02,.02-.08,.04-.12,.05-.17,.14-.71,.27-1.42,.42-2.14,.11-.52,.43-.84,.94-.96,.06-.01,.12-.03,.19-.05,0-.25,0-.5,0-.75,0-.19,.02-.39,.07-.58,.27-1.02,1.26-1.69,2.29-1.56,1.05,.14,1.83,1.01,1.85,2.06,0,.28,0,.56,0,.83,.83,.23,1.05,.49,1.18,1.33,.1,.65,.25,1.3,.38,1.99Zm-2.97-2.48c0-.51,0-1.03,0-1.54,0-.48-.28-.79-.7-.79-.42,0-.71,.31-.71,.79,0,1.04,0,2.07,0,3.11,0,.47,.3,.79,.71,.79,.4,0,.69-.33,.7-.78,0-.52,0-1.04,0-1.57Z" /><path d="M11.99,16.55c.65,0,1.3,0,1.95,0,.48,0,.72,.19,.83,.66,.09,.39,.16,.78,.24,1.17,.13,.65-.08,1.2-.5,1.69-1.3,1.5-3.72,1.49-5.02,0-.46-.53-.64-1.14-.48-1.83,.08-.35,.15-.7,.23-1.05,.11-.44,.35-.63,.8-.63,.65,0,1.3,0,1.95,0Zm1.51,1.42h-3c-.04,.2-.07,.41-.12,.6-.05,.19,0,.35,.11,.5,.69,.93,2.32,.93,3,0,.08-.11,.14-.26,.13-.38-.01-.24-.08-.47-.13-.71Z" />
    </svg>
  );
};

export { Zipper };
