import { styled } from 'styled-components';

const SpacedColumn = styled.div<{ $gap: string }>`
  & > * {
    margin-bottom: ${({ $gap }) => $gap};
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export { SpacedColumn };
