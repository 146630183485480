import React, { ReactNode } from 'react';
import { spaces } from '../../tokens';
import { ColBase, FlexAlignItems, FlexJustifyContent } from './Col.styles';

interface ICol {
  children: ReactNode;
  justifyContent?: FlexJustifyContent;
  gutter?: string;
  alignItems?: FlexAlignItems;
  minHeight?: string;
}

const Col = ({
  children,
  gutter = spaces.medium,
  justifyContent = 'space-between',
  alignItems = 'flex-start',
  minHeight = '0',
}: ICol) => {
  return (
    <ColBase
      $justifyContent={justifyContent}
      $gutter={gutter}
      $alignItems={alignItems}
      $minHeight={minHeight}
    >
      {children}
    </ColBase>
  );
};

export { Col };
