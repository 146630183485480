import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import validator from 'validator';
import {
  Form,
  FormControl,
  TextField,
  Typography,
  GridContainer,
  GridItem,
  Button,
  colors,
  spaces,
  ProgressIndicator,
  Center,
  Padding,
  DatePicker,
  Select,
} from '../../../../ui';
import { useGraphQl } from '../../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { StoreContext } from '../../../contexts/StoreContext';
import { useRedirect } from '../../../../utils/redirect';
import { formatTel, yupTel, telInputProps } from '../../../../utils/tel';
import { cpfInputProps, formatCpf, yupCpf } from '../../../../utils/cpf';
import { cleanupCpf, cleanupTel } from '../../../../../common/utils';
import { MediaModel, StoreModel, AdminUserModel, RoleModel, UserNameType } from '../../../../../common/models';
import { yupDate } from '../../../../utils/date';
import { MediaCard } from '../../medias';
import { teamMemberQuery, updateMyInfoMutation, updateTeamMemberMutation } from '../../../queries';
import { AuthenticatorContext } from '../../../contexts/AuthenticatorContext';

const inviteTeamMemberValidationSchema = yup.object({
  email: yup.string().test('validator', 'E-mail inválido.', async (value) => {
    return validator.isEmail(value);
  }).required('O E-mail é obrigatório.'),
  avatarId: yup.number().typeError('O id da imagem é inválido.').optional(),
  tel: yupTel({ required: true }).required('O telefone é obrigatório.'),
  name: yup.string().optional(),
  cpf: yupCpf().optional(),
  birthDate: yupDate().optional(),
  roleId: yup.number().typeError('O id do cargo é inválido.').required('O cargo é obrigatório.'),
});

type InventoryCreateEditFabricItemFormProps = {
  storeId: number,
  memberId: number,
  onSubmitStarted?: () => void,
  onSubmitSucceeded?: (store: StoreModel) => void,
  onSubmitFailed?: (err: Error) => void,
  onExitForm?: () => void,
};

const getEmail = (user: AdminUserModel): string => {
  let email: string = undefined;
  if (user?.userNames && user?.userNames.length > 0) {
    email = user.userNames.find((userName) => userName.userNameType === UserNameType.EMAIL)?.userName;
  }
  return email;
};

const getTel = (user: AdminUserModel): string => {
  let email: string = undefined;
  if (user?.userNames && user?.userNames.length > 0) {
    email = user.userNames.find((userName) => userName.userNameType === UserNameType.TEL)?.userName;
  }
  return email;
};

const getRole = (user: AdminUserModel): RoleModel => {
  let role: RoleModel = undefined;
  if (user?.currentStoreRole) {
    role = user.currentStoreRole;
  }
  return role;
};

const TeamMemberEditForm = ({
  storeId,
  memberId,
  onSubmitFailed,
  onSubmitStarted,
  onSubmitSucceeded,
  onExitForm,
}: InventoryCreateEditFabricItemFormProps) => {
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const { currentUser, setUser } = useContext(AuthenticatorContext);
  const redirect = useRedirect();
  const [teamMember, setTeamMember] = useState<AdminUserModel | null>(null);

  const teamMemberRequest = useGraphQl<{ teamMember: AdminUserModel }>({
    query: teamMemberQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: getEmail(teamMember),
      tel: formatTel(getTel(teamMember)),
      name: teamMember?.name,
      avatarId: teamMember?.avatar?.id,
      cpf: formatCpf(teamMember?.cpf),
      birthDate: teamMember?.birthDate,
      roleId: getRole(teamMember)?.id,
    },
    resolver: yupResolver(inviteTeamMemberValidationSchema),
  });

  useEffect(() => {
    setValue('roleId', getRole(teamMember)?.id);
  }, [teamMember]);

  const castedRegister = register as unknown as UseFormRegister<FieldValues>;
  const updateTeamMemberRequest = useGraphQl<{ updateTeamMember: AdminUserModel }>({
    query: updateTeamMemberMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateMyInfoRequest = useGraphQl<{ updateMyInfo: AdminUserModel }>({
    query: updateMyInfoMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const isUser = useMemo(() => currentUser && memberId && (currentUser.id === memberId), [memberId, currentUser]);
  const userRole = useMemo(() => getRole(teamMember), [teamMember]);

  const onSubmit = useCallback((data: yup.InferType<typeof inviteTeamMemberValidationSchema>) => {
    if (onSubmitStarted) onSubmitStarted();
    const variables = {
      id: memberId ? memberId : undefined,
      storeId: parseInt(String(storeId)),
      email: data?.email,
      tel: cleanupTel(data?.tel),
      name: data?.name,
      avatar: data?.avatarId,
      cpf: cleanupCpf(data?.cpf),
      birthDate: data?.birthDate,
      roleId: parseInt(String(data?.roleId)),
    };

    if (isUser) {
      updateMyInfoRequest.invoke({
        variables,
        onFail: (err) => {
          if (onSubmitFailed) onSubmitFailed(err);
          addSnackbar({
            title: `Falha ao atualizar membro da equipe. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        },
        onSuccess: ({ data: { updateMyInfo } }) => {
          if (!updateMyInfo) return;
          if (onSubmitSucceeded) onSubmitSucceeded(updateMyInfo);
          addSnackbar({
            title: `Membro ${data.name} atualizado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/team`);
        },
      });
    } else {
      updateTeamMemberRequest.invoke({
        variables,
        onFail: (err) => {
          if (onSubmitFailed) onSubmitFailed(err);
          addSnackbar({
            title: `Falha ao atualizar membro da equipe. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        },
        onSuccess: ({ data: { updateTeamMember } }) => {
          if (!updateTeamMember) return;
          if (onSubmitSucceeded) onSubmitSucceeded(updateTeamMember);
          addSnackbar({
            title: `Membro ${data.name} atualizado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/team`);
        },
      });
    }
  }, [teamMember]);

  useEffect(() => {
    if (memberId) {
      teamMemberRequest.invoke({
        variables: {
          id: memberId,
          storeId: storeId,
        },
        onSuccess: ({ data: { teamMember } }) => {
          setTeamMember(teamMember);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Erro ao carregar membro da equipe. ${err.message}`,
            actionText: 'OK',
            fail: true,
          });
          redirect.to(`/store/${storeId}/team`);
        },
      });
    }
  }, [memberId, setValue]);

  return memberId && !teamMember ? (
    <Padding padding={`${spaces.huge} 0`}>
      <Center>
        <ProgressIndicator />
      </Center>
    </Padding>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Typography variant="title" color={colors.primary}>
          Editar {teamMember?.name ?? 'usuário'}
        </Typography>
        <Typography variant="sublead" color={colors.black} margin={`${spaces.xSmall} 0 0`}>
          Altere os dados que deseja atualizar e confirme.
        </Typography>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem lg={3} md={4} sm={6}>
            <MediaCard
              id={`${teamMember?.id}TeamMemberMedia`}
              uploadable
              wide
              store={store}
              src={teamMember?.avatar?.presignedUrl ?? teamMember?.avatar?.url}
              onChange={async ({ target: { value } }) => {
                const media: MediaModel = JSON.parse(value) as MediaModel;
                setValue('avatarId', media.id);
                if (!teamMember?.id) return;
                updateTeamMemberRequest.invoke({
                  variables: {
                    id: teamMember?.id,
                    storeId,
                    avatarId: media.id,
                  },
                  onFail: (err) => {
                    addSnackbar({
                      title: `Erro ao atualizar a imagem do membro da equipe "${teamMember?.name}". ${err.message}`,
                      actionText: 'OK',
                      fail: true,
                    });
                  },
                  onSuccess: () => {
                    if (isUser) {
                      setUser({ ...currentUser, avatar: media });
                    }
                    addSnackbar({
                      title: `Imagem do membro da equipe "${teamMember?.name}" atualizada com sucesso.`,
                      actionText: 'OK',
                      fail: false,
                    });
                  },
                });
              }}
            />
          </GridItem>
          <GridItem lg={9} md={8} sm={12}>
            <FormControl>
              <TextField
                id="name"
                type="text"
                label="Nome completo"
                defaultValue={teamMember?.name}
                register={castedRegister}
                errorMessage={errors.name?.message}
                state={errors.name ? 'invalid' : 'neutral'}
                required
              />
            </FormControl>
            <TextField
              id="email"
              type="email"
              label="E-mail"
              defaultValue={getEmail(teamMember)}
              register={castedRegister}
              errorMessage={errors.email?.message}
              state={errors.email ? 'invalid' : 'neutral'}
              required
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem sm={12} lg={6}>
            <TextField
              id="tel"
              type="tel"
              label="Telefone"
              defaultValue={formatTel(getTel(teamMember))}
              register={castedRegister}
              errorMessage={errors.tel?.message}
              state={errors.tel ? 'invalid' : 'neutral'}
              required
              {...telInputProps}
            />
          </GridItem>
          <GridItem sm={12} lg={6}>
            <TextField
              id="cpf"
              type="text"
              label="CPF"
              defaultValue={formatCpf(teamMember?.cpf)}
              register={castedRegister}
              errorMessage={errors.cpf?.message}
              state={errors.cpf ? 'invalid' : 'neutral'}
              {...cpfInputProps}
            />
          </GridItem>
        </GridContainer>
      </FormControl>
      <FormControl>
        <GridContainer noPadding>
          <GridItem sm={12} lg={6}>
            <DatePicker
              id="birthDate"
              label="Data de nascimento"
              errorMessage={errors.birthDate?.message}
              defaultValue={teamMember?.birthDate}
              state={errors.birthDate ? 'invalid' : 'neutral'}
              onChange={(date) => {
                setValue('birthDate', date);
              }}
            />
          </GridItem>
          <GridItem sm={12} lg={6}>
            {userRole && store?.roles && ( // TODO: Não alterar seu próprio cargo.
              <Select
                id="roleId"
                label="Cargo"
                listItems={store?.roles ? store?.roles.map((role) => ({
                  text: role.name,
                  value: role.id,
                })) : []}
                defaultValue={userRole?.id}
                onChange={(_, { text, value }) => {
                  console.log({ text, value });
                  setTeamMember({
                    ...teamMember,
                    currentStoreRole: {
                      ...teamMember.currentStoreRole,
                      id: value as number,
                      name: text,
                    },
                  });
                  setValue('roleId', parseInt(value as unknown as string));
                }}
                state={errors.roleId ? 'invalid' : 'neutral'}
                errorMessage={errors.roleId?.message}
              />
            )}
          </GridItem>
        </GridContainer>
      </FormControl>
      <GridContainer noPadding>
        <GridItem lg={6}>
          {onExitForm && (
            <Button
              variant="back"
              wide
              type="button"
              onClick={onExitForm}
            >
              Voltar
            </Button>
          )}
        </GridItem>
        <GridItem lg={6}>
          <Button variant="default" wide type="submit">
            Confirmar
          </Button>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

export { TeamMemberEditForm };
