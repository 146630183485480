import { styled } from 'styled-components';
import { colors, spaces } from '../../tokens';

const AccordionWrapper = styled.div`
  border: 1px solid ${colors.secondary};
`;

const AccordionHeader = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  padding: ${spaces.medium};
  cursor: pointer;
`;

const AccordionPanel = styled.div`
  border-top: 1px solid ${colors.secondary};
  padding: ${spaces.medium};
  overflow: auto;
`;

export { AccordionWrapper, AccordionHeader, AccordionPanel };
