import React, { useContext, useEffect, useMemo } from 'react';
import { useRedirect } from '../../../utils/redirect';
import { AuthenticatorContext } from '../../contexts/AuthenticatorContext';
import * as session from '../../../utils/sessionStorage';
import { LoadingPage } from '../LoadingPage';

const HomePage = () => {
  const redirect = useRedirect();
  const { getToken } = useContext(AuthenticatorContext);

  const accessToken = useMemo(() => {
    if (!getToken) {
      return session.getItem<string>('access_token');
    }
    return getToken();
  }, [getToken]);

  useEffect(() => {
    if (!accessToken) {
      redirect.to('/login');
    } else {
      redirect.to('/store');
    }
  }, [accessToken, location, redirect]);

  return <LoadingPage />;
};

export { HomePage };
