import gql from 'graphql-tag';

const signUpQuery = gql`
  mutation(
    $name: String!
    $userName: String!
    $password: String!
    $userNameType: UserNameType
  ) {
    signUp(
      name: $name
      userName: $userName
      password: $password
      userNameType: $userNameType
    ) {
      id
    }
  }
`;

export { signUpQuery };
