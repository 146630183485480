import { MouseEventHandler } from 'react';
import { styled, css } from 'styled-components';
import { colors } from '../../tokens';

const CardWrapper = styled.div<{
  width: string;
  $nWidth: number;
  height: string;
  $padding: string;
  $margin: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  $overflow?: 'hidden' | 'scroll';
}>`
  background-color: ${colors.white};
  border-radius: 19px;
  box-shadow: 0px 1px 3px ${colors.dropShadow};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  ${({ $overflow }) => $overflow && css`
    overflow: ${$overflow};
  `}
  padding: ${({ $padding }) => $padding};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  margin-left: ${({ $margin }) => `${$margin}px`};
  margin-right: ${({ $margin }) => `${$margin}px`};

  ${({ $nWidth, $margin }) => css`
    @media screen and (max-width: ${$nWidth + (2 * $margin)}px) {
      width: calc(100% - (2 * ${$margin}px));
    }
  `}
`;

export { CardWrapper };
