import gql from 'graphql-tag';

export const unloadingLogQuery = gql`
  query(
    $id: Int!
    $storeId: Int!
  ) {
    unloadingLog(
      id: $id
      storeId: $storeId
    ) {
      id
      guid
      createdAt
      updatedAt
      invalidated
      observation
      actor {
        id
        name
      }
      institution {
        id
        name
        cnpj {
          value
        }
      }
      measureUnitResidueInventoryUnitRelation {
        quantity
        measureUnitName
      }
      residue {
        id
        name
      }
    }
  }
`;
