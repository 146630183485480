import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { useRedirect } from '../../../../utils/redirect';
import { useGraphQl } from '../../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { createLogMutation, unloadingLogQuery } from '../../../queries';
import {
  Button,
  Card,
  Center,
  colors,
  Form,
  FormControl,
  GridContainer,
  GridItem,
  Padding,
  ProgressIndicator,
  Select,
  spaces,
  TextField,
  Typography,
} from '../../../../ui';
import { MeasureUnitModel } from '../../../../../common/models/MeasureUnitModel';
import { findMeasureUnitQuery } from '../../../queries/findMeasureUnitQuery';
import { MeasureUnit } from '../../../../../common/models/MeasureUnit';
import { measureInputProps, yupMeasure, formatMeasure } from '../../../../utils/measure';
import { measureToNumber } from '../../../../../common/utils/measure';
import { ResiduesUnloadingLogModel } from '../../../../../common/models/ResiduesUnloadingLogModel';
import { InstitutionModel } from '../../../../../common/models/InstitutionModel';
import { ResidueModel } from '../../../../../common/models/ResidueModel';
import { institutionsOptionsQuery } from '../../../queries/institutionsOptionsQuery';
import { residuesOptionsQuery } from '../../../queries/residuesOptionsQuery';
import { CreateResiduesUnloadingLogInput } from '../../../../../common/models/CreateResiduesUnloadingLogInput';

const unloadingLogFormValidationSchema = yup.object({
  residueId: yup.number().typeError('Houve um erro na seleção do resíduo.').required('A escolha do resíduo é obrigatório.'),
  institutionId: yup.number().typeError('Houve um erro na seleção da instituição.').required('A escolha de instituição é obrigatório.'),
  weight: yupMeasure().required('O peso dos registros de saída é obrigatório.'),
  observation: yup.string().optional(),
});

const UnloadingLogForm = () => {
  const { addSnackbar } = useContext(SnackbarContext);
  const { storeId, itemId } = useParams();
  const redirect = useRedirect();
  const [item, setItem] = useState<ResiduesUnloadingLogModel | null>(null);
  const [kgMeasureUnitId, setKgMeasureUnitId] = useState<number | null>(null);
  const [institutions, setInstitutions] = useState<Array<InstitutionModel>>([]);
  const [residues, setResidues] = useState<Array<ResidueModel>>([]);

  const measureUnitRequest = useGraphQl<{ findMeasureUnit: MeasureUnitModel }>({
    query: findMeasureUnitQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const unloadingLogRequest = useGraphQl<{ unloadingLog: ResiduesUnloadingLogModel }>({
    query: unloadingLogQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const institutionsOptionsRequest = useGraphQl<{ institutionsOptions: Array<InstitutionModel> }>({
    query: institutionsOptionsQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const residuesOptionsRequest = useGraphQl<{ residuesOptions: Array<ResidueModel> }>({
    query: residuesOptionsQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const createLogRequest = useGraphQl<{ createLog: ResiduesUnloadingLogModel }>({
    query: createLogMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const weight = useMemo(() => {
    const measure = item?.measureUnitResidueInventoryUnitRelation?.find(m => m.measureUnitName === MeasureUnit.kg);
    if (!measure) return undefined;
    return formatMeasure(measure.quantity.toString(), 'kg');
  }, [item]);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      residueId: item?.residue?.id,
      institutionId: item?.institution?.id,
      weight,
      observation: item?.observation,
    },
    resolver: yupResolver(unloadingLogFormValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  useEffect(() => {
    measureUnitRequest.invoke({
      variables: {
        name: MeasureUnit.kg,
      },
      onSuccess: ({ data: { findMeasureUnit } }) => {
        setKgMeasureUnitId(findMeasureUnit.id);
      },
      onFail: (err) => {
        addSnackbar({
          fail: true,
          title: `Erro ao carregar o registro de saída. ${err.message}`,
          actionText: 'OK',
        });
      },
    });
  }, []);

  useEffect(() => {
    setValue('residueId', item?.residue?.id);
  }, [item]);

  useEffect(() => {
    setValue('institutionId', item?.institution?.id);
  }, []);

  useEffect(() => {
    institutionsOptionsRequest.invoke({
      variables: { storeId: parseInt(storeId) },
      onSuccess: ({ data: { institutionsOptions } }) => {
        setInstitutions(institutionsOptions);
      },
    });
  }, []);

  useEffect(() => {
    residuesOptionsRequest.invoke({
      variables: { storeId: parseInt(storeId) },
      onSuccess: ({ data: { residuesOptions } }) => {
        setResidues(residuesOptions);
      },
    });
  }, []);

  useEffect(() => {
    if (itemId) {
      unloadingLogRequest.invoke({
        variables: {
          id: parseInt(itemId),
          storeId: parseInt(String(storeId)),
        },
        onSuccess: ({ data: { unloadingLog } }) => {
          setItem(unloadingLog);
        },
        onFail: (err) => {
          addSnackbar({
            title: `Erro ao carregar o registro de saída. ${err.message}`,
            actionText: 'OK',
            fail: true,
          });
          redirect.to(`/store/${storeId}/unloadinglog`);
        },
      });
    }
  }, [itemId]);

  const onSubmit = useCallback((data: yup.InferType<typeof unloadingLogFormValidationSchema>) => {
    const variables: CreateResiduesUnloadingLogInput = {
      storeId: parseInt(String(storeId)),
      institutionId: data?.institutionId,
      data: {
        residueId: data?.residueId,
        measures: [{
          measureUnitId: kgMeasureUnitId,
          quantity: measureToNumber(data?.weight)[0],
        }],
        observation: data.observation,
      },
    };

    createLogRequest.invoke({
      variables,
      onSuccess: ({ data: { createLog } }) => {
        if (!createLog) return;
        addSnackbar({
          title: 'Registro criado com sucesso!',
          actionText: 'OK',
          fail: false,
        });
        redirect.to(`/store/${storeId}/unloadinglog`);
      },
      onFail: (err) => {
        addSnackbar({
          title: `Falha ao criar registro de saída. ${err?.message}`,
          actionText: 'OK',
          fail: true,
        });
      },
    });
  }, [item, kgMeasureUnitId]);

  return itemId && !item ? (
    <Padding padding={`${spaces.huge} 0`}>
      <Center>
        <ProgressIndicator />
      </Center>
    </Padding>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Padding padding={`0 0 ${spaces.xLarge}`}>
        <Card padding={spaces.xLarge}>
          <FormControl>
            <Typography variant="title" color={colors.primary}>
              {itemId ? 'Visualizar registro de saída' : 'Criar novo registro de saída'}
            </Typography>
          </FormControl>
          <FormControl>
            <GridContainer noPadding>
              <GridItem lg={6} sm={12}>
                <Select
                  id="residueId"
                  label="Resíduo"
                  placeholder="Selecione o resíduo..."
                  errorMessage={errors.residueId?.message}
                  state={errors.residueId ? 'invalid' : 'neutral'}
                  required
                  listItems={residues?.map(i => ({
                    value: i.id,
                    text: i.name,
                  }))}
                  searchable
                  onChange={(_, selectedItem) => setValue('residueId', parseInt(selectedItem.value as string))}
                  loading={residuesOptionsRequest.isFetching()}
                />
              </GridItem>
              <GridItem lg={6} sm={12}>
                <Select
                  id="institutionId"
                  label="Instituição"
                  placeholder="Selecione a instituição..."
                  errorMessage={errors.institutionId?.message}
                  state={errors.institutionId ? 'invalid' : 'neutral'}
                  required
                  listItems={institutions?.map(i => ({
                    value: i.id,
                    text: i.name,
                  }))}
                  searchable
                  onChange={(_, selectedItem) => setValue('institutionId', parseInt(selectedItem.value as string))}
                  loading={institutionsOptionsRequest.isFetching()}
                />
              </GridItem>
              <GridItem lg={6} sm={12}>
                <TextField
                  id="weight"
                  type="text"
                  label="Peso (kg)"
                  register={castedRegister}
                  defaultValue={weight}
                  errorMessage={errors.weight?.message}
                  state={errors.weight ? 'invalid' : 'neutral'}
                  required
                  {...measureInputProps('kg')}
                />
              </GridItem>
              <GridItem lg={6} sm={12}>
                <TextField
                  id="observation"
                  label="Observações"
                  type="text"
                  defaultValue={item?.observation}
                  register={castedRegister}
                  errorMessage={errors.observation?.message}
                  state={errors.observation ? 'invalid' : 'neutral'}
                />
              </GridItem>
            </GridContainer>
          </FormControl>
        </Card>
      </Padding>

      <GridContainer noPadding>
        <GridItem lg={6}>
          <Button
            variant="back"
            wide
            type="button"
            onClick={() => redirect.to(`/store/${storeId}/unloadinglog`)}
          >
            Voltar
          </Button>
        </GridItem>
        <GridItem lg={6}>
          <Button variant="default" wide type="submit">
            {itemId ? 'Atualizar' : 'Cadastrar'}
          </Button>
        </GridItem>
      </GridContainer>
    </Form >
  );
};
const UnloadingLogFormWrapper = () => {
  return (
    <UnloadingLogForm />
  );
};

export {
  UnloadingLogFormWrapper,
};
