import { ProgressIndicator } from '../../../../../ui';
import { OnboardingStepProps } from '../../OnboardingPage';
import { AdminUserModel } from '../../../../../../common/models';
import React, { useCallback, useContext, useState } from 'react';
import { SuccessFeedback } from '../SuccessFeedback';
import { AuthenticatorContext } from '../../../../contexts/AuthenticatorContext';
import { UpdatePasswordForm } from '../../../team/UpdatePasswordForm/UpdatePasswordForm';

const OnboardingCreatePassword = ({ step, onComplete }: OnboardingStepProps) => {
  const [
    updateSuccessResponse,
    setUpdateSuccessResponse,
  ] = useState<{ userId: number } | null>(null);
  const { currentUser, setUser } = useContext(AuthenticatorContext);

  const handleCompleted = useCallback(() => {
    if (!step) return;
    onComplete({
      ...step,
      completed: true,
    });
    setUser({
      ...(currentUser as AdminUserModel),
      userCreatedPassword: true,
    });
  }, [step, onComplete]);

  return (
    step ?
      updateSuccessResponse ?
        (<SuccessFeedback step={step} handleCompleted={handleCompleted} />) :
        (<UpdatePasswordForm
          title={step.title}
          onSubmitSucceeded={setUpdateSuccessResponse}
        />) :
      (<ProgressIndicator />)
  );
};

export { OnboardingCreatePassword };
