import { Center, FullHeight, Page } from '../../../ui';
import { MecenaLoadingGif } from '../../../resources/MecenaLoadingGif';

const LoadingPage = () => {
  return (
    <Page variant="primary">
      <FullHeight>
        <Center>
          <MecenaLoadingGif />
        </Center>
      </FullHeight>
    </Page>
  );
};

export { LoadingPage };
