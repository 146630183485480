import { styled } from 'styled-components';
import { colors, spaces } from '../../tokens';

const BreadcrumbItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BreadcrumbButton = styled.button`
  background-color: ${colors.breadcrumbs};
  border: none;
  cursor: pointer;
  padding: ${spaces.xxSmall} ${spaces.small};
  height: 26px;

  & > * {
    pointer-events: none;
  }

  &:hover {
    background-color: ${colors.breadcrumbsHover};
  }
`;

export { BreadcrumbButton, BreadcrumbItemContainer };
