import validator from 'validator';

const validateTel = (tel: string | number): boolean => {
  const telValue = cleanupTel(tel.toString());
  return telValue?.length >= 10 &&
    validator.isMobilePhone(telValue);
};

const cleanupTel = (value: string) => {
  if (!value || validator.isEmpty(value)) return undefined;
  return value?.replace(/[^\d]/g, '');
};

const formatTel = (value: string): string => {
  return value?.replace(/(\d{2})(\d+)/, '($1) $2');
};

export {
  validateTel,
  cleanupTel,
  formatTel,
};
