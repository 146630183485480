import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import {
  Form,
  FormControl,
  TextField,
  Typography,
  GridContainer,
  GridItem,
  Button,
  colors,
  spaces,
  ProgressIndicator,
  Center,
  Padding,
  TextArea,
  Select,
  Card,
} from '../../../../ui';
import { useGraphQl } from '../../../../utils/gql';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { MediaModel, ProductModel } from '../../../../../common/models';
import {
  createProductMutation,
  updateProductMutation,
} from '../../../queries';
import React, { useCallback, useContext } from 'react';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { StoreContext } from '../../../contexts/StoreContext';
import { useRedirect } from '../../../../utils/redirect';
import { MediaCard } from '../../medias';
import { SpecSheetMaterials } from './SpecSheetMaterials';

const createProductValidationSchema = yup.object({
  name: yup.string().required('O nome do produto é obrigatório.'),
  description: yup.string().optional().nullable(),
  collectionName: yup.string().required('O nome da coleção é obrigatório.'),
  technicalDrawId: yup.number().typeError('Valor da desenho técnico é inválido.').optional(),
});

const SpecSheetForm = ({
  product,
  setProduct,
}: {
  product?: ProductModel,
  setProduct?: (product: ProductModel) => void,
}) => {
  const { store, updateStore } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const { storeId } = useParams();
  const redirect = useRedirect();

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: product?.name,
      description: product?.description,
      collectionName: product?.garmentSpecSheet?.collection?.name,
      technicalDrawId: product?.garmentSpecSheet?.technicalDraw?.id,
    },
    resolver: yupResolver(createProductValidationSchema),
  });

  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  const createProductRequest = useGraphQl<{ createProduct: ProductModel }>({
    query: createProductMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });
  const updateProductRequest = useGraphQl<{ updateProduct: ProductModel }>({
    query: updateProductMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const onSubmit = useCallback((data: yup.InferType<typeof createProductValidationSchema>) => {
    const variables = {
      input: {
        id: product?.id ? product.id : undefined,
        storeId: parseInt(String(storeId)),
        name: data.name,
        description: data.description,
        collectionName: data.collectionName,
        technicalDrawId: data.technicalDrawId,
      },
    };

    if (product) {
      updateProductRequest.invoke({
        variables,
        onFail: (err) => {
          addSnackbar({
            title: `Falha ao criar produto. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        },
        onSuccess: ({ data: { updateProduct } }) => {
          if (!updateProduct) return;
          if (variables.input.collectionName) {
            const collections = store?.collections?.map(
              collection => collection.name === variables.input.collectionName ?
                updateProduct?.garmentSpecSheet?.collection :
                collection,
            );

            updateStore({
              ...store,
              collections,
            });
          }
          addSnackbar({
            title: `Item ${data.name} atualizado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          redirect.to(`/store/${storeId}/specsheet`);
          setProduct(null);
        },
      });
    } else {
      createProductRequest.invoke({
        variables,
        onFail: (err) => {
          addSnackbar({
            title: `Falha ao criar produto. ${err?.message
              }`,
            actionText: 'OK',
            fail: true,
          });
        },
        onSuccess: ({ data: { createProduct } }) => {
          if (!createProduct) return;
          if (variables.input.collectionName) {
            updateStore({
              ...store,
              collections: [
                ...(store?.collections ?? []),
                createProduct?.garmentSpecSheet?.collection,
              ],
            });
          }
          addSnackbar({
            title: `Item ${data.name} criado com sucesso!`,
            actionText: 'OK',
            fail: false,
          });
          setProduct(createProduct);
        },
      });
    }
  }, [product]);

  return product && !product ? (
    <Card padding={spaces.xLarge}>
      <Padding padding={`${spaces.huge} 0`}>
        <Center>
          <ProgressIndicator />
        </Center>
      </Padding>
    </Card>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card padding={spaces.xLarge}>
        <FormControl>
          <Typography variant="title" color={colors.primary}>
            {product ? `Alterar ${product?.name ?? 'produto'}` : 'Criar novo produto'}
          </Typography>
          <Typography variant="sublead" color={colors.black} margin={`${spaces.xSmall} 0 0`}>
            Crie um produto novo e sua ficha-técnica.
          </Typography>
        </FormControl>
        <GridContainer noPadding>
          <GridItem lg={6}>
            <FormControl>
              <MediaCard
                id={`${product?.id}InventoryItemMedia`}
                uploadable
                wide
                store={store}
                src={product?.garmentSpecSheet?.technicalDraw?.url}
                onChange={async ({ target: { value } }) => {
                  const media: MediaModel = JSON.parse(value) as MediaModel;
                  setValue('technicalDrawId', media.id);
                  const name = product?.name ?? getValues('name');
                  const collectionName = product?.garmentSpecSheet?.collection?.name ?? getValues('collectionName');
                  if (!product?.id) return;
                  updateProductRequest.invoke({
                    variables: {
                      input: {
                        id: product?.id,
                        storeId: parseInt(storeId),
                        technicalDrawId: media.id,
                        collectionName: collectionName,
                      },
                    },
                    onFail: (err) => {
                      addSnackbar({
                        title: `Erro ao atualizar a imagem do produto "${name}". ${err.message}`,
                        actionText: 'OK',
                        fail: true,
                      });
                    },
                    onSuccess: () => {
                      addSnackbar({
                        title: `Imagem do produto "${name}" atualizada com sucesso.`,
                        actionText: 'OK',
                        fail: false,
                      });
                    },
                  });
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem lg={6} sm={12}>
            <FormControl>
              <TextField
                id="name"
                type="text"
                label="Nome do produto"
                defaultValue={product?.name}
                register={castedRegister}
                errorMessage={errors.name?.message}
                state={errors.name ? 'invalid' : 'neutral'}
                required
              />
            </FormControl>
            <FormControl>
              <Select
                id="collectionName"
                label="Nome da coleção"
                errorMessage={errors.collectionName?.message}
                state={errors.collectionName ? 'invalid' : 'neutral'}
                required
                listItems={store?.collections ? store.collections?.map((collection) => ({
                  text: collection.name,
                  value: collection.id,
                })) : []}
                defaultValue={product?.garmentSpecSheet?.collection?.id}
                onChange={(_, selectedItem) => setValue('collectionName', selectedItem.text)}
                searchable
                searchOptions={{
                  acceptNewValue: true,
                }}
              />
            </FormControl>
            <FormControl>
              <TextArea
                id="description"
                label="Descrição do produto"
                defaultValue={product?.description}
                register={castedRegister}
                errorMessage={errors.description?.message}
                state={errors.description ? 'invalid' : 'neutral'}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
      </Card>
      {product && (
        <Padding padding={`${spaces.xLarge} 0`}>
          <Card padding={spaces.xLarge}>
            <SpecSheetMaterials />
          </Card>
        </Padding>
      )}
      <Padding padding={`${spaces.xLarge} 0`}>
        <GridContainer noPadding>
          <GridItem lg={6}>
            <Button
              variant="back"
              wide
              type="button"
              onClick={() => redirect.to(`/store/${storeId}/specsheet`)}
            >
              Voltar
            </Button>
          </GridItem>
          <GridItem lg={6}>
            <Button variant="default" wide type="submit">
              {product ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </GridItem>
        </GridContainer>
      </Padding>
    </Form>
  );
};

export { SpecSheetForm };
