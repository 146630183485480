import React, { SVGProps } from 'react';

const SelectedArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 15 53" width={15} height={53} {...props}>
      <polygon points="0,53 0,0 15,26.5 " />
    </svg>
  );
};

export { SelectedArrow };
