import React, { ReactNode } from 'react';
import { Page as PageUi } from '../../../ui';
// import { CookiesModal } from '../cookies';
import { Account } from '../account';

type PageProps = {
  showAccountButton?: boolean;
  children: ReactNode;
  variant?: 'white' | 'primary';
  height?: string;
}

const Page = ({
  children,
  variant = 'white',
  height = 'auto',
  showAccountButton = true,
}: PageProps) => {
  return (
    <>
      {/* <CookiesModal /> */}
      <PageUi variant={variant} height={height}>
        {showAccountButton && <Account />}
        {children}
      </PageUi>
    </>
  );
};

export {
  Page,
};
