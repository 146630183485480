import gql from 'graphql-tag';

export const unloadingLogsPaginationQuery = gql`
  query(
    $storeId: Int!
    $invalidated: Boolean!
    $first: Int!
    $after: Int
    $offset: Int
    $orderBy: OrderByInput
    $filters: [ResiduesUnloadingLogFilter]
  ) {
    unloadingLogsPagination(
      storeId: $storeId
      invalidated: $invalidated
      first: $first
      after: $after
      offset: $offset
      orderBy: $orderBy
      filters: $filters
    ) {
      totalCount,
        edges {
          node {
            id
            guid
            createdAt
            updatedAt
            invalidated
            observation
            actor {
              id
              name
            }
            institution {
              id
              name
              cnpj {
                value
              }
            }
            measureUnitResidueInventoryUnitRelation {
              quantity
              measureUnitName
            }
            residue {
              id
              name
            }
          }
          cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
