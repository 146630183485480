import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { apiRoutes, getApiEndpoint } from '../../utils/apiEndpoints';
import { storeQuery } from '../queries';
import { StoreModel } from '../../../common/models';
import { useRedirect } from '../../utils/redirect';
import { LoadingPage } from '../components/LoadingPage';
import { SnackbarContext } from './SnackbarContext';
import { useGraphQl } from '../../utils/gql';
import { AuthenticatorContext } from './AuthenticatorContext';

type StoreContextProps = {
  store: StoreModel;
  invalidateFetching: () => void;
  updateStore: (newStore: StoreModel) => void;
};

const StoreContext = createContext({} as StoreContextProps);

const StoreProvider = () => {
  const { storeId } = useParams();
  const redirect = useRedirect();
  const { currentUser } = useContext(AuthenticatorContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState<StoreModel>({
    id: undefined,
    guid: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    name: undefined,
    owner: undefined,
    development: undefined,
    storeSetting: undefined,
    role: undefined,
    roles: undefined,
    logo: undefined,
  });
  const [fetching, setFetching] = useState(false);
  const storeRequest = useGraphQl<{ store: StoreModel }>({
    query: storeQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  useEffect(() => {
    if (!currentUser?.completedOnboarding) {
      redirect.to('/onboarding');
      return;
    }
  }, [currentUser, redirect]);

  const handleNonExistentStore = useCallback(() => {
    addSnackbar({
      title: 'O id da empresa é inválido...',
      actionText: 'OK',
      fail: true,
    });
    redirect.to('/store');
  }, [addSnackbar, redirect]);

  useEffect(() => {
    if (currentUser?.completedOnboarding) {
      if (!storeId) {
        handleNonExistentStore();
        return;
      }

      storeRequest.invoke({
        variables: {
          storeId: parseInt(storeId),
        },
        onFail: (err) => {
          JSON.stringify(err);
          addSnackbar({
            title: err.message,
            actionText: 'OK',
            fail: true,
          });
          redirect.to('/store');
        },
        onSuccess: ({ data }) => {
          if (!data.store) {
            handleNonExistentStore();
            return;
          }
          setFetching(false);
          setData({
            id: data.store?.id,
            guid: data.store?.guid,
            createdAt: data.store?.createdAt,
            updatedAt: data.store?.updatedAt,
            name: data.store?.name,
            development: data.store?.development,
            owner: data.store?.owner,
            storeSetting: data.store?.storeSetting,
            cnpj: data.store?.cnpj,
            role: data.store?.role,
            roles: data.store?.roles,
            logo: data.store?.logo,
            collections: data.store?.collections,
          });
        },
      });
    }
  }, [currentUser, fetching, storeId]);

  const contextValue = useMemo(() => ({
    store: data,
    invalidateFetching: () => {
      setFetching(false);
    },
    updateStore: (newStoreData: StoreModel) => {
      if (!newStoreData) return;
      setData(newStoreData);
    },
  }), [data]);

  return (
    <StoreContext.Provider
      value={contextValue}
    >
      {(fetching || storeRequest.isFetching() || !currentUser?.completedOnboarding) && <LoadingPage />}
      {!fetching && !storeRequest.isFetching() && currentUser?.completedOnboarding && <Outlet />}
    </StoreContext.Provider>
  );
};

export { StoreProvider, StoreContext };
