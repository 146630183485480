import { formatFloatToFiveDigits } from './numeric';

const measureToString = (quantity: number, unit: string): string =>
  `${formatFloatToFiveDigits(quantity).replace('.', ',')} ${unit}`;

const measureToNumber = (text: string): [number, string] => {
  try {
    const [value, unit] = text.split(' ');
    const quantity = parseFloat(value.replace(',', '.'));
    return [quantity, unit];
  } catch (_err) {
    return [null, null];
  }
};

export { measureToString, measureToNumber };
