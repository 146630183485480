import { ReactNode } from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const ChildrenContainer = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { Container, ChildrenContainer };
