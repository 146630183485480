import React, {
  FocusEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  FormControl,
  spaces,
  DynamicTable,
  HorizontalRule,
} from '../../../../ui';
import {
  MaterialInventoryItemModel,
  MaterialType,
  MeasureOutputModel,
} from '../../../../../common/models';
import { formatFloatValuePrice } from '../../../../utils/money';
import {
  addMaterialToGarmentSpecSheetMutation,
  garmentSpecSheetMaterialsQuery,
  materialInventoryItemsQuery,
  removeMaterialOfGarmentSpecSheetMutation,
  updateMaterialQuantityOnProductMutation,
} from '../../../queries';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { useGraphQl } from '../../../../utils/gql';
import { StoreContext } from '../../../contexts/StoreContext';
import { useRedirect } from '../../../../utils/redirect';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { ProductContext } from '../../../contexts/ProductContext';
import {
  MaterialQuantityInput,
  MaterialQuantityLabel,
  MaterialQuantityInputWrapper,
  MaterialQuantityInputContainer,
} from './SpecSheetMaterials.styles';

const SpecSheetMaterials = () => {
  const { store } = useContext(StoreContext);
  const { addSnackbar } = useContext(SnackbarContext);
  const { product, setProductMaterials } = useContext(ProductContext);
  const redirect = useRedirect();
  const [trimMaterials, setTrimMaterials] = useState<Array<MaterialInventoryItemModel>>([]);
  const [fabricMaterials, setFabricMaterials] = useState<Array<MaterialInventoryItemModel>>([]);
  const [materials, setMaterials] = useState<Array<MaterialInventoryItemModel>>([]);

  const materialInventoryItemsRequest = useGraphQl<{ materialInventoryItems: Array<MaterialInventoryItemModel> }>({
    query: materialInventoryItemsQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const garmentSpecSheetMaterialsRequest = useGraphQl<{ garmentSpecSheetMaterials: Array<MaterialInventoryItemModel> }>({
    query: garmentSpecSheetMaterialsQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const addMaterialToGarmentSpecSheetRequest = useGraphQl<{ addMaterialToGarmentSpecSheet: MaterialInventoryItemModel }>({
    query: addMaterialToGarmentSpecSheetMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const removeMaterialOfGarmentSpecSheetRequest = useGraphQl<{ removeMaterialOfGarmentSpecSheet: MaterialInventoryItemModel }>({
    query: removeMaterialOfGarmentSpecSheetMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const updateMaterialQuantityOnProductRequest = useGraphQl<{ updateMaterialQuantityOnProduct: Array<MaterialInventoryItemModel> }>({
    query: updateMaterialQuantityOnProductMutation,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  useEffect(() => {
    materialInventoryItemsRequest.invoke({
      variables: {
        storeId: store?.id,
        materialType: MaterialType.Aviamento,
      },
      onSuccess: ({ data: { materialInventoryItems } }) => {
        setTrimMaterials(materialInventoryItems);
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar a lista de aviamentos.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, []);

  useEffect(() => {
    garmentSpecSheetMaterialsRequest.invoke({
      variables: {
        storeId: store?.id,
        productId: product.id,
      },
      onSuccess: ({ data: { garmentSpecSheetMaterials } }) => {
        setMaterials(garmentSpecSheetMaterials);
        setProductMaterials(garmentSpecSheetMaterials);
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar os insumos do produto.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, []);

  useEffect(() => {
    materialInventoryItemsRequest.invoke({
      variables: {
        storeId: store?.id,
        materialType: MaterialType.Tecido,
      },
      onSuccess: ({ data: { materialInventoryItems } }) => {
        setFabricMaterials(materialInventoryItems);
      },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível carregar a lista de tecidos.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, []);

  const processMaterialValue = (materialItem: MaterialInventoryItemModel) => {
    if (!materialItem.itemValue || !materialItem.measures) {
      return 'Sem valor definido';
    }
    return [
      formatFloatValuePrice(materialItem.itemValue),
      ...(materialItem.measures?.map((measure) => measure.unit === 'custom' ?
        measure.customUnit :
        measure.unit).filter(u => u !== 'custom')) ?? [],
    ].join(' / ');
  };

  const processMaterialQuantities = useCallback((materialItem: MaterialInventoryItemModel) => {
    const onQuantityBlur = (e: FocusEvent<HTMLInputElement>, materialId: number, measure: MeasureOutputModel) => {
      updateMaterialQuantityOnProductRequest.invoke({
        variables: {
          storeId: store?.id,
          productId: product.id,
          materialId: materialId,
          unit: measure.unit === 'custom' ? measure.customUnit : measure.unit,
          quantity: parseFloat(e?.target?.value),
        },
        onSuccess: ({ data: { updateMaterialQuantityOnProduct } }) => {
          setMaterials(updateMaterialQuantityOnProduct);
          setProductMaterials(updateMaterialQuantityOnProduct);
        },
        onFail: (err) => {
          if (err.name === 'UnauthorizedRequestError') {
            redirect.to('/login');
            return;
          }

          addSnackbar({
            title: 'Não foi possível carregar os insumos do produto.',
            fail: true,
            actionText: 'OK',
          });
        },
      });
    };

    const productQuantitiesUnits = materialItem?.productQuantities?.map(pq => pq.unit === 'custom' ? pq.customUnit : pq.unit);

    const measures = [
      ...(materialItem?.measures?.filter(
        (measure) => !productQuantitiesUnits?.includes(measure.unit),
      ).map((measure) => ({
        unit: measure.unit,
        customUnit: measure.customUnit,
        quantity: undefined,
      })) ?? []),
      ...(materialItem?.productQuantities ?? []),
    ];

    return (
      <MaterialQuantityInputContainer>
        {measures?.map((measure) => {
          const unit = measure.unit === 'custom' ? measure.customUnit : measure.unit;
          if (unit === 'custom') return;
          const id = `${materialItem.id}-${unit}`;

          return (
            <MaterialQuantityInputWrapper key={unit}>
              <MaterialQuantityInput
                id={id}
                name={id}
                type="number"
                step="0.001"
                defaultValue={measure.quantity}
                onBlur={(e) => onQuantityBlur(e, materialItem.id, measure)}
              />
              <MaterialQuantityLabel htmlFor={id}>{unit}</MaterialQuantityLabel>
            </MaterialQuantityInputWrapper>
          );
        })}
      </MaterialQuantityInputContainer>
    );
  }, []);

  const onAddItemHandler = useCallback((addedItem: MaterialInventoryItemModel) => {
    addMaterialToGarmentSpecSheetRequest.invoke({
      variables: {
        storeId: store?.id,
        productId: product.id,
        materialId: addedItem.id,
      },
      // onSuccess: ({ data: { addMaterialToGarmentSpecSheet } }) => {
      // },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível adicionar o insumos ao produto.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, []);

  const onRemoveItemHandler = useCallback((removedItem: MaterialInventoryItemModel) => {
    removeMaterialOfGarmentSpecSheetRequest.invoke({
      variables: {
        storeId: store?.id,
        productId: product.id,
        materialId: removedItem.id,
      },
      // onSuccess: ({ data: { addMaterialToGarmentSpecSheet } }) => {
      // },
      onFail: (err) => {
        if (err.name === 'UnauthorizedRequestError') {
          redirect.to('/login');
          return;
        }

        addSnackbar({
          title: 'Não foi possível remover o insumo do produto.',
          fail: true,
          actionText: 'OK',
        });
      },
    });
  }, []);

  const onTableItemsChangeHandler = useCallback((changedItems: Array<MaterialInventoryItemModel>) => {
    setMaterials(changedItems);
    setProductMaterials(changedItems);
  }, []);

  return (
    <>
      {garmentSpecSheetMaterialsRequest.isFetching() ?
        null : (
          <>
            <FormControl>
              <DynamicTable<MaterialInventoryItemModel>
                id="fabrics"
                label="Selecione os tecidos"
                availableItems={fabricMaterials}
                initialItems={materials?.filter(m => m.itemType === MaterialType.Tecido)}
                title="Tecidos"
                emptyStateText="Nenhum tecido foi adicionado"
                optionProcessor={(item) => ({
                  key: 'id',
                  text: `${item.itemRef} - ${item.itemName} - ${item.itemColorName ?? 'Sem cor'}`,
                })}
                tableProcessor={{
                  'Nome': (item) => `${item.itemRef} - ${item.itemName} - ${item.itemColorName ?? 'Sem cor'}`,
                  'Fornecedor': (item) => item.supplierName,
                  'Valor': processMaterialValue,
                  'Quantidades': processMaterialQuantities,
                }}
                onAddItem={onAddItemHandler}
                onRemoveItem={onRemoveItemHandler}
                onTableItemsChange={onTableItemsChangeHandler}
                loading={materialInventoryItemsRequest.isFetching()}
                searchable
              />
            </FormControl>
            <HorizontalRule margin={`0 0 ${spaces.xLarge}`} />
            <FormControl>
              <DynamicTable<MaterialInventoryItemModel>
                id="trims"
                label="Selecione os aviamentos"
                availableItems={trimMaterials}
                initialItems={materials?.filter(m => m.itemType === MaterialType.Aviamento)}
                title="Aviamentos"
                emptyStateText="Nenhum aviamento foi adicionado"
                optionProcessor={(item) => ({
                  key: 'id',
                  text: `${item.itemRef} - ${item.itemName} - ${item.itemColorName ?? 'Sem cor'}`,
                })}
                tableProcessor={{
                  'Nome': (item) => `${item.itemRef} - ${item.itemName} - ${item.itemColorName ?? 'Sem cor'}`,
                  'Fornecedor': (item) => item.supplierName,
                  'Valor': processMaterialValue,
                  'Quantidades': processMaterialQuantities,
                }}
                onAddItem={onAddItemHandler}
                onRemoveItem={onRemoveItemHandler}
                onTableItemsChange={onTableItemsChangeHandler}
                loading={materialInventoryItemsRequest.isFetching()}
                searchable
              />
            </FormControl>
          </>
        )}
    </>
  );
};

export {
  SpecSheetMaterials,
};
