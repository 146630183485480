import { styled } from 'styled-components';
import { PaddingProps } from './Padding.types';

const PaddingStyle = styled.div<{ $padding: string, $wide: boolean }>`
  padding: ${({ $padding }) => $padding};
  width: ${({ $wide }) => ($wide ? '100%' : 'auto')};
`;

export type { PaddingProps };
export { PaddingStyle };
