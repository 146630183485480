import React, { useMemo, useState } from 'react';
import { Typography } from '../Typography';
import { ArrowHeadStart, ArrowHeadEnd } from '../../icons';
import { colors, fontWeight } from '../../tokens';
import { useRedirect } from '../../../utils/redirect';
import {
  BreadcrumbButton,
  BreadcrumbItemContainer,
} from './BreadcrumbItem.styles';

type BreadcrumbItemProps = {
  name: string;
  link: string;
  redirectOptions?: {
    params?: { [key: string]: string },
    doNotPreserveParams?: boolean,
    removeParams?: Array<string>,
    scroll?: boolean,
    repeatKeys?: boolean,
  },
};

const BreadcrumbItem = ({ name, link, redirectOptions }: BreadcrumbItemProps) => {
  const redirect = useRedirect();
  const [hovered, setHovered] = useState(false);
  const fill = useMemo(() => hovered ? colors.breadcrumbsHover : colors.breadcrumbs, [hovered]);

  return (
    <BreadcrumbItemContainer>
      <ArrowHeadStart fill={fill} />
      <BreadcrumbButton
        onClick={() => {
          setHovered(true);
          redirect.to(link, redirectOptions);
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Typography variant="caption" fontWeight={fontWeight.semiBold} fontStyle="normal" color={colors.primary}>
          {name}
        </Typography>
      </BreadcrumbButton>
      <ArrowHeadEnd fill={fill} />
    </BreadcrumbItemContainer >
  );
};

export type { BreadcrumbItemProps };
export { BreadcrumbItem };
