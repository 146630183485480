import React, { useContext, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useRedirect } from '../../../utils/redirect';
import { FeatureName } from '../../../../common/models/FeatureName';
import { useFeature } from '../../hooks/useFeature';
import { StoreContext } from '../../contexts/StoreContext';

const FeaturedRoute = ({ featureName }: { featureName: FeatureName }) => {
  const redirect = useRedirect();
  const { hasFeature } = useFeature();
  const { store } = useContext(StoreContext);

  const allowed = useMemo(() => hasFeature(featureName), [hasFeature, featureName]);

  useEffect(() => {
    if (!allowed) {
      if (store) {
        redirect.to(`store/${store?.id}`);
        return;
      }
      redirect.to('/store');
    }
  }, [allowed, store, redirect]);

  return <>{allowed ? <Outlet /> : null}</>;
};

export {
  FeaturedRoute,
};
