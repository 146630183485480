import React, { useState, ChangeEvent, useMemo } from 'react';
import {
  UseFormRegister,
  FieldValues,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form';
import { ValidState } from '../TextField';
import {
  InputRoot,
  TextAreaWrapper,
  InputLabel,
  TextAreaField,
  HintsWrapper,
  InputMessage,
  CharactersCounter,
} from './TextArea.styles';

interface TextAreaProps<T extends FieldValues> {
  id: string;
  label: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl>;
  helperText?: string;
  charLimit?: number;
  disabled?: boolean;
  state?: keyof typeof ValidState;
  defaultValue?: string;
  register?: UseFormRegister<T>;
  rows?: number;
  placeholder?: string;
  required?: boolean;
  forceActive?: boolean;
}

const TextArea = <T extends FieldValues,>({
  id,
  label,
  rows,
  errorMessage = '',
  helperText = '',
  charLimit,
  disabled = false,
  state = 'neutral',
  defaultValue = '',
  register,
  required = false,
  forceActive = true,
  ...rest
}: TextAreaProps<T>) => {
  const [value, setValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);
  const [valueLength, setValueLength] = useState(0);
  const [elementHeight, setElementHeight] = useState('');
  const isValid = useMemo(() => state === 'valid', [state]);
  const isInvalid = useMemo(() => state === 'invalid', [state]);
  const isActive = useMemo(() => forceActive || value !== '' || isFocused, [value, isFocused]);
  const showInputMessage = useMemo(
    () => isInvalid || helperText !== '',
    [isInvalid, helperText],
  );
  const inputMessage = useMemo(
    () => (isInvalid ? errorMessage.toString() : helperText),
    [isInvalid, errorMessage, helperText],
  );
  const hasHint = useMemo(
    () => showInputMessage || Boolean(charLimit),
    [showInputMessage, charLimit],
  );

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValueLength(e.target.value.length);
    setValue(e.target.value);
    setElementHeight(`${e.target.scrollHeight}px`);
  };

  const restWithRegister = useMemo(() => typeof register === 'function' ? {
    ...rest, ...(register as UseFormRegister<FieldValues>)(id, {
      onBlur: handleOnBlur,
      onChange: handleOnChange,
      required,
    }),
  } : { ...rest }, [rest, register]);

  return (
    <InputRoot>
      <TextAreaWrapper
        $isInvalid={isInvalid}
        $isValid={isValid}
        disabled={disabled}
      >
        <TextAreaField
          defaultValue={defaultValue}
          $customHeight={elementHeight}
          onFocus={handleOnFocus}
          id={id}
          disabled={disabled}
          maxLength={charLimit}
          rows={rows}
          {...restWithRegister}
        />
        <InputLabel
          htmlFor={id}
          $isInvalid={isInvalid}
          $isValid={isValid}
          disabled={disabled}
          $isActive={isActive}
          $placeholder={rest.placeholder}
        >
          {label}
          {required && ' *'}
        </InputLabel>
      </TextAreaWrapper>
      {hasHint && (
        <HintsWrapper $isInvalid={isInvalid}>
          <InputMessage>{inputMessage}</InputMessage>
          {charLimit && (
            <CharactersCounter>{`${valueLength}/${charLimit}`}</CharactersCounter>
          )}
        </HintsWrapper>
      )}
    </InputRoot>
  );
};

export { ValidState, TextArea };
