import React, { SVGProps } from 'react';

const ImagePlaceholder = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 72 72" height={72} width={72} {...props}>
      <path d="M72 64V8C72 3.6 68.4 0 64 0H8C3.6 0 0 3.6 0 8V64C0 68.4 3.6 72 8 72H64C68.4 72 72 68.4 72 64ZM22 42L32 54.04L46 36L64 60H8L22 42Z" />
    </svg>
  );
};

export { ImagePlaceholder };



