const colors = {
  backButton: '#C7C7ECFF',
  backButtonDarker: '#B4B4D6FF',
  background: '#2A114D0D',
  backgroundHover: '#2A114D1E',
  backgroundPrimary: '#ECEDF7',
  black: '#000000',
  breadcrumbs: '#BDC1EAFF',
  breadcrumbsHover: '#D2D6FFFF',
  buttonActive: '#22309CFF',
  buttonActiveDarker: '#0F175EFF',
  buttonHover: '#4B5AD6FF',
  disabled: '#ABAEC7FF',
  disabledDarker: '#7D809AFF',
  dropShadow: '#2A114D4C',
  error: '#E8224BFF',
  ghost: '#A2A2AFFF',
  gray: '#DDDDDD',
  neutral: '#2A114D4C',
  overlay: '#17082BC4',
  primary: '#303DA6FF',
  primaryDarker: '#172172FF',
  primaryLight: '#B8BDE9FF',
  secondary: '#182B3A99',
  success: '#43C88A',
  transparent: '#00000000',
  white: '#FFFFFFFF',
} as const;

export { colors };
