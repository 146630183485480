import gql from 'graphql-tag';

const updateApiTokenDomainQuery = gql`
  mutation($storeId: Int!, $domain: String!) {
    updateApiTokenDomain(storeId: $storeId, domain: $domain) {
      id
      guid
      createdAt
      updatedAt
      token
      domain
      enabled
    }
  }
`;

export { updateApiTokenDomainQuery };
