import React, { SVGProps } from 'react';

const MecenaIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" height={24} width={24} {...props}>
      <defs>
        <clipPath id="a">
          <path d="m0 1080h1080v-1080h-1080z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.3333 0 0 -1.3333 0 1440)">
        <g transform="translate(-117.02 446)">
          <g transform="translate(330.25 634)">
            <path fill="#FF7AAB" d="M-195.2-1v-10.9h-4.8l0,5.9h-8.3v5L-195.2-1z" />
          </g>
          <g transform="translate(272.81 575.12)">
            <path fill="#F5F24F" d="M-142.6,52.9h-8.3v5L-142.6,52.9L-142.6,52.9z" />
          </g>
          <g transform="translate(174.42 502.81)">
            <path fill="#303DA6" d="M-52.6,119.1l8.3-4.8h-13.2v10.9h4.8L-52.6,119.1z" />
          </g>
          <g transform="translate(272.81 575.12)">
            <path fill="#00FFFF" d="M-142.6,52.9l0-5.9v-5h0L-142.6,52.9L-142.6,52.9z" />
          </g>
          <g transform="translate(272.86 575.12)">
            <path fill="#59C9AD" d="M-142.7,52.9V42h0l-8.3,4.8v6.1L-142.7,52.9z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { MecenaIcon };
