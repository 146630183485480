import { styled } from 'styled-components';
import { spaces } from '../../../../ui';

const QuickAccessMenuContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: ${spaces.small};

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 950px) {
    overflow-x: scroll;
    display: flex;
    justify-content: space-between;
  }
`;

export {
  QuickAccessMenuContainer,
};
