import React, { ReactNode, HTMLAttributes } from 'react';
import { CardWrapper } from './Card.styles';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  width?: string;
  height?: string;
  padding?: string;
  margin?: number;
  nWidth?: number;
  overflow?: 'hidden' | 'scroll';
}

const Card = ({
  children,
  width = 'auto',
  height = 'auto',
  padding = '0',
  margin = 0,
  nWidth = 0,
  overflow,
  ...rest
}: CardProps) => {
  return (
    <CardWrapper
      width={width}
      height={height}
      $padding={padding}
      $margin={margin}
      $nWidth={nWidth}
      $overflow={overflow}
      {...rest}
    >
      {children}
    </CardWrapper>
  );
};

export type { CardProps };
export { Card };
