import gql from 'graphql-tag';

const removeTeamMemberMutation = gql`
  mutation(
    $id: Int!
    $storeId: Int!
  ) {
    removeTeamMember(
      id: $id
      storeId: $storeId
    )
  }
`;

export {
  removeTeamMemberMutation,
};
