const screens = {
  xxxl: '1600px',
  xxl: '1400px',
  xl: '1260px',
  lg: '1200px',
  md2: '1150px',
  md: '980px',
  sm2: '950px',
  sm: '768px',
  xs: '320px',
  xxxlMin: '1601px',
  xxlMin: '1261px',
  xlMin: '1401px',
  lgMin: '1201px',
  md2Min: '1151px',
  mdMin: '981px',
  sm2Min: '951px',
  smMin: '769px',
  xsMin: '321px',
} as const;

export { screens };
