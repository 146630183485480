import gql from 'graphql-tag';

const forgetPasswordMutation = gql`
  mutation($userName: String!) {
    forgetPassword(userName: $userName) {
      userName
      verified
      userNameType
    }
  }
`;

export { forgetPasswordMutation };
