import React, { SVGProps } from 'react';

const CheckBoxOutlineBlank = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 27 27" width={27} height={27} {...props}>
      <path
        d="M19,3c2.8,0,5,2.2,5,5v11c0,2.8-2.2,5-5,5H8c-2.8,0-5-2.2-5-5V8c0-2.8,2.2-5,5-5H19 M19,0H8C3.6,0,0,3.6,0,8v11
	c0,4.4,3.6,8,8,8h11c4.4,0,8-3.6,8-8V8C27,3.6,23.4,0,19,0L19,0z"
      />
    </svg>
  );
};

export { CheckBoxOutlineBlank };
