import { styled, css } from 'styled-components';
import { Size } from './Avatar.types';
import { colors, spaces } from '../../tokens';

interface AvatarProps {
  src?: string;
  size: Size;
}

const avatarSizeStyle = ({ size }: AvatarProps) => {
  if (size === 'large') {
    return css`
      width: 53px;
      height: 53px;
    `;
  }

  return css`
    width: 36px;
    height: 36px;
  `;
};

const AvatarWrapper = styled.div<AvatarProps>`
  ${avatarSizeStyle};
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  border-radius: 50%;
`;

const FallbackAvatar = styled.div<AvatarProps>`
  ${avatarSizeStyle};
  border-radius: 50%;
  background-color: ${colors.primary};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: ${spaces.xLarge};

  & svg {
    fill: ${colors.primaryLight};
  }
`;

export { AvatarWrapper, FallbackAvatar };
