import React, { useContext, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm, UseFormRegister } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  colors,
  EyeIcon,
  EyeOffIcon,
  Form,
  FormControl,
  TextField,
  Typography,
} from '../../../../ui';
import { useGraphQl } from '../../../../utils/gql';
import { AdminUserModel } from '../../../../../common/models';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';
import { updatePasswordQuery } from '../../../queries/updatePasswordQuery';
import { SnackbarContext } from '../../../contexts/SnackbarContext';

const updatePasswordValidationSchema = yup.object({
  password: yup.string().required('A nova senha é obrigatória.').min(8, 'A senha deve ter no mínimo 8 caracteres.'),
  confirmPassword: yup.string().required('A confirmação da nova senha é obrigatória.'),
});

const UpdatePasswordForm = ({
  title,
  onSubmitSucceeded,
}: {
  title: string,
  onSubmitSucceeded: (response: { userId: number }) => void,
}) => {
  const { addSnackbar } = useContext(SnackbarContext);
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    resolver: yupResolver(updatePasswordValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  const updatePasswordRequest = useGraphQl<{ updatePassword: AdminUserModel }>({
    query: updatePasswordQuery,
    url: getApiEndpoint({ route: apiRoutes.admin }),
  });

  const onSubmit = (data: { password: string, confirmPassword: string }) => {
    if (data.password !== data.confirmPassword) {
      addSnackbar({
        title: 'As senhas devem ser iguais nos dois campos.',
        actionText: 'OK',
        fail: true,
      });
      return;
    }

    updatePasswordRequest.invoke({
      variables: {
        password: data.password,
        confirmPassword: data.confirmPassword,
      },
      onFail: (err) => {
        addSnackbar({
          title: `Falha ao atualizar a senha do usuário. ${err?.message
            }`,
          actionText: 'OK',
          fail: true,
        });
      },
      onSuccess: ({ data }) => {
        onSubmitSucceeded({ userId: data.updatePassword.id });
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Typography variant="lead" color={colors.primary}>{title}</Typography>
      </FormControl>
      <FormControl>
        <TextField
          id="password"
          type={hidePassword ? 'password' : 'text'}
          label="nova senha"
          register={castedRegister}
          errorMessage={errors.password?.message}
          state={errors.password ? 'invalid' : 'neutral'}
          iconButton={{
            icon: hidePassword ? <EyeOffIcon /> : <EyeIcon />,
            label: hidePassword ? 'exibir senha' : 'esconder senha',
            onClick: () => setHidePassword(!hidePassword),
          }}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="confirmPassword"
          type={hideConfirmPassword ? 'password' : 'text'}
          label="confirmar nova senha"
          register={castedRegister}
          errorMessage={errors.confirmPassword?.message}
          state={errors.confirmPassword ? 'invalid' : 'neutral'}
          iconButton={{
            icon: hideConfirmPassword ? <EyeOffIcon /> : <EyeIcon />,
            label: hideConfirmPassword ? 'exibir senha' : 'esconder senha',
            onClick: () => setHideConfirmPassword(!hideConfirmPassword),
          }}
        />
      </FormControl>
      <Button type='submit' variant='default'>Continuar</Button>
    </Form>
  );
};

export { UpdatePasswordForm };
