import { createGlobalStyle } from 'styled-components';
import { colors, fontFamily } from '../../tokens';

const GlobalReset = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${fontFamily.montserrat};
  }

  body {
    background-color: ${colors.white};
  }

  html, body, body > * {
    min-height: 100%;
  }

  ul {
    list-style-type: none;
  }

  a {
    display: block;
    text-decoration: solid;
  }
`;

export { GlobalReset };
