import React, { SVGProps, useMemo } from 'react';
import { useLocalization } from '../../contexts/LocalizationContext';

type MecenaLogoProps = {
  size?: 'lg' | 'md' | 'sm' | 'xs',
  variant?: 'whiteBg' | 'blackBg' | 'yellowBg',
} & SVGProps<SVGSVGElement>;

type MecenaLogoColor = 'white' | 'black' | '#F5F24F';

type MecenaLogoColors = {
  typeColor: MecenaLogoColor,
  detailColor: MecenaLogoColor,
}

const LogoLg = ({ typeColor, detailColor }: MecenaLogoColors) => (
  <g>
    <path d="M92.9,0v57.7h-25V26.3H25V0H92.9z" fill="#FF7AAB" />
    <path d="M67.9,26.3H25V0L67.9,26.3L67.9,26.3z" fill={detailColor} />
    <path d="M25,58.6L67.9,84H0V26.3h25V58.6z" fill="#303DA6" />
    <path d="M67.9,26.3V84l0,0L25,58.6V26.3H67.9z" fill="#59C9AD" />
    <path d="M174.2,31.9c-5.5,0-10.1,2.4-12.6,6.2c-2.2-4.2-6.3-6.2-11.3-6.2c-4.9,0-8.9,1.9-11.3,5.3v-5h-5.7v33.5h5.9V48.5
		c0-7.3,3.9-11.1,9.8-11.1c5.4,0,8.4,3.2,8.4,9.8v18.6h5.9V48.5c0-7.3,3.9-11.1,9.8-11.1c5.4,0,8.4,3.2,8.4,9.8v18.6h5.9V46.4
		C187.6,36.6,182.2,31.9,174.2,31.9z M226.2,49.2c0-10.3-6.7-17.2-16.1-17.2c-9.4,0-16.4,7.1-16.4,17.1s7,17.1,17.6,17.1
		c5.4,0,9.9-1.9,12.9-5.4l-3.3-3.9c-2.4,2.7-5.6,4-9.4,4c-6.5,0-11.1-3.9-11.8-9.8h26.5C226.2,50.4,226.2,49.7,226.2,49.2 M210.1,37
		c5.7,0,9.9,4,10.5,9.6h-20.9C200.2,40.9,204.4,37,210.1,37z M247.5,66.1c5.9,0,10.7-2.5,13.3-7.2l-4.5-2.9
		c-2.1,3.3-5.3,4.8-8.9,4.8c-6.4,0-11.2-4.5-11.2-11.8c0-7.1,4.8-11.7,11.2-11.7c3.6,0,6.8,1.5,8.9,4.8l4.5-3
		c-2.6-4.7-7.5-7.1-13.3-7.1c-10,0-17.2,7.1-17.2,17.1S237.5,66.1,247.5,66.1 M296,49.2c0-10.3-6.7-17.2-16.1-17.2
		s-16.4,7.1-16.4,17.1s7,17.1,17.6,17.1c5.4,0,9.9-1.9,12.9-5.4l-3.3-3.9c-2.4,2.7-5.6,4-9.4,4c-6.5,0-11.1-3.9-11.8-9.8H296
		C295.9,50.4,296,49.7,296,49.2 M279.9,37c5.7,0,9.9,4,10.5,9.6h-20.9C270,40.9,274.2,37,279.9,37z M319.8,31.9
		c-5.2,0-9.4,2-11.8,5.4v-5.1h-5.7v33.5h5.9V48.5c0-7.3,4.1-11.1,10.4-11.1c5.6,0,8.8,3.2,8.8,9.8v18.6h5.9V46.4
		C333.3,36.6,327.7,31.9,319.8,31.9z M354,31.9c-5.2,0-10.1,1.5-13.5,4.3l2.5,4.5c2.5-2.2,6.5-3.6,10.3-3.6c5.9,0,8.7,3,8.7,8.1v1.2
		h-9.4c-9.7,0-13.1,4.4-13.1,9.8c0,5.8,4.7,9.9,12.1,9.9c5.1,0,8.7-1.8,10.7-4.8v4.4h5.6V45.5C368,36.3,363,31.9,354,31.9
		 M352.7,61.4c-4.5,0-7.2-2.1-7.2-5.4c0-2.8,1.7-5.2,7.5-5.2h9.1v4.7C360.6,59.3,357.1,61.4,352.7,61.4" fill={typeColor} />
  </g>
);

const LogoMd = ({ typeColor, detailColor }: MecenaLogoColors) => (
  <g>
    <path d="M71.9,0v44.6H52.5V20.4H19.3V0H71.9z" fill="#FF7AAB" />
    <path d="M52.5,20.4H19.3V0L52.5,20.4L52.5,20.4z" fill={detailColor} />
    <path d="M19.3,45.3L52.5,65H0V20.4h19.3V45.3z" fill="#303DA6" />
    <path d="M52.5,20.4V65l0,0L19.3,45.3v-25H52.5z" fill="#59C9AD" />
    <path d="M134.8,24.7c-4.3,0-7.8,1.9-9.8,4.8c-1.7-3.2-4.9-4.8-8.7-4.8c-3.8,0-6.9,1.5-8.7,4.1v-3.9h-4.4v25.9h4.6V37.5
		c0-5.6,3-8.6,7.6-8.6c4.2,0,6.5,2.5,6.5,7.6v14.4h4.6V37.5c0-5.6,3-8.6,7.6-8.6c4.2,0,6.5,2.5,6.5,7.6v14.4h4.6v-15
		C145.2,28.3,141,24.7,134.8,24.7z M175,38.1c0-8-5.2-13.3-12.5-13.3c-7.3,0-12.7,5.5-12.7,13.2s5.4,13.2,13.6,13.2
		c4.2,0,7.7-1.5,10-4.2l-2.6-3c-1.9,2.1-4.3,3.1-7.3,3.1c-5,0-8.6-3-9.1-7.6H175C175,39,175,38.5,175,38.1 M162.6,28.6
		c4.4,0,7.7,3.1,8.1,7.4h-16.2C154.9,31.6,158.2,28.6,162.6,28.6z M191.5,51.1c4.6,0,8.3-1.9,10.3-5.6l-3.5-2.2
		c-1.6,2.6-4.1,3.7-6.9,3.7c-5,0-8.7-3.5-8.7-9.1c0-5.5,3.7-9.1,8.7-9.1c2.8,0,5.3,1.2,6.9,3.7l3.5-2.3c-2-3.6-5.8-5.5-10.3-5.5
		c-7.7,0-13.3,5.5-13.3,13.2S183.8,51.1,191.5,51.1 M229,38.1c0-8-5.2-13.3-12.5-13.3s-12.7,5.5-12.7,13.2s5.4,13.2,13.6,13.2
		c4.2,0,7.7-1.5,10-4.2l-2.6-3c-1.9,2.1-4.3,3.1-7.3,3.1c-5,0-8.6-3-9.1-7.6H229C229,39,229,38.5,229,38.1 M216.6,28.6
		c4.4,0,7.7,3.1,8.1,7.4h-16.2C208.9,31.6,212.2,28.6,216.6,28.6z M247.5,24.7c-4,0-7.3,1.5-9.1,4.2v-3.9h-4.4v25.9h4.6V37.5
		c0-5.6,3.2-8.6,8-8.6c4.3,0,6.8,2.5,6.8,7.6v14.4h4.6v-15C257.9,28.3,253.6,24.7,247.5,24.7z M273.9,24.7c-4,0-7.8,1.2-10.4,3.3
		l1.9,3.5c1.9-1.7,5-2.8,8-2.8c4.6,0,6.7,2.3,6.7,6.3v0.9h-7.3c-7.5,0-10.1,3.4-10.1,7.6c0,4.5,3.6,7.7,9.4,7.7
		c3.9,0,6.7-1.4,8.3-3.7v3.4h4.3V35.2C284.8,28.1,280.9,24.7,273.9,24.7 M272.9,47.5c-3.5,0-5.6-1.6-5.6-4.2c0-2.2,1.3-4,5.8-4h7
		v3.6C279,45.9,276.3,47.5,272.9,47.5" fill={typeColor} />
  </g>
);

const LogoSm = ({ typeColor, detailColor }: MecenaLogoColors) => (
  <g>
    <path d="M55.3,0v34.3H40.4V15.7H14.9V0H55.3z" fill="#FF7AAB" />
    <path d="M40.4,15.7H14.9V0L40.4,15.7L40.4,15.7z" fill={detailColor} />
    <path d="M14.9,34.9L40.4,50H0V15.7h14.9V34.9z" fill="#303DA6" />
    <path d="M40.4,15.7V50l0,0L14.9,34.9V15.7H40.4z" fill="#59C9AD" />
    <path d="M103.7,19c-3.3,0-6,1.4-7.5,3.7c-1.3-2.5-3.8-3.7-6.7-3.7c-2.9,0-5.3,1.1-6.7,3.2v-3h-3.4v19.9h3.5V28.9
		c0-4.3,2.3-6.6,5.8-6.6c3.2,0,5,1.9,5,5.8v11.1h3.5V28.9c0-4.3,2.3-6.6,5.8-6.6c3.2,0,5,1.9,5,5.8v11.1h3.5V27.6
		C111.7,21.8,108.5,19,103.7,19z M134.6,29.3c0-6.1-4-10.2-9.6-10.2c-5.6,0-9.8,4.2-9.8,10.2s4.2,10.2,10.5,10.2
		c3.2,0,5.9-1.1,7.7-3.2l-2-2.3c-1.4,1.6-3.3,2.4-5.6,2.4c-3.9,0-6.6-2.3-7-5.8h15.8C134.6,30,134.6,29.6,134.6,29.3 M125.1,22
		c3.4,0,5.9,2.4,6.3,5.7h-12.4C119.2,24.3,121.7,22,125.1,22z M147.3,39.3c3.5,0,6.4-1.5,7.9-4.3l-2.7-1.7c-1.2,2-3.2,2.9-5.3,2.9
		c-3.8,0-6.7-2.7-6.7-7c0-4.2,2.9-7,6.7-7c2.1,0,4,0.9,5.3,2.9l2.7-1.8c-1.5-2.8-4.5-4.2-7.9-4.2c-6,0-10.2,4.2-10.2,10.2
		S141.4,39.3,147.3,39.3 M176.2,29.3c0-6.1-4-10.2-9.6-10.2s-9.8,4.2-9.8,10.2s4.2,10.2,10.5,10.2c3.2,0,5.9-1.1,7.7-3.2l-2-2.3
		c-1.4,1.6-3.3,2.4-5.6,2.4c-3.9,0-6.6-2.3-7-5.8h15.8C176.1,30,176.2,29.6,176.2,29.3 M166.6,22c3.4,0,5.9,2.4,6.2,5.7h-12.4
		C160.7,24.3,163.2,22,166.6,22z M190.4,19c-3.1,0-5.6,1.2-7,3.2v-3h-3.4v19.9h3.5V28.9c0-4.3,2.4-6.6,6.2-6.6
		c3.3,0,5.2,1.9,5.2,5.8v11.1h3.5V27.6C198.4,21.8,195.1,19,190.4,19z M210.7,19c-3.1,0-6,0.9-8,2.6l1.5,2.7
		c1.5-1.3,3.9-2.1,6.1-2.1c3.5,0,5.2,1.8,5.2,4.8v0.7h-5.6c-5.8,0-7.8,2.6-7.8,5.8c0,3.5,2.8,5.9,7.2,5.9c3,0,5.2-1.1,6.4-2.9v2.6
		h3.3v-12C219,21.6,216.1,19,210.7,19 M209.9,36.5c-2.7,0-4.3-1.2-4.3-3.2c0-1.7,1-3.1,4.5-3.1h5.4V33
		C214.6,35.3,212.6,36.5,209.9,36.5" fill={typeColor} />
  </g>
);

const LogoXs = ({ typeColor, detailColor }: MecenaLogoColors) => (
  <g>
    <path d="M33.2,0v20.6h-8.9V9.4H8.9V0H33.2z" fill="#FF7AAB" />
    <path d="M24.3,9.4H8.9V0L24.3,9.4L24.3,9.4z" fill={detailColor} />
    <path d="M8.9,20.9L24.3,30H0V9.4h8.9V20.9z" fill="#303DA6" />
    <path d="M24.3,9.4V30l0,0L8.9,20.9V9.4H24.3z" fill="#59C9AD" />
    <path d="M62.2,11.4c-2,0-3.6,0.9-4.5,2.2c-0.8-1.5-2.2-2.2-4-2.2c-1.7,0-3.2,0.7-4,1.9v-1.8h-2v12h2.1v-6.1c0-2.6,1.4-4,3.5-4
		c1.9,0,3,1.1,3,3.5v6.6h2.1v-6.2c0-2.6,1.4-4,3.5-4c1.9,0,3,1.1,3,3.5v6.6h2.1v-6.9C67,13.1,65.1,11.4,62.2,11.4z M80.8,17.6
		c0-3.7-2.4-6.1-5.8-6.1c-3.4,0-5.9,2.5-5.9,6.1s2.5,6.1,6.3,6.1c1.9,0,3.5-0.7,4.6-1.9l-1.2-1.4c-0.9,1-2,1.4-3.4,1.4
		c-2.3,0-4-1.4-4.2-3.5h9.5C80.8,18,80.8,17.8,80.8,17.6 M75,13.2c2,0,3.5,1.4,3.8,3.4h-7.5C71.5,14.6,73,13.2,75,13.2z M88.4,23.6
		c2.1,0,3.8-0.9,4.8-2.6l-1.6-1c-0.8,1.2-1.9,1.7-3.2,1.7c-2.3,0-4-1.6-4-4.2c0-2.5,1.7-4.2,4-4.2c1.3,0,2.4,0.5,3.2,1.7l1.6-1.1
		c-0.9-1.7-2.7-2.5-4.8-2.5c-3.6,0-6.1,2.5-6.1,6.1S84.8,23.6,88.4,23.6 M105.7,17.6c0-3.7-2.4-6.1-5.8-6.1s-5.9,2.5-5.9,6.1
		s2.5,6.1,6.3,6.1c1.9,0,3.5-0.7,4.6-1.9l-1.2-1.4c-0.9,1-2,1.4-3.4,1.4c-2.3,0-4-1.4-4.2-3.5h9.5C105.7,18,105.7,17.8,105.7,17.6
		 M100,13.2c2,0,3.5,1.4,3.8,3.4h-7.5C96.4,14.6,97.9,13.2,100,13.2z M114.2,11.4c-1.9,0-3.4,0.7-4.2,1.9v-1.8h-2v12h2.1v-6.1
		c0-2.6,1.5-4,3.7-4c2,0,3.1,1.1,3.1,3.5v6.6h2.1v-6.9C119,13.1,117,11.4,114.2,11.4z M126.4,11.4c-1.9,0-3.6,0.5-4.8,1.5l0.9,1.6
		c0.9-0.8,2.3-1.3,3.7-1.3c2.1,0,3.1,1.1,3.1,2.9v0.4h-3.4c-3.5,0-4.7,1.6-4.7,3.5c0,2.1,1.7,3.5,4.3,3.5c1.8,0,3.1-0.6,3.8-1.7v1.6
		h2v-7.2C131.4,13,129.6,11.4,126.4,11.4 M126,21.9c-1.6,0-2.6-0.8-2.6-1.9c0-1,0.6-1.9,2.7-1.9h3.3v1.7
		C128.8,21.2,127.5,21.9,126,21.9" fill={typeColor} />
  </g>
);

const MecenaLogoVariants = ({
  size = 'lg',
  variant = 'whiteBg',
  ...props
}: MecenaLogoProps) => {
  const { locale } = useLocalization();

  const [typeColor, detailColor]: [MecenaLogoColor, MecenaLogoColor] = useMemo(() => {
    switch (variant) {
      case 'blackBg':
        return ['white', '#F5F24F'];
      case 'whiteBg':
        return ['black', '#F5F24F'];
      case 'yellowBg':
        return ['black', 'black'];
      default:
        return ['black', '#F5F24F'];
    }
  }, [variant]);

  const [width, height, Component] = useMemo(() => {
    switch (size) {
      case 'lg':
        return [367.9015, 84, LogoLg];
      case 'md':
        return [284.6857, 65, LogoMd];
      case 'sm':
        return [218.989, 50, LogoSm];
      case 'xs':
        return [131.3934, 30, LogoXs];
      default:
        return [0, 0, null];
    }
  }, [size]);

  const alt = useMemo(() => locale('logo.alt') === 'logo.alt' ? 'Logo Mecena' : locale('logo.alt'), [locale]);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <title>{alt}</title>
      <Component typeColor={typeColor} detailColor={detailColor} />
    </svg>
  );
};

export { MecenaLogoVariants };
