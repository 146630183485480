import { useEffect } from 'react';

type useKeyListenerProps = {
  condition: boolean;
  callback(): void;
  keyCode?: 'Escape' | 'Enter';
};

const useKeyListener = ({ condition, callback, keyCode = 'Escape' }: useKeyListenerProps) => {
  useEffect(() => {
    const onCloseListener = (event: KeyboardEvent) => {
      if (event.key === keyCode && condition) {
        callback();
      }
    };

    window.addEventListener('keydown', onCloseListener, false);
    return () => window.removeEventListener('keydown', onCloseListener, false);
  });
};

export { useKeyListener };
