import React from 'react';
import { Head } from './Table.styles';
import { TableHeadProps } from './Table.types';
import { colors } from '../../tokens';

const TableHead = ({
  children,
  color = colors.background,
  ...props
}: TableHeadProps) => {
  return (
    <Head color={color} {...props}>
      {children}
    </Head>
  );
};

export { TableHead };
