import { styled } from 'styled-components';
import {
  InputRoot,
  InputWrapper,
  InputLabel,
  HintsWrapper,
  InputMessage,
  CharactersCounter,
} from '../TextField';

interface TextAreaFieldProps {
  $customHeight: string;
}

const TextAreaWrapper = styled(InputWrapper)`
  min-height: 56px;
  height: auto;
`;

const TextAreaField = styled.textarea<TextAreaFieldProps>`
  resize: none;
  width: 100%;
  height: ${({ $customHeight }) => $customHeight || '100%'};
  background-color: transparent;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

export {
  TextAreaField,
  InputRoot,
  TextAreaWrapper,
  InputLabel,
  HintsWrapper,
  InputMessage,
  CharactersCounter,
};
