import React, { useState, useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useStateMachine } from '../../../contexts/StateMachine';
import {
  FormControl,
  Typography,
  Button,
  TextField,
  Form,
  Padding,
  EyeIcon,
  EyeOffIcon,
  colors,
} from '../../../../ui';
import { initialRedirectTo, useRedirect } from '../../../../utils/redirect';
import { useGraphQl } from '../../../../utils/gql';
import { loginMutation } from '../../../queries';
import { AuthenticatorContext } from '../../../contexts/AuthenticatorContext';
import { AuthStateMachineData } from '../AuthPage';
import { AuthLinks } from './LoginForm.styles';
import { authPageStateValues, AdminUserModel } from '../../../../../common/models';
import { apiRoutes, getApiEndpoint } from '../../../../utils/apiEndpoints';

const loginValidationSchema = yup.object({
  userName: yup.string().required('O login é obrigatório.'),
  password: yup.string().required('A senha é obrigatória.'),
});

type LoginInput = {
  userName: string,
  password: string,
}

const LoginForm = () => {
  const redirect = useRedirect();
  const { invalidateFetching, setToken, setRefreshToken, setUser } = useContext(AuthenticatorContext);
  const { setState, data: stateMachineData } = useStateMachine<AuthStateMachineData>();
  const [hidePassword, setHidePassword] = useState(true);
  const [queryParams] = useSearchParams();
  const loginRequest = useGraphQl<{ login: AdminUserModel }>({ query: loginMutation, url: getApiEndpoint({ route: apiRoutes.auth }) });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      userName: stateMachineData.userName,
      password: undefined,
    },
    resolver: yupResolver(loginValidationSchema),
  });
  const castedRegister = register as unknown as UseFormRegister<FieldValues>;

  const onSubmit = useCallback((data: LoginInput) => {
    setState(authPageStateValues.loading, { userName: data.userName });

    loginRequest.invoke({
      variables: data,
      onSuccess: (response) => {
        if (!response.data.login) return;

        const user = response.data.login;

        const token = response.headers.authorization ??
          response.headers.Authorization ??
          response.headers['x-amzn-remapped-authorization'] ??
          response.headers['X-Amzn-Remapped-Authorization'];

        setToken(token);
        setRefreshToken(response.headers['x-mecena-ref-token']);
        setUser(user);

        invalidateFetching();

        if (!user?.completedOnboarding) {
          redirect.to('/onboarding', { removeParams: ['redirect_to'] });
          return;
        }

        initialRedirectTo(queryParams, redirect);
      },
      onFail: (loginError) => {
        setState(authPageStateValues.error, {
          errorMessage: loginError?.message === 'Network Error' ? 'Erro de conexão com o servidor.' : loginError?.message,
        });
      },
    });
  }, [setToken, setUser]);

  return (
    <Padding padding="20px 0" wide>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Typography variant="subtitle" color={colors.primary} align="center">
            Efetuar login
          </Typography>
        </FormControl>
        <FormControl>
          <TextField
            id="userName"
            label="login"
            type="text"
            placeholder="E-mail, telefone ou CPF"
            register={castedRegister}
            errorMessage={errors.userName?.message}
            state={errors.userName ? 'invalid' : 'neutral'}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="password"
            type={hidePassword ? 'password' : 'text'}
            label="senha"
            register={castedRegister}
            errorMessage={errors.password?.message}
            state={errors.password ? 'invalid' : 'neutral'}
            iconButton={{
              icon: hidePassword ? <EyeOffIcon /> : <EyeIcon />,
              label: hidePassword ? 'exibir senha' : 'esconder senha',
              onClick: () => setHidePassword(!hidePassword),
            }}
          />
        </FormControl>
        <FormControl>
          <Button variant="default" wide type="submit">
            Entrar
          </Button>
        </FormControl>
        <AuthLinks>
          {/* <Typography
            variant="link"
            cursor="pointer"
            fontWeight={fontWeight.bold}
            align="center"
            color={colors.primary}
            onClick={() => {
              setState(authPageStateValues.password, { userName: getValues('userName') });
            }}
          >
            Esqueceu a senha?
          </Typography> */}
          {/* <Separator>|</Separator>
          <Typography
            variant="link"
            cursor="pointer"
            fontWeight={fontWeight.bold}
            align="center"
            color={colors.primary}
            onClick={() => {
              setState(authPageStateValues.signup);
            }}
          >
            Cadastrar-se
          </Typography> */}
        </AuthLinks>
      </Form>
    </Padding>
  );
};

export { LoginForm };
