import { styled } from 'styled-components';
import { screens, spaces } from '../../tokens';

const Wrapper = styled.div`
  margin-left: 252px;
  margin-bottom: ${spaces.xLarge};
  max-width: 1100px;
  box-sizing: border-box;
  width: calc(100% - 252px);
  padding: 0px ${spaces.xLarge};

  @media screen and (max-width: ${screens.sm}) {
    width: 100%;
    margin-left: auto;
  }
`;

export { Wrapper };
