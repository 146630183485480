import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import FocusLock, { AutoFocusInside } from 'react-focus-lock';
import { useKeyListener } from '../../../utils/useKeyListener';
import { Typography } from '../Typography';
import {
  ContentWrapper,
  ModalWrapper,
  ModalOverlay,
  Header,
  Body,
  CloseButton,
  CloseIcon,
} from './Modal.styles';
import { colors, zIndex } from '../../tokens';

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  overlayProps?: Record<string, unknown>;
  title?: string;
}

const Modal = ({
  children,
  isOpen,
  onClose,
  overlayProps = {},
  title = '',
}: ModalProps) => {
  useKeyListener({ condition: isOpen, callback: onClose });

  useEffect(() => {
    if (document?.body?.style?.overflow) {
      document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    }

    const mainMenuContainer = document.getElementById('main-menu-container');
    const mainMenuOverlay = document.getElementById('main-menu-overlay');
    const accountContainer = document.getElementById('account-container');

    if (mainMenuContainer && mainMenuContainer.style) {
      mainMenuContainer.style.zIndex = String(isOpen ? 0 : zIndex.heaven);
    }

    if (mainMenuOverlay && mainMenuOverlay.style) {
      mainMenuOverlay.style.zIndex = String(isOpen ? 0 : zIndex.purgatory);
    }

    if (accountContainer && accountContainer.style) {
      accountContainer.style.zIndex = String(isOpen ? 0 : zIndex.mountains);
    }

    if (
      document && document.body && document.body.style &&
      mainMenuContainer && mainMenuContainer.style &&
      mainMenuOverlay && mainMenuOverlay.style &&
      accountContainer && accountContainer.style
    ) {
      return () => {
        document.body.style.overflow = 'unset';
        mainMenuContainer.style.zIndex = String(zIndex.heaven);
        mainMenuOverlay.style.zIndex = String(zIndex.purgatory);
        accountContainer.style.zIndex = String(zIndex.mountains);
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <ContentWrapper>
      <ModalOverlay onClick={onClose} {...overlayProps} />
      <FocusLock>
        <ModalWrapper aria-modal="true" role="dialog" title={title}>
          <Header>
            {title ? (
              <Typography variant="lead" color={colors.primary}>
                {title}
              </Typography>
            ) : (
              <div />
            )}
            <CloseButton
              aria-label="Fechar"
              title="Fechar"
              type="button"
              onClick={onClose}
            >
              <CloseIcon />
            </CloseButton>
          </Header>
          <Body>
            <AutoFocusInside>{children}</AutoFocusInside>
          </Body>
        </ModalWrapper>
      </FocusLock>
    </ContentWrapper>,
    document.body,
  );
};

export { Modal };
