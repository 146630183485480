import React, { SVGProps } from 'react';

const Gear = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 24 24" height={24} width={24} {...props}>
			<path d="M20,9.2l-0.2,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.2,0-0.2,0c0,0,0,0-0.1-0.1c-0.1-0.2-0.1-0.2,0-0.3c0.2-0.2,0.4-0.3,0.6-0.6
		c0.7-0.8,0.7-1.5,0-2.3C19.5,5.5,19.2,5.3,19,5l-0.4-0.4c-0.7-0.7-1.1-1.1-1.7-1.1s-1,0.4-1.7,1.1C15,4.8,15,4.8,14.8,4.7
		c-0.2-0.1-0.2-0.1-0.2-0.3c0-0.2,0-0.4,0-0.6l0-0.2c0-1-0.6-1.7-1.7-1.7c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0
		c-0.8,0-1.6-0.1-2,0.5C9,2.8,8.9,3.5,9,4.5c0,0.1,0,0.2-0.2,0.3c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1-0.1C8.3,4.5,8.2,4.3,8,4.1
		c-0.8-0.8-1.6-0.8-2.4,0C5.3,4.4,5,4.7,4.7,4.9L4.4,5.3C3.7,5.9,3.3,6.4,3.3,7c0,0.6,0.4,1,1.1,1.7c0.1,0.1,0.2,0.2,0.2,0.2
		c0,0,0,0,0,0.1C4.4,9.2,4.4,9.2,4.3,9.2c-0.2,0-0.5,0-0.7,0l-0.1,0c-1,0-1.6,0.6-1.6,1.6c0,0.9,0,1.7,0,2.6c0,0.9,0.7,1.5,1.6,1.6
		l0.1,0c0.2,0,0.4,0,0.6,0c0.2,0,0.2,0,0.3,0.2c0.1,0.2,0.1,0.2-0.1,0.3C4.2,15.6,4,15.8,3.8,16c-0.7,0.8-0.7,1.5,0,2.3
		c0.3,0.3,0.5,0.5,0.8,0.8L5,19.4c0.7,0.7,1.1,1.1,1.7,1.1c0,0,0,0,0,0c0.6,0,1-0.4,1.7-1.1c0.1-0.1,0.2-0.1,0.2-0.1c0,0,0,0,0.1,0
		c0.3,0.1,0.2,0,0.2,0.2c0,0.2,0,0.5,0,0.7l0,0.1c0,1,0.6,1.6,1.6,1.7c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5,0l0.5,0
		c2.1,0,2.5-0.3,2.5-2.5c0-0.1,0-0.2,0-0.2c0,0,0,0,0.1-0.1c0.2-0.1,0.2-0.1,0.4,0.1c0.3,0.3,0.6,0.5,0.9,0.8
		c0.1,0.1,0.3,0.2,0.4,0.3l0.3,0.2l0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.3-0.3,0.6-0.5,0.8-0.8l0.4-0.4
		c0.7-0.7,1.1-1.1,1.1-1.7c0-0.6-0.4-1-1.1-1.7c-0.2-0.2-0.2-0.2-0.1-0.3c0.1-0.2,0.1-0.2,0.3-0.1c0.2,0,0.3,0,0.5,0l0.2,0
		c1.1,0,1.7-0.6,1.7-1.7c0-0.7,0-1.5,0-2.2C21.8,9.8,21.3,9.2,20,9.2z M17.4,14.3c-0.6,1.3,0.2,2.1,0.7,2.5c0.1,0,0.1,0.1,0.1,0.1
		l-1.4,1.4c-0.7-0.7-1.6-1.5-3-0.7c-1,0.6-1,1.4-1,2c0,0.1,0,0.2,0,0.3h-1.9c0-1-0.1-2.3-1.9-2.7c-1-0.2-1.6,0.4-2,0.8
		c0,0-0.1,0.1-0.1,0.1l-1.4-1.4c0,0,0.1-0.1,0.1-0.1c0.5-0.5,1.2-1.2,0.7-2.5C5.7,13,4.7,13,4,13c-0.1,0-0.2,0-0.3,0v-1.9
		c1.1,0,2.3-0.1,2.7-1.8c0.3-1.1-0.3-1.6-0.8-2C5.6,7.2,5.5,7.1,5.4,7.1l1.5-1.4c0,0,0.1,0.1,0.1,0.1C7.5,6.3,8.2,7,9.5,6.5
		c1.4-0.6,1.3-1.6,1.3-2.4c0-0.1,0-0.1,0-0.2h2c0,0.1,0,0.2,0,0.3c0,0.7,0,1.7,1.3,2.2c1.3,0.6,2.1-0.2,2.6-0.7
		c0.1-0.1,0.1-0.1,0.2-0.2l1.4,1.5c-0.1,0.1-0.1,0.1-0.2,0.2c-0.5,0.5-1.2,1.2-0.7,2.5c0.5,1.3,1.6,1.3,2.3,1.3c0.1,0,0.2,0,0.2,0v2
		c-0.1,0-0.2,0-0.3,0C18.9,13,17.9,13,17.4,14.3z"/>
			<path d="M11.8,7.8C11.8,7.8,11.8,7.8,11.8,7.8c-2.4,0-4.2,1.8-4.2,4.2c0,1.1,0.4,2.2,1.2,3c0.8,0.8,1.8,1.2,3,1.2c0,0,0,0,0,0
		c2.3,0,4.2-1.9,4.2-4.2C16,9.7,14.2,7.8,11.8,7.8z M14.2,12.1c0,0.6-0.3,1.2-0.7,1.7c-0.5,0.4-1.1,0.7-1.6,0.7
		c-1.3,0-2.3-1.1-2.3-2.3c0-0.6,0.3-1.2,0.7-1.7c0.4-0.4,1-0.7,1.6-0.7c0,0,0,0,0,0c0.6,0,1.2,0.3,1.7,0.7
		C13.9,10.9,14.2,11.5,14.2,12.1z"/>
		</svg>
	);
};

export { Gear };
