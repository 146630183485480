import { styled } from 'styled-components';
import { colors, fontFamily, fontSize, fontWeight, spaces } from '../../../../ui';

const MaterialQuantityInput = styled.input`
  width: 70px;
  height: 44px;
  text-align: right;
  border: none;
  border-radius: 8px;
  margin-right: 10px;
  font-family: ${fontFamily.montserrat};
  font-size: ${fontSize.paragraph};
  background-color: ${colors.background};
  font-weight: ${fontWeight.medium};
`;

const MaterialQuantityLabel = styled.label`
  font-weight: ${fontWeight.semiBold};
`;

const MaterialQuantityInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 ${spaces.small};
`;

const MaterialQuantityInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > *:first-child {
    padding-top: ${spaces.small};
  }
`;

export {
  MaterialQuantityInput,
  MaterialQuantityLabel,
  MaterialQuantityInputWrapper,
  MaterialQuantityInputContainer,
};
