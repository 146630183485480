import { css, styled } from 'styled-components';
import { ProgressIndicator } from '../ProgressIndicator';
import { Typography, TypographyVariants } from '../Typography';
import { colors, spaces, outline } from '../../tokens';
import { ButtonVariants, buttonVariants } from './ButtonVariants';

interface ButtonStyleProps {
  $noBorder: boolean;
  $wide: boolean;
  disabled: boolean;
  $loading: boolean | number;
  $variant: ButtonVariants;
  $textVariant?: TypographyVariants;
  $width: string;
  $height: string;
  $float: 'left' | 'right' | 'none';
  $maxWidth: string;
}

const ButtonText = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease-in-out;
`;

const LoadingIcon = styled(ProgressIndicator).attrs({ size: 0 })`
  position: absolute;
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
`;

const ButtonStyle = styled.button<ButtonStyleProps>`
  border: none;
  border-radius: ${({ $noBorder }) => ($noBorder ? '0' : '5px')};
  ${({ $variant }) => {
    return buttonVariants[$variant].borderStrokeColor ?
      css`
        border: solid 2px ${buttonVariants[$variant].borderStrokeColor};
      `
      : css`
      border-bottom: solid 4px ${buttonVariants[$variant].backgroundColorBorderShadow};

      &:hover:not(:disabled) {
        border-bottom: solid 4px ${buttonVariants[$variant].backgroundColorHoverBorderShadow};
      }

      &:focus:not(:disabled) {
        border-bottom: solid 4px ${buttonVariants[$variant].backgroundColorFocusBorderShadow};
      }

      &:active:not(:disabled) {
        border-bottom: solid 4px ${buttonVariants[$variant].backgroundColorPressedBorderShadow};
      }
      `;
  }}
  position: relative;
  padding: ${spaces.xLarge};
  width: ${({ $wide, $width }) => ($wide ? '100%' : $width)};
  height: ${({ $height }) => $height};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background-color: ${({ $variant }) => buttonVariants[$variant].backgroundColor};
  pointer-events: ${({ $loading }) =>
    $loading === true || $loading === 1 ? 'none' : 'auto'};
  transition: background-color 0.4s ease;
  float: ${({ $float }) => $float};
  ${({ $maxWidth }) => $maxWidth && css`
    max-width: ${$maxWidth};
  `}

  & svg {
    fill: ${({ $variant }) => buttonVariants[$variant].textColor};
  }

  & * {
    pointer-events: none;
  }

  ${LoadingIcon} {
    visibility: ${({ $loading, disabled }) =>
    $loading && !disabled ? 'visible' : 'hidden'};
  }

  ${ButtonText} {
    visibility: ${({ $loading, disabled }) =>
    !$loading || disabled ? 'visible' : 'hidden'};
    color: ${({ $variant }) => buttonVariants[$variant].textColor};
  }

  &:disabled {
    background-color: ${({ $variant }) =>
    buttonVariants[$variant].backgroundColorDisabled};

    & svg {
      fill: ${colors.disabled};
    }

    ${ButtonText} {
      color: ${colors.disabled};
    }
  }

  &:hover:not(:disabled) {
    background-color: ${({ $variant }) =>
    buttonVariants[$variant].backgroundColorHover};
  }

  &:focus:not(:disabled) {
    outline: ${outline.default};
    background-color: ${({ $variant }) => buttonVariants[$variant].backgroundColorFocus};
  }

  &:active:not(:disabled) {
    background-color: ${({ $variant }) => buttonVariants[$variant].backgroundColorPressed};
  }
`;

export { ButtonStyle, ButtonText, LoadingIcon };
